{
  "home": {
    "title": "¡Un viaje hacia tu primer trabajo!",
    "banner": "¡Completa tus datos para postularte!",
    "ruky": "¡Hola, <b>soy Ruky</b>! <br>Te voy acompañar en cada paso de este viaje.",
    "register": "REGISTRARME",
    "login": "INGRESAR",
    "trip": {
      "title": "Inicia tu camino",
      "first": {
        "title": "Muestra tu mejor versión",
        "description": "Información sobre tu perfil y lo que te gusta"
      },
      "second": {
        "title": "Mide tus habilidades",
        "description": "Reconoce tus habilidades y súmalas a tu perfil"
      },
      "third": {
        "title": "Entrénate",
        "description": "Cursos online 100% gratuitos seleccionados para ti"
      },
      "fourth": {
        "title": "Postula",
        "description": "Ofertas laborales afines a tu perfil e intereses"
      }
    },
    "job_offers": {
      "title": "Descubre ofertas laborales",
      "view_more": "VER MÁS",
      "apply": "POSTULARME"
    },
    "skills": {
      "title": "Conócete mas",
      "description": "Desafíate. Pon en juego tus habilidades",
      "view_all": "VER TODOS",
      "game": {
        "Pending": "Pendiente",
        "Completed": "Completado",
        "InProgress": "En progreso",
        "powered": "Powered by:"
      }
    },
    "resources": {
      "title": "Prepárate para el viaje",
      "description": "Podrás capacitarte en diversas áreas laborales y asi fortalecer tus competencias para aplicar a más variedad de empleos",
      "courses": "Cuantos más cursos hagas, más chances tienes en tus postulaciones",
      "view_more": "VER MÁS"
    },
    "check-in": {
      "title": "¡No olvides hacer el check-in!",
      "description": "Estos son los requisitos",
      "card": {
        "title": "Requisitos",
        "label1": "Completar todo tu perfil",
        "label2": "Realizar los test de habilidades",
        "label3": "Revisar si hay rutas o cursos de tu interés"
      }
    },
    "sponsors": {
      "title": "Nuestros aliados"
    },
    "aboutus": {
      "description": "Motivamos a jóvenes económicamente vulnerables para que accedan a una vida de calidad a través del trabajo, el aprendizaje continuo y el compromiso con la comunidad.",
      "view_more": "QUIERO SABER MÁS DE FORGE..."
    }
  },
  "register": {
    "title": "¡HOLA!",
    "description": "¡Conozcámonos un poco más! A mí me dicen Ruky. ¿A ti? ¿Cómo te gusta que te llamen?",
    "social": "¡Conozcámonos un poco más! A mí me dicen Ruky.",
    "input_nickname": "Ingresa tu nombre o apodo",
    "validate_age": "* Debes tener entre 18 y 25 años para registrarte",
    "start": "¡COMENCEMOS!",
    "startDisabled": "COMENZAMOS ...",
    "have-account": "YA TENGO CUENTA",
    "next": "CONTINUAR",
    "nextDisabled": "PROCESANDO ...",
    "invalidInstitutionLink": "El enlace de registración es incorrecto \n\n Si ya tienes cuenta haz click",
    "step": {
      "first": {
        "title": "TUS DATOS",
        "description": "Rellena los siguientes datos para ingresar al sistema",
        "input_firstName": "Nombre",
        "input_lastName": "Apellido",
        "input_conalepNumber": "Matrícula",
        "input_email": "Mail",
        "input_age": "Edad",
        "login_with": "Ingresar con:"
      },
      "second": {
        "title": "PERFIL",
        "description": "¡Genial! Te pido algunos pocos datos más:",
        "input_gender": "Género",
        "input_birthDate": "Fecha de nacimiento",
        "input_nationality": "País de residencia"
      },
      "third": {
        "title": "SEGURIDAD",
        "description": "<i class='material-icons login__icon'>error_outline</i> Recuerde ingresar una contraseña que contenga al menos: una mayúscula, una minúscula, un número y sea mayor a 6 caracteres",
        "input_password": "Contraseña",
        "input_repeatPassword": "Repetir contraseña"
      },
      "termsAndConds": {
        "title": "ACUERDO",
        "description": "Estos son los <a style='text-decoration: underline' href='/terms.pdf' target='_blank'>términos y condiciones</a> legales de esta plataforma. Los aceptas haciendo click en el botón de Confirmar",
        "confirm": "CONFIRMAR",
        "confirmDisabled": "PROCESANDO ...",
        "viewForLogin": "Ver <a style='text-decoration: underline' href='/terms.pdf' target='_blank'>términos y condiciones</a>"
      },
      "confirm": {
        "title": "CODIGO DE VERIFICACION",
        "description": "Envié un código de verificación a tu email. Por favor ingrésalo aquí:",
        "input_code": "Código de verificación",
        "action": "ACEPTAR",
        "actionDisabled": "PROCESANDO ..."
      }
    }
  },
  "login": {
    "title": "¡HOLA!",
    "description": "¡Es un gusto tenerte aquí nuevamente!",
    "input_email": "Mail ",
    "input_password": "Contraseña",
    "forgot-password": "Olvidé mi contraseña",
    "login-with": "Ingresar con:",
    "action": "INGRESAR",
    "actionDisabled": "INGRESANDO ...",
    "create-account": "CREAR CUENTA",
    "recoveryPassword": {
      "title": "RECUPERAR CONTRASEÑA",
      "description": "Ingresa el email con el que estás registrado",
      "endDescription": "Envié un código de verificación a tu email. Por favor ingrésalo aquí:",
      "action": "CONTINUAR"
    }
  },
  "learning": {
    "title": "Prepárate para el viaje",
    "description": "Podrás capacitarte en áreas laborales para estar mejor preparado para postular a empleos",
    "tabs": {
      "routes": "RUTAS DE APRENDIZAJE",
      "courses": "CURSOS"
    },
    "routes": {
      "news": "Nuevas rutas",
      "all": "Todas las rutas",
      "view_more": "VER MÁS"
    },
    "courses": {
      "news": "Nuevos cursos",
      "all": "Todos los cursos"
    },
    "detail_course": {
      "level": "NIVEL:",
      "start": "¡COMENZAR!"
    },
    "detail_route": {
      "content": "Contenido de la ruta",
      "view_detail": "VER DETALLE"
    }
  },
  "learning_card": {
    "courses_include": "Incluye {count} curso{plural}"
  },
  "work": {
    "tabs": {
      "jobOffers": "ofertas laborales",
      "myApplications": "mis postulaciones",
      "saved": "ofertas guardadas"
    },
    "status": {
      "Ok": "Postulación realizada",
      "Viewed": "Perfil visto",
      "Hired": "Seleccionado",
      "NotHired": "No Seleccionado",
      "NotInterviewed": "Aún no te han entrevistado",
      "ContactDataRequired": "Datos de contacto solicitados"
    },
    "detailJob": {
      "actionsModal": {
        "save": "guardar",
        "discard": "descartar",
        "share": "compartir"
      },
      "state": "Etapa",
      "descriptionJob": "Descripción del puesto",
      "contractType": "Tipo de contrato",
      "seniority": "Seniority",
      "modality": "Modalidad",
      "required": "Requerimientos",
      "skills": "Habilidades",
      "beneffits": "Beneficios",
      "aboutJob": "sobre la empresa",
      "goSite": "Ir al sitio web",
      "apply": "POSTULARME",
      "applied": "YA TE POSTULASTE",
      "salaryAround": "Salario en torno a",
      "educationLevel": "Mínimo nivel educativo",
      "gender": "Género",
      "educationLevelLabel": {
        "DoesNotApply": "No aplica",
        "CurrentHighSchool": "Secundario en curso",
        "FinishHighSchool": "Secundario completo",
        "CurrentUniversity": "Universitario en curso",
        "FinishUniversity": "Universitario completo"
      }
    },
    "jobOffers": {
      "results": "RESULTADOS DE TU BÚSQUEDA"
    },
    "myapplications": {
      "active": "Activas",
      "finished": "Finalizadas",
      "state": "Etapa"
    },
    "diffDates": {
      "today": "Publicado hoy",
      "yesterday": "Publicado ayer",
      "days": "Publicado hace {diff} días"
    }
  },
  "profile": {
    "from": "Desde",
    "to": "Hasta",
    "present": "Actualidad",
    "finish": "Finalizar",
    "available_from": "Disponible a partir del",
    "myInformation": {
      "language": {
        "basic": "Básico",
        "intermediate": "Intermedio",
        "advanced": "Avanzado"
      },
      "personalInformation": {
        "title": "MIS DATOS PERSONALES",
        "nickName": "Apodo",
        "firstName": "Nombre/s",
        "lastName": "Apellido/s",
        "identification": "RUT",
        "birthDate": "Fecha de nacimiento",
        "gender": "Género",
        "selfPerceivedGender": "Género Autopercibido",
        "nationality": "País de nacimiento",
        "infoContact": "Información de contacto",
        "otherPhone": "Teléfono Particular",
        "mobilePhone": "Celular",
        "email": "Email",
        "location": "Ubicación",
        "country": "País de Residencia",
        "state": "Región",
        "city": "Provincia",
        "neighborhood": "Comuna",
        "address": "Dirección",
        "select": "Seleccione"
      },
      "academics": {
        "title": "MI FORMACION",
        "secondary": "Colegio",
        "academics": "Terciario / Universitario",
        "languages": "Idiomas",
        "label": "No tienes información cargada"
      },
      "jobs": {
        "title": "EXPERIENCIA LABORAL",
        "label": "No tienes información cargada"
      }
    },
    "myBestVersion": {
      "activities": "AGREGAR  ACTIVIDAD",
      "externalCourses": "AGREGAR CURSO",
      "volunteerings": "AGREGAR VOLUNTARIADO",
      "modalDelete": {
        "activities": "de tus actividades",
        "externalCourses": "de tus cursos",
        "volunteerings": "de tu voluntariado",
        "videos": "de tus videos",
        "technologicalTools": "de tus herramientas tecnológicas"
      },
      "tooltip": {
        "changeData": "¿NECESITAS CAMBIAR ALGÚN DATO? Ponte en contacto con tu facilitador",
        "interest": "¿Qué te gusta hacer? Permite a quienes se interesen en tu perfil que te conozcan más.",
        "photos": "Puedes compartir hasta 5 imágenes que complementen tu perfil y muestren más de tí y de lo que te gusta",
        "videos": "Puedes compartir hasta 5 videos que hayas subido a Youtube y les permita a las empresas conocerte mejor",
        "otherActivities": "Cuéntanos sobre actividades que realizas o realizaste que crees que puedan dar más información sobre tus valores e intereses. Por ejemplo, si haces deporte, si integras un equipo, si dibujas o haces música",
        "externalCourses": "Cuéntanos que cursos estas realizando o ya realizaste afuera de Ruky en forma presencial o en algún portal de estudio. Por ejemplo: curso de fotografía",
        "volunteerings": "¿Realizas o realizaste alguna acción de voluntariado? ¿Participas de alguna acción para ayudar a los demás o mejorar tu comunidad? cuéntanos más detalles",
        "technologicalTools": "Si sabes utilizar ciertas herramientas tecnológicas ya sea en tu computadora o celular completa la lista con cuales utilizas, por ejemplo, un procesador de textos o una planilla de cálculo.",
        "skills": "Cuéntale a las empresas cuáles son tus habilidades. Por ejemplo, que te destacas por tu comunicación, autonomía o responsabilidad."
      }
    },
    "certificates": {
      "name": "Nombre del referente",
      "type": "Tipo de relación",
      "add": "+ agregar "
    },
    "my_education": {
      "title": "MI FORMACIÓN",
      "none_information": "No tienes información cargada",
      "secondary": "Colegio",
      "educationName": "Nombre de institución",
      "educationInstitution": "Institución Educativa",
      "educationSpeciality": "Especialidad técnica",
      "nameSpeciality": "Nombre de especialidad",
      "from": "Desde",
      "to": "Hasta",
      "academic": "Terciario / Universitario",
      "subjectsLefts": "Materias pendientes",
      "averageScore": "Promedio",
      "language": "Idioma",
      "detail": "Detalle",
      "languages": "Idiomas",
      "level": "Nivel",
      "add": "+ agregar ",
      "other": "Otra",
      "select": "Seleccione",
      "inProgress": "En curso",
      "finished": "Finalizado",
      "true": "Sí",
      "false": "No",
      "levelBasic": "Básico",
      "levelIntermediate": "Intermedio",
      "levelAdvanced": "Avanzado",
      "delete": "Eliminar",
      "addNewEducation": "AGREGAR NUEVA FORMACIÓN",
      "editEducation": "EDITAR EDUCACIÓN",
      "cancel": "CANCELAR",
      "save": "AGREGAR",
      "questionDelete": "¿Eliminar <strong>{name}</strong> de tu formación?"
    },
    "work_experience": {
      "title": "EXPERIENCIA LABORAL",
      "none_information": "No tienes información cargada",
      "position": "Puesto",
      "organization": "Empresa",
      "from": "Desde",
      "to": "Hasta",
      "current": "Actualmente",
      "modalNew_current": "Actualmente en este puesto",
      "select": "Seleccione",
      "contractType": "Modalidad de contratación",
      "description": "Descripción",
      "other": "Otro",
      "positionName": "Nombre del puesto",
      "organizationName": "Nombre de empresa",
      "titleModal": "AGREGAR  TRABAJO",
      "cancel": "CANCELAR",
      "save": "AGREGAR",
      "questionDelete": "¿Eliminar <strong>{name}</strong> de tu experiencia laboral?",
      "true": "Sí",
      "false": "No",
      "add": "+ agregar ",
      "delete": "Eliminar"
    },
    "tabs": {
      "resume": "resumen",
      "myBestVersion": "mi mejor versión",
      "myInformation": "mis datos",
      "myCV": "mi cv"
    },
    "my_best_version": {
      "whoAmI": {
        "title": "¿QUIÉN SOY?",
        "label": "No tienes información cargada"
      },
      "whatLookNextJob": {
        "title": "¿QUÉ BUSCO EN MI PRÓXIMO EMPLEO?",
        "label": "No tienes información cargada"
      },
      "whatMakesMeUniqueEdit": {
        "title": "¿QUÉ ME HACE ESPECIAL?",
        "label": "No tienes información cargada"
      },
      "photos": {
        "title": "MIS FOTOS",
        "label": "Sube hasta 5 fotos que cuenten más acerca de ti",
        "add": "+ agregar foto",
        "added": "Subida"
      },
      "video": {
        "title": "MIS VIDEOS",
        "label": "Agrega hasta 5 videos para que te conozcan mejor",
        "add": "+ Subir video",
        "link": "www.youtube.com/..."
      },
      "otherActivities": {
        "title": "ARTE Y DEPORTE",
        "label": "No tienes información cargada",
        "taskDone": "Tarea realizada",
        "activityName": "Actividad",
        "description": "Descripción",
        "tellUsMore": "Cuéntanos más",
        "link": "Link",
        "loadLink": "¿Te gustaría subir un link mostrándolo?",
        "howLong": "¿Cuánto tiempo lo hiciste?",
        "add": "+ agregar actividad",
        "current": "Realizando esta actividad actualmente",
        "delete": "Eliminar"
      },
      "externalCourses": {
        "title": "CURSOS",
        "label": "No tienes información cargada",
        "certificate": "Certificado",
        "name": "Nombre",
        "institution": "Institución",
        "linkCertificate": "Link del certificado",
        "linkInstitution": "Link de la institución",
        "current": "Realizando este curso actualmente",
        "add": "+ agregar cursos"
      },
      "volunteerings": {
        "title": "VOLUNTARIADO",
        "label": "No tienes información cargada",
        "taskDone": "Tarea realizada",
        "name": "Nombre",
        "description": "Descripción",
        "link": "Link",
        "add": "+ agregar voluntariado",
        "current": "Realizando este voluntariado actualmente",
        "institution": "Institución"
      },
      "technologicalTools": {
        "title": "HERRAMIENTAS TECNOLOGICAS",
        "label": "No tienes información cargada",
        "technologicalTool": "Herramienta tecnológica",
        "add": "+ Agregar herramienta tecnológica"
      },
      "interest": {
        "title": "MIS INTERESES",
        "label": "Agrega tus intereses.",
        "modal": {
          "title": "AGREGA TUS INTERESES",
          "add": "AGREGAR"
        }
      },
      "skills": {
        "title": "MIS HABILIDADES",
        "label": "Agrega tus habilidades.",
        "modal": {
          "title": "AGREGA TUS HABILIDADES",
          "add": "AGREGAR"
        }
      },
      "modalDelete": "Eliminar",
      "accept": "ACEPTAR",
      "cancel": "CANCELAR",
      "save": "GUARDAR"
    },
    "resume": {
      "mySkills": "MIS HABILIDADES",
      "videoInterview": "VIDEO ENTREVISTA",
      "poweredBy": "Powered by",
      "takeTest": "Realizar test",
      "myCertifications": "MIS CERTIFICADOS",
      "modalCertificatesDescription": "Si tienes un diploma o certificado, físico o digital, puedes cargarlo aquí adjuntando una imagen o pegando el link.",
      "labelCertifications": "Proximamente aquí vas a poder encontrar tus certificados.",
      "references": "REFERENCIAS",
      "labelReferences": "Proximamente aquí vas a poder solicitar a tus referentes que escriban unas palabras para que las empresas sepan cómo eres.",
      "labelLearningProgress": "Aquí podrás ver los cursos y rutas de aprendizaje en los que has mostrado interés, y marcar si los finalizaste.",
      "viewResults": "Ver Resultados",
      "testForge": {
        "name": "Evaluación Forge",
        "presence": "Presencia",
        "autocritic": "Autocrítica",
        "communication": "Comunicación",
        "empathy": "Empatía",
        "autonomy": "Autonomía",
        "effort": "Esfuerzo",
        "responsability": "Responsabilidad"
      }
    }
  },
  "social_register": {
    "login-with": "Ingresar con {type}",
    "register-with": "Registrarme con {type}",
    "complete-with": "Completar con {type}"
  },
  "notifications": {
    "Invalid hash": "Error. Los datos no coinciden.",
    "errorLoginOption": "Link inválido",
    "errorGeneric": "Ocurrió un error",
    "invalidUserOrPassword": "Usuario o contraseña inválido",
    "userNotAvtivated": "Usuario no activado",
    "userAlreadyExists": "El usuario ya existe",
    "emailNotAvailable": "El email ingresado ya está en uso",
    "invalidMail": "Email inválido",
    "invalidCode": "Código inválido",
    "invalidUser": "Usuario inválido",
    "recoveryPassword": "Se recuperó la contraseña correctamente",
    "cant-login-with-google": "No se puede ingresar con Google",
    "userNotRegister": "Usuario no registrado. Si no lo logras, puedes contactarte con Forge",
    "updatePersonalInformation": "Se actualizó la información correctamente",
    "updatePersonalInformationPicture": "Se subió correctamente la imagen",
    "updateAboutMe": {
      "whatMakesMeUnique": "Se actualizó la presentación correctamente",
      "whoIAm": "Se actualizó quién soy correctamente",
      "whatIExpectFromNextJob": "Se actualizó que busco en mi proximo empleo correctamente",
      "profileInterest": "Se actualizaron los intereses correctamente",
      "skills": "Se actualizaron las habilidades correctamente",
      "photos": "Se actualizó mis fotos correctamente",
      "videos": "Se actualizó mis videos correctamente",
      "activities": "Se actualizó otras actividades correctamente",
      "externalCourses": "Se actualizó cursos correctamente",
      "volunteerings": "Se actualizó voluntariado correctamente",
      "technologicalTools": "Se actualizó herramientas tecnológicas correctamente",
      "remove": {
        "whatMakesMeUnique": "Se eliminó la presentación correctamente",
        "whoIAm": "Se eliminó quién soy correctamente",
        "whatIExpectFromNextJob": "Se eliminó que busco en mi próximo empleo correctamente",
        "profileInterest": "Se eliminó un interesés correctamente",
        "skills": "Se eliminó una habilidad correctamente",
        "photos": "Se eliminó la foto correctamente",
        "videos": "Se eliminó el video correctamente",
        "activities": "Se eliminó la actividad correctamente",
        "externalCourses": "Se eliminó el curso correctamente",
        "volunteerings": "Se eliminó voluntariado correctamente",
        "technologicalTools": "Se eliminó la herramienta tecnológica correctamente"
      }
    },
    "updateJobs": "Se actualizó los empleos correctamente",
    "updateStudy": "Se actualizó la formación correctamente",
    "wishes_remove": "Se descartó de favoritos la oferta laboral",
    "wishes_add": "Se agregó a favoritos la oferta laboral",
    "completeRequiredFields": "Complete todos los campos obligatorios",
    "CANNOT_DELETE_MAIN_CV": "No es posible eliminar este CV porque es el CV principal."
  }
}
