<template>

<div class="modal__overlay" @click.stop="showPreview = false" :style="{visibility: !showPreview ? 'hidden' : 'visible'}" >
  <i @click.stop="showPreview = false" class="material-icons">close</i>
  <div class="cv-container" @click.stop="''">
    <Vue3Html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="9999"
      filename="CV"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      @beforeDownload="beforeDownload($event)"
    >
      <template v-slot:pdf-content>
        <!-- PDF Content Here -->
        <div
          id="cv-container"
          style="display: grid; grid-template-columns: 30% 1fr; padding: 0; padding-right: 80px;"
          >
          <div style="padding:26px; background:#2566AF; color: #fff;">
            <div v-if="CVModel.withPicture" style="margin: 0 auto; margin-bottom: 32px; width: 150px; height: 150px; border-radius: 150px; overflow: hidden;">
              <img
              :src="getImage(CVModel.picture, CVModel.isPictureFromCDN)"
              style="display: block; margin-left: auto; margin-right: auto; width: 150px;"
              />
            </div>
            <div style="font-size: 24px; font-weight: 800; margin-bottom: 18px">
              {{ CVModel.fullName.toUpperCase() }}
            </div>
            <div style="font-size: 16px; margin-bottom: 32px">
              {{ getAge(CVModel.birthDate) }} AÑOS
            </div>
            <div style="font-size: 16px; font-weight: 800; margin-bottom: 18px">
              DATOS DE CONTACTO
            </div>
            <div style="font-size: 12px; margin-bottom: 12px; display:flex; align-items:center; gap:8px">
              <i class="material-icons" style="font-size:18px">phone_iphone</i>
              {{ CVModel.mobilePhone }}
            </div>
            <div style="font-size: 12px; margin-bottom: 12px; display:flex; align-items:center; gap:8px; word-break: break-word">
              <i class="material-icons" style="font-size:18px">markunread</i>
              {{ CVModel.email }}
            </div>
            <div style="font-size: 12px; margin-bottom: 32px; display:flex; align-items:center; gap:8px">
              <i class="material-icons" style="font-size:19px">room</i>
              {{ CVModel.neighborhood + (CVModel.neighborhood != '' ? ', ' : '') + CVModel.city }}
            </div>
            <div style="font-size: 16px; font-weight: 800; margin-bottom: 18px">
              HERRAMIENTAS
            </div>
            <div
              style="display:flex;flex-wrap: wrap;gap:12px; margin-bottom: 32px;"
            >
              <template
                v-for="(tool, idx) in CVModel.listTechnologicalTools"
                :key="idx"
              >
                <div
                  style="width:fit-content; flex-grow:1; border-radius:44px; background:#F6F6F6;padding:8px 16px 8px 13px; color:#2566AF;text-align:center; font-size: 14px; font-weight: 600;"
                >
                  {{ tool }}
                </div>
              </template>
            </div>

            <div id="interest" v-if="CVModel.listProfileInterest.length">
              <div style="font-size: 16px; font-weight: 800; margin-bottom: 18px">
                INTERESES
              </div>
              <div
                style="display:flex;flex-wrap: wrap;gap:12px; margin-bottom: 32px;"
              >
                <template
                  v-for="(tool, idx) in CVModel.listProfileInterest"
                  :key="idx"
                >
                  <div
                    style="width:fit-content; flex-grow:1; border-radius:44px; background:#F6F6F6;padding:8px 16px 8px 13px; color:#2566AF;text-align:center; font-size: 14px; font-weight: 600;"
                  >
                    {{ tool.name }}
                  </div>
                </template>
              </div>
            </div>

            <div id="volunteerings" v-if="CVModel.listVolunteerings.some((el) => el.checked)">
              <template v-for="(vol, volIndex) in CVModel.listVolunteerings" :key="vol.id">
                <div v-if="vol.checked" style="margin-bottom:16px;" id="volunteerings-element">
                  <div style="font-size: 16px; font-weight: 800; margin-bottom: 18px" v-if="volIndex == 0">VOLUNTARIADO</div>
                  <div style="display:flex; align-items:center;margin-bottom:4px;">
                    <div style="width:40px;height:22px;">
                      <i class="material-icons" style="color:#fff; font-size:22px;">radio_button_checked</i>
                    </div>
                    <div style="width:fit-content; height:22px; display:flex; align-items:center; font-size: 14px; font-weight: 700;">{{ vol.institution }}</div>
                  </div>
                  <div style="display:flex;">
                    <div style="width:40px;"></div>
                    <div>
                      <div style="margin:4px 0; font-size: 14px; font-weight: 400;">{{ vol.name }}</div>
                      <div style="margin:4px 0; font-size: 14px; font-weight: 400;">
                        {{ vol.yearFrom }} -
                        {{ vol.yearTo ?? $t('profile.present') }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

          </div>
          
          <div style="padding:32px; background: #fff;" id="cv">
            <!-- Perfil y objetivo laboral -->
            <div style="padding-top:28px;">
              <div style="font-size:20px; font-weight:700; margin-bottom:18px;">
                Perfil y objetivo profesional
              </div>
              <div style="font-size:14px; font-weight:400;">
                {{ CVModel.whoIAm }}
              </div>
              <div style="font-size:14px; font-weight:400;">
                {{ CVModel.whatMakesMeUnique }}
              </div>
              <div style="font-size:14px; font-weight:400;">
                {{ CVModel.whatIExpectFromNextJob }}
              </div>
            </div>

            <div id="skills" v-if="CVModel.listSkills.length" style="padding-top:28px;">
              <div style="font-size:20px; font-weight:700; margin-bottom:18px;">
                Habilidades
              </div>
              <div style="display:flex; flex-wrap:wrap; gap:12px">
                <div
                  v-for="(skill, idx) in CVModel.listSkills"
                  :key="idx"
                  style="background: #F6F6F6; border: 1px solid #2566AF; border-radius: 44px; padding: 8px 25px 8px 29px; color: #2566AF; font-size:14px; font-weight: 600;"
                >
                  {{ skill.skillName }}
                </div>
              </div>
            </div>

            <!-- Estudios -->
            <div id="academics" v-if="CVModel.listAcademics.some((el) => el.checked)" style="padding-top:28px;">
              <div style="font-size:20px; font-weight:700; margin-bottom:18px;">Formación</div>
              <template v-for="(academic, idx) in CVModel.listAcademics.filter(academ => academ.checked)" :key="academic.id">
                <div v-if="academic.studyLevel >= 3" style="margin-bottom:16px; position: relative;" class="academics-element">
                  <div style="display:flex; align-items:center; margin-bottom:4px;">
                    <div style="width:40px;height:22px;">
                      <i class="material-icons" style="color:#2566AF; font-size:22px;">radio_button_checked</i>
                      <div v-if="idx !== CVModel.listAcademics.filter(academ => academ.checked).length - 1" class="dot-line"></div>
                    </div>
                    <div style="width:106px; height:22px; display:flex; align-items:center; justify-content:center; background:#EAE8FF; border-radius: 4px; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1); font-size: 11px; font-weight: 800; color: #2566AF;">
                      UNIVERSITARIO
                    </div>
                  </div>
                  <div style="display:flex;">
                    <div style="width:40px;"></div>
                    <div>
                      <div style="margin:4px 0; font-size: 14px; font-weight: 700;">
                        {{
                          academic.educationalInstitutionId == null
                            ? academic.educationalInstitutionOther
                            : getInstitution(academic.educationalInstitutionId)
                        }}
                      </div>
                      <div style="margin:4px 0; font-size: 14px; font-weight: 400;">
                        {{
                          academic.educationSpecialtyId == null
                            ? academic.educationSpecialtyOther
                            : educationSpecialty(
                                academic.educationSpecialtyId,
                                academic.studyLevel >= 3 ? 2 : 1
                              )
                        }}
                      </div>
                      <div style="margin:4px 0; font-size: 14px; font-weight: 400;">
                        {{ academic.fromYear }} -
                        {{
                          academic.toYear == null
                            ? $t('profile.present')
                            : academic.toYear
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              <!-- </template>
              <template v-for="academic in CVModel.listAcademics" :key="academic.id"> -->
                <div v-if="academic.studyLevel <= 2" style="margin-bottom:16px;" class="academics-element">
                  <div style="display:flex; align-items:center; margin-bottom:4px;">
                    <div style="width:40px;height:22px;">
                      <i class="material-icons" style="color:#2566AF; font-size:22px;">radio_button_checked</i>
                      <div v-if="idx !== CVModel.listAcademics.filter(academ => academ.checked).length - 1" class="dot-line"></div>
                    </div>
                    <div
                      style="width:106px; height:22px; display:flex; align-items:center; justify-content:center; background:#EAE8FF; border-radius: 4px; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1); font-size: 11px; font-weight: 800; color: #2566AF;"
                    >
                      SECUNDARIO
                    </div>
                  </div>
                  <div style="display:flex;">
                    <div style="width:40px;"></div>
                    <div>
                      <div
                        style="margin:4px 0; font-size: 14px; font-weight: 700;"
                      >
                        {{
                          academic.educationalInstitutionId == null
                            ? academic.educationalInstitutionOther
                            : getInstitution(academic.educationalInstitutionId)
                        }}
                      </div>
                      <div style="margin:4px 0; font-size: 14px; font-weight: 400;">
                        {{
                          academic.educationSpecialtyId == null
                            ? academic.educationSpecialtyOther
                            : educationSpecialty(
                                academic.educationSpecialtyId,
                                academic.studyLevel == 3 ? 2 : 1
                              )
                        }}
                      </div>
                      <div style="margin:4px 0; font-size: 14px; font-weight: 400;">
                        {{ academic.fromYear }} -
                        {{
                          academic.toYear == null
                            ? $t('profile.present')
                            : academic.toYear
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <!-- Experiencia laboral -->
            <div id="jobs" v-if="CVModel.listJobs.some((el) => el.checked)" style="padding-top:28px;">
              <template v-for="(job, jobIndex) in CVModel.listJobs.filter((el) => el.checked)" :key="job.id">
                <div style="margin-bottom:16px;" class="jobs-element">
                  <div style="font-size:20px; font-weight:700; margin-bottom:18px;" v-if="jobIndex == 0">Experiencia laboral</div>
                  <div style="display:flex; align-items:center; margin-bottom:4px;">
                    <div style="width:40px;height:22px;">
                      <i class="material-icons" style="color:#2566AF; font-size:22px;">radio_button_checked</i>
                    </div>
                    <div style="width:fit-content; padding:0 12px; height:22px; display:flex; align-items:center; background:#EAE8FF; border-radius: 4px; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1); font-size: 11px; font-weight: 800; color: #2566AF;">
                      {{ getContractType(job.employmentContractTypeId).toUpperCase() }}
                    </div>
                  </div>
                  <div style="display:flex;">
                    <div style="width:40px;"></div>
                    <div>
                      <div style="margin:4px 0; font-size: 14px; font-weight: 700;">
                        {{
                          job.positionId == null || job.positionId == -1
                            ? job.positionOther
                            : job.positionName
                        }} -
                        {{
                          job.organizationId == null || job.organizationId == -1
                            ? job.organizationOther
                            : job.organizationName
                        }}
                      </div>
                      <div style="margin:4px 0; font-size: 14px; font-weight: 400;">
                        {{ job.yearFrom }} - {{ job.yearTo ?? $t('profile.present') }}
                      </div>
                      <div style="margin:4px 0; font-size: 14px; font-weight: 400;">
                        {{ job.description }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div v-else id="jobs-element" style="padding-top: 28px;">
              <div style="font-size:20px; font-weight:700; margin-bottom:18px;">Experiencia laboral</div>
              <div style="display: flex; flex-direction: row; background: #e9e7f9; padding: 8px 16px; gap: 8px; border-radius: 12px;">
                <i class="material-icons" style="color: #2566af">work</i>
                {{ CVModel.textNotWork }}
              </div>
            </div>
            
            <!-- Idiomas -->
            <div id="languages" v-if="CVModel.listLanguages.some((el) => el.checked)" style="padding-top:28px; padding-right: 12px;">
              <div style="font-size:20px; font-weight:700; margin-bottom:18px;">Idiomas</div>
              <div style="display:flex; gap:100px">
                <template v-for="lang in CVModel.listLanguages.filter((el) => el.checked)" :key="lang.id">
                  <div style="margin-bottom:16px;">
                    <div style="display:flex; align-items:center;margin-bottom:4px;">
                      <div style="width:40px;height:22px;">
                        <i class="material-icons" style="color:#2566AF; font-size:22px;">radio_button_checked</i>
                      </div>
                      <div style="width:fit-content; height:22px; display:flex; align-items:center; font-size: 14px; font-weight: 700;">
                        {{ lang.languageKnowledgeName }}
                      </div>
                    </div>
                    <div style="display:flex;">
                      <div style="width:40px;"></div>
                      <div>
                        <div style="font-size: 14px; font-weight: 400;">
                          {{ $t(`profile.myInformation.language.${lang.level}`) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <!-- Cursos -->
            <div id="courses" v-if="CVModel.listExternalCourses.some((el) => el.checked)" style="padding-top:28px;">
              <template v-for="(course, courseIndex) in CVModel.listExternalCourses.filter(course => course.checked)" :key="course.id">
                <div style="margin-bottom:16px;" class="courses-element">
                  <div style="font-size:20px; font-weight:700; margin-bottom:18px;" v-if="courseIndex == 0">Otros cursos</div>
                  <div style="display:flex; align-items:center;">
                    <div style="width:40px;height:22px;">
                      <i class="material-icons" style="color:#2566AF; font-size:22px;">radio_button_checked</i>
                    </div>
                    <div style="width:fit-content; display:flex; align-items:center; font-size: 14px; font-weight: 700;">{{ course.institution }}</div>
                  </div>
                  <div style="display:flex;">
                    <div style="width:40px;"></div>
                    <div>
                      <div style="font-size: 13px; font-weight: 400;">
                        {{ course.yearFrom }} -
                        {{ course.yearTo ?? $t('profile.present') }}
                      </div>
                      <div style="font-size: 14px; font-weight: 400;">{{ course.name }}</div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </Vue3Html2pdf>
  </div>
</div>

<div class="actions-container">
  <button class="btn-preview" @click="showPreview = true"><span>Previsualizar CV</span><i class="material-icons">remove_red_eye</i></button>

  <div class="next-back-btns">
    <button class="back" @click="$emit('back')" :disabled="uploadingCv"><i class="material-icons">arrow_back</i>Atrás</button>
    <button class="next" @click="generateReport()" :disabled="uploadingCv">{{uploadingCv ? 'Generando CV...' : 'Generar CV'}} <i class="material-icons">arrow_forward</i></button>
  </div>
</div>


</template>

<script>
import { ref, onMounted } from 'vue';
import Vue3Html2pdf from 'vue3-html2pdf';
import storage from '@/plugins/azure-storage';
import { useStore } from 'vuex';
import moment from 'moment';

export default {
  props: {
    CVModel: Object,
  },
  components: {
    Vue3Html2pdf,
  },
  setup(props) {
    const store = useStore();
    const getAge = (birthDate) => moment().diff(moment(birthDate), 'years');
    const html2Pdf = ref(null);
    const showPreview = ref(false);
    const uploadingCv = ref(false);
    // const cv = ref(null)
  

    function generateReport() {
      html2Pdf.value.generatePdf();
    }

    function getImage(path, isFromCDN) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      }
      return path ? `${process.env.VUE_APP_FORGE_MEDIA_URL}${path}` : null;
    }

    async function beforeDownload({ html2pdf, options, pdfContent }) {
      uploadingCv.value = true;
      
      // Si el elemento cae en el corte de pagina, agregarle un margin top
      const skillsElement = document.getElementById('skills');
      const academicsElement = document.getElementsByClassName('academics-element');
      const jobsElement = document.getElementsByClassName('jobs-element');
      const languagesElement = document.getElementById('languages');
      const coursesElement = document.getElementsByClassName('courses-element');
      const allElements = [skillsElement, ...academicsElement, ...jobsElement, languagesElement, ...coursesElement];
      let statusContentWhite = 'first';
      let statusContentBlue = 'first';
      allElements.forEach((element, index) => {
        if (!element) return;
        
        let previousElement;
        for (let i = index - 1; i >= 0; i--)
        {
          previousElement = allElements[i];
          if (previousElement){
            break;
          }
        }

        if (!previousElement) {
          return;
        }

        if ((element.offsetTop + element.offsetHeight) > 2180) {
          if (index > 1 && statusContentWhite == 'third') {
            element.style.marginTop = `${(previousElement.offsetTop + previousElement.offsetHeight + 24) - element.offsetTop}px`;
          } else {
            element.style.marginTop = `${2288 - element.offsetTop}px`;
            statusContentWhite = 'third';
          }
        } else if ((element.offsetTop + element.offsetHeight) > 1060) {
          if (index > 1 && statusContentWhite == 'second') {
            element.style.marginTop = `${(previousElement.offsetTop + previousElement.offsetHeight + 24) - element.offsetTop}px`;
          } else {
            element.style.marginTop = `${1200 - element.offsetTop}px`;
            statusContentWhite = 'second';
          }
        }
      })
      // Lateral Azul
      const interestElement = document.getElementById('interest');
      const volunteeringsElement = document.getElementsByClassName('volunteerings-element');
      [interestElement, ...volunteeringsElement].forEach((element, index) => {
        if (!element) return;
        if ((element.offsetTop + element.offsetHeight) > 1060) {
          if (index > 1 && statusContentBlue == 'second') {
            element.style.marginTop = `${(interestElement.offsetTop + interestElement.offsetHeight + 24) - element.offsetTop}px`;
          } else {
            element.style.marginTop = `${1200 - element.offsetTop}px`;
            statusContentBlue = 'second';
          }
        }
      })

      // CV
      const cvElement = document.getElementById('cv');
      const cvContainerElement = document.getElementById('cv-container');

      if (cvContainerElement.offsetHeight > 2244) {
        cvElement.style.minHeight = '3366px';
      } else if (cvContainerElement.offsetHeight > 1122) {
        cvElement.style.minHeight = '2244px';
      } else {
        cvElement.style.minHeight = '1122px';
      }


      try {
        const fileData = await html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .get('pdf')
          .output();
        const byteNumbers = new Array(fileData.length);
        for (let i = 0; i < fileData.length; i++) {
          byteNumbers[i] = fileData.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const file = new File([blob], 'cv.pdf');
        const fileNamePDF = await storage.updateFile(file);
        const model = {
          fileUrl: fileNamePDF,
          fileSize: file.size + 'KB',
          name: `CV-${props.CVModel.fullName}`,
          description: props.CVModel.ref,
          createdOnRuky: true
        };
        await store.dispatch('profile_postCVs',model);
        location.reload();
      } catch (error) {
        console.log(error);
        store.dispatch("notifications_create", {
          text: "Error al intentar guardar CV",
        });
        uploadingCv.value = false;
      }
    }

    let listeducationSpecialtyType1 = ref([]);
    let listeducationSpecialtyType2 = ref([]);
    let listEducationalInstitution = ref([]);
    function educationSpecialty(specialityId, type) {
      const list = type === 1 ? listeducationSpecialtyType1 : listeducationSpecialtyType2;
      const speciality = list.value.find((s) => s.id == specialityId);
      return speciality ? speciality.name : '-';
    }
    function getInstitution(institutionId) {
      const institution = listEducationalInstitution.value.find(
        (i) => i.id == institutionId
      );
      return institution ? institution.name : '-';
    }
    function getContractType(id) {
      return store.getters.get_employmentContractTypes(id);
    } 

    onMounted(async () => {
      listeducationSpecialtyType1.value = await store.dispatch(
        'stuff_educationSpecialty',
        1
      );
      listeducationSpecialtyType2.value = await store.dispatch(
        'stuff_educationSpecialty',
        2
      );
      listEducationalInstitution.value = await store.dispatch(
        'stuff_educationalInstitution'
      );
    });

    return {
      html2Pdf,
      generateReport,
      getImage,
      beforeDownload,
      educationSpecialty,
      getInstitution,
      getContractType,
      getAge,
      showPreview,
      uploadingCv,
      // cv
    };
  },
};
</script>

<style lang="scss">
section{
  padding: 0;
}
.actions-container {
  padding: 10px 0;
  .btn-preview{
    padding: 0;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    span{
      font-size: 16px;
      font-weight: 600;
      color: black;
    }
  }
  .next-back-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 28px 0px;
    gap: 36px;
    button {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 600;
      .material-icons {
        font-size: 18px;
      }
      &:disabled {
        opacity: 0.7;
        cursor: default;
      }
    }
    .next {
      padding: 10px 22px 10px 36px;
      background: $orange;
      color: #fff;
      border-radius: 44px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    }
  }
}
.modal__overlay{
  height: 100vh;
  overflow-y: auto;
  > .material-icons{
    position: absolute;
    cursor: pointer;
    top: 12px;
    right: 12px;
    color: #fff;
  }
}
.cv-container{ 
  height: 90vh;
  position: absolute;
  width: fit-content;
  @media (max-width: $break-md){
    height: 130%;
    transform: scale(.7);
  }
  @media (max-width: $break-sm){
    height: 185%;
    transform: scale(.5);
  }
  @media (max-width: 530px){
    height: 225%;
    transform: scale(.4); 
  }
  //overflow-x: visible;
  .vue-html2pdf {
    //position: relative !important;
    //height: fit-content !important;
    width: 840px;
  }
  .layout-container {
    position: relative !important;
    width: auto !important;
    padding: 0;
    border-radius: 10px;
    box-shadow: 5px 5px 35px #d9d9d9;
    .content-wrapper {
      padding: 0;
      width: 100% !important;
    }
  }
}

.dot-line{
    width: 1px;
    border-left: 0px solid rgb(37, 102, 175);
    border-style: dotted;
    height: 94px;
    position: absolute;
    left: 10.3px;
    top: 21px;
    color: rgb(37, 102, 175);
}


</style>
