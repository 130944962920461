<template>
  <VisibleModal v-if="show"/>
  <div class="modal__overlay" @click.stop="close()" v-if="show">
    <div class="modal modal-register" @click.stop="''">
      <span class="material-icons" @click.stop="close()">close</span>
      <img src="@/assets/passport.svg" alt="">
      <h3>¡Registrate!</h3>
      <p>Es necesario que inicies sesión o te registres para navegar por el sitio</p>
      <div class="modal__buttons">
        <button @click.stop="close()" class="button--gray">ahora no</button>
        <button @click.stop="login()">ingresar</button>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import VisibleModal from '@/components/Common/VisibleModal.vue';

export default {
  components: {
    VisibleModal
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const show = computed(() => store.getters.manageUser_forgeLogin);

    function close() {
      store.dispatch('manageUser_forceLogin', true);
    }

    function login () {
      store.dispatch('manageUser_forceLogin', true);
      router.push('/login')
    }

    return {
      show,
      close,
      login
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-register {
  h3, .modal__buttons button {
    color: $red;
  }

  img {
    width: 80px;
    margin-bottom: 0;
  }
}
</style>