<template>
  <div class="login">
    <!-- <img src="@/assets/ruky.svg" alt=""> -->
    <div class="container">
      <div class="login__container">
        <h1>{{ $t("register.title") }}</h1>
        <p>{{ $t("register.social") }}</p>
        
        <input type="text" :placeholder="$t('register.input_nickname')" autocomplete="off" v-model="nickName" :class="{'input-invalid': v$.nickName.$invalid && submitted}">
        <span class="input-message" v-if="v$.nickName.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.nickName.required.$message}}</span>
        
        <select v-model="genderId" :class="{'input-invalid': v$.genderId.$invalid && submitted}">
          <option disabled selected value="">{{ $t('register.step.second.input_gender') }}</option>
          <option v-for="g in listGender" :key="g.id" :value="g.id">{{ g.name }}</option>
        </select>
        <span class="input-message" v-if="v$.genderID.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.genderID.required.$message}}</span>

        <input type="date" :placeholder="$t('register.step.second.input_birthDate')" autocomplete="off" v-model="birthDate" :class="{'input-invalid': v$.birthDate.$invalid && submitted}">
        <span class="input-message" v-if="v$.birthDate.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.birthDate.required.$message}}</span>

        <select v-model="residenceCountryId" :class="{'input-invalid': v$.residenceCountryId.$invalid && submitted}">
          <option disabled selected value="">{{ $t('register.step.second.input_nationality') }}</option>  
          <option v-for="n in listNationalities" :key="n.id" :value="n.id">{{ n.name }}</option>
        </select>
        <span class="input-message" v-if="v$.residenceCountryId.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.residenceCountryId.required.$message}}</span>

        <button @click="register()" class="button button--red button--register" :disabled="awaitRequest">{{ awaitRequest ? $t("register.startDisabled") : $t("register.start") }}</button>
        <small>{{ $t("register.validate_age") }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import persistentData from '@/plugins/persistentData';
import { useI18n } from 'vue-i18n';

import moment from 'moment';
function validBirthDate(birthDate) {
  let diffDates = moment().diff(moment(birthDate), 'years');
  return diffDates <= 25 && diffDates >= 18;
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitted = ref(false);
    let awaitRequest = ref(false);
    const v$ = useVuelidate();
    const { t } = useI18n();
    const userModelSocial = {...store.getters.register_userModelSocial};
    const nickNameState = store.getters.register_userModelNickName;

    const nickName = ref('');
    if (nickNameState !== '') nickName.value = nickNameState;
    const genderId = ref('');
    const residenceCountryId = ref('');
    const birthDate = ref('');

    const listNationalities = ref([]);
    const listGender = ref([]);
    onMounted(async () => {
      listGender.value = await store.dispatch('stuff_gender');
      listNationalities.value = await store.dispatch('stuff_countries');
      if (!userModelSocial.firstName) {
        router.push('/')
      }
    });

    async function register() {
      submitted.value = true;
      awaitRequest.value = true;
      if (!v$.value.nickName.$invalid && !v$.value.genderId.$invalid && !v$.value.residenceCountryId.$invalid && !v$.value.birthDate.$invalid) {
        const model = {
          firstName: userModelSocial.firstName,
          lastName: userModelSocial.lastName,
          email: userModelSocial.email,
          birthDate: birthDate.value,
          nickName: nickName.value,
          residenceCountryId: residenceCountryId.value,
          genderId: genderId.value,
          gmt: 0,
          facebookId: userModelSocial.facebookId
        }
        try {
          const request = await store.dispatch('security_userCreateSocialMedia', model);
          if (request.status == 200) {
            persistentData.set('token', request.data.token);
            persistentData.set('tokenExpiration', request.data.tokenExpiration);
            persistentData.set('language', request.data.countryCode);
            store.dispatch('manageUser_active');
            submitted.value = false;
            awaitRequest.value = false;
            router.push('/');
          }
        } catch (error) {
          submitted.value = false;
          awaitRequest.value = false;
          store.dispatch('notifications_create', { text: t(`notifications.${error.response.data}`) });
        }
      } else {
        awaitRequest.value = false;
      }
    }

    return {
      nickName,
      genderId,
      residenceCountryId,
      listNationalities,
      listGender,
      birthDate,
      submitted,
      awaitRequest,
      register,
      v$
    }
  },
  validations () {
    return {
      nickName: { 
        required: helpers.withMessage('Este campo es requerido', required) 
      },
      genderId: { 
        required: helpers.withMessage('Este campo es requerido', required)
      },
      birthDate: { 
        required: helpers.withMessage('Este campo es requerido', required), 
        validBirthDate
      },
      residenceCountryId: { 
        required: helpers.withMessage('Este campo es requerido', required)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  img {
    position: absolute;
    bottom: -90px;
    left: -40px;
    transform: rotate(20deg);
    width: 184px;

    @media (min-height: 736px) {
      bottom: -50px;
    }
  }
</style>