<template>
  <div class="profile__work-experience">
    <div class="container main-container" v-if="completeLoad">
      <div class="profile__card">
        <div class="profile__card__header profile__card__header--space">
          <div class="profile__card__header__title">
            <i class="material-icons">person</i>
              {{ $t("profile.work_experience.title") }}
          </div>
        </div>
          <strong v-if="jobs.length == 0">{{ $t("profile.work_experience.none_information") }}</strong> 
          <div class="card-job" v-for="(job, idx) in jobs" :key="idx">
            <div class="card-job__content">
              <div>
                <span>{{ $t("profile.work_experience.position") }}</span>
                <strong>{{job.positionId == -1 ? job.positionOther : getPosition(job.positionId)}}</strong>
              </div>
              <div>
                <span>{{ $t("profile.work_experience.organization") }}</span>
                <strong>{{job.organizationId == -1 ? job.organizationOther : getOrganization(job.organizationId)}}</strong>
              </div>
              <div>
                <span>{{ $t("profile.work_experience.from") }}</span>
                <strong>{{job.monthFrom}}/{{job.yearFrom}}</strong>
              </div>
              <div>
                <span>{{ $t("profile.work_experience.to") }}</span>
                <strong v-if="job.yearTo==null">{{$t('profile.present')}}</strong>
                <strong v-else>{{job.monthTo}}/{{job.yearTo}}</strong>
              </div>
              <div>
                <span>{{ $t("profile.work_experience.contractType") }}</span>
                <strong>{{getContractType(job.employmentContractTypeId)}}</strong>
              </div>
              <div v-if="job.description !==''" class="profile-description">
                <span>{{ $t("profile.work_experience.description") }}</span>
                <div class="profile-description__content">                             
                  <strong v-if="fullDescriptionActive != true">{{getDescription(job.description)}}</strong>          
                  <strong v-if="fullDescriptionActive == true">{{job.description}}</strong>
                  <i class="material-icons" v-if="job.description.length > 50 && !fullDescriptionActive" @click="fullDescriptionActive = true">expand_more</i>
                  <i class="material-icons" v-if="fullDescriptionActive" @click="fullDescriptionActive = false">expand_less</i> 
                </div>     
              </div>
            </div>
            <div class="card-job__btns">
              <i class="material-icons profile__card__header__arrow" @click="editMode(idx);">edit</i>
              <i class="material-icons profile__card__header__arrow" @click="modalDelete(idx, `${job.positionId == -1 ? job.positionOther : getPosition(job.positionId)} (${job.organizationId == -1 ? job.organizationOther : getOrganization(job.organizationId)})`)">delete</i>
            </div>
          </div>
          <div class="btn-add_container">
            <button class="profile__card__button" @click="modalNewJob = true; submitted = false"><i class="material-icons">add</i>agregar nueva</button>
          </div>
      </div>
    </div>
  </div>


  <!-- Modal New -->
  <div v-if="modalNewJob">
    <VisibleModal/>
    <div class="modal__overlay" @click.stop="modalNewJob = false">
      <div class="modal" @click.stop="''">
        <div class="modal__header">  
          {{ $t("profile.work_experience.titleModal") }}
        </div>
        <div class="modal__content">

          <div class="profile__card--personal-edit">

            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.work_experience.position") }}</span>
              <Multiselect v-model="modelJob.positionId" noResultsText="Sin resultados"
                :options="listJobPositions" :placeholder="$t('profile.work_experience.position')" trackBy="name" valueProp="id" label="name"
                :closeOnSelect="true" :searchable="true" @select="modelJob.positionOther = ''" :class="{'input-invalid': v$.modelJob.positionId.$invalid && submitted}">
              </Multiselect>
            </div>

            <div class="profile__card--personal-edit__fullrow" v-if="modelJob.positionId == -1">
              <span>{{ $t("profile.work_experience.positionName") }}</span>
              <input type="text" :placeholder="$t('profile.work_experience.positionName')" v-model="modelJob.positionOther"  :class="{'input-invalid': v$.modelJob.positionOther.$invalid && submitted}">
            </div>

            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.work_experience.organization") }}</span>
              <Multiselect v-model="modelJob.organizationId" noResultsText="Sin resultados"
                :options="listOrganization" :placeholder="$t('profile.work_experience.organization')" trackBy="name" valueProp="id" label="name"
                :closeOnSelect="true" :searchable="true" @select="modelJob.organizationOther = ''" :class="{'input-invalid': v$.modelJob.organizationId.$invalid && submitted}">
              </Multiselect>
            </div>

            <div class="profile__card--personal-edit__fullrow" v-if="modelJob.organizationId == -1">
              <span>{{ $t("profile.work_experience.organizationName") }}</span>
              <input type="text" :placeholder="$t('profile.work_experience.organizationName')" v-model="modelJob.organizationOther"  :class="{'input-invalid': v$.modelJob.organizationOther.$invalid && submitted}">
            </div>

            <div class="profile__card--personal-edit__fullrow currentJob">
              <input type="checkbox" name="stillWorking" :checked="modelJob.monthTo==null && modelJob.yearTo==null" @click.stop="toggleCurrentJob(modelJob)"> 
              <label for="stillWorking">{{ $t("profile.work_experience.modalNew_current") }}</label>
            </div>

<!--             <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.work_experience.from") }}</span>
              <input type="month" v-model="modelJob.from" :class="{'input-invalid': v$.modelJob.from.$invalid && submitted}">
            </div>

            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.work_experience.to") }}</span>
              <input type="month" v-model="modelJob.to" :disabled="modelJob.current" :class="{'input-invalid': v$.modelJob.to.$invalid && submitted}">
            </div>
 -->
            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.work_experience.from") }}</span>
              <div class="date-container">
                <select v-model="modelJob.monthFrom" :class="{'input-invalid': v$.modelJob.monthFrom.$invalid && submitted}">
                  <option :value="''" disabled>Mes</option>
                  <option v-for="month in monthsList" :key="month.id" :value="month.id">{{month.name}}</option>
                </select>
                <select v-model="modelJob.yearFrom" :class="{'input-invalid': v$.modelJob.yearFrom.$invalid && submitted}">
                  <option :value="''" disabled>Año</option>
                  <option v-for="year in yearsList" :key="year" :value="year">{{year}}</option>
                </select>
              </div>
            </div>

            <div v-if="!modelJob.current" class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.work_experience.to") }}</span>
              <div class="date-container">
                <select v-model="modelJob.monthTo" :class="{'input-invalid': v$.modelJob.monthTo.$invalid && submitted}">
                  <option :value="''" disabled>Mes</option>
                  <option v-for="month in monthsList" :key="month.id" :value="month.id">{{month.name}}</option>
                </select>
                <select v-model="modelJob.yearTo" :class="{'input-invalid': v$.modelJob.yearTo.$invalid && submitted}">
                  <option :value="''" disabled>Año</option>
                  <option v-for="year in yearsList" :key="year" :value="year">{{year}}</option>
                </select>
              </div>
            </div>
            
            <!-- ************* -->
            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.work_experience.contractType") }}</span>
              <select v-model="modelJob.employmentContractTypeId" :class="{'input-invalid': v$.modelJob.employmentContractTypeId.$invalid && submitted}">
                <option :value="null">{{ $t("profile.work_experience.select") }}</option>
                <option v-for="contract in listContractType" :key="contract.id" :value="contract.id">{{contract.name}}</option>
              </select>
            </div>

            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.work_experience.description") }}</span>
              <textarea v-model="modelJob.description" @input="assertMaxChars()" autocomplete="off"></textarea>
              <div class="textarea-charcount">{{descriptionCharLimit - modelJob.description.length}}</div>
            </div>

          </div>
        </div>

        <div class="modal__buttons">
          <button @click.stop="modalNewJob = false" class="button--gray">{{ $t("profile.work_experience.cancel") }}</button>
          <button v-if="!editModeActive" @click.stop="addJob">{{ $t("profile.finish") }}</button>
          <button v-else @click.stop="updateJobs">{{ $t("profile.finish") }}</button>
        </div>

      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div v-if="modalDeleteActive">
    <VisibleModal/>
    <div class="modal__overlay" @click.stop="modalDeleteActive = false">
      <div class="modal" @click.stop="''">
        <span class="material-icons" @click.stop="modalDeleteActive = false">close</span>
        <p v-html="$t('profile.work_experience.questionDelete', {name: itemDelete.name})"></p>
        <div class="modal__buttons">
          <button @click.stop="modalDeleteActive = false" class="button--gray">{{ $t("profile.work_experience.false") }}</button>
          <button @click.stop="removeJob">{{ $t("profile.work_experience.true") }}</button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { ref } from '@vue/reactivity';
import { computed, onMounted, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import moment from 'moment';
import VisibleModal from '@/components/Common/VisibleModal.vue';
import { useI18n } from 'vue-i18n';
import { required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Multiselect from '@vueform/multiselect';

export default {
  components: {
    VisibleModal,
    Multiselect
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const v$ = useVuelidate();
    let jobs = computed(() => [...store.getters.profile_jobs]);
    let modalNewJob = ref(false);
    let editModeActive = ref(false);
    let modalDeleteActive = ref(false);
    let itemDelete = ref({});
    let listContractType = ref([]);
    let listJobPositions = ref([]);
    let listOrganization = ref([]);
    let editingJobIndex = ref(null);
    let modelJob = ref({
      monthFrom: '',
      monthTo: '',
      yearFrom: '',
      yearTo: '',
      organizationId: null,
      organizationOther: "",
      positionId: null,
      positionOther: "",
      employmentContractTypeId: null,
      description: ""
    });
    let submitted = ref(false);
    let completeLoad = ref(false);
    const descriptionCharLimit = 4000;
    let fullDescriptionActive = ref(false);
    const yearsList = ref([]);
    const monthsList = [
      {id: "1", name: "Enero"},
      {id: "2", name: "Febrero"},
      {id: "3", name: "Marzo"},
      {id: "4", name: "Abril"},
      {id: "5", name: "Mayo"},
      {id: "6", name: "Junio"},
      {id: "7", name: "Julio"},
      {id: "8", name: "Agosto"},
      {id: "9", name: "Septiembre"},
      {id: "10", name: "Octubre"},
      {id: "11", name: "Noviembre"},
      {id: "12", name: "Diciembre"},
    ]

    function createWorkYears() {
      let anio = new Date().getFullYear();
      let anios = [];
      for (let i = anio; i > anio - 20; i--) {
        anios.push(i.toString());
      }
      yearsList.value = anios;
    }

    async function llamadaProvisoria() {
      const profile = await store.dispatch('profile_profile');
      store.commit('profile_updateProfile', profile);
      listContractType.value = await store.dispatch('stuff_employmentContractTypes');
      listJobPositions.value = await store.dispatch('stuff_jobPositions');
      listOrganization.value = await store.dispatch('stuff_organization');

      jobs.value.forEach(job => {
        if(job.positionId === null) job.positionId = -1;
        if(job.organizationId === null) job.organizationId = -1;
      })
      createWorkYears();
      completeLoad.value = true;
    }

    onMounted(llamadaProvisoria());

    function editMode(index){
      editingJobIndex.value = index;
      editModeActive.value = true;
      modelJob.value.positionId = jobs.value[index].positionId;
      modelJob.value.positionOther = jobs.value[index].positionOther;
      modelJob.value.organizationId = jobs.value[index].organizationId;
      modelJob.value.organizationOther = jobs.value[index].organizationOther;
      modelJob.value.employmentContractTypeId = jobs.value[index].employmentContractTypeId;
      modelJob.value.description = jobs.value[index].description;
      modelJob.value.monthFrom = jobs.value[index].monthFrom;
      modelJob.value.monthTo = jobs.value[index].monthTo;
      modelJob.value.yearFrom = jobs.value[index].yearFrom;
      modelJob.value.yearTo = jobs.value[index].yearTo;
      if(jobs.value[index].yearTo == null) modelJob.value.current = true;
      modalNewJob.value = true;
    }

    watch(modalNewJob, function() {
      if (!modalNewJob.value) {
        modelJob.value = {
          monthFrom: '',
          monthTo: '',
          yearFrom: '',
          yearTo: '',
          organizationId: null,
          organizationOther: "",
          positionId: null,
          positionOther: "",
          employmentContractTypeId: null,
          description: "",
          current: false
        };
        editModeActive.value = false;
        editingJobIndex.value = null;
        submitted.value = false;
      }
    });

    async function addJob () {
      submitted.value = true;
      if (v$.value.modelJob.$invalid) {
        store.dispatch('notifications_create', { text: t(`notifications.completeRequiredFields`) });
        return;
      }
      try {
        store.commit('profile_addJob', modelJob.value);
        await store.dispatch('profile_updateJobs', jobs.value);
        store.dispatch('notifications_create', { text: t(`notifications.updateJobs`) });
        modelJob.value = {
          monthFrom: '',
          monthTo: '',
          yearFrom: '',
          yearTo: '',
          organizationId: null,
          organizationOther: "",
          positionId: null,
          positionOther: "",
          employmentContractTypeId: null,
          description: ""
        };
        modalNewJob.value = false;
        submitted.value = false;
        llamadaProvisoria();
      } catch (error) {
        console.log(error);
      }
    }

    function modalDelete(idx, name) {
      itemDelete.value = {
        idx,
        name
      }
      modalDeleteActive.value = true;
    }
    function removeJob() {
      store.commit('profile_removeJob', itemDelete.value.idx);
      itemDelete.value = {}
      modalDeleteActive.value = false;
      updateJobs();
    }

    async function updateJobs() { 
      if(editModeActive.value){
        submitted.value = true;
        if (v$.value.modelJob.$invalid){
          store.dispatch('notifications_create', { text: t(`notifications.completeRequiredFields`) });
          return;
        } 
        let index = editingJobIndex.value;
        jobs.value[index].positionId = modelJob.value.positionId;
        jobs.value[index].positionOther = modelJob.value.positionOther;
        jobs.value[index].organizationId = modelJob.value.organizationId;
        jobs.value[index].organizationOther = modelJob.value.organizationOther;
        jobs.value[index].employmentContractTypeId = modelJob.value.employmentContractTypeId;
        jobs.value[index].description = modelJob.value.description;
        jobs.value[index].monthFrom = modelJob.value.monthFrom;
        jobs.value[index].monthTo = modelJob.value.monthTo;
        jobs.value[index].yearFrom = modelJob.value.yearFrom;
        jobs.value[index].yearTo = modelJob.value.yearTo;
      }
      try {
        await store.dispatch('profile_updateJobs', jobs.value);
        store.dispatch('notifications_create', { text: t(`notifications.updateJobs`) });
        modalNewJob.value = false;
        modelJob.value = {
          monthFrom: "",
          monthTo: "",
          yearFrom: "",
          yearTo: "",
          organizationId: null,
          organizationOther: "",
          positionId: null,
          positionOther: "",
          employmentContractTypeId: null,
          description: ""
        };
        submitted.value = false;
        editingJobIndex.value = null;
        editModeActive.value = false;
        llamadaProvisoria();
      } catch (error) {
        console.log(error);
      }
    }

    function getPosition(positionId) {
      const position = listJobPositions.value.find(p => p.id == positionId);
      return position ? position.name : '-';
    }

    function getOrganization(organizationId) {
      const organization = listOrganization.value.find(o => o.id == organizationId);
      return organization ? organization.name : '-';
    }

    function getContractType(contractId) {
      const contract = listContractType.value.find(c => c.id == contractId);
      return contract ? contract.name : '-';
    }

    function getDescription(description) {
      if (description.length > 50) {
        description = description.substring(0, 50) + "[...]";
      }
      return description;
    }

    function formatDate(date, format = 'L') {
      return moment(date).format(format);
    }
    
    function toggleCurrentJob(job){
      if(event.target.checked){
        job.current = true;
        job.monthTo = null;
        job.yearTo = null;
      } else{
        job.current = false;
        job.monthTo = '';
        job.yearTo = '';
      }
    }

    function assertMaxChars(){
        if (modelJob.value.description.length >= descriptionCharLimit) {
            modelJob.value.description = modelJob.value.description.substring(0,descriptionCharLimit);
        }
    }

    function searchWithOther(milanesa) {
      console.log(milanesa);
      //return {id:-1,name:'Otro'};
      return false;
    }

    return {
      modalNewJob,
      modelJob,
      listContractType,
      listJobPositions,
      listOrganization,
      editModeActive,
      addJob,
      jobs,
      getPosition,
      getOrganization,
      formatDate,
      getContractType,
      getDescription,
      updateJobs,
      submitted,
      v$,
      completeLoad,
      modalDeleteActive,
      modalDelete,
      itemDelete,
      removeJob,
      toggleCurrentJob,
      editMode,
      editingJobIndex,
      assertMaxChars,
      descriptionCharLimit,
      fullDescriptionActive,
      yearsList,
      monthsList,
      searchWithOther
    }
  },
  validations () {
    return {
      modelJob: {
        monthFrom: {required},
        yearFrom: {required},
        monthTo: {requiredIfRef: requiredIf(this.modelJob.monthTo !== null)},
        yearTo: {requiredIfRef: requiredIf(this.modelJob.yearTo !== null)},
        positionId: { required },
        positionOther: {requiredIfRef: requiredIf(this.modelJob.positionId == -1)},
        organizationId: { required },
        organizationOther: {requiredIfRef: requiredIf(this.modelJob.organizationId == -1)},
        employmentContractTypeId: {required}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .profile__work-experience {
    min-height: 100vh;
    background: url('~@/assets/profile-bg.svg'), linear-gradient(180deg, #E87B1B 0%, #FFC905 100%);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 1270px, auto;
    padding-bottom: 96px;
    padding-top: $margin-kilo;

    .container {
      width: auto;
      padding: 0 $margin-base;

      @media (min-width: $break-sm) {
        padding: 0;
      }
    }
  }

  .card-job{
    margin-top: $margin-base;
    margin-bottom: $margin-kilo;
    padding-bottom: $margin-kilo;
    width: 100%;
    padding-bottom: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(232, 123, 27, .4);
    &__content{
      display: flex;
      flex-direction: column;
      gap: 5px;
      div{
        display: flex;
        flex-direction: column;
        span{
          color: $gray;
        }
        strong{
          color: $orange;
        }
      }
      .profile-description{
        &__content{
          display:flex;
          flex-direction:row;
          i{
            cursor: pointer;
          }
        }
      }
    }
    &__btns{
      align-self: flex-start;
      display: flex;
      color: $orange;

    }
  }

  .btn-add_container{
    display: flex;
    justify-content: center;
    button {
      width: 250px;
      margin: 0!important;
      background: $orange;
      border-radius: 100px;
      font-family: $font-palanquin;
      @include font-settings(base, mega);
      color: $white;
      padding: $margin-milli $margin-kilo;
      font-weight: 700;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
      display: inline-flex;
      justify-content: center;
      cursor: pointer;
    }
  }

  .profile__card {
    max-width: 600px;
    margin: 0 auto;

    &.profile-card--personal-data {
      background: $white;
    }

    button {
      margin-top: $margin-mega;
    }

    .profile__card__button--edit {
      color: $orange;
      background: none;
      border: 1px solid $orange;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $margin-milli;
      max-width: 156px;
      margin: $margin-kilo auto;
    }

    .card-detail__separator {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: row;
      grid-column: 1 / 2 span;
      margin: $margin-milli 0;

      &:before {
        content: '';
        border-top: 2px solid $graybg;
        position: absolute;
        top: 9px;
        width: 100%;
      }

      &__title {
        font-family: $font-palanquin;
        text-align: center;
        @include font-settings(milli,base);
        color: $graybg;
        text-transform: uppercase;
        background: $white;
        position: relative;
        width: fit-content;
        padding: 0 $margin-milli;
      } 
    }
  }

  .profile__card--personal-edit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "image nickname"
    "image fullname"
    "fullrow fullrow";
    gap: $margin-base;
    margin-top: $margin-base;

    &__nickname {
      grid-area: nickname;
    }

    &__fullname {
      grid-area: fullname;
      gap: $margin-base;
    }

    &__fullrow {
      grid-column: 1 / 2 span;
      .date-container{
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        gap: 12px;
      }
    }

    &__image {
      width: 120px;
      height: 120px;
      background: $white;
      border-radius: 50%;
      border: 3px solid $orange;
      flex-shrink: 0;
      background-size: cover;
      background-position: center center;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      grid-area: image;
    }

    input {
      color: $gray;
      font-family: $font-palanquin;
      @include font-settings(kilo, base);
      border: none;
      background: none;
      font-weight: 700;
      width: 100%;

      &[type="file"] {
        width: 120px;
        height: 120px;
        background: $orange;
        border-radius: 50%;
        border: 3px solid $orange;
        flex-shrink: 0;
        background-size: cover;
        background-position: center center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        grid-area: image;
        cursor: pointer;

        &:before {
          font-family: "Material Icons";
          content: "\e412";
          font-size: 32px;
          display: flex;
          color: $white;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 100%;
          width: 100%;
        }

        &::file-selector-button {
          opacity: 0;
        }
      }
      &:disabled{
        opacity: .5;
      }
    }

    textarea {
      background: none;
      text-align: left;
      border: 2px solid $gray;
      height: 40px;
      padding: $margin-milli;
      font-family: $font-opensans;
      color: $gray;
      @include font-settings(milli, base);
      width: 100%;

      &::placeholder {
        color: $graybg;
      }
    }

    select {
      color: $gray;
      font-family: $font-palanquin;
      @include font-settings(kilo, base);
      border: none;
      background: none;
      font-weight: 700;
      width: 100%;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    &.multiselect{
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
      span {
        color: $gray;
      }
      strong {
        color: $orange;
        font-family: $font-palanquin;
        @include font-settings(kilo, base);
      }
      
    }
    .currentJob{
      flex-direction: row;
      align-items: center;
      input{
        width: auto;
        margin: 0 5px 0 0;
      }
    }
    textarea{
      width: 100%;
      min-height: 60px;
      margin: 2px;
    }
  }

.textarea-charcount {
  align-self: flex-end;
  font-size: 13px;
  font-weight: 100;
}

.profile-card--experience {

    &__container {
      padding: $margin-milli 0;
    }

    &__title {
      color: $orange;
      font-family: $font-palanquin;
      @include font-settings(kilo, mega);
      font-weight: 700;
    }
    
    &__subtitle {
      color: $graybg;
      font-family: $font-palanquin;
      @include font-settings(milli, base);
      font-weight: 700;
    }

    span {
      font-family: $font-palanquin;
      color: $graybg;
      display: block;
    }

    &__content {
      color: $gray;
      font-family: $font-opensans;
      @include font-settings(milli, base);
      padding-top: $margin-milli;
    }
  }

.modal {
  h4, &__buttons button {
    color: $orange;
  }
}
</style>