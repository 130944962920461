<template>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <h1>{{ $t("register.title") }}</h1>
        <p>{{ $t("register.social") }}</p>
        
        <input type="text" :placeholder="$t('register.step.first.input_email')" autocomplete="off" v-model="email" :class="{'input-invalid': v$.email.$invalid && submitted}">
        <span class="input-message" v-if="v$.email.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.required.$message}}</span>
        
        <input type="text" :placeholder="$t('register.step.confirm.input_code')" autocomplete="off" v-model="code" :class="{'input-invalid': v$.code.$invalid && submitted}">
        <span class="input-message" v-if="v$.code.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.code.required.$message}}</span>

        <button @click="register()" class="button button--red button--register" :disabled="awaitRequest">{{ awaitRequest ? $t("register.startDisabled") : $t("register.start") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import persistentData from '@/plugins/persistentData';
import { useI18n } from 'vue-i18n';
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const code = ref('');
    const email = ref('');
    const submitted = ref(false);
    let awaitRequest = ref(false);
    const v$ = useVuelidate();
    const { t } = useI18n();

    onMounted(() => {
      code.value = route.query.c;
      email.value = route.query.e;
    });

    async function register() {
      submitted.value = true;
      awaitRequest.value = true;
      if (!v$.value.code.$invalid && !v$.value.email.$invalid) {
        const model = {
          activationCode: code.value,
          email: email.value
        }
        try {
          const request = await store.dispatch('security_userActivateModel', model);
          if (request.status == 200) {
            persistentData.set('token', request.data.token);
            persistentData.set('tokenExpiration', request.data.tokenExpiration);
            persistentData.set('language', request.data.countryCode);
            store.dispatch('manageUser_active');
            submitted.value = false;
            awaitRequest.value = false;
            router.push('/');
          }
        } catch (error) {
          submitted.value = false;
          awaitRequest.value = false;
          store.dispatch('notifications_create', { text: t(`notifications.${error.response.data}`) });
        }
      } else {
        awaitRequest.value = false;
      }
    }

    return {
      email,
      code,
      submitted,
      awaitRequest,
      register,
      v$
    }
  },
  validations () {
    return {
      email: { 
        required: helpers.withMessage('Este campo es requerido', required) 
      },
      code: { 
        required: helpers.withMessage('Este campo es requerido', required)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  img {
    position: absolute;
    bottom: -90px;
    left: -40px;
    transform: rotate(20deg);
    width: 184px;

    @media (min-height: 736px) {
      bottom: -50px;
    }
  }
</style>