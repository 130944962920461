<template>
  <article class="course__card" @click="goToWithForce(`/learning/courses/${course.type}/${course.id}`)">
    <div class="course__card__img" :style="`background-image: url(${course.picture})`" :alt="course.name">
    </div>
    <div class="course__card__title">
      {{course.name}}
    </div>
    <div class="course__card__vendor">{{course.vendor}}</div>
    <div class="course__card__rate">
      <i class="material-icons" v-for="i in [0,1,2,3,4]" :key="i" :class="{'active': i < course.rate}">star</i>
    </div> 
    <p class="course__card__subtitle">
      {{course.description}}
    </p>
    <span>NIVEL: {{course.level}}</span>
  </article>
</template>
<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  props: {
    course: Object
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    async function goToWithForce(path) {
      const force = await store.dispatch('manageUser_forceLogin');
      if (force) return;
      router.push(path);
    }

    return {
      goToWithForce
    }
  }
}
</script>
<style lang="scss" scoped>
  .course__card {
    background: $white;
    border-radius: $margin-basedo;
    width: 292px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    padding-bottom: 32px;
    cursor: pointer;

    &__img {
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 0;
      padding-bottom: 56.5%;
    }

    &__title {
      font-family: $font-opensans;
      text-align: left;
      @include font-settings(kilo,base);
      color: $yellow;
      font-weight: 700;
      margin: $margin-base 0;
      padding: 0 $margin-kilo;
      margin-bottom: 4px;
      height: 44px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }

    &__vendor {
      font-family: $font-palanquin;
      @include font-settings(xmilli,base);
      text-transform: uppercase;
      color: $gray;
      padding: 0 $margin-kilo;
      font-weight: 400;
      margin-bottom: $margin-milli;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 280px;
    }

    span {
      font-family: $font-palanquin;
      @include font-settings(milli,base);
      padding-left: $margin-kilo;
      font-weight: 400;
      padding-top: $margin-kilo;
      color: $lightgray;
      text-transform: uppercase;
    }

    &__rate {
      display: flex;
      padding-left: $margin-kilo;
      margin-bottom: $margin-base;

      i {
        color: #BDBDBD;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        transform: rotate(-5.31deg);
        
        &.active {
          color: #FFC905;
        }
      }
    }
    
    p.course__card__subtitle {
      font-family: $font-opensans;
      text-align: left;
      text-shadow: none;
      @include font-settings(milli,base);
      color: $gray;
      font-weight: 400;
      padding: 0 $margin-kilo $margin-kilo;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      padding-bottom: 0;
      margin: 0;
    }

    &__included {
      padding: 0 $margin-kilo $margin-kilo;

      img {
        width: $margin-kilo;
        margin-right: 4px;
      }
    }
  }
</style>