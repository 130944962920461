import interceptor from '@/plugins/interceptor';
const pathController = '/Organization';

export default {
  state: () => ({}),
  mutations: {},
  actions: {
    async organization_jobSearches(_, params) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/jobSearches?filter=${params.filter}&pageNumber=${params.pageNumber}&rowsPerPage=${params.rowsPerPage}`);
      if (request.status === 200) return request.data;
      return [];
    },
    async organization_jobSearch(_, id) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/jobSearch/${id}`);
      if (request.status === 200) return request.data;
      return [];
    },
    async organization_wishes() {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/jobSearch/wishes`);      
      if (request.status === 200) return request.data;
      return [];
    },
    async organization_wishes_add(_, id) {
      const request = await interceptor.anonymous.post(`${process.env.VUE_APP_API_SITE_URL}${pathController}/jobSearch/wishes/add/${id}`, {});
      return request;
    },
    async organization_wishes_remove(_, id) {
      const request = await interceptor.anonymous.post(`${process.env.VUE_APP_API_SITE_URL}${pathController}/jobSearch/wishes/remove/${id}`, {});
      return request;
    },
    //Finish trip
    async organization_finishTrip() {
      const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/tripCompleted`);
      if(request.status == 200){
        return request;
      } else{
        throw request;
      }
    },
    async organization_jobSearch_apply(_, id) {
      const request = await interceptor.authenticate.post(`${process.env.VUE_APP_API_SITE_URL}${pathController}/jobSearch/${id}/apply`, {});
      if(request.status == 200){
        return request;
      } else{
        throw request.statusText;
      }
    },
    async organization_jobSearch_mineActives() {
      const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/jobSearch/mineActives`);
      return request;
    },
    async organization_jobSearch_mineHistorical() {
      const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/jobSearch/mineHistorical`);
      return request;
    }
  }
}