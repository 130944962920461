<template>
  <div v-if="!resultLoaded" class="games__loading">
    <SvgAnimated class="module-two__ruky" :data="loader" />
  </div>
  <iframe :class="!resultLoaded ? 'genoma-result-loading' : ''" :src="link" frameborder="0" allow="fullscreen;camera *;microphone *" ref="gameIFrame"></iframe>
</template>
<script>
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import SvgAnimated from '@/components/SvgAnimated/Main.vue';
import loader from '@/components/SvgAnimated/JSON/loader.json';


export default {
  components: {
    SvgAnimated
  },

  setup() {
    const link = ref('');
    const store = useStore();
    const route = useRoute();
    const navigateToResult = ref(false);
    const gameIFrame = ref(null);
    const resultLoaded = ref(false);
    const resultType = ref(route.params.resultType);
    const gameCode = ref(route.params.gameCode.toLowerCase());

    function calculateViewport() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    onMounted( async () => {
      link.value = await store.dispatch('game_gameLink', route.params.gameCode);
      
      if (resultType.value !== undefined && resultType.value == "resultGenoma") {
        gameIFrame.value.addEventListener("load", () => {
          if (!navigateToResult.value) {
            navigateToResult.value = true;
            setTimeout(() => {
              link.value = "https://app.staging.genoma.work/me/scores";
              setTimeout(() => {
                resultLoaded.value = true;
              },1000)
              
            },3000)
          }
        })
      } else {
        gameIFrame.value.addEventListener("load", () => {
          resultLoaded.value = true;
        });
      }

      calculateViewport();
      window.addEventListener('resize', calculateViewport);
    });

    return {
      link,
      resultType,
      resultLoaded,
      gameIFrame,
      gameCode,
      loader
    }
  }
}
</script>
<style lang="scss" scoped>
  .games__loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: capitalize; 
    @include font-settings(tera,base);
    
    height: calc(calc(var(--vh, 1vh) * 100) - 75px);
    @media (orientation:landscape)  {
      height: calc(100vh - 75px);
    }
  }
  iframe {
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 75px);
    @media (orientation:landscape)  {
      height: calc(100vh - 75px);
    }
    display: block;
    &.genoma-result-loading {
      display: none;
    }
  }
</style>