<template>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <h1>{{ $t("login.title") }}</h1>
        <p>{{ $t("login.description") }}</p>

        <SocialRegister text="login"/>
        
        <input type="text" :placeholder="$t('login.input_email')" v-model="username" :class="{'input-invalid': v$.username.$invalid && submitted}">
        <span class="input-message" v-if="v$.username.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.username.required.$message}}</span>
        <span class="input-message" v-if="v$.username.email.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.username.email.$message}}</span>
        <div class="password_container">
          <input autocomplete="off" :type="passwordType" :placeholder="$t('login.input_password')" v-model="password" :class="{'input-invalid': v$.password.$invalid && submitted}">
          <i v-if="passwordType == 'password'" class="material-icons" @click.stop="passwordType = 'text'">visibility</i>
          <i v-else class="material-icons" @click.stop="passwordType = 'password'">visibility_off</i>
        </div>

        <span class="input-message" v-if="v$.password.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.password.required.$message}}</span>
        
        <!--<router-link class="login__recover" to="/login/recoveryPassword">{{ $t("login.forgot-password") }}</router-link>-->
        <!--<a class="login__recover"></a>-->

        <button @click="login" class="button button--red button--register" :disabled="awaitRequest">{{ awaitRequest  ? $t("login.actionDisabled") : $t("login.action") }}</button>
        <span class="termsAndConds" v-html="$t('register.step.termsAndConds.viewForLogin')"></span>
      </div>
    </div>
  </div>
</template> 
<script>
import { useStore } from 'vuex';
import { ref, onBeforeUnmount } from 'vue';
import persistentData from '@/plugins/persistentData';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import SocialRegister from '@/components/Common/SocialRegister.vue';

export default {
  components: {
    SocialRegister
  },
  setup() {
    const store = useStore();
    const router = useRouter();  
    const username = ref("");
    const password = ref("");
    const passwordType = ref('password');
    const submitted = ref(false);
    const v$ = useVuelidate();
    let awaitRequest = ref(false);
    const { t } = useI18n();

    function onKeydown (event) {
      if (event.key === 'Enter') {
        login();
      }
    }

    window.addEventListener('keydown', onKeydown);
    onBeforeUnmount(() => {
      window.removeEventListener('keydown', onKeydown);
    })
    
    async function login () {
      submitted.value = true;
      awaitRequest.value = true;
      if (!v$.value.username.$invalid && !v$.value.password.$invalid) {
        try {
          const request = await store.dispatch('security_authenticate', {username: username.value, password: password.value});
          if (request.status === 200) {
            persistentData.set('token', request.data.token);
            persistentData.set('tokenExpiration', request.data.tokenExpiration);
            persistentData.set('registrationReference', request.data.registrationReference);
            persistentData.set('language', request.data.countryCode);
            persistentData.set('forgeLevel', request.data.forgeLevel);
            store.dispatch('manageUser_active');
            submitted.value = false;
            awaitRequest.value = false;
            if (request.data.registrationReference != null && request.data.registrationReference.toLowerCase() == 'trip') {
              router.push('/gamesExternal/TRIP/trip');
            } else if (request.data.registrationReference == 'IIVVO') {
              router.push('/gamesExternal/IIVVO/IIVVO');
            } else {
              router.push('/');
            }
          }
        } catch (e) {
          store.dispatch('notifications_create', { text: t(`notifications.${e.response.data}`) });
          store.commit('register_updateField', {field: 'email', value: username.value});
          submitted.value = false;
          awaitRequest.value = false;
          if (e.response.data === 'userNotAvtivated') {
            router.push('/register/activate');
          }
        }
      } else {
        awaitRequest.value = false;
      }
    }

    return {
      username,
      password,
      passwordType,
      login,
      onKeydown,
      submitted,
      awaitRequest,
      v$
    }
  },
  validations () {
    return {
      username: { 
        required: helpers.withMessage('Este campo es requerido', required),
        email: helpers.withMessage('El formato del mail es invalido', email)
      },
      password: { 
        required: helpers.withMessage('Este campo es requerido', required)
      },
    }
  }
}
</script>

<style lang="scss" scoped>
  .termsAndConds {
    color: $white;
    font-weight: 700;
    font-size: 16px;
  }
  .password_container{
    margin-bottom: 16px;
    position: relative;
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 2px solid #fff;
    .material-icons{
      position: absolute;
      top: 50%;
      right: 0;
      padding-right: 8px;
      transform: translateY(-50%);
      color: #fff;
      font-size: 28px;
      cursor: pointer;
    }
    input{
      margin: 0;
      flex-grow: 1;
      border: none;
    }
  }
  input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus{
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff;
    font-size: 16px !important;
  }
</style>