<template>
  <!-- <button class="video-intro">
    <img src="@/assets/play.svg" alt="">
  </button> -->
  <div class="content__module">

    <section class="module-one">
      <div class="container">
        <template v-if="logged">  
          <div class="module-one__logged container main-container">
            <a @click="goToWithForce('/profile/resume')" class="banner--home">
              <div class="banner banner--orange">
                  <img src="@/assets/passport-banner.svg" alt="">
                  <h3>{{ $t("home.banner") }}</h3>
              </div>
            </a>
          </div>
        </template>
        <template v-else>
          <h1>{{ $t("home.title") }}</h1>
          <button class="button button--yellow">
            <router-link to="/login">{{ $t("home.login") }}</router-link>
          </button> 
        </template>
      </div>
    </section>
    <section class="module-two">
      <div class="container">
        <div class="module-two__header">
          <SvgAnimated class="module-two__ruky" :data="rukyAnimated" />
          <h2>{{ $t("home.trip.title") }}</h2>
        </div>
        <div class="module-two__steps">
          <div class="module-two__step">
            <a @click="goToWithForce('/profile/myBestVersion')">
              <img src="@/assets/step-1.svg" alt="">
            </a>
            <div class="module-two__step__content">
              <h3>{{ $t("home.trip.first.title") }}</h3>
              <span>{{ $t("home.trip.first.description") }}</span>
            </div>
          </div>
          <div class="module-two__step">
            <div class="module-two__step__content">
              <h3>{{ $t("home.trip.second.title") }}</h3>
              <span>{{ $t("home.trip.second.description") }}</span>
            </div>
            <a @click="goToWithForce('/profile/resume')">
              <img src="@/assets/step-2.svg" alt="">
            </a>
          </div>
          <div class="module-two__step">
            <a @click="goToWithForce('/learning/routes')">
              <img src="@/assets/step-3.svg" alt="">
            </a>
            <div class="module-two__step__content">
              <h3>{{ $t("home.trip.third.title") }}</h3>
              <span>{{ $t("home.trip.third.description") }}</span>
            </div>
          </div>
          <div class="module-two__step">
            <div class="module-two__step__content">
              <h3>{{ $t("home.trip.fourth.title") }}</h3>
              <span>{{ $t("home.trip.fourth.description") }}</span>
            </div>
            <a @click="goToWithForce('/work/jobOffers')">
              <img src="@/assets/step-4.svg" alt="">
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="module-three">
      <div class="container main-container">
        <div class="module-three__title">
          <img src="@/assets/airplane.svg" alt="">
          <h2>{{ $t("home.job_offers.title") }}</h2>
        </div>
        <div class="jobs-container">
          <template v-if="levelProfile >= 5">
            <div class="jobs-table">
              <div class="jobs-table__job-offer" v-for="job in jobSearchs" :key="job.id">
                <div class="jobs-table__job-offer__title">
                  {{ job.jopPosition }}<br>
                  <span>{{ job.organization }}</span>
                </div>
                <div class="jobs-table__job-offer__location">
                  {{ job.location }}<br>
                  {{ job.schedule }}
                </div>
                <button @click="goToWithForce(`/work/jobOffers/${job.id}`)">{{ $t("home.job_offers.apply") }}</button>
              </div>        
            </div>
            <div class="module-three__button">
              <button class="button button--outline">
                <a @click="goToWithForce('/work/jobOffers')">{{$t("home.job_offers.view_more")}}</a>
              </button>
            </div>
          </template>
          <div v-else class="jobs-nolevel5">
            <div class="available-level-5">
              <i class="material-icons">error</i>
              <span>Disponible a partir del <b>NIVEL 5</b></span> 
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="module-four">
      <div class="container">
        <h2>{{ $t("home.skills.title") }}</h2>
        <p>{{ $t("home.skills.description") }}</p>
        <div class="carousel">
          <article class="module-four__carousel__card" v-for="game in games" :key="game.code" @click="levelProfile >= 5 ? goToWithForce(`/gamesExternal/${game.code}`) : ''">
            <div class="module-four__carousel__card__img" :style="`background-image: url(${game.picture})`" :alt="game.name">
            </div>
            <div class="module-four__carousel__card__title">
              {{game.name}}
            </div>
            <div class="module-four__carousel__card__status">
              <div :class="game.status" v-if="levelProfile >= 5"></div>
              <span>{{ levelProfile >= 5 ? $t(`home.skills.game.${game.status}`) : 'Disponible a partir del nivel 5'}}</span> 
            </div>
            <p class="module-four__carousel__card__subtitle">
              {{game.description}}
            </p>
            <small class="module-four__carousel__card__subtitle">{{ $t(`home.skills.game.powered`) }} {{game.vendor}}</small>  
          </article>
        </div>
        <div class="module-four__button">
          <button class="button button--outline-white">
            <a @click="goToWithForce('/profile/resume')">{{ $t("home.skills.view_all") }}</a>
          </button>
        </div>
      </div>
    </section>
    <section class="module-five">
      <div class="container">
        <h2>{{ $t("home.resources.title") }}</h2>
        <p>{{ $t("home.resources.description") }}</p>
        <div class="carousel">
          <LearningCard v-for="route in learningRoutes" :key="route.id" :learning="route"/>
        </div>
        <div class="module-five__button">
          <button class="button button--outline-white">
            <a @click="goToWithForce('/learning/routes')">{{ $t("home.resources.view_more") }}</a>
          </button>
        </div>
        <p class="module-five__subtitle">{{ $t("home.resources.courses") }}</p>
        <div class="carousel">
          <CourseCard v-for="item in learningItems" :key="item.id" :course="item" />
        </div>
        <div class="module-five__button">
          <button class="button button--outline-white">
            <a @click="goToWithForce('/learning/courses')">{{ $t("home.resources.view_more") }}</a>
          </button>
        </div>
      </div>
    </section>
    <section class="module-six">
      <div class="container main-container">
        <div class="module-six__title">
          <h2>{{ $t("home.check-in.title") }}</h2>
          <span>{{ $t("home.check-in.description") }}</span>
        </div>
        <div class="module-six__card">
          <h4>{{ $t("home.check-in.card.title") }}</h4>
          <img src="@/assets/clip.svg" alt="" class="module-six__card__clip">
          <ul>
            <li>{{ $t("home.check-in.card.label1") }}</li>
            <li>{{ $t("home.check-in.card.label2") }}</li>
            <li>{{ $t("home.check-in.card.label3") }}</li>
          </ul>
        </div>
      </div>
      <h3></h3>
      <!-- <h3>{{ $t("home.sponsors.title") }}</h3> -->
    </section>
    <section class="module-seven">
      <div class="container main-container">
        <!-- <img src="@/assets/brands.jpg" alt=""> -->
      </div>
    </section>
    <section class="module-eight">
      <div class="container">
        <img src="@/assets/forge.svg" alt="">
        <p>{{ $t("home.aboutus.description") }}</p>
        <button>
          <a href="https://fundacionforge.org/" target="_blank">{{ $t("home.aboutus.view_more") }}</a>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
  import { computed, onMounted } from '@vue/runtime-core';
  import { useStore } from 'vuex';
  import { ref } from 'vue';
  import LearningCard from '@/components/Learning/Card.vue';
  import CourseCard from '@/components/Course/Card.vue';
  import { useRouter } from 'vue-router';
  import SvgAnimated from '@/components/SvgAnimated/Main.vue';
  import rukyAnimated from '@/components/SvgAnimated/JSON/home_rukys.json';
  import persistentData from '@/plugins/persistentData';

  export default {
    components: {
      LearningCard,
      CourseCard,
      SvgAnimated
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const levelProfile = ref(0);
      let games = ref([]);
      let learningRoutes = ref([]);
      let learningItems = ref([]);
      let jobSearchs = ref([]);
      let logged = computed(() => store.getters.manageUser_logged);

      onMounted(async () => {
        levelProfile.value = persistentData.get("forgeLevel", true);
        const request = await store.dispatch('info_homeImfo');
        games.value = request.games;
        learningRoutes.value = request.learningRoutes;
        learningItems.value = request.learningItems;
        jobSearchs.value = request.jobSearchs;
      });

      async function goToWithForce(path) {
        const force = await store.dispatch('manageUser_forceLogin');
        if (force) return;
        router.push(path);
      }

      async function goGame(gameCode) {
        const force = await store.dispatch('manageUser_forceLogin');
        if (force) return;

        router.push(`/gamesExternal/${gameCode}`);
      }

      return {
        levelProfile,
        games,
        learningRoutes,
        learningItems,
        jobSearchs,
        logged,
        goGame,
        goToWithForce,
        rukyAnimated
      }
    }
  }
</script>

<style lang="scss">

.content__module{
  section{
    padding: $margin-mega $margin-base ;
  }
}
  .banner--home {
    width: 100%;

    .banner {
      height: 126px;
    }
  }

  .dialog-box {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: $margin-basedo;
    padding: $margin-kilo;
    margin: $margin-base 0;
    text-align: center;
    background: $white;
    max-width: 400px;
    width: 100%;
    position: relative;
    margin-bottom: -32px;

    @media (min-width: $break-md) {
      margin-right: 330px;
      margin-bottom: 0;
      margin-top: 32px;
    }

    &:after {
      content: " ";
      position: absolute;
      right: 80px;
      bottom: -15px;
      border-top: 15px solid white;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
      border-bottom: none;

      @media (min-width: $break-md) {
        right: -22px;
        bottom: 47px;
        transform: rotate(-90deg);
      }
    }

    p {
      text-shadow: none;
      color: $gray;
      font-family: $font-opensans;
      font-weight: 700;
      @include font-settings(base,base);

      b {
        color: $red;
      }
    }
  }

  .video-intro {
    width: 64px;
    height: 64px;
    background: $white;
    border-radius: 100%;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    left: $margin-base;
    bottom: $margin-base;
    padding: $margin-small;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  .module-one {
    // background: linear-gradient(180deg, #E25523 25.59%, #F1B80F 75.02%);
    background: url('~@/assets/module-one-bg.svg') no-repeat, linear-gradient(180deg, #E87B1B 25.59%, #ea8d18 75.02%);
    background-position: center bottom;
    background-size: contain;
    
    height: 300px;
    h1 {
      @media (max-width: 375px) {
        @include font-settings(tera, base);
      }
    }

    @media (min-width: $break-md) {
      height: 680px;
    }

    @media (min-width: $break-lg) {
      height: 620px;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;
      z-index: 2;
    }
    
    h1 {
      max-width: 540px;
      margin-bottom: $margin-kilo;
      
      @media (min-width: $break-md) {
        @include font-settings(middlepeta, mega);
        margin-bottom: $margin-tera;
        letter-spacing: -3px;
      }
    }

    &__logged {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .module-two {
    // background: linear-gradient(163.13deg, #F1B80F 14.3%, #04A190 88.79%);
    background: url('~@/assets/module-two-bg--small.svg') no-repeat, $red;
    background-position: center top;
    background-size: cover;
    // padding-bottom: 40px;
    @media (min-width: $break-md) {
      background: url('~@/assets/module-two-bg.svg') no-repeat, $red;
      background-position: center top;
      background-size: cover;
      // padding-bottom: 130px;
    }
    @media (min-width: $break-lg){
      // padding-bottom: 130px;
    }

    .module-two__ruky {
      filter: drop-shadow( 1px 1px 4px rgba(0, 0, 0, .6));

      @media (min-width: $break-md) {
        width: 960px;
        margin-right: -24px;
      }

      @media (min-width: $break-lg) {
        margin-right: 134px;
      }
    }

    &__steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url('~@/assets/line.svg') no-repeat bottom;
      margin-bottom: 40px;
      background-size: cover;

      @media (min-width: 450px) {
        background-size: initial;
      }

      @media (min-width: $break-md) {
        background: url('~@/assets/line-md.svg') no-repeat top;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 80px;
      }

      @media (min-width: $break-lg) {
        background: url('~@/assets/line-lg.svg') no-repeat bottom;
        flex-wrap: nowrap;
        margin-bottom: 360px;
      }
    }

    &__step {
      width: 300px;
      display: flex;
      flex-direction: row;

      @media (min-width: $break-md) {
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;

        &__content {
          text-align: center;
        }

        &:nth-child(even) {
          flex-direction: column-reverse;
        }

        &:nth-child(1) {
          position: relative;
          top: -42px;
        }

        &:nth-child(2) {
          position: relative;
          left: 170px;
          top: 30px;
        }

        &:nth-child(3) {
          position: relative;
          left: -430px;
          top: 308px;
        }

        &:nth-child(4) {
          position: relative;
          left: 590px;
          top: 78px;
        }
      }

      @media (min-width: $break-lg) {
        width: 320px;
        margin-left: -8px;

        &:nth-child(1) {
          position: relative;
          top: 50px;
        }

        &:nth-child(2) {
          position: relative;
          top: 130px;
          left: 0;
        }

        &:nth-child(3) {
          position: relative;
          top: 50px;
          left: 0;
        }

        &:nth-child(4) {
          position: relative;
          top: 120px;
          left: 0;
        }
      }
      
      &__content {
        margin: 0 $margin-base;
      }

      &:nth-child(2n) {
        text-align: right;
      }

      img {
        width: 112px;
        flex-shrink: 0;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: $margin-mega;
      margin-top: -200px;

      @media (min-width: $break-md) {
        align-items: flex-end;
        margin-top: -620px;
      }

      @media (min-width: $break-lg) {
        margin-bottom: 0;

        h2 {
          align-self: center;
        }
      }

      h2 {
        max-width: 280px;
        text-align: right;

        @media (min-width: $break-md) {
          max-width: 520px;
          text-align: right;
          @include font-settings(peta, mega);
          letter-spacing: -2px;
          margin-top: -32px;
        } 
      }
    }
  }

  .module-three {
    background: $blue;
    border-top: 3px solid $green;
    border-bottom: 3px solid $green;

    @media (min-width: $break-md) {
      background: linear-gradient($red, $yellow);
      border: none;
    }

    @media (min-width: $break-lg) {
      background: url('~@/assets/module-two-bg.svg') no-repeat, linear-gradient($red, $yellow);
      background-position: center -871px, top;
      background-size: cover;
    }

    @media (min-width: $break-lg) {
      padding: 96px 0;
    }

    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    img {
      width: $margin-mega;
      margin-bottom: $margin-milli;
      margin-right: $margin-base;

      @media (min-width: $break-md) {
        width: 48px;
      }
    }

    h2 {
      margin-bottom: $margin-kilo;

      @media (min-width: $break-md) {
        text-align: center;
        @include font-settings(peta, mega);
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      margin-top: $margin-kilo;
    }

    .jobs-container {

      @media (min-width: $break-md) {
        background: $blue;
        border-radius: 20px;
        border: 3px solid $green;
        padding: $margin-kilo 0;
      }
    }

    .jobs-table {
      display: grid;
      margin: 0 -16px;

      @media (min-width: $break-md) {
        margin: 0;
      }

      &__job-offer {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        min-height: 80px;
        align-items: center;
        gap: $margin-milli;
        padding: $margin-milli $margin-base;

        &__title {
          color: $white;

          span {
            color: $lightgray;
          }
        }

        &__location {
          @include font-settings(xmilli,base);
          color: $lightgray;
        }

        button {
          font-family: $font-palanquin;
          color: $green;
          text-transform: uppercase;
          @include font-settings(milli,base);
        }

        &:nth-child(even) {
          background: $violet;
        }
      }
    }
    .jobs-nolevel5{
      margin: 8px 0;
      display: flex;
      justify-content: center;
    }
  }

  .module-four {
    background: url('~@/assets/clouds.svg') no-repeat, $yellow;
    background-position: center center;
    background-size: initial;
    padding: $margin-mega 0;

    @media (min-width: $break-lg) {
      padding: 96px 0;
    }

    h2 {
      text-align: center;

      @media (min-width: $break-md) {
        @include font-settings(peta, mega);
      }
    }

    p {
      font-family: $font-palanquin;
      text-align: center;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      @include font-settings(kilo,base);
      color: $white;
      font-weight: 400;

      @media (min-width: $break-md) {
        @include font-settings(mega, base);
        margin-bottom: $margin-mega;
      }
    }

    // @media (min-width: $break-md) {
    //   .carousel {
    //     justify-content: center;
    //   }
    // }

    // .module-four__carousel {
      // margin: $margin-kilo 0;
      // display: flex;
      // flex-direction: row;
      // overflow-x: auto;
      // overflow-y: hidden;
      // width: 100%;
      // padding: 0 0 $margin-milli;
      // padding-left: $margin-base;

      .module-four__carousel__card {
        background: $white;
        border-radius: $margin-basedo;
        width: 292px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        flex-shrink: 0;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 74px;
        cursor: pointer;

        &__img {
          width: 148px;
          height: 148px;
          border: 3px solid $red;
          box-shadow: -3px 4px 0px rgba(20, 30, 55, 0.25);
          border-radius: 100%;
          margin-top: -74px;
          background-size: cover;
          background-position: center center;
        }

        &__title {
          font-family: $font-opensans;
          text-align: center;
          @include font-settings(kilo,base);
          color: $red;
          font-weight: 700;
          margin-top: $margin-base;
          text-transform: uppercase;
        }
        
        &__status {
          margin: $margin-milli 0;
          display: flex;
          align-items: center;

          div {
            width: 12px;
            height: 12px; 
            border-radius: 50%;
            margin-right: $margin-milli;

            &.Pending {
              background: $red;
            }

            &.Completed {
              background: $green;
            }

            &.InProgress {
              background: $yellow;
            }
          }

          span {
            color: $gray;
            text-transform: uppercase;
            font-family: $font-palanquin;
            @include font-settings(milli,base);
            font-weight: 700;
          }
        }

        p {
          font-family: $font-opensans;
          text-align: center;
          text-shadow: none;
          @include font-settings(milli,base);
          color: $gray;
          font-weight: 400;
          padding: 0 $margin-kilo;
          margin-bottom: 0;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }

        small {
          padding: $margin-milli 0 $margin-mega;
          color: $graybg;
        }
      }
    // }

    &__button {
      display: flex;
      justify-content: center;
      margin-top: $margin-kilo;
    }
  }

  .module-five {
    // background: $green;
    background: url('~@/assets/module-five-bg.svg') no-repeat, linear-gradient(180deg, #F1B80F 0%, #ffc949 100%);;
    background-position: center center;
    background-size: cover;
    padding: $margin-mega 0;

    @media (min-width: $break-lg) {
      padding: 100px 0;
    }

    &__button {
      display: flex;
      justify-content: center;
      margin-top: $margin-kilo;
      margin-bottom: $margin-mega;
    }

    &__subtitle {

      @media (min-width: $break-md) {
        margin-top: 96px!important;
      }
    }

    h2 {
      text-align: center;

      @media (min-width: $break-md) {
        @include font-settings(peta, mega);
      }
    }

    h2 {
      text-align: center;

      @media (min-width: $break-md) {
        @include font-settings(peta, mega);
      }
    }

    p {
      font-family: $font-palanquin;
      text-align: center;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      @include font-settings(kilo,base);
      color: $white;
      font-weight: 400;
      max-width: 540px;
      margin: 0 auto;

      @media (min-width: $break-md) {
        @include font-settings(mega, base);
        margin-bottom: $margin-mega;
      }
    }

    // .module-five__carousel {
    //   margin: $margin-kilo 0;
    //   display: flex;
    //   flex-direction: row;
    //   overflow-x: auto;
    //   overflow-y: hidden;
    //   width: 100%;
    //   padding: 0 0 $margin-milli;
    //   padding-left: $margin-base;
    // }
  }

  .module-six {
    background: url('~@/assets/clouds.svg') no-repeat, #ffc949;
    background-position: center center;
    background-size: initial;
    overflow-x: hidden;

    @media (min-width: $break-lg) {
      padding: 96px 0 $margin-base;
    }

    .container {

      @media (min-width: $break-md) {
        display: flex;
        align-items: center;
        gap: $margin-mega;
      }
    }

    h2 {
      @media (min-width: $break-md) {
        @include font-settings(peta, mega);
      }
    }
    
    h3 {
      text-align: center;
      margin-top: 96px;
      margin-bottom: 0;
      height: 32px;

      @media (min-width: $break-md) {
        @include font-settings(peta, mega);
        height: 56px;
      }
    }

    span {

      @media (min-width: $break-md) {
        @include font-settings(base, base);
      }
    }

    &__card {
      background: $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      transform: rotate(-7.45deg);
      padding: $margin-base;
      position: relative;
      top: $margin-kilo;
      left: $margin-kilo;
      margin-bottom: $margin-mega;
      width: 400px;
      flex-shrink: 0;

      &__clip {
        position: absolute;
        top: -20px;
        left: 240px;
        height: 80px;
      }

      h4 {
        font-family: $font-opensans;
        color: $green;
        @include font-settings(mega,mega);
        border-bottom: 1px solid $yellow;
      }

      ul {
        margin-top: $margin-base;

        li {
          font-size: 18px;
          line-height: 36px;
          color: $green;
          display: flex;
          align-items: center;
          margin-bottom: $margin-milli;

          &:before {
            content: '';
            width: 32px;
            height: 32px;
            display: inline-block;
            background-image: url('~@/assets/check.svg');
            background-size: contain;
            padding-right: 40px;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .module-seven {

    img {
      width: 100%;
    }
  }

  .module-eight {
    padding: 32px 16px 96px;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      width: 80px;
      margin-bottom: $margin-kilo;
    }

    p {
      text-align: center;
      color: $gray;
      padding: 0 $margin-kilo $margin-base;
      max-width: 872px;
      text-shadow: none;
    }

    button {
      font-family: $font-palanquin;
      text-transform: uppercase;
      color: $yellow;
    }
  }
</style>