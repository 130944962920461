<template>
  <div class="navbar-overlay" @click="setNavbar(false)" v-if="openNavbar"></div>
  <div class="navbar" ref="navbar" v-if="showNav && logged" :class="{'active': openNavbar}">
    <!-- Close -->
    <button class="navbar-icon" v-if="!openNavbar" @click="setNavbar(true)">
      <img src="@/assets/navbar.svg" alt="">
    </button>
    <!-- Open -->
    <nav v-else>
      <button @click="setNavbar(false)">
        <img src="@/assets/collapse.svg" alt="">
      </button>
      <ul>
        <router-link to="/">
          <li :class="{'disabled': routeActive !== 'HOME'}">
            <img src="@/assets/icon-home.svg" alt="">
            <span>home</span>
          </li>
        </router-link>
        <router-link to="/work/jobOffers">
          <li :class="{'disabled': routeActive !== 'WORK'}">
            <img src="@/assets/icon-work.svg" alt="">
            <span class="blue">trabajar</span>
          </li>
        </router-link>
        <router-link to="/learning/routes">
          <li :class="{'disabled': routeActive !== 'LEARNING'}">
            <img src="@/assets/icon-learn.svg" alt="">
            <span class="green">aprender</span>
          </li>
        </router-link>
        <li :class="{'disabled': routeActive !== 'PROFILE'}" @click="goProfile">
          <img src="@/assets/icon-profile.svg" alt="">
          <span class="orange">perfil</span>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import { watch, onMounted } from '@vue/runtime-core';
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const openNavbar = ref(false);
    const routeActive = ref('HOME');
    const showNav = ref(true);
    const logged = computed(() => store.getters.manageUser_logged);
  
    function setNavbar(value) { openNavbar.value = value }

    watch(() => route.path, (value) => {
      routeActive.value = 'HOME';
      if (value.search('learning') > -1) routeActive.value = 'LEARNING';
      if (value.search('work') > -1) routeActive.value = 'WORK';
      if (value.search('profile') > -1) routeActive.value = 'PROFILE';
      showNav.value = !(value.search('gamesExternal') > -1 && !(value.search('genomaResults') > -1));
      openNavbar.value = false;
    });

    onMounted(() => {
      showNav.value = !(route.path.search('gamesExternal') > -1);
    });

    async function goProfile() {
      const force = await store.dispatch('manageUser_forceLogin')
      if (force) return;

      router.push('/profile/resume');
    }

    return {
      routeActive,
      openNavbar,
      setNavbar,
      goProfile,
      showNav,
      logged
    }
  }
}
</script>
<style lang="scss" scoped>
  .navbar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: none;
  }

  .navbar {
    z-index: 3;
    position: fixed;
    left: $margin-base;
    bottom: $margin-base;

    &.active {
      width: calc(100% - 32px);
      max-width: 480px;
    }
      
    &-icon {
      width: 64px;
      height: 64px;
      background: $white;
      border-radius: 100%;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      padding: $margin-small;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    nav {
      border: 3px solid white;
      background: linear-gradient(178.53deg, #FFFFFF 3.48%, #F2F2F2 140.42%);
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      display: flex;
      padding: 3px $margin-kilo;
      align-items: center;
      justify-content: center;
      position: relative;

      button {
        position: absolute;
        left: $margin-milli;
        padding: 0;
      }

      .disabled {
        img {
          filter: grayscale(100%);
          opacity: .6;
        }
        span {
          color: $lightgray!important;
        }
      }

      img {
        height: 32px;
      }

      ul {
        display: flex;
        gap: $margin-base;
        align-items: center;
        justify-content: center;

        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          cursor: pointer;
  
          span {
            color: $red;
            text-transform: uppercase;
            font-family: $font-palanquin;
            font-weight: 700;
            @include font-settings(xmilli,base);

            &.blue {
              color: $blue;
            }

            &.green {
              color: $green;
            }

            &.yellow {
              color: $yellow;
            }

            &.orange {
              color: $orange;
            }
          }
        }
      }
    }
  } 
</style>