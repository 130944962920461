<template>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <div class="login__circle">
          <img src="@/assets/passport.svg" alt="">
        </div>
        <h3>{{ $t("register.step.third.title") }}</h3>
        <p v-html="$t('register.step.third.description')"></p>
        <div class="password_container">
          <input :type="passwordType" :placeholder="$t('register.step.third.input_password')" autocomplete="off" v-model="password" :class="{'input-invalid': v$.password.$invalid && submitted}">
          <i v-if="passwordType == 'password'" class="material-icons" @click.stop="passwordType = 'text'">visibility</i>
          <i v-else class="material-icons" @click.stop="passwordType = 'password'">visibility_off</i>
        </div>
        <span class="input-message" v-if="v$.password.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.password.required.$message}}</span>
        
        <input :type="passwordType" :placeholder="$t('register.step.third.input_repeatPassword')" autocomplete="off" v-model="passwordConfirm" :class="{'input-invalid': v$.passwordConfirm.$invalid && submitted}">
        <span class="input-message" v-if="v$.passwordConfirm.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.passwordConfirm.required.$message}}</span>

        <button @click="continueRegister"  class="button button--red button--register">{{ $t("register.next") }}</button>
      </div>
    </div>
  </div> 
  <BulletsRegister :active="3"/>
</template>
<script>
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { computed, ref } from 'vue';
  import BulletsRegister from '@/components/Register/BulletsRegister';
  import useVuelidate from '@vuelidate/core';
  import { required, sameAs, helpers } from '@vuelidate/validators';

  function validPassword(password) {
    let validPassword = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{6,32})$/); //eslint-disable-line
    if (validPassword.test(password)) return true;
    return false;
  }

  export default {
    components: {
      BulletsRegister
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const submitted = ref(false);
      const v$ = useVuelidate();
      const passwordType = ref('password');
      const password = computed({
        get: () => store.state.Register.userModel.password,
        set: (value) => store.commit('register_updateField', {field: 'password', value})
      });
      const passwordConfirm = computed({
        get: () => store.state.Register.userModel.passwordConfirm,
        set: (value) => store.commit('register_updateField', {field: 'passwordConfirm', value})
      });

      function continueRegister() {
        submitted.value = true;
        if (!v$.value.password.$invalid && !v$.value.passwordConfirm.$invalid) {
          submitted.value = false;
          router.push('/register/termsAndConds');
        }
      }

      return {
        password,
        passwordConfirm,
        continueRegister,
        passwordType,
        submitted,
        v$
      }
    },
    validations () {
      return {
        password: { 
          required: helpers.withMessage('Este campo es requerido', required), 
          validPassword 
        },
        passwordConfirm: { 
          required: helpers.withMessage('Este campo es requerido', required), 
          sameAsPassword: sameAs(this.password) 
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.password_container{
    margin-bottom: 16px;
    position: relative;
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 2px solid #fff;
    .material-icons{
      position: absolute;
      top: 50%;
      right: 0;
      padding-right: 8px;
      transform: translateY(-50%);
      color: #fff;
      font-size: 28px;
      cursor: pointer;
    }
    input{
      margin: 0;
      flex-grow: 1;
      border: none;
    }
  }
  input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus{
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff;
    font-size: 16px !important;
  }
</style>