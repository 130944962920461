<template>
    <div class="main-container">
        <div class="tabs">
            <button :class="{'tabs--active': !showFinished}" @click="showFinished=false">En progreso</button>
            <button :class="{'tabs--active': showFinished}" @click="showFinished=true">Finalizados</button>
        </div>

        <div v-if="!showFinished" class="courses-card">
            <span v-if="!areCoursesInProgress" class="courses-card__no-courses">No tienes cursos en progreso...</span>
            <template  v-for="(route,idx) in courses" :key="idx">
                <div v-if="!route.finished && route.courses.some(course => !course.finished)" class="courses-card__route">
                    <div class="courses-card__route__name" v-if="route.learningRouteName !=null"><span>{{route.learningRouteName}}</span></div> 
                    <template v-for="(course,idx) in route.courses" :key="idx">
                        <div class="courses-card__course" v-if="!course.finished">
                            <div class="courses-card__course__image" :style="`background-image: url(${course.image})`"></div>
                            <div class="courses-card__course__title">
                                <span>{{course.title}}</span>
                                <span>Iniciado: {{formatDate(course.time)}}</span>
                            </div>       
                            <div class="courses-card__course__btns">
                                <button class="courses-card__course__btns__finish" @click="course.finished=true;updateCourses();">
                                    <i class="material-icons">done</i>
                                    <span>Finalizado</span>
                                </button>
                                <button class="courses-card__course__btns__delete" @click="route.courses.splice(idx,1);updateCourses()">
                                    <i class="material-icons">delete</i>
                                    <span>Eliminar</span>
                                </button>
                            </div>
                            <div class="courses-card__course__btn-actions">
                                <i class="material-icons" @click.stop="course.modalActions=true;">more_horiz</i>
                            </div>
                            <div v-if="course.modalActions" class="courses-card__course__modal-actions">
                                <div class="courses-card__course__modal-actions__close">
                                    <i class="material-icons" @click.stop="course.modalActions=false">close</i>
                                </div>
                                <button @click.stop="course.finished=true;updateCourses();">
                                    <i class="material-icons">done</i>
                                    <span>Finalizado</span>
                                </button>
                                <button @click.stop="route.courses.splice(idx,1);updateCourses()">
                                    <i class="material-icons">delete</i>
                                    <span>Eliminar</span>
                                </button>                               
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </div>

        <div v-else class="courses-card">
            <span v-if="!areCoursesFinished" class="courses-card__no-courses">No tienes cursos finalizados...</span>
            <template  v-for="(route,idx) in courses" :key="idx">
                <div v-if="route.courses.some(course => course.finished)" class="courses-card__route">
                    <div class="courses-card__route__name" v-if="route.learningRouteName !=null"><span>{{route.learningRouteName}}</span></div> 
                    <template v-for="(course,idx) in route.courses" :key="idx">
                        <div class="courses-card__course" v-if="course.finished">
                            <div class="courses-card__course__image" :style="`background-image: url(${course.image})`"></div>
                            <div class="courses-card__course__title">
                                <span>{{course.title}}</span>
                                <span>Iniciado: {{formatDate(course.time)}}</span>
                            </div>       
                            <div class="courses-card__course__btns">
                                <button class="courses-card__course__btns__delete" @click="route.courses.splice(idx,1);updateCourses()">
                                    <i class="material-icons">delete</i>
                                    <span>Eliminar</span>
                                </button>
                            </div>
                            <div class="courses-card__course__btn-actions">
                                <i class="material-icons" @click.stop="course.modalActions=true">more_horiz</i>
                            </div>
                            <div v-if="course.modalActions" class="courses-card__course__modal-actions">
                                <div class="courses-card__course__modal-actions__close">
                                    <i class="material-icons" @click.stop="course.modalActions=false">close</i>
                                </div>
                                <button class="courses-card__course__modal-actions__finished-delete" @click.stop="route.courses.splice(idx,1);updateCourses()">
                                    <i class="material-icons">delete</i>
                                    <span>Eliminar</span>
                                </button>                               
                            </div>
                        </div>
                    </template>
                </div>
            </template>           
        </div>

    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { onMounted, computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
import moment from 'moment';
export default {
    setup() {
        const store = useStore();
        let getCourses = computed(() => [...store.getters.profile_courses]);
        const showFinished = ref(false);
        const courses = ref([]);
        const areCoursesInProgress = computed(()=>{
            let are; 
            courses.value.forEach(route =>{
                route.courses.forEach(course=>{
                    if(!course.finished){
                        are = true;
                    }
                })
            })
            if(are) return true;
            else return false;
        })
        const areCoursesFinished = computed(()=>{
            let are; 
            courses.value.forEach(route =>{
                route.courses.forEach(course=>{
                    if(course.finished){
                        are = true;
                    }
                })
            })
            if(are) return true;
            else return false;
        })

        onMounted(async ()=>{
            if(getCourses.value.length == 0){
                let res = await store.dispatch('profile_profile');
                store.commit('profile_updateProfile',res);
            }
            courses.value = getCourses.value;
        });

        function formatDate(date){
            return moment(date).format('DD/MM/YYYY');
        }

        async function updateCourses(){
            let onlyCourses = []
            courses.value.forEach(route =>{
                onlyCourses.push(...route.courses);
            });
            let res = await store.dispatch('learning_learningItems_users',onlyCourses);
            store.commit('profile_updateLearningProgress',res);
        }

        return{
            showFinished,
            courses,
            formatDate,
            updateCourses,
            areCoursesInProgress,
            areCoursesFinished
        }
    },
}
</script>

<style lang="scss" scoped>
.main-container {
    min-height: 100vh;
    background: url('~@/assets/profile-bg.svg'), linear-gradient(180deg, #E87B1B 0%, #FFC905 100%);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 1270px, auto;
    padding: $margin-small;
    padding-bottom: 96px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .tabs{
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        gap: 24px;
        button{
            width: 204px;
            height: 40px;
            border-radius: 210px;
            font-family: Palanquin Dark;
            font-size: 18px;
            font-weight: 600;
            line-height: 16px;
            font-style: normal;
            color: #fff;
            text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
        }
        &--active{
            background-color: #E25523;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }
    .courses-card {
        width: 100%;
        max-width: 800px;
        background: $white;
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
        padding: $margin-base;
        &__no-courses{
            font-family: Palanquin Dark;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $orange;
        }
        &__route{
            border-bottom: 2px solid #E0E0E0;
            &:last-child{
                border: none;
            }
            &__name{
                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                padding: 8px 0;
                span{
                    font-family: Palanquin Dark;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 19px;
                    color: $green;
                }
            }
        }
        &__course{
            position: relative;
            padding: 16px 0;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            gap: 18px;
            &__image {
                width: 76px;
                height: 76px;
                background: $white;
                border-radius: 50%;
                border: 3px solid $orange;
                flex-shrink: 0;
                background-size: cover;
                background-position: center center;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            }
            &__title{
                display: flex;
                flex-flow: column nowrap;
                span:first-child{
                    font-family: Palanquin Dark;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    color: $yellow;
                }
                span:last-child{
                    font-family: Palanquin Dark;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    color: $mediumgray;
                }
            }
            &__btns{
                flex-grow: 1;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                gap: 8px;
                @media (max-width: $break-sm) {
                    display: none;
                }
                button{
                    width: 136px;
                    height: 33px;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;
                    border-radius: 20px;
                }
                &__finish{
                    background: $orange;
                    i{
                        color: #fff;
                    }
                    span{
                        font-family: Palanquin Dark;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        color: #fff;
                    }
                }
                &__delete{
                    border: 1px solid $orange;
                    i{
                        color: $orange;
                    }
                    span{
                        color: $orange;
                    }
                }
            }
            &__btn-actions{
                flex-grow: 1;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-end;
                @media (min-width: $break-sm) {
                    display: none;
                }
                i{
                    font-size: 38px;
                    color: $mediumgray;
                    line-height: 1px;
                }
            }
            &__modal-actions{
                position: absolute;
                right: 0;
                width: 153px;
                height: 88px;
                padding-bottom: 4px;
                display: flex;
                flex-flow: column nowrap;
                justify-content: space-evenly;
                background-color: #fff;
                box-shadow: -1px 2px 4px rgb(0 0 0 / 25%);
                border-radius: 10px;
                &__close{
                    height: fit-content;
                    padding: 4px 4px 0 0;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-end;
                    i{
                        line-height: 16px;
                    }
                }
                button{
                    width: 100%;
                    padding-left: 16px;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 4px;
                    cursor: pointer;
                    i{
                        color: $mediumgray;
                    }
                    span{
                        font-family: Palanquin Dark;
                        font-weight: 500;
                        font-size: 17px;
                        letter-spacing: 1px;
                        color: $mediumgray;              
                    }
                }
                &__finished-delete{
                    padding-bottom: 16px;
                }
            }
        }
    }
}

</style>