<template>
  <div class="learning">
    <div class="container">
      <!-- Ahora este contenido tiene que ir en un modal la primera vez que accedes. Esta en la home -->
      <!-- <div class="learning__intro">
        <h2>{{ $t("learning.title") }}</h2>
        <p>{{ $t("learning.description") }}</p>
        <img src="@/assets/duolingo.png" alt="Duolingo">  
      </div> -->

      <div class="tabs">
        <button class="tabs__tab" @click="changeTab('routes')" :class="{'active': path == '/learning/routes'}">{{ $t("learning.tabs.routes") }}</button>
        <button class="tabs__tab" @click="changeTab('courses')" :class="{'active': path == '/learning/courses'}">{{ $t("learning.tabs.courses") }}</button>
      </div>
    </div>
    <RouterView />
  </div>
</template>
<script>
import { computed } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const path = computed(() =>route.path)

    function changeTab(params) {
      router.push(params);
    }

    return {
      changeTab,
      path
    }
  }  
}
</script>

<style lang="scss">
  .learning {
    min-height: 100vh;
    background: url('~@/assets/learning-bg.svg'), linear-gradient(180deg, #04A190, #80D8D1);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 1270px, auto;
    padding-bottom: 96px;

    h3 {
      padding: 0 $margin-base;

      @media (min-width: $break-sm) {
        padding: 0;
      }
    }

    &__intro {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      h2 {
        margin: $margin-tera $margin-xterabig $margin-tera;
      }

      img {
        mix-blend-mode: multiply;
        width: 200px;
        margin-top: $margin-kilo;
        align-self: flex-end;
      }

      p {
        max-width: 300px;
        margin-bottom: 48px;
      }
    }

    .tabs__tab.active {
      background: $darkgreen;
    }
  }
</style>