<template>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <h1>{{ $t("login.recoveryPassword.title") }}</h1>
        <p>{{ step === 'begin' ? $t("login.recoveryPassword.description") : $t("login.recoveryPassword.endDescription") }}</p>
        
        <template v-if="step === 'begin'">
          <input type="text" :placeholder="$t('login.input_email')" v-model="email" :class="{'input-invalid': v$.email.$invalid && submitted}">
          <span class="input-message" v-if="v$.email.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.required.$message}}</span>
          <span class="input-message" v-if="v$.email.email.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.email.$message}}</span>

          <button @click="beginRecoveryPassword" class="button button--red button--register" :disabled="awaitRequest">{{ awaitRequest  ? $t("login.actionDisabled") : $t("login.recoveryPassword.action") }}</button>  
        </template>
        <template v-else>
          <input type="text" autocomplete="off" :placeholder="$t('register.step.confirm.input_code')" v-model="activationCode" :class="{'input-invalid': v$.activationCode.$invalid && submitted}">
          <span class="input-message" v-if="v$.activationCode.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.activationCode.required.$message}}</span>

          <input type="text" :placeholder="$t('login.input_email')" v-model="email" :class="{'input-invalid': v$.email.$invalid && submitted}">
          <span class="input-message" v-if="v$.email.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.required.$message}}</span>
          <span class="input-message" v-if="v$.email.email.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.email.$message}}</span>

          <div class="password_container">
            <input :type="passwordType" :placeholder="$t('register.step.third.input_password')" v-model="password" :class="{'input-invalid': v$.password.$invalid && submitted}">
            <i v-if="passwordType == 'password'" class="material-icons" @click.stop="passwordType = 'text'">visibility</i>
            <i v-else class="material-icons" @click.stop="passwordType = 'password'">visibility_off</i>
          </div>
          <span class="input-message" v-if="v$.password.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.password.required.$message}}</span>
          
          <input :type="passwordType" :placeholder="$t('register.step.third.input_repeatPassword')" v-model="passwordConfirm" :class="{'input-invalid': v$.passwordConfirm.$invalid && submitted}">
          <span class="input-message" v-if="v$.passwordConfirm.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.passwordConfirm.required.$message}}</span>
          
          <button @click="endRecoveryPassword" class="button button--red button--register" :disabled="awaitRequest">{{ awaitRequest  ? $t("login.actionDisabled") : $t("register.step.confirm.action") }}</button>  
          <small v-html="$t('register.step.third.description')"></small>
        </template>
      </div>
    </div>
  </div>
</template> 
<script>
import { ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email, sameAs, helpers } from '@vuelidate/validators';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

function validPassword(password) {
  let validPassword = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{6,32})$/); //eslint-disable-line
  if (validPassword.test(password)) return true;
  return false;
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const v$ = useVuelidate();
    const { t } = useI18n();
    const email = ref("");
    const activationCode = ref("");
    const password = ref("");
    const passwordConfirm = ref("");
    const submitted = ref(false);
    const passwordType = ref('password');
    let awaitRequest = ref(false);
    let step = ref('begin');

    async function beginRecoveryPassword() {
      submitted.value = true;
      if (!v$.value.email.$invalid) {
        awaitRequest.value = true;
        try {
          await store.dispatch('security_beginPasswordRecovery', email.value);
          step.value = 'end';
          submitted.value = false;
          awaitRequest.value = false;
        } catch (error) {
          submitted.value = false;
          awaitRequest.value = false;
          store.dispatch('notifications_create', { text: t(`notifications.${error.response.data}`) });
        }
      }
    }

    async function endRecoveryPassword() {
      submitted.value = true;
      if (!v$.value.email.$invalid && !v$.value.activationCode.$invalid && !v$.value.password.$invalid && !v$.value.passwordConfirm.$invalid) {
        awaitRequest.value = true;
        const model = {
          activationCode: activationCode.value,
          password: password.value,
          passwordConfirm: passwordConfirm.value,
          email: email.value
        };
        try {
          await store.dispatch('security_endPasswordRecovery', model);
          awaitRequest.value = false;
          submitted.value = false;
          step.value = 'begin';
          store.dispatch('notifications_create', { text: t(`notifications.recoveryPassword`) });
          router.push('/login');
        } catch (error) {
          awaitRequest.value = false;
          submitted.value = false;
          store.dispatch('notifications_create', { text: error.response.data });
        }
      }
    }

    return {
      email,
      activationCode,
      password,
      passwordConfirm,
      submitted,
      awaitRequest,
      beginRecoveryPassword,
      endRecoveryPassword,
      v$,
      step,
      passwordType
    }
  },
  validations () {
    return {
      email: { 
        required: helpers.withMessage('Este campo es requerido', required),
        email: helpers.withMessage('El formato del mail es invalido', email)
      },
      activationCode: { 
        required: helpers.withMessage('Este campo es requerido', required)
      },
      password: { 
        required: helpers.withMessage('Este campo es requerido', required), 
        validPassword 
      },
      passwordConfirm: { 
        required: helpers.withMessage('Este campo es requerido', required), 
        sameAsPassword: sameAs(this.password) 
      } 
    }
  }
}
</script>
<style lang="scss" scoped>
h1{
  text-align: center;
}
.password_container{
    margin-bottom: 16px;
    position: relative;
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 2px solid #fff;
    .material-icons{
      position: absolute;
      top: 50%;
      right: 0;
      padding-right: 8px;
      transform: translateY(-50%);
      color: #fff;
      font-size: 28px;
      cursor: pointer;
    }
    input{
      margin: 0;
      flex-grow: 1;
      border: none;
    }
  }
  input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus{
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff;
    font-size: 16px !important;
  }
</style>