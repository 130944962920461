import { createRouter, createWebHistory } from 'vue-router';
import persistentData from '@/plugins/persistentData';
import Home from '@/views/Home/Main.vue';
import Profile from '@/views/Profile/Main.vue';
import ProfileResume from '@/views/Profile/Resume.vue';
import ProfileMyInformation from '@/views/Profile/MyInformation.vue';
import ProfileMyBestVersion from '@/views/Profile/MyBestVersion.vue';
import ProfileCertificates from '@/views/Profile/Certificates/Main.vue';
import ProfileProgressLearning from '@/views/Profile/ProgressLearning/Main.vue';
import ProfileReferences from '@/views/Profile/References/Main.vue';
import ProfileReferencesCreate from '@/views/Profile/References/Create.vue';
import ProfileSkills from '@/views/Profile/Skills/Main.vue';
import ProfileSkillsDetail from '@/views/Profile/Skills/Detail.vue';
import ProfileMyEducation from '@/views/Profile/MyEducation/Main.vue';
import ProfileWorkExperience from '@/views/Profile/WorkExperience/Main.vue';
import ProfileMyCV from '@/views/Profile/MyCV.vue';
import Activate from '@/views/Login/Active.vue';
import Register from '@/views/Register/Main.vue';
import RegisterStepFirst from '@/views/Register/Steps/First.vue';
import RegisterStepSecond from '@/views/Register/Steps/Second.vue';
import RegisterStepThird from '@/views/Register/Steps/Third.vue';
import RegisterTermsAndConds from '@/views/Register/Steps/TermsAndConds.vue';
import RegisterActivate from '@/views/Register/Steps/Activate.vue';
import LoginRedirectFacebook from '@/views/Login/RedirectFacebook.vue';
import LoginOnboardingSocial from '@/views/Login/OnboardingSocial.vue';
import Login from '@/views/Login/Main.vue';
import LoginRecoveryPassword from '@/views/Login/RecoveryPassword.vue';
import TripRegister from '@/views/Trip/Register.vue';
import TripForgeEducadoresRegister from '@/views/Trip/RegisterForgeEducadores.vue';
import IIVVORegister from '@/views/IIVVO/Register.vue';
import Learning from '@/views/Learning/Main.vue';
import LearningRoutes from '@/views/Learning/Routes.vue';
import LearningCourses from '@/views/Learning/Courses.vue';
import LearningDetailRoute from '@/views/Learning/DetailRoute.vue';
import LearningDetailCourse from '@/views/Learning/DetailCourse.vue';
import DetailJob from '@/views/Work/DetailJob.vue';
import Work from '@/views/Work/Main.vue';
import JobOffers from '@/views/Work/JobOffers.vue';
import MyApplications from '@/views/Work/MyApplications.vue';
import SavedOffers from '@/views/Work/SavedOffers.vue';
import GamesExternal from '@/views/GamesExternal/Main.vue';
import GenomaResults from '@/views/GamesExternal/GenomaResults.vue';
import SharedGameResults from '@/views/GamesExternal/SharedGameResults.vue'
import RedirectCampus from '@/views/Login/RedirectCampus.vue'

const guard = (to, from, next) => {
  if (persistentData.get('token') !== null) {
    next()
  } else {
    next('/')
  }
};

const guardRegistration = (to, from, next) => {
  const registrationReference = persistentData.get('registrationReference')
  if (persistentData.get('token') !== null && registrationReference != null && registrationReference.toLowerCase() !== 'trip' && registrationReference.toLowerCase() !== 'iivvo') {
    next();
  } else {
    if (registrationReference != null && registrationReference.toLowerCase() === 'trip') {
      persistentData.get('token') !== null ? next('/gamesExternal/TRIP/trip') : next('/login');
    } else if (registrationReference != null && registrationReference.toLowerCase() === 'iivvo') {
      persistentData.get('token') !== null ? next('/gamesExternal/IIVVO/IIVVO') : next('/login');
    }
    to.path === '/' ? next() : next('/');
  }
}

const routes = [
  { path: '/', component: Home, name: 'HOME', beforeEnter: guardRegistration },
  { path: '/profile', component: Profile, children: [
    { path: 'resume', component: ProfileResume, name: 'PROFILE_RESUME', beforeEnter: guardRegistration },
    { path: 'myInformation', component: ProfileMyInformation, name: 'PROFILE_MY_INFORMATION', beforeEnter: guardRegistration },
    { path: 'myBestVersion', component: ProfileMyBestVersion, name: 'PROFILE_MY_BEST_VERSION', beforeEnter: guardRegistration },
    { path: 'myCV', component: ProfileMyCV, name: 'PROFILE_MY_CV', beforeEnter: guardRegistration },
  ]},
  { path: '/profile/certificates', component: ProfileCertificates, name: 'PROFILE_CERTIFICATES', beforeEnter: guardRegistration },
  { path: '/profile/progressLearning', component: ProfileProgressLearning, name: 'PROFILE_PROGRESS_LEARNING', beforeEnter: guardRegistration },
  { path: '/profile/references', component: ProfileReferences, name: 'PROFILE_REFERENCES', beforeEnter: guardRegistration },
  { path: '/profile/references/create', component: ProfileReferencesCreate, name: 'PROFILE_REFERENCES_CREATE', beforeEnter: guardRegistration },
  { path: '/profile/skills', component: ProfileSkills, name: 'PROFILE_SKILLS', beforeEnter: guardRegistration },
  { path: '/profile/skills/detail/:id', component: ProfileSkillsDetail, name: 'PROFILE_SKILLS_DETAILS', beforeEnter: guardRegistration },
  { path: '/profile/myEducation', component: ProfileMyEducation, name: 'PROFILE_MY_EDUCATION', beforeEnter: guardRegistration },
  { path: '/profile/workExperience', component: ProfileWorkExperience, name: 'PROFILE_WORK_EXPERIENCE', beforeEnter: guardRegistration },
  { path: '/activate', component: Activate, name: 'ACTIVATE' },
  { path: '/register', component: Register, name: 'REGISTER' },
  { path: '/register/first', component: RegisterStepFirst, name: 'REGISTER_FIRST' },
  { path: '/register/second', component: RegisterStepSecond, name: 'REGISTER_SECOND' },
  { path: '/register/third', component: RegisterStepThird, name: 'REGISTER_THIRD' },
  { path: '/register/termsAndConds', component: RegisterTermsAndConds, name: 'REGISTER_TERMS' },
  { path: '/register/activate', component: RegisterActivate, name: 'REGISTER_ACTIVE' },
  { path: '/login/redirect-facebook', component: LoginRedirectFacebook },
  { path: '/onboardingSocial', component: LoginOnboardingSocial, name: 'ONBOARDING_SOCIAL' },
  { path: '/login', component: Login, name: 'LOGIN'},
  { path: '/login/recoveryPassword', component: LoginRecoveryPassword, name: 'LOGIN_RECOVERY_PASSWORD' },
  { path: '/trip-OD', component: TripRegister, name: 'TRIP_REGISTER_BY_ONDEMAND' },
  { path: '/trip/:countryCode', component: TripRegister, name: 'TRIP_REGISTER' },
  { path: '/trip/:countryCode/:loginOptionCode', component: TripRegister, name: 'TRIP_REGISTER_BY_INSTITUTION' },
  { path: '/tripeducadores/:loginOptionCode/:activityIdentifier/:contentId', component: TripForgeEducadoresRegister, name: 'TRIP_FORGE_EDUCADORES_DOCENTEALUMNO' },
  { path: '/tripeducadores/:loginOptionCode/:activityIdentifier/:contentId/:countryCode', component: TripForgeEducadoresRegister, name: 'TRIP_FORGE_EDUCADORES_DOCENTE' },
  { path: '/IIVVO/:countryCode', component: IIVVORegister, name: 'IIVVO_REGISTER' },
  { path: '/learning/routes/:id', component: LearningDetailRoute, name: 'LEARN_ROUTE_DETAIL', beforeEnter: guardRegistration },
  { path: '/learning/courses/:type/:id', component: LearningDetailCourse, name: 'LEARN_COURSE_DETAIL', beforeEnter: guardRegistration },
  { path: '/learning', component: Learning, name: 'LEARN', children: [
    {path: 'routes', component: LearningRoutes, name: 'LEARN_ROUTE', beforeEnter: guardRegistration },
    {path: 'courses', component: LearningCourses, name: 'LEARN_COURSES', beforeEnter: guardRegistration }
  ]},
  { path: '/work/jobOffers/:id', component: DetailJob, name: 'WORK_JOB_DETAIL' },
  { path: '/work', component: Work, name: 'WORK', children: [
    {path: 'jobOffers', component: JobOffers, name: 'WORK_JOBS_OFFERS', beforeEnter: guardRegistration },
    {path: 'myApplications', component: MyApplications, name: 'WORK_MY_APPLICATIONS', beforeEnter: guardRegistration },
    {path: 'saved', component: SavedOffers, name: 'WORK_SAVED_OFFERS', beforeEnter: guardRegistration }
  ]},
  /* Genoma Results */
  { path: '/gamesExternal/genomaResults', component: GenomaResults, name: 'GAMES_EXTERNAL_GENOMA_RESULTS', beforeEnter: guardRegistration },
  { path: '/gamesExternal/shareGenoma/:gameIdentification', component: GenomaResults, name: 'SHARE_GENOMA_RESULTS' },
  { path: '/gamesExternal/:gameCode', component: GamesExternal, name: 'GAMES_EXTERNAL', beforeEnter: guardRegistration},
  { path: '/gamesExternal/:gameCode/trip', component: GamesExternal, name: 'GAMES_EXTERNAL_RUKY', beforeEnter: guard},
  { path: '/gamesExternal/:gameCode/IIVVO', component: GamesExternal, name: 'GAMES_EXTERNAL_IIVVO', beforeEnter: guard},
  { path: '/gamesExternal/:gameCode/:resultType', component: GamesExternal, name: 'GAMES_EXTERNAL_RUKY', beforeEnter: guard},
  /* Share game result*/
  { path: '/games/TRIP/:gameIdentification', component: SharedGameResults, name: 'GAMES_SHARE_TRIP' },
  { path: '/games/IIVVO/:gameIdentification', component: SharedGameResults, name: 'GAMES_SHARE_IIVVO' },
  { path: '/games/GENOMA/:gameIdentification', component: SharedGameResults, name: 'GAMES_SHARE_GENOMA' },
  { path: '/:catchAll(.*)', redirect: '/' },
  { path: '/login/campus', component: RedirectCampus },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () { window.scrollTo(0, 0) }
});

export default router;