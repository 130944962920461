<template>
  <div class="profile__work-experience">
    <div class="container main-container" v-if="completeLoad">
      <div class="profile__card">
        <div class="profile__card__header profile__card__header--space">
          <div class="profile__card__header__title">
            <i class="material-icons">person</i>
              {{ $t("profile.my_education.title") }}
          </div>
        </div>
        <strong v-if="study.academics.length === 0 && study.languages.length === 0">{{ $t("profile.my_education.title") }}</strong>

          <div>

            <!-- Terciario -->
            <div v-if="hasAcademic">
              <div class="card-detail__separator profile__card--personal-edit__fullrow">
                <div class="card-detail__separator__title">{{ $t("profile.my_education.academic") }}</div>
              </div>
              <template  v-for="(academic, index) in study.academics" :key="index">
                <template v-if="academic.studyLevel>=3">
                  <div class="card-study">
                    <div class="card-study__content">
                      <div class="profile__card--personal-edit__fullrow">
                        <strong>{{ academic.educationalInstitutionId == -1 ? academic.educationalInstitutionOther : getInstitution(academic.educationalInstitutionId) }}</strong>
                      </div>
                      <div class="profile__card--personal-edit__fullrow">{{ academic.educationSpecialtyId == -1 ? academic.educationSpecialtyOther : getSpeciality(academic.educationSpecialtyId, 2) }}</div>
                      <span v-if="academic.subjectsLefts>0">{{ $t("profile.my_education.subjectsLefts") }}: {{academic.subjectsLefts}}</span>
                      <span v-if="academic.averageScore>0">{{ $t("profile.my_education.averageScore") }}: {{academic.averageScore}}</span>
                      <span>{{ academic.fromYear }} - {{ academic.toYear == null ? $t('profile.present') : academic.toYear }}</span>
                    </div>                    
                    <div class="card-study__btns">
                      <i class="material-icons profile__card__header__arrow" @click="editMode(index, 'academics')">edit</i>
                      <i class="material-icons profile__card__header__arrow" @click="modalDelete('academics', academic.id, `${academic.educationalInstitutionId == null ? academic.educationalInstitutionOther : getInstitution(academic.educationalInstitutionId)}`)">delete</i>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <!-- /Terciario -->

            <!-- Secundario -->
            <div v-if="hasSecondary">
              <div class="card-detail__separator profile__card--personal-edit__fullrow" >
                <div class="card-detail__separator__title">{{ $t("profile.my_education.secondary") }}</div>
              </div>
              <template v-for="(secondarySchool, index) in study.academics" :key="index">
                <template v-if="secondarySchool.studyLevel<=2">
                  <div class="card-study">
                    <div class="card-study__content">
                      <strong> {{ secondarySchool.educationalInstitutionId == -1 ? secondarySchool.educationalInstitutionOther : getInstitution(secondarySchool.educationalInstitutionId) }} </strong>
                      <div v-if="secondarySchool.educationSpecialtyId !=null">{{ secondarySchool.educationSpecialtyId == -1 ? secondarySchool.educationSpecialtyOther : getSpeciality(secondarySchool.educationSpecialtyId, 1) }}</div>
                      <span v-if="secondarySchool.subjectsLefts>0">{{ $t("profile.my_education.subjectsLefts") }}: {{secondarySchool.subjectsLefts}}</span>
                      <span v-if="secondarySchool.averageScore>0">{{ $t("profile.my_education.averageScore") }}: {{secondarySchool.averageScore}}</span>
                      <span>{{ secondarySchool.fromYear }} - {{ secondarySchool.toYear == null ? $t('profile.present') : secondarySchool.toYear }}</span>                    
                    </div> 
                    <div class="card-study__btns">
                      <i class="material-icons profile__card__header__arrow" @click="editMode(index, 'secondarySchool')">edit</i>
                      <i class="material-icons profile__card__header__arrow" @click="modalDelete('academics', secondarySchool.id, `${secondarySchool.educationalInstitutionId == null ? secondarySchool.educationalInstitutionOther : getInstitution(secondarySchool.educationalInstitutionId)}`)">delete</i>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <!-- /Secundario -->

            <!-- Idiomas -->
            <div v-if="study.languages.length > 0">
              <div class="card-detail__separator profile__card--personal-edit__fullrow">
                <div class="card-detail__separator__title">{{ $t("profile.my_education.language") }}</div>
              </div>
              <div class="card-study" v-for="(language, index) in study.languages" :key="index">
                <div class="card-study__content">
                  <div>
                    <strong>{{getLanguageKnowledge(language.languageKnowledgeId)}} {{getLanguageKnowledge(language.languageKnowledgeId) == 'Otro' ? `- ${language.languageKnowledgeName}` : ''}}</strong>
                  </div>
                  <div>
                    <span>{{ $t(`profile.myInformation.language.${language.level}`) }}</span>
                  </div>
                </div>
                <div class="card-study__btns">
                  <i class="material-icons profile__card__header__arrow" @click="editMode(index, 'languages')">edit</i>
                  <i class="material-icons profile__card__header__arrow" @click="modalDelete('languages', language.id, getLanguageKnowledge(language.languageKnowledgeId))">delete</i>
                </div>
              </div>
            </div>
            <!-- /Idiomas -->

          </div>
          <div class="btn-add_container">
            <button class="profile__card__button" @click="modalNewOptions = true"><i class="material-icons">add</i>agregar nueva</button>
          </div>
      </div>
    </div>
  </div>

  <!-- Modal New -->
  <div v-if="modalNewOptions">
    <VisibleModal/>
    <div class="modal__overlay" @click.stop="modalNewOptions = false">
      <div class="modal" @click.stop="''">
        <span class="material-icons" @click.stop="modalNewOptions = false">close</span>
        <div class="modal__options">
          <button @click="openModalNew('secondarySchool')">{{ $t("profile.my_education.secondary") }}</button>
          <button @click="openModalNew('academics')">{{ $t("profile.my_education.academic") }}</button>
          <button @click="openModalNew('languages')">{{ $t("profile.my_education.language") }}</button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="modalNewTypeActive !== ''">
    <VisibleModal/>
    <div class="modal__overlay">
      <div class="modal" @click.stop="''">
        <div class="modal__header">
         {{ !editModeActive ? $t("profile.my_education.addNewEducation") : $t("profile.my_education.editEducation")}}
        </div>

        <!-- Universitario -->
        <div class="modal__content" v-if="modalNewTypeActive === 'academics'">
          <div class="modal__content__separator">
            <div class="modal__content__separator__title">{{ $t("profile.my_education.academic") }}</div>
          </div>
          <div class="profile__card--personal-edit">
            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.my_education.educationInstitution") }}</span>
              <Multiselect v-model="modelNewStudy.educationalInstitutionId"
              :options="listAcademicInstitutions" :placeholder="$t('profile.my_education.educationInstitution')" trackBy="name" valueProp="id" label="name"
              :closeOnSelect="true" :searchable="true" @select="modelNewStudy.educationalInstitutionOther = ''" :class="{'input-invalid': v$.modelNewStudy.educationalInstitutionId.$invalid && submitted}">
              </Multiselect>
             </div>
            <div class="profile__card--personal-edit__fullrow" v-if="modelNewStudy.educationalInstitutionId == -1">
              <span>{{ $t("profile.my_education.educationName") }}</span>
              <input type="text" :placeholder="$t('profile.my_education.educationName')" v-model="modelNewStudy.educationalInstitutionOther" :class="{'input-invalid': v$.modelNewStudy.educationalInstitutionOther.$invalid && submitted}">
            </div>

            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.my_education.educationSpeciality") }}</span>
              <Multiselect v-model="modelNewStudy.educationSpecialtyId" 
              :options="listEducationSpecialtyType2" :placeholder="$t('profile.my_education.educationSpeciality')" trackBy="name" valueProp="id" label="name"
              :closeOnSelect="true" :searchable="true" @select="modelNewStudy.educationSpecialtyOther = ''" :class="{'input-invalid': v$.modelNewStudy.educationSpecialtyId.$invalid && submitted}">
              </Multiselect>
            </div>
            <div class="profile__card--personal-edit__fullrow" v-if="modelNewStudy.educationSpecialtyId == -1">
              <span>{{ $t("profile.my_education.nameSpeciality") }}</span>
              <input type="text" :placeholder="$t('profile.my_education.nameSpeciality')" v-model="modelNewStudy.educationSpecialtyOther" :class="{'input-invalid': v$.modelNewStudy.educationSpecialtyOther.$invalid && submitted}">
            </div>

            <div>
              <span>{{ $t("profile.my_education.from") }}</span>
              <select v-model="modelNewStudy.fromYear" :class="{'input-invalid': v$.modelNewStudy.fromYear.$invalid && submitted}">
                <option :value="null">{{ $t("profile.my_education.select") }}</option>
                <option v-for="year in studyYears" :key="year" :value="year">{{year}}</option>
              </select>
            </div>
            <div>
              <span>{{ $t("profile.my_education.to") }}</span>
              <select v-model="modelNewStudy.toYear" :class="{'input-invalid': v$.modelNewStudy.toYear.$invalid && submitted}" :disabled="modelNewStudy.fromYear == null">
                <option :value="null">{{ $t("profile.my_education.inProgress") }}</option>
                <template v-for="year in studyYears" :key="year">
                  <option v-if="modelNewStudy.fromYear <= year" :value="year">{{year}}</option>
                </template>
              </select>
            </div>
            <div v-if="modelNewStudy.toYear == null">
              <span>{{ $t("profile.my_education.subjectsLefts") }}</span>
              <input type="number" v-model.number="modelNewStudy.subjectsLefts" placeholder="Materias pendientes" :class="{'input-invalid': v$.modelNewStudy.subjectsLefts.$invalid && submitted}">
            </div>
            <div>
              <span>{{ $t("profile.my_education.averageScore") }}</span>
              <input type="number" v-model.number="modelNewStudy.averageScore"
                :placeholder="$t('profile.my_education.averageScore')"
                :class="{'input-invalid': v$.modelNewStudy.averageScore.$invalid && submitted}">
            </div>
          </div>
        </div>
        <!-- /Universitario -->

        <!-- Secundario -->
        <div class="modal__content" v-if="modalNewTypeActive === 'secondarySchool'">
          <div class="modal__content__separator">
            <div class="modal__content__separator__title">{{ $t("profile.my_education.secondary") }}</div>
          </div>
          <div class="profile__card--personal-edit">
            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.my_education.educationInstitution") }}</span>
              <Multiselect v-model="modelNewStudy.educationalInstitutionId" 
              :options="listSecondaryInstitutions" :placeholder="$t('profile.my_education.educationInstitution')" trackBy="name" valueProp="id" label="name"
              :closeOnSelect="true" :searchable="true" @select="modelNewStudy.educationalInstitutionOther = ''" :class="{'input-invalid': v$.modelNewStudy.educationalInstitutionId.$invalid && submitted}">
              </Multiselect>
            </div>
            <div class="profile__card--personal-edit__fullrow" v-if="modelNewStudy.educationalInstitutionId == -1">
              <span>{{ $t("profile.my_education.educationName") }}</span>
              <input type="text" :placeholder="$t('profile.my_education.educationName')" v-model="modelNewStudy.educationalInstitutionOther" :class="{'input-invalid': v$.modelNewStudy.educationalInstitutionOther.$invalid && submitted}">
            </div>
            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.my_education.educationSpeciality") }}</span>
              <Multiselect v-model="modelNewStudy.educationSpecialtyId" 
              :options="listEducationSpecialtyType1" :placeholder="$t('profile.my_education.educationSpeciality')" trackBy="name" valueProp="id" label="name"
              :closeOnSelect="true" :searchable="true" @select="modelNewStudy.educationSpecialtyOther = ''" :class="{'input-invalid': v$.modelNewStudy.educationSpecialtyId.$invalid && submitted}">
              </Multiselect>
            </div>
            <div class="profile__card--personal-edit__fullrow" v-if="modelNewStudy.educationSpecialtyId == -1">
              <span>{{ $t("profile.my_education.nameSpeciality") }}</span>
              <input type="text" :placeholder="$t('profile.my_education.nameSpeciality')" v-model="modelNewStudy.educationSpecialtyOther" :class="{'input-invalid': v$.modelNewStudy.educationSpecialtyOther.$invalid && submitted}">
            </div>
            <div>
              <span>{{ $t("profile.my_education.from") }}</span>
              <select v-model="modelNewStudy.fromYear" :class="{'input-invalid': v$.modelNewStudy.fromYear.$invalid && submitted}">
                <option :value="null">{{ $t("profile.my_education.select") }}</option>
                <option v-for="year in studyYears" :key="year" :value="year">{{year}}</option>
              </select>
            </div>
            <div>
              <span>{{ $t("profile.my_education.to") }}</span>
              <select v-model="modelNewStudy.toYear" :class="{'input-invalid': v$.modelNewStudy.toYear.$invalid && submitted}" :disabled="modelNewStudy.fromYear == null">
                <option :value="null">{{ $t("profile.my_education.inProgress") }}</option>
                <template v-for="year in studyYears" :key="year">
                  <option v-if="modelNewStudy.fromYear <= year" :value="year">{{year}}</option>
                </template>
              </select>
            </div>
            <div v-if="modelNewStudy.toYear == null">
              <span>{{ $t("profile.my_education.subjectsLefts") }}</span>
              <input type="number" v-model.number="modelNewStudy.subjectsLefts" placeholder="Materias pendientes" :class="{'input-invalid': v$.modelNewStudy.subjectsLefts.$invalid && submitted}">
            </div>
            <div>
              <span>{{ $t("profile.my_education.averageScore") }}</span>
              <input type="number" v-model.number="modelNewStudy.averageScore"
                :placeholder="$t('profile.my_education.averageScore')"
                :class="{'input-invalid': v$.modelNewStudy.averageScore.$invalid && submitted}">
            </div>
          </div>
        </div>
        <!-- /Secundario -->

        <!-- Idioma -->
        <div class="modal__content" v-if="modalNewTypeActive === 'languages'">
          <div class="modal__content__separator">
            <div class="modal__content__separator__title">{{ $t("profile.my_education.languages") }}</div>
          </div>
          <div class="profile__card--personal-edit">
            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.my_education.language") }}</span>
              <select v-model="modelNewLanguages.languageKnowledgeId">
                <option v-for="language in listLanguageKnowledges" :key="language.id" :value="language.id">{{language.name}}</option>
              </select>
            </div>
            <div class="profile__card--personal-edit__fullrow" v-if="modelNewLanguages.languageKnowledgeId == null">
              <span>{{ $t("profile.my_education.detail") }}</span>
              <input type="text" v-model="modelNewLanguages.languageKnowledgeName" :placeholder="$t('profile.my_education.detail')" :class="{'input-invalid': modelNewLanguages.languageKnowledgeId == null && modelNewLanguages.languageKnowledgeName == '' && submitted}" />
            </div>
            <div class="profile__card--personal-edit__fullrow">
              <div class="modalPhoto">
                <span>Certificado</span>
                <div v-if="modelNewLanguages.fileUrl" class="previewImage">
                  <a :href="getImage(modelNewLanguages.fileUrl)" target="_blank" rel="noopener noreferrer">
                    Ver certificado
                  </a>
                  <i class="material-icons" @click="modelNewLanguages.fileUrl = ''">close</i>
                </div>
                <div class="uploadPhoto_container" v-else>
                  <label for="upload-photo">+</label>
                  <input type="file" name="photo" id="upload-photo" @change="uploadCertificate($event)">
                </div>
              </div>
            </div>
            <div class="profile__card--personal-edit__fullrow">
              <span>{{ $t("profile.my_education.level") }}</span>
              <select v-model="modelNewLanguages.level" :class="{'input-invalid': v$.modelNewLanguages.level.$invalid && submitted}">
                <option value="basic">{{ $t("profile.my_education.levelBasic") }}</option>
                <option value="intermediate">{{ $t("profile.my_education.levelIntermediate") }}</option>
                <option value="advanced">{{ $t("profile.my_education.levelAdvanced") }}</option>
              </select>
            </div>
          </div>
        </div>
        <!-- /Idioma -->
        <div class="modal__buttons">
          <button @click.stop="modalNewTypeActive = ''" class="button--gray">{{ $t("profile.my_education.cancel") }}</button>
          <button @click.stop="updateEducation" :disabled="submittedUploadData">{{ $t("profile.finish") }}</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete -->
  <div v-if="modalDeleteActive">
    <VisibleModal/>
    <div class="modal__overlay" @click.stop="modalDeleteActive = false">
      <div class="modal" @click.stop="''">
        <span class="material-icons" @click.stop="modalDeleteActive = false">close</span>
        <p v-html="$t('profile.my_education.questionDelete', {name: itemDelete.name})"></p>
        <div class="modal__buttons">
          <button @click.stop="modalDeleteActive = false" class="button--gray">{{ $t("profile.my_education.false") }}</button>
          <button @click.stop="removeItem" :disabled="submitted">{{ $t("profile.my_education.true") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { computed, onMounted, watch } from '@vue/runtime-core';
import VisibleModal from '@/components/Common/VisibleModal.vue';
import { useI18n } from 'vue-i18n';
import { required, minValue, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Multiselect from '@vueform/multiselect';
import storage from "@/plugins/azure-storage";

export default {
  components: {
    VisibleModal,
    Multiselect
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const v$ = useVuelidate();
    let study = computed(() => {
      return {...store.getters.profile_study}
    });
    let modalNewOptions = ref(false);
    let modalNewTypeActive = ref('');
    let modalDeleteActive = ref(false);
    let itemDelete = ref({}); 
    let listEducationalInstitution = ref([]);
    let listSecondaryInstitutions = ref([]);
    let listAcademicInstitutions = ref([]);
    let listEducationSpecialtyType1 = ref([]);
    let listEducationSpecialtyType2 = ref([]);
    let listLanguageKnowledges = ref([]);
    let completeLoad = ref(false);
    let educationEdit = ref(false);
    let submitted = ref(false);
    let hasSecondary = ref(false);
    let hasAcademic = ref(false);
    let studyYears = ref([]);
    let editModeActive = ref(false);
    let editingItemIndex = ref(null);
    let submittedUploadData = ref(false);
    

    let modelNewStudy = ref({
      educationalInstitutionId: null,
      educationalInstitutionOther: "",
      educationSpecialtyId: null,
      educationSpecialtyOther: "",
      fromYear: null,
      toYear: null,
      averageScore: 0,
      finished: false,
      subjectsLefts: 0,
      studyLevel: null
    });


    let modelNewLanguages = ref({
      languageKnowledgeId: null,
      languageKnowledgeName: '',
      level: "basic"
    });

    onMounted(async () => {
      const profile = await store.dispatch('profile_profile');
      store.commit('profile_updateProfile', profile);
      listEducationalInstitution.value = await store.dispatch('stuff_educationalInstitution');
      listLanguageKnowledges.value = await store.dispatch('stuff_languageKnowledges');
      listEducationSpecialtyType1.value = await store.dispatch('stuff_educationSpecialty', 1);
      listEducationSpecialtyType2.value = await store.dispatch('stuff_educationSpecialty', 2);
      listSecondaryInstitutions.value = listEducationalInstitution.value.filter(el=>el.type==1);
      listAcademicInstitutions.value = listEducationalInstitution.value.filter(el=>el.type==2);
      listLanguageKnowledges.value.unshift({id: null,name:"Otro"});
      listSecondaryInstitutions.value.unshift({id:-1,name:"Otro"});
      listAcademicInstitutions.value.unshift({id:-1,name:"Otro"});
      listEducationSpecialtyType1.value.unshift({id:-1,name:"Otro"});
      listEducationSpecialtyType2.value.unshift({id:-1,name:"Otro"});
      study.value.academics.forEach(academic => {
        if (academic.studyLevel == 1 || academic.studyLevel == 2) {
          hasSecondary.value = true;
        } 
        if (academic.studyLevel == 3 || academic.studyLevel == 4) {
          hasAcademic.value = true;
        }
        if(academic.educationalInstitutionId == null) academic.educationalInstitutionId = -1
        if(academic.educationSpecialtyId == null) academic.educationSpecialtyId = -1
      })
      completeLoad.value = true;
 
    });

  function createStudyYears() {
    let anio = new Date().getFullYear();
    let anios = [];
    for (let i = anio; i > anio - 50; i--) {
      anios.push(i);
    }
    studyYears.value = anios;
  }
  createStudyYears();

    function getLanguageKnowledge(languageId) {
      const contract = listLanguageKnowledges.value.find(c => c.id == languageId);
      return contract ? contract.name : '-';
    }

    function getInstitution(institutionId) {
      const institution = listEducationalInstitution.value.find(i => i.id == institutionId);
      return institution ? institution.name : '-';
    }

    function getSpeciality(specialityId, type) {
      const list = type === 1 ? listEducationSpecialtyType1 : listEducationSpecialtyType2
      const speciality = list.value.find(s => s.id == specialityId);
      return speciality ? speciality.name : null;
    }

    //Limpio refs cuando se cierra el modal
    watch(modalNewTypeActive, function() {
      if (modalNewTypeActive.value=="") {
        modelNewStudy.value = {
          educationalInstitutionId: null,
          educationalInstitutionOther: "",
          educationSpecialtyId: null,
          educationSpecialtyOther: "",
          fromYear: null,
          toYear: null,
          averageScore: 0,
          finished: false,
          subjectsLefts: 0,
          studyLevel: null
        };

        modelNewLanguages.value = {
          languageKnowledgeId: null,
          languageKnowledgeName: '',
          level: "basic",
          fileUrl: ''
        };

        editModeActive.value = false;
        editingItemIndex.value = null;
        submitted.value = false;
      }
  });

    async function openModalNew(type) {
      modalNewOptions.value = false;
      modalNewTypeActive.value = type;      
      submitted.value = false;
    }

    async function updateEducation() {
      submitted.value = true;
      submittedUploadData.value = true;
      if (modalNewTypeActive.value != 'languages') {
        if (v$.value.modelNewStudy.$invalid) {
          submittedUploadData.value = false;
          store.dispatch('notifications_create', { text: t(`notifications.completeRequiredFields`) });
          return;
        }
        modelNewStudy.value.subjectsLefts = Number(modelNewStudy.value.subjectsLefts);
      }else if (v$.value.modelNewLanguages.$invalid) {
        submittedUploadData.value = false;
        store.dispatch('notifications_create', { text: t(`notifications.completeRequiredFields`) });
        return;
      }

      switch (modalNewTypeActive.value) {
        case 'secondarySchool':
          if(modelNewStudy.value.toYear != null)
          {
            modelNewStudy.value.subjectsLefts = 0
            modelNewStudy.value.studyLevel = 2;
            modelNewStudy.value.finished = true;
          } else{
            modelNewStudy.value.studyLevel = 1;
            modelNewStudy.value.finished = false;
          }
          if(editModeActive.value){
            study.value.academics[editingItemIndex.value] = modelNewStudy.value;
          }else{
            study.value.academics.push(modelNewStudy.value);
          }
        break;
        case 'academics':
          if(modelNewStudy.value.toYear != null)
          {
            modelNewStudy.value.subjectsLefts = 0
            modelNewStudy.value.studyLevel = 4;
            modelNewStudy.value.finished = true;
          } else{
            modelNewStudy.value.studyLevel = 3;
            modelNewStudy.value.finished = false;
          }
          if(editModeActive.value){
            study.value.academics[editingItemIndex.value] = modelNewStudy.value;       
          }else{
            study.value.academics.push(modelNewStudy.value);
          }
        break;
        case 'languages':
          if(editModeActive.value){
            study.value.languages[editingItemIndex.value] = modelNewLanguages.value;
          }else{
            study.value.languages.push(modelNewLanguages.value);
          }
        break;
      }
      try {
        await store.dispatch('profile_updateStudy', study.value);
        store.dispatch('notifications_create', { text: t(`notifications.updateStudy`) });
        modalNewTypeActive.value = "";
      } catch (error) {
        store.dispatch('notifications_create', { text: 'Ocurrió un error' });       
      }

      study.value.academics.forEach(academic => {
        if (academic.studyLevel == 1 || academic.studyLevel == 2) {
          hasSecondary.value = true;
        } 
        if (academic.studyLevel == 3 || academic.studyLevel == 4) {
          hasAcademic.value = true;
        }
      })
      submittedUploadData.value = false;
    }

    function editMode(index, type){
      editModeActive.value = true;
      editingItemIndex.value = index;
      switch (type) {
        case 'secondarySchool':
        case 'academics':
          modelNewStudy.value = study.value.academics[index];       
        break;
        case 'languages':
          modelNewLanguages.value = study.value.languages[index];
        break;
      }
      submitted.value = false;
      modalNewTypeActive.value = type;
    }

    async function removeItem() {
      submitted.value = true;
      store.commit('profile_removeStudy', itemDelete.value);
      await store.dispatch('profile_updateStudy', study.value);
      store.dispatch('notifications_create', { text: t(`notifications.updateStudy`) });

      modalDeleteActive.value = false;
      itemDelete.value = {};

      hasSecondary.value = false;
      hasAcademic.value = false;
      study.value.academics.forEach(academic => {
        if (academic.studyLevel == 1 || academic.studyLevel == 2) {
          hasSecondary.value = true;
        } 
        if (academic.studyLevel == 3 || academic.studyLevel == 4) {
          hasAcademic.value = true;
        }
      })
    }

    function modalDelete(list, id, name) {
      itemDelete.value = {
        list,
        id,
        name
      };
      modalDeleteActive.value = true;
    }

    function getImage(path) {
      return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
    }

    async function uploadCertificate(event) {
      const file = event.target.files[0]
      if(!file) return false;
      const imageName = await storage.updateFile(file);
      modelNewLanguages.value.fileUrl = imageName; 
    }

    return {
      study,
      modalNewOptions,
      modalNewTypeActive,
      modalDelete,
      modelNewStudy,
      listEducationalInstitution,
      listEducationSpecialtyType1,
      listEducationSpecialtyType2,
      modelNewLanguages,
      listLanguageKnowledges,
      openModalNew,
      getLanguageKnowledge,
      getInstitution,
      getSpeciality,
      completeLoad,
      educationEdit,
      updateEducation,
      removeItem,
      submitted,
      v$,
      hasSecondary,
      hasAcademic,
      modalDeleteActive,
      itemDelete,
      createStudyYears,
      studyYears,
      editMode,
      editModeActive,
      editingItemIndex,
      listSecondaryInstitutions,
      listAcademicInstitutions,
      submittedUploadData,
      getImage,
      uploadCertificate
    }
  },
  validations () {
    return {
      modelNewStudy: {
        educationalInstitutionId: { required },
        educationSpecialtyId: {requiredIfRef: requiredIf(this.modalNewTypeActive != "secondarySchool")},
        educationalInstitutionOther: {requiredIfRef: requiredIf(this.modelNewStudy.educationalInstitutionId == -1)},
        educationSpecialtyOther: {requiredIfRef: requiredIf(this.modelNewStudy.educationSpecialtyId == -1 && this.modalNewTypeActive != "secondarySchool")},
        fromYear: { required },
        toYear: { minValueValue: minValue(this.modelNewStudy.fromYear) },
        subjectsLefts: { minValueValue: minValue(0) },
        averageScore: { minValueValue: minValue(0) }
      },
      modelNewLanguages: {
        // languageKnowledgeId: {required},
        level: {required}
      }
    }
  }
    
}
</script>

<style lang="scss" scoped>
  .profile__work-experience {
    min-height: 100vh;
    background: url('~@/assets/profile-bg.svg'), linear-gradient(180deg, #E87B1B 0%, #FFC905 100%);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 1270px, auto;
    padding-bottom: 96px;
    padding-top: $margin-kilo;

    .container {
      padding: 0 $margin-base;

      @media (min-width: $break-sm) {
        padding: 0;
      }
    }
  }

  .profile-card__group {
    margin-bottom: $margin-kilo;
    padding-bottom: $margin-kilo;
    gap: $margin-base;
    grid-column: 1/2 span;
    align-items: center;
    display: grid!important;
    .btns{
        color: $orange;
      }
  }

  .profile__card {
    max-width: 600px;
    margin: 0 auto;
    &.profile-card--personal-data {
      background: $white;
    }

    button {
      margin-top: $margin-mega;
    }

    .profile__card__button--edit {
      color: $orange;
      background: none;
      border: 1px solid $orange;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $margin-milli;
      max-width: 156px;
      margin: $margin-kilo auto;
    }

    .card-detail__separator {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: row;
      grid-column: 1 / 2 span;
      margin: $margin-milli 0;

      &:before {
        content: '';
        border-top: 2px solid $graybg;
        position: absolute;
        top: 9px;
        width: 100%;
      }

      &__title {
        font-family: $font-palanquin;
        text-align: center;
        @include font-settings(milli,base);
        color: $graybg;
        text-transform: uppercase;
        background: $white;
        position: relative;
        width: fit-content;
        padding: 0 $margin-milli;
      } 
    }
  }

  .profile-card--edit {
    .currentDate{
      flex-direction: row;
      align-items: center;
      input{
        width: auto;
        margin: 0 5px 0 0;
      }
    }
    input[type="date"]:disabled {
      opacity: .5;
    }
  }

  .profile__card--personal-edit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "image nickname"
    "image fullname"
    "fullrow fullrow";
    gap: $margin-base;
    margin-top: $margin-base;

    &__nickname {
      grid-area: nickname;
    }

    &__fullname {
      grid-area: fullname;
      gap: $margin-base;
    }

    &__fullrow {
      grid-column: 1 / 2 span;
    }

    &__image {
      width: 120px;
      height: 120px;
      background: $white;
      border-radius: 50%;
      border: 3px solid $orange;
      flex-shrink: 0;
      background-size: cover;
      background-position: center center;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      grid-area: image;
    }

    input {
      color: $gray;
      font-family: $font-palanquin;
      @include font-settings(kilo, base);
      border: none;
      background: none;
      font-weight: 700;
      width: 100%;

      &[type="file"] {
        width: 120px;
        height: 120px;
        background: $orange;
        border-radius: 50%;
        border: 3px solid $orange;
        flex-shrink: 0;
        background-size: cover;
        background-position: center center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        grid-area: image;
        cursor: pointer;

        &:before {
          font-family: "Material Icons";
          content: "\e412";
          font-size: 32px;
          display: flex;
          color: $white;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 100%;
          width: 100%;
        }

        &::file-selector-button {
          opacity: 0;
        }
      }
    }

    select {
      color: $gray;
      font-family: $font-palanquin;
      @include font-settings(kilo, base);
      border: none;
      background: none;
      font-weight: 700;
      width: 100%;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    &.multiselect{
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
      span {
        color: $gray;
      }
      strong {
        color: $orange;
        font-family: $font-palanquin;
        @include font-settings(kilo, base);
      }
    }
  }

.profile-card--experience {

    &__container {
      padding: $margin-milli 0;
    }

    &__title {
      color: $orange;
      font-family: $font-palanquin;
      @include font-settings(kilo, mega);
      font-weight: 700;
    }
    
    &__subtitle {
      color: $graybg;
      font-family: $font-palanquin;
      @include font-settings(milli, base);
      font-weight: 700;
    }

    span {
      font-family: $font-palanquin;
      color: $graybg;
      display: block;
    }

    &__content {
      color: $gray;
      font-family: $font-opensans;
      @include font-settings(milli, base);
      padding-top: $margin-milli;
    }
  }

  .modal .modal__options {
    button {
      display: flex;
      justify-content: center;
      color: $orange;
      border-bottom: 1px solid $orange;
      font-family: $font-palanquin;
      font-weight: 700;
      @include font-settings(base , mega);
      text-transform: uppercase;
      padding-bottom: $margin-milli;
      margin-bottom: $margin-milli;
      width: 100%;

      &:last-child {
        border-bottom: 0px;
      }
    }
  }

  .modal__content {
    &__separator {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: row;
      grid-column: 1 / 2 span;
      margin: $margin-base 0;

      &:before {
        content: '';
        border-top: 2px solid $graybg;
        position: absolute;
        top: 9px;
        width: 100%;
      }

      &__title {
        font-family: $font-palanquin;
        text-align: center;
        @include font-settings(milli,base);
        color: $graybg;
        text-transform: uppercase;
        background: #F2F2F2;
        position: relative;
        width: fit-content;
        padding: 0 $margin-milli;
      } 
    }
  }

.profile-card--experience{
    .secondarySchool,.academic,.languages{
      width: 100%;
      padding-bottom: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(232, 123, 27, .4);
      .btns{
        color: $orange;
      }
    }
    .btn-add_container{
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }
}

.profile__card__header__title {
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    width: 100%;
}

.modal {
  h4, &__buttons button {
    color: $orange;
  }
  p {
    margin: $margin-basedo 0 $margin-milli 0;
  }
}

.card-study{
  margin-top: $margin-base;
  margin-bottom: $margin-kilo;
  padding-bottom: $margin-kilo;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(232, 123, 27, .4);
  &__content{
    display: flex;
    flex-direction: column;
    gap: 5px;
    span {
      color: $gray;
    }
    strong {
      color: $orange;
      font-family: $font-palanquin;
      @include font-settings(kilo, base);
    }
  }
  &__btns{
    align-self: flex-start;
    display: flex;
    color: $orange;
  }
  &:last-child{
    border: none;
    padding-bottom: 0;
  }
}
.btn-add_container{
    display: flex;
    justify-content: center;
    button {
      width: 250px;
      margin: 0!important;
      background: $orange;
      border-radius: 100px;
      font-family: $font-palanquin;
      @include font-settings(base, mega);
      color: $white;
      padding: $margin-milli $margin-kilo;
      font-weight: 700;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
      display: inline-flex;
      justify-content: center;
      cursor: pointer;
    }
  }

  .modalPhoto{
    width: 100%;

    img{
      width: 100%;
      height: auto;
    }
    .uploadPhoto_container{
      width: 100%;
      text-align: center;
      align-items: normal;

      label{
        display: block;
        padding: 5px 0;
        border: 3px dashed #bdbdbd;
        font-size: 2.5rem;
        font-weight: bold;
        color: #bdbdbd;
        cursor: pointer;
      }
      #upload-photo{
        display: none;
      }
    }
    .previewImage {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: bold;
      color: $orange;
      width: 100%;

      i {
        position: inherit;
        font-weight: bold;
        color: $orange;
      }
    }
  }
</style>