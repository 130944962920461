<template>
  <div class="container">
    <div class="work__sub-tabs">
      <button :class="{'active': tabActive === 'active'}" @click="tabActive = 'active'">{{$t("work.myapplications.active")}}</button>
      <button :class="{'active': tabActive === 'finished'}" @click="tabActive = 'finished'">{{$t("work.myapplications.finished")}}</button>
    </div>
    <div class="work__grid" v-if="completeLoad">
      <div class="work__grid__results">
        <template v-if="tabActive === 'active'">
          <template v-if="listMineActives.length > 0">
            <article class="work__result-card" v-for="(job, idx) in listMineActives" :key="idx" @click="goDetail(job.id)">
              <div class="work__result-card__image" :style="`background-image: url('${getImage(job.organizationImage, job.isPictureFromCDN)}')`"></div>
              <div class="work__result-card__content">
                <h3>{{job.title}}</h3>
                <h4>{{job.organizationName}}</h4>
                <div class="work__result-card__content__details">
                  <span>{{job.location}}</span>
                  <span>{{job.schedule}}</span> 
                </div>
                <div class="work__result-card__content__stages">
                  <div class="work__result-card__content__stages__stage" v-for="i in [1,2,3,4]" :key="i" :class="{'active': i <= getIndexStatus(job.status)}"></div>
                  <h4>{{$t("work.myapplications.state")}} {{getIndexStatus(job.status)}}: <b>{{ $t(`work.status.${job.status}`)}}</b></h4>
                </div>
              </div>
              <div class="work__result-card__star" @click.stop="toggleWishlist(idx, job.wish, job.id)">
                <i class="material-icons" :class="{'star__active': job.wish}">{{job.wish ? 'star' : 'star_outline'}}</i>
              </div>
            </article>
          </template>
          <div v-else class="no-data">Todavía no te postulaste a ninguna oferta laboral</div>
        </template>
        <template v-if="tabActive === 'finished'">
          <template v-if="listMineHistorical.length > 0">
            <article class="work__result-card disabled" v-for="(job, idx) in listMineHistorical" :key="idx">
              <div class="work__result-card__image" :style="`background-image: url('${getImage(job.organizationImage, job.isPictureFromCDN)}')`"></div>
              <div class="work__result-card__content">
                <h3>{{job.title}}</h3>
                <h4>{{job.organizationName}}</h4>
                <div class="work__result-card__content__details">
                  <span>{{job.location}}</span>
                  <span>{{job.schedule}}</span>
                </div>
              </div>
            </article>
          </template>
          <div v-else class="no-data">Todavía no te postulaste a ninguna oferta laboral</div>
        </template>
      </div>
    </div>
    <div v-if="!completeLoad" class="loading-points"><span>.</span><span>.</span><span>.</span></div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import { onMounted, computed } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const tabActive = ref('active');
    const listMineActives = ref([]);
    const listMineHistorical = ref([]);
    const jobsWishlist = computed(() => store.getters.profile_jobsWishlist);
    const completeLoad = ref(false);

    onMounted(async () => {
      const force = await store.dispatch('manageUser_forceLogin')
      if (force) return;
      try {
        let requestMineActives = await store.dispatch('organization_jobSearch_mineActives');
        let requestMineHistorical = await store.dispatch('organization_jobSearch_mineHistorical');      
        listMineActives.value = requestMineActives.data;
        listMineHistorical.value = requestMineHistorical.data;
        completeLoad.value = true;   
      } catch (error) {
        store.dispatch('notifications_create', { text: t(`notifications.errorGeneric`) });
      } 
    });

    async function toggleWishlist(idx, active, id) {
      const force = await store.dispatch('manageUser_forceLogin')
      if (force) return;
      if (jobsWishlist.value.includes(id)) return;

      store.commit('profile_addJobWishlist', id);
      
      if (active) {
        await store.dispatch('organization_wishes_remove', id);
        store.dispatch('notifications_create', { text: t(`notifications.wishes_remove`) });
      } else {
        await store.dispatch('organization_wishes_add', id);
        store.dispatch('notifications_create', { text: t(`notifications.wishes_add`) });
      }
      listMineActives.value[idx].wish = !active;
    }

    function goDetail(id) {
      router.push(`/work/jobOffers/${id}`);
    }

    function getImage(path, isFromCDN) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      } else {
        return path ? `${process.env.VUE_APP_FORGE_ORGANIZATION_MEDIA_URL}${path}` : null;
      }
    }

    function getIndexStatus(status) {
      let indexStatus;
      switch (status) {
        case 'Ok':
          indexStatus = 1;
          break;
        case 'Viewed':
          indexStatus = 2;
          break;
        case 'NotInterviewed':
        case 'ContactDataRequired':
          indexStatus = 3;
          break;
        case 'Hired':
        case 'NotHired':
          indexStatus = 4;
          break;
      }
      return indexStatus;
    }

    return {
      tabActive,
      listMineActives,
      listMineHistorical,
      goDetail,
      toggleWishlist,
      getIndexStatus,
      getImage,
      completeLoad
    }
  }
}
</script>
<style lang="scss" scoped>
  .work__grid {
      padding: 0 $margin-base;

      @media (min-width: $break-sm) {
        padding: 0;
      }

      @media (min-width: $break-md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: $margin-base;
        grid-template-areas: "cards cards"
        "cards cards";
      }
    }

    .work__grid__results-number {
      grid-area: results;
      color: $white;
      margin-bottom: $margin-base;

      @media (min-width: $break-md) {
        margin-bottom: 0;
      }
    }

    .work__grid__filters {
      background: $blue;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: $margin-base $margin-base $margin-kilo;
      color: $white;
      display: none;
      grid-area: filters;

      @media (min-width: $break-md) {
        display: block;
      }
    }

    .work__grid__results {
      display: grid;
      gap: $margin-base;
      grid-area: cards;

      @media (min-width: $break-md) {
        grid-template-columns: 1fr 1fr;
      }
    }

.loading-points {
  //display: inline-block;
  padding-top: 24px;
  text-align: center;
  color: #fff;
  span {
    font-size: 46px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    &:nth-child(2) {
        animation-delay: .2s;
    }
    &:nth-child(3) {
        animation-delay: .4s;
    }
  }
  @keyframes blink {
    0% {
    opacity: .2;
    }
    20% {
    opacity: 1;
    }
    100% {
    opacity: .2;
    }
  }
}
.no-data{
  padding-top: 24px;
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-style: italic;
}
</style>