import interceptor from '@/plugins/interceptor';
const pathController = '/Profile';

export default {
  state: () => ({}),
  mutations: {},
  actions: {
    async profile_profile() {
      const request = await interceptor.authenticate.get(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}`
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateInterests(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/interests`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updatePhotos(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/photos`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateWhatMakesMeUnique(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/whatMakesMeUnique`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateWhoIAm(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/whoIAm`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateWhatIExpectFromNextJob(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/whatIExpectFromNextJob`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateVideos(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/videos`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateTechnologicalTools(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/technologicalTools`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateVolunteerings(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/volunteerings`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateActivities(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/activities`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateExternalCourses(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/externalCourses`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateVideoPreferences(_, boolean) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/videoPreferences`,
        boolean
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updatePersonalInformation(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/updatePersonalInformation`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateJobs(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/updateJobs`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateStudy(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/updateStudy`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateContacts(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/updateContacts`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updatePreferences(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/updatePreferences`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateLocation(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/updateLocation`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateAboutMe(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/updateAboutMe`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateCertificates(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/certificates`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_pictureFromCDN() {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/migratedPicture`
      );
      if (request.status === 200) return request.data;
      return [];
    },
    async profile_updateSkills(_, model) {
      const request = await interceptor.authenticate.put(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/skills`,
        model
      );
      if (request.status === 200) return request.data;
      return [];
    },
    //CV
    async profile_getCVs() {
      return await interceptor.authenticate.get(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/cvs`
      );
    },
    async profile_postCVs(_, model) {
      return await interceptor.authenticate.post(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/cvs`,
        model
      );
    },
    async profile_favCVs(_, params) {
      return await interceptor.authenticate.patch(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/cvs/${params.id}/main`
      );
    },
    async profile_deleteCVs(_, params) {
      return await interceptor.authenticate.delete(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/cvs/${params.id}`
      );
    },
    async profile_canCreateCVs() {
      return await interceptor.authenticate.post(
        `${process.env.VUE_APP_API_SITE_URL}${pathController}/cvs/canCreate`
      );
    },
  },
};
