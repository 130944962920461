<template>
  <div id="best-version-container-mobile" v-show="mobileVersion"></div>
  <div class="profile__grid__column">
    <div class="profile-card--first">
      <BannerPercentage />
    </div>
    <!-- Mis intereses -->
    <div class="profile__card interests">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <span class="counter">5</span>
          {{ $t("profile.my_best_version.interest.title") }}
          <Tooltip 
            :text="$t('profile.myBestVersion.tooltip.interest')"
            :left="mobileVersion ? '-204px' : ''"
            :top="mobileVersion ? '30px' : ''"
          />
        </div>
      </div>
      <template v-if="levelProfile > 2">
        <div class="profile__card--tabs"> 
        <div class="profile__card__content" v-if="aboutMeModel.profileInterest.length == 0">{{ $t("profile.my_best_version.interest.label") }}</div>
          <button v-for="(tab, idx) in aboutMeModel.profileInterest" :key="idx">
            {{tab.name}}
            <i class="material-icons" @click="removeInterest(idx)">close</i>
          </button>
        </div>
        <div class="btn-add_container">
          <button class=" interest_btn-add" @click="modalInterest = true">AGREGAR <i class="material-icons">add</i></button>
        </div>
      </template>
      <template v-else>
        <div class="profile__card__content available">
          {{ $t("profile.available_from") }} <strong>NIVEL 3</strong>
        </div>
      </template> 
    </div>
    <!-- Voluntariado -->
    <div class="profile__card profile__card profile-card--experience">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <span class="counter">8</span>
          {{ $t("profile.my_best_version.volunteerings.title") }}
          <Tooltip 
            :text="$t('profile.myBestVersion.tooltip.volunteerings')"
            :left="mobileVersion ? '-204px' : ''"
            :top="mobileVersion ? '30px' : ''"
          />
        </div>
      </div> 
      <template v-if="levelProfile > 2">
        <div class="profile__card__content" v-if="aboutMeModel.volunteerings.length == 0">{{ $t("profile.my_best_version.volunteerings.label") }}</div>
        <div class="volunteerings" v-for="(volunteering, index) in aboutMeModel.volunteerings" :key="index">
          <div class="data">
            <div class="profile-card--experience__title">{{volunteering.name}}</div>
            <div class="profile-card--experience__subtitle">{{volunteering.institution}}</div>
            <span>{{volunteering.monthFrom}}/{{volunteering.yearFrom}} - {{volunteering.yearTo == null ? $t('profile.present') : `${volunteering.monthTo}/${volunteering.yearTo}`}}</span>
            <span>{{ $t("profile.my_best_version.volunteerings.taskDone") }}: {{volunteering.taskDone}}</span>
            <a :href="volunteering.link">{{volunteering.link}}</a>
          </div>
          <div class="btns">
            <i class="material-icons profile__card__header__arrow" @click="typeModalCreate = 'volunteerings'; editMode(index);">edit</i>
            <i class="material-icons profile__card__header__arrow" @click="modelModalRemove = {idx: index, name: volunteering.name, list: 'volunteerings'}; modalRemove = true;">delete</i>
          </div>
        </div>
        <div class="btn-add_container">
          <button @click="typeModalCreate = 'volunteerings'; modalCreate = true" class="btn-add">Agregar<i class="material-icons">add</i></button>
        </div>
      </template>
      <template v-else>
        <div class="profile__card__content available">
          {{ $t("profile.available_from") }} <strong>NIVEL 3</strong>
        </div>
      </template> 
    </div>
  </div>

  <div class="profile__grid__column">
    <!-- Quien soy -->
    <div class="profile__card">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <span class="counter">1</span>
          {{ $t("profile.my_best_version.whoAmI.title") }}
        </div>
        <i class="material-icons profile__card__header__arrow" v-if="!whoIAmEdit && levelProfile > 2" @click="whoIAmEdit = !whoIAmEdit">edit</i>
      </div>
      <template v-if="levelProfile > 2">
        <div class="profile__card__content" v-if="!whoIAmEdit">
          {{aboutMeModel.whoIAm !== '' ? aboutMeModel.whoIAm : $t("profile.my_best_version.whoAmI.label") }}
        </div>
        <div class="profile__card__content" v-else>
          <textarea cols="30" rows="5" v-model="aboutMeModel.whoIAm"></textarea>
          <button class="profile__card__button" @click="updateWhoIAm(); whoIAmEdit = !whoIAmEdit">GUARDAR</button>
        </div>
      </template>  
      <template v-else>
        <div class="profile__card__content available">
          <div>
            {{ $t("profile.available_from") }} <strong>NIVEL 3</strong>
          </div>
        </div>
      </template>  
    </div>
    <!-- Que me hace especial -->
    <div class="profile__card">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <span class="counter">3</span>
          {{ $t("profile.my_best_version.whatMakesMeUniqueEdit.title") }}
        </div>
        <i class="material-icons profile__card__header__arrow" v-if="!whatMakesMeUniqueEdit && levelProfile > 2" @click="whatMakesMeUniqueEdit = !whatMakesMeUniqueEdit">edit</i>
      </div>
      <template v-if="levelProfile > 2">
        <div class="profile__card__content" v-if="!whatMakesMeUniqueEdit">
          {{aboutMeModel.whatMakesMeUnique !== '' ? aboutMeModel.whatMakesMeUnique : $t("profile.my_best_version.whatMakesMeUniqueEdit.label") }}
        </div>
        <div class="profile__card__content" v-else>
          <textarea cols="30" rows="5" v-model="aboutMeModel.whatMakesMeUnique"></textarea>
          <button class="profile__card__button" @click="updateWhatMakesMeUnique(); whatMakesMeUniqueEdit = !whatMakesMeUniqueEdit">GUARDAR</button>
        </div>
      </template>  
      <template v-else>
        <div class="profile__card__content available">
          <div>
            {{ $t("profile.available_from") }} <strong>NIVEL 3</strong>
          </div>
        </div>
      </template>  
    </div>
    <!-- Herramientas tecnologicas -->
    <div class="profile__card techTools">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <span class="counter">6</span>
          {{ $t("profile.my_best_version.technologicalTools.title") }}
          <Tooltip 
            :text="$t('profile.myBestVersion.tooltip.technologicalTools')"
            :left="mobileVersion ? '-204px' : ''"
            :top="mobileVersion ? '30px' : ''"
          />
        </div>
      </div>
      <template v-if="levelProfile > 2">
        <div class="profile__card--tabs">
          <div class="profile__card__content" v-if="aboutMeModel.technologicalTools.length == 0">{{ $t("profile.my_best_version.technologicalTools.label") }}</div>
          <button v-for="(technology, idxTechnology) in aboutMeModel.technologicalTools" :key="idxTechnology">
            {{technology}}
            <i class="material-icons" @click="modelModalRemove = {idx: idxTechnology, name: technology, list: 'technologicalTools'}; modalRemove = true;">close</i>
          </button>
        </div>
        <div class="btn-add_container">
          <button class="interest_btn-add" @click="modalTechnology = true">AGREGAR <i class="material-icons">add</i></button>
        </div>
      </template>
      <template v-else>
        <div class="profile__card__content available">
          {{ $t("profile.available_from") }} <strong>NIVEL 3</strong>
        </div>
      </template> 
    </div>
    <!-- Mis fotos -->
    <div class="profile__card profile__card--photos">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <span class="counter">9</span>
            {{ $t("profile.my_best_version.photos.title") }}
            <Tooltip 
              :text="$t('profile.myBestVersion.tooltip.photos')"
              :left="mobileVersion ? '-204px' : ''"
              :top="mobileVersion ? '30px' : ''"
            />
        </div>
      </div>
      <template v-if="levelProfile > 4">  
        <div class="profile__card__content">
          {{ $t("profile.my_best_version.photos.label") }}
        </div>
        <div class="profile__card__photos" v-if="aboutMeModel.photos.length > 0">
          <div v-for="(photo, idx) in aboutMeModel.photos" :key="idx" class="profile__card__photos__item">
            <img :src="getImage(photo.picture)" />
            <small>{{photo.description}}</small>
            <i class="material-icons" @click="removePhoto(idx)">close</i>
          </div>
        </div>
        <div class="btn-add_container">
          <button @click="modalPhoto = true" :disabled="aboutMeModel.photos.length >= 5">AGREGAR<i class="material-icons">add</i></button>
        </div>
      </template>
      <template v-else>
        <div class="profile__card__content available">
          {{ $t("profile.available_from") }} <strong>NIVEL 5</strong>
        </div>
      </template> 
    </div>
  </div>

  <div class="profile__grid__column">
    <!-- Que busco en mi proximo empleo -->
    <div class="profile__card">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <span class="counter">2</span>
          {{ $t("profile.my_best_version.whatLookNextJob.title") }}
        </div>
        <i class="material-icons profile__card__header__arrow" v-if="!whatIExpectFromNextJob && levelProfile > 2" @click="whatIExpectFromNextJob = !whatIExpectFromNextJob">edit</i>
      </div>
      <template v-if="levelProfile > 2">
        <div class="profile__card__content" v-if="!whatIExpectFromNextJob">
          {{aboutMeModel.whatIExpectFromNextJob !== '' ? aboutMeModel.whatIExpectFromNextJob : $t("profile.my_best_version.whatLookNextJob.label") }}
        </div>
        <div class="profile__card__content" v-else>
          <textarea cols="30" rows="5" v-model="aboutMeModel.whatIExpectFromNextJob"></textarea>
          <button class="profile__card__button" @click="updateWhatIExpectFromNextJob(); whatIExpectFromNextJob = !whatIExpectFromNextJob">GUARDAR</button>
        </div>
      </template>  
      <template v-else>
        <div class="profile__card__content available">
          <div>
            {{ $t("profile.available_from") }} <strong>NIVEL 3</strong>
          </div>
        </div>
      </template>  
    </div>
    <!-- Mis habilidades -->
    <div class="profile__card interests">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <span class="counter">4</span>
            {{ $t("profile.my_best_version.skills.title") }}
            <Tooltip 
              :text="$t('profile.myBestVersion.tooltip.skills')"
              :left="mobileVersion ? '-204px' : ''"
              :top="mobileVersion ? '30px' : ''"
            />
        </div>
      </div>
      <template v-if="levelProfile > 2">
        <div class="profile__card__content" v-if="aboutMeModel.skills.length == 0">{{ $t("profile.my_best_version.skills.label") }}</div>
        <div class="profile__card--tabs"> 
          <button v-for="(tab, idx) in aboutMeModel.skills" :key="idx">
            {{tab.skillName}}
            <i class="material-icons" @click="removeSkill(idx)">close</i>
          </button>
        </div>
        <div class="btn-add_container">
          <button class=" interest_btn-add" @click="modalSkills = true">AGREGAR <i class="material-icons">add</i></button>
        </div>
      </template>
      <template v-else>
        <div class="profile__card__content available">
          {{ $t("profile.available_from") }} <strong>NIVEL 3</strong>
        </div>
      </template> 
    </div>
    <!-- otras actividades -->
    <!-- <div class="profile__card profile-card--experience">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <i class="material-icons">person</i>
          {{ $t("profile.my_best_version.otherActivities.title") }}
            <Tooltip :text="$t('profile.myBestVersion.tooltip.otherActivities')"/>
        </div>
      </div> 
      <template v-if="levelProfile > 2">
        <div class="activities" v-for="(activity, idxActivity) in aboutMeModel.activities" :key="idxActivity">
          <div class="data">
            <div class="profile-card--experience__title">{{activity.name}}</div>
            <div class="profile-card--experience__subtitle">{{activity.description}}</div>
            <span>{{activity.monthFrom}}/{{activity.yearFrom}} - {{activity.yearTo == null ? $t('profile.present') : `${activity.monthTo}/${activity.yearTo}`}}</span>
            <a :href="activity.link">{{activity.link}}</a>
          </div>
          <div class="btns">
              <i class="material-icons profile__card__header__arrow" @click="typeModalCreate = 'activities'; editMode(idxActivity);">edit</i>
              <i class="material-icons profile__card__header__arrow" @click="modelModalRemove = {idx: idxActivity, name: activity.name, list: 'activities'}; modalRemove = true;">delete</i>
          </div>
        </div>
        <div class="btn-add_container">
          <button @click="typeModalCreate = 'activities'; modalCreate = true" class="btn-add">Agregar<i class="material-icons">add</i></button>
        </div>
      </template>
      <template v-else>
        <div class="profile__card__content available">
          {{ $t("profile.available_from") }} <strong>NIVEL 3</strong>
        </div>
      </template> 
    </div> -->
    <!-- Cursos-->
    <div class="profile__card profile-card--experience">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <span class="counter">7</span>
          {{ $t("profile.my_best_version.externalCourses.title") }}
            <Tooltip 
              :text="$t('profile.myBestVersion.tooltip.externalCourses')"
              :left="mobileVersion ? '-204px' : ''"
              :top="mobileVersion ? '30px' : ''"
            />
        </div>
      </div> 
      <template v-if="levelProfile > 2">
        <div class="courses" v-for="(course, index) in aboutMeModel.externalCourses" :key="index">
          <div class="data">
            <div class="profile-card--experience__title">{{course.name}}</div>
            <div class="profile-card--experience__subtitle">{{course.institution}}</div>
            <span>{{course.monthFrom}}/{{course.yearFrom}} - {{course.yearTo == null ? $t('profile.present') : `${course.monthTo}/${course.yearTo}`}}</span>
            <span v-if="course.certificateLink !==''">{{ $t("profile.my_best_version.externalCourses.certificate") }}: <a :href="course.certificateLink">{{course.certificateLink}}</a></span>
            <span v-if="course.organizationLink !==''">{{ $t("profile.my_best_version.externalCourses.linkInstitution") }}: <a :href="course.organizationLink">{{course.organizationLink}}</a></span>
          </div>
          <div class="btns">
              <i class="material-icons profile__card__header__arrow" @click="typeModalCreate = 'externalCourses'; editMode(index);">edit</i>
              <i class="material-icons profile__card__header__arrow" @click="modelModalRemove = {idx: index, name: course.name, list: 'externalCourses'}; modalRemove = true;">delete</i>
          </div>
        </div>
        <div class="btn-add_container">
          <button @click="typeModalCreate = 'externalCourses'; modalCreate = true" class="btn-add">Agregar<i class="material-icons">add</i></button>
        </div>
      </template>
      <template v-else>
        <div class="profile__card__content available">
          {{ $t("profile.available_from") }} <strong>NIVEL 3</strong>
        </div>
      </template> 
    </div>
    <!-- videos -->
    <div class="profile__card profile__card--video">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <span class="counter">10</span>
            {{ $t("profile.my_best_version.video.title") }}
            <Tooltip 
              :text="$t('profile.myBestVersion.tooltip.videos')"
              :left="mobileVersion ? '-204px' : ''"
              :top="mobileVersion ? '30px' : ''"
            />
        </div>
      </div>
      <template v-if="levelProfile > 4">  
        <div class="profile__card__content">
          {{ $t("profile.my_best_version.video.label") }}
        </div>
        <ul class="profile__card__list">
          <li v-for="(video, idxVideo) in aboutMeModel.videos" :key="idxVideo">
            <a :href="video.link" target="_blank">{{video.description || video.link}} <i class="material-icons">open_in_new</i></a>
            <i class="material-icons profile__card__header__arrow delete" @click="modelModalRemove = {idx: idxVideo, name: video.description, list: 'videos'}; modalRemove = true;">delete</i>
          </li>
        </ul>
        <div class="btn-add_container">
          <button @click="modalVideo = true;" :disabled="aboutMeModel.videos.length >= 5">AGREGAR<i class="material-icons">add</i></button>
        </div>
      </template>
      <template v-else>
        <div class="profile__card__content available">
          {{ $t("profile.available_from") }} <strong>NIVEL 5</strong>
        </div>
      </template> 
    </div>
  </div>

  <!-- Modal Add technology -->
  <div v-if="modalTechnology" class="modal_technology">
    <VisibleModal/>
    <div class="modal__overlay">
      <div class="modal" @click.stop="''">
        <div class="modal__header">  
          {{ $t("profile.my_best_version.technologicalTools.title") }}
        </div> 
        <input type="text" :placeholder="$t('profile.my_best_version.technologicalTools.technologicalTool')" v-model="technologicalToolString" maxlength="50">
        <small>{{technologicalToolString.length}}/50 Caracteres</small> 
        <div class="modal__buttons">
          <button @click.stop="modalTechnology = false; technologicalToolString = ''" class="button--gray">{{ $t("profile.my_best_version.cancel") }}</button>
          <button  @click.stop="technologicalToolString !== '' ? uploadTecnologicalTools() : null" :disabled="technologicalToolString ==''">Agregar</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Add video -->
  <div v-if="modalVideo" class="modal_technology">
    <VisibleModal/>
    <div class="modal__overlay">
      <div class="modal" @click.stop="''">
        <div class="modal__header">  
          {{ $t("profile.my_best_version.video.title") }}
        </div>
        <div class="modal_video">
          <label>Pega aquí el link de tu video de Youtube:</label>
          <input type="text" :placeholder="$t('profile.my_best_version.video.link')" v-model="videoModel.link" :class="{'input-invalid': v$.videoModel.link.$invalid && submitted}">
          <span class="input-message" v-if="v$.videoModel.link.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.videoModel.link.isFromYoutube.$message}}</span>
          <input type="text" placeholder="Título" v-model="videoModel.description" :class="{'input-invalid': videoModel.description == '' && submitted }"> 
          <div class="modal__buttons">
          <button @click.stop="modalVideo = false; videoModel.link = ''; videoModel.description = ''; submitted=false;" class="button--gray">{{ $t("profile.my_best_version.cancel") }}</button>
          <button  @click.stop="uploadVideo()" :disabled="videoModel.link ==''">Agregar</button>
        </div>
        </div>       
      </div>
    </div>
  </div>

  <!-- Modal Add Interest -->
  <div v-if="modalInterest">
    <VisibleModal/>
    <div class="modal__overlay">
      <div class="modal modalInterest" @click.stop="''">
        <div class="modal__header">  
          {{ $t("profile.my_best_version.interest.modal.title") }}
        </div> 
        <Multiselect v-model="interestAddModel" :options="listInterestFiltered"
          trackBy="name" valueProp="name" label="name" :closeOnSelect="true" :searchable="true" @open="toggleMultiselect(true)" @close="toggleMultiselect(false)" @change="toggleMultiselect(true)"></Multiselect>
        <br>
        <input placeholder="Descripción" type="text" v-if="interestAddModel == 'Otro'" v-model="interestOtherModel">

        <div class="modal__buttons" ref="buttonsInterest">
          <button @click.stop="modalInterest = false" class="button--gray">{{ $t("profile.my_best_version.cancel") }}</button>
          <button @click.stop="addInterest(interestAddModel)">Agregar</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Add Skills -->
  <div v-if="modalSkills">
    <VisibleModal/>
    <div class="modal__overlay">
      <div class="modal modalInterest" @click.stop="''">
        <div class="modal__header">  
          {{ $t("profile.my_best_version.skills.modal.title") }}
        </div> 
        <Multiselect v-model="skillAddModel" :options="listSkillsFiltered"
          trackBy="name" valueProp="name" label="name" :closeOnSelect="true" :searchable="true" @open="toggleMultiselect(true)" @close="toggleMultiselect(false)" @change="toggleMultiselect(true)"></Multiselect>
        <br>
        <input placeholder="Descripción" type="text" v-if="skillAddModel == 'Otro'" v-model="skillOtherModel">
        
        
        <div class="modal__buttons" ref="buttonsInterest">
          <button @click.stop="modalSkills = false" class="button--gray">{{ $t("profile.my_best_version.cancel") }}</button>
          <button @click.stop="addSkill(skillAddModel)">Agregar</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Add Photo -->
  <div v-if="modalPhoto">
    <VisibleModal/>
    <div class="modal__overlay" @click.stop="modalPhoto = false">
      <div class="modal modalPhoto" @click.stop="''">
        <div class="modal__header">  
          Agregar Foto
        </div>
        <div @change="uploadPhoto($event)">
          <img :src="getImage(modelImages.picture)" v-if="hasImageUpload">
          <div class="uploadPhoto_container" v-else>
            <label for="upload-photo">+</label>
            <input type="file" name="photo" id="upload-photo">
          </div>
        </div>
        <div class="description">
          <span>Agrega una descripción para esta imagen</span>
          <textarea rows="2" cols="50" maxlength="50" name="description" v-model="modelImages.description">
          </textarea>
          <!-- <input type="text" maxlength="50" v-model="modelImages.description"> -->
        </div>
        <div class="modal__buttons">
          <button @click.stop="cancelUploadPhoto" class="button--gray">{{ $t("profile.my_best_version.cancel") }}</button>
          <button :disabled="!hasImageUpload" @click="updatePhotos(); modalPhoto = false">{{ $t("profile.finish") }}</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal create -->
  <div v-if="modalCreate">
    <VisibleModal/>
    <div class="modal__overlay">
      <div class="modal" @click.stop="''">
        <div v-if="!editModeActive" class="modal__header">  
         {{ $t(`profile.myBestVersion.${typeModalCreate}`) }}
        </div> 
        <div v-else class="modal__header">
          Editar Experiencia
        </div>
        <div class="modal__content">

          <!-- Arte y deporte (ex Actividades) -->
          <div class="profile-card--edit" v-if="typeModalCreate == 'activities'">
            <div>
              <span>{{ $t("profile.my_best_version.otherActivities.activityName") }}</span>
              <input type="text" :placeholder="$t('profile.my_best_version.otherActivities.activityName')" v-model="modelModalCreate.field1" :class="{'input-invalid': v$.modelModalCreate.field1.$invalid && submitted}">
            </div>
            <div>
              <span :class="{'label-invalid': v$.modelModalCreate.field2.$invalid && submitted}">{{ $t("profile.my_best_version.otherActivities.tellUsMore") }}!</span>
              <textarea v-model="modelModalCreate.field2" class="tell-us-more" :class="{'input-invalid': v$.modelModalCreate.field2.$invalid && submitted}"></textarea>
            </div>
            <div>
              <span>{{ $t("profile.my_best_version.otherActivities.loadLink") }}</span>
              <input type="text" :placeholder="$t('profile.my_best_version.otherActivities.link')" v-model="modelModalCreate.field4">
            </div>
            <div>
              <span>{{ $t("profile.my_best_version.otherActivities.howLong") }}</span>
            </div>
            <div class="currentDate">
              <input type="checkbox" name="still" :checked="modelModalCreate.yearTo==null && modelModalCreate.monthTo==null" @click.stop="toggleCurrentDate(modelModalCreate)"> 
              <span style="font-weight: 600;">{{ $t("profile.my_best_version.otherActivities.current") }}</span>
            </div>

            <div class="date">
              <span>{{ $t("profile.work_experience.from") }}</span>
              <div class="date-container">
                <select v-model="modelModalCreate.monthFrom" :class="{'input-invalid': v$.modelModalCreate.monthFrom.$invalid && submitted}">
                  <option :value="''" disabled>Mes</option>
                  <option v-for="month in monthsList" :key="month.id" :value="month.id">{{month.name}}</option>
                </select>
                <select v-model="modelModalCreate.yearFrom" :class="{'input-invalid': v$.modelModalCreate.yearFrom.$invalid && submitted}">
                  <option :value="''" disabled>Año</option>
                  <option v-for="year in yearsList" :key="year" :value="year">{{year}}</option>
                </select>
              </div>
            </div>

            <div v-if="!modelModalCreate.current" class="date">
              <span>{{ $t("profile.work_experience.to") }}</span>
              <div class="date-container">
                <select v-model="modelModalCreate.monthTo" :class="{'input-invalid': v$.modelModalCreate.monthTo.$invalid && submitted}">
                  <option :value="''" disabled>Mes</option>
                  <option v-for="month in monthsList" :key="month.id" :value="month.id">{{month.name}}</option>
                </select>
                <select v-model="modelModalCreate.yearTo" :class="{'input-invalid': v$.modelModalCreate.yearTo.$invalid && submitted}">
                  <option :value="''" disabled>Año</option>
                  <option v-for="year in yearsList" :key="year" :value="year">{{year}}</option>
                </select>
              </div>
            </div>

          </div>

          <!-- Cursos -->
          <div class="profile-card--edit modalPhoto" v-if="typeModalCreate == 'externalCourses'">
            <div>
              <span>{{ $t("profile.my_best_version.externalCourses.name") }}</span>
              <input type="text" :placeholder="$t('profile.my_best_version.externalCourses.name')" v-model="modelModalCreate.field1" :class="{'input-invalid': v$.modelModalCreate.field1.$invalid && submitted}">
            </div>
            <div>
              <span>{{ $t("profile.my_best_version.externalCourses.institution") }}</span>
              <input type="text" :placeholder="$t('profile.my_best_version.externalCourses.institution')" v-model="modelModalCreate.field2" :class="{'input-invalid': v$.modelModalCreate.field2.$invalid && submitted}">
            </div>
            <div>
              <span>{{ $t("profile.my_best_version.externalCourses.linkCertificate") }}</span>
              <input type="text" :placeholder="$t('profile.my_best_version.externalCourses.linkCertificate')" v-model="modelModalCreate.field3" :class="{'input-invalid': v$.modelModalCreate.field3.$invalid && submitted}">
            </div>
            <div>
              <span>{{ $t("profile.my_best_version.externalCourses.linkInstitution") }}</span>
              <input type="text" :placeholder="$t('profile.my_best_version.externalCourses.linkInstitution')" v-model="modelModalCreate.field4">
            </div>
            <div>
              <span>Certificado</span>
              <div v-if="modelModalCreate.fileUrl" class="previewImage">
                <a :href="getImage(modelModalCreate.fileUrl)" target="_blank" rel="noopener noreferrer">
                  Ver certificado
                </a>
                <i class="material-icons" @click="modelModalCreate.fileUrl = ''">close</i>
              </div>
              <div class="uploadPhoto_container" v-else>
                <label for="upload-photo">+</label>
                <input type="file" name="photo" id="upload-photo" @change="uploadCertificate($event)">
              </div>
            </div>
            <div class="currentDate">
              <input type="checkbox" name="still" :checked="modelModalCreate.yearTo==null" @click.stop="toggleCurrentDate(modelModalCreate)"> 
              <span>{{ $t("profile.my_best_version.externalCourses.current") }}</span>
            </div>
            <div class="date">
              <span>{{ $t("profile.work_experience.from") }}</span>
              <div class="date-container">
                <select v-model="modelModalCreate.monthFrom" :class="{'input-invalid': v$.modelModalCreate.monthFrom.$invalid && submitted}">
                  <option :value="''" disabled>Mes</option>
                  <option v-for="month in monthsList" :key="month.id" :value="month.id">{{month.name}}</option>
                </select>
                <select v-model="modelModalCreate.yearFrom" :class="{'input-invalid': v$.modelModalCreate.yearFrom.$invalid && submitted}">
                  <option :value="''" disabled>Año</option>
                  <option v-for="year in yearsList" :key="year" :value="year">{{year}}</option>
                </select>
              </div>
            </div>
            <div v-if="!modelModalCreate.current" class="date">
              <span>{{ $t("profile.work_experience.to") }}</span>
              <div class="date-container">
                <select v-model="modelModalCreate.monthTo" :class="{'input-invalid': v$.modelModalCreate.monthTo.$invalid && submitted}">
                  <option :value="''" disabled>Mes</option>
                  <option v-for="month in monthsList" :key="month.id" :value="month.id">{{month.name}}</option>
                </select>
                <select v-model="modelModalCreate.yearTo" :class="{'input-invalid': v$.modelModalCreate.yearTo.$invalid && submitted}">
                  <option :value="''" disabled>Año</option>
                  <option v-for="year in yearsList" :key="year" :value="year">{{year}}</option>
                </select>
              </div>
            </div>
          </div>

          <!-- Voluntariado -->
          <div class="profile-card--edit" v-if="typeModalCreate == 'volunteerings'">
            <div>
              <span>{{ $t("profile.my_best_version.volunteerings.name") }}</span>
              <input type="text" :placeholder="$t('profile.my_best_version.volunteerings.name')" v-model="modelModalCreate.field1" :class="{'input-invalid': v$.modelModalCreate.field1.$invalid && submitted}">
            </div>
            <div>
              <span>{{ $t("profile.my_best_version.volunteerings.institution") }}</span>
              <input type="text" :placeholder="$t('profile.my_best_version.volunteerings.institution')" v-model="modelModalCreate.field2" :class="{'input-invalid': v$.modelModalCreate.field2.$invalid && submitted}">
            </div>
            <div>
              <span>{{ $t("profile.my_best_version.volunteerings.taskDone") }}</span>
              <input type="text" :placeholder="$t('profile.my_best_version.volunteerings.taskDone')" v-model="modelModalCreate.field3" :class="{'input-invalid': v$.modelModalCreate.field3.$invalid && submitted}">
            </div>
            <div>
              <span>{{ $t("profile.my_best_version.volunteerings.link") }}</span>
              <input type="text" :placeholder="$t('profile.my_best_version.volunteerings.link')" v-model="modelModalCreate.field4">
            </div>
            <div class="currentDate">
              <input type="checkbox" name="still" :checked="modelModalCreate.yearTo==null" @click.stop="toggleCurrentDate(modelModalCreate)"> 
              <span>{{ $t("profile.my_best_version.volunteerings.current") }}</span>
            </div>
            <div class="date">
              <span>{{ $t("profile.work_experience.from") }}</span>
              <div class="date-container">
                <select v-model="modelModalCreate.monthFrom" :class="{'input-invalid': v$.modelModalCreate.monthFrom.$invalid && submitted}">
                  <option :value="''" disabled>Mes</option>
                  <option v-for="month in monthsList" :key="month.id" :value="month.id">{{month.name}}</option>
                </select>
                <select v-model="modelModalCreate.yearFrom" :class="{'input-invalid': v$.modelModalCreate.yearFrom.$invalid && submitted}">
                  <option :value="''" disabled>Año</option>
                  <option v-for="year in yearsList" :key="year" :value="year">{{year}}</option>
                </select>
              </div>
            </div>
            <div v-if="!modelModalCreate.current" class="date">
              <span>{{ $t("profile.work_experience.to") }}</span>
              <div class="date-container">
                <select v-model="modelModalCreate.monthTo" :class="{'input-invalid': v$.modelModalCreate.monthTo.$invalid && submitted}">
                  <option :value="''" disabled>Mes</option>
                  <option v-for="month in monthsList" :key="month.id" :value="month.id">{{month.name}}</option>
                </select>
                <select v-model="modelModalCreate.yearTo" :class="{'input-invalid': v$.modelModalCreate.yearTo.$invalid && submitted}">
                  <option :value="''" disabled>Año</option>
                  <option v-for="year in yearsList" :key="year" :value="year">{{year}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal__buttons">
          <button @click.stop="modalCreate = false; submitted = false" class="button--gray">{{ $t("profile.my_best_version.cancel") }}</button>
          <button @click.stop="addModalCreate()">{{ $t("profile.finish") }}</button>
        </div>
      </div>
    </div>
  </div>

  <!-- modalRemove -->
  <div v-if="modalRemove">
    <VisibleModal/>
    <div class="modal__overlay" @click.stop="modalRemove = false; modelModalRemove = {idx: '', name: '', list: ''}">
      <div class="modal" @click.stop="''">
        <span class="material-icons" @click.stop="modalRemove = false; modelModalRemove = {idx: '', name: '', list: ''}">close</span>
        <p>¿{{ $t("profile.my_best_version.modalDelete") }} <strong>{{modelModalRemove.name}}</strong> {{$t(`profile.myBestVersion.modalDelete.${modelModalRemove.list}`)}}?</p>
        <div class="modal__buttons">
          <button @click.stop="modalRemove = false; modelModalRemove = {idx: '', name: '', list: ''}" class="button--gray">{{ $t("profile.my_best_version.cancel") }}</button>
          <button @click.stop="removeModal()">{{ $t("profile.my_best_version.accept") }}</button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import BannerPercentage from '@/components/Profile/BannerPercentage.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ref } from '@vue/reactivity';
import VisibleModal from '@/components/Common/VisibleModal.vue';
import { computed, onMounted, watch, inject } from '@vue/runtime-core';
import Multiselect from '@vueform/multiselect';
import storage from "@/plugins/azure-storage";
import { required, requiredIf, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Tooltip from '@/components/Profile/Tooltip.vue';
import moment from 'moment';
import persistentData from '@/plugins/persistentData';


function isFromYoutube (videoLink) {
  const youtubeValidPattern = new RegExp(/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/); //eslint-disable-line
  if (youtubeValidPattern.test(videoLink)) return true;
  return false;
}

export default {
  components: {
    BannerPercentage,
    VisibleModal,
    Multiselect,
    Tooltip
  },
  setup() {
    const v$ = useVuelidate();
    const { t } = useI18n();
    const store = useStore();
    const mobileVersion = inject('mobileVersion')
    const aboutMe = computed(()=>store.getters.profile_aboutMe);
    //const aboutMe = {...store.getters.profile_aboutMe}
    const aboutMeModel = ref(aboutMe);
    const personalInformation = {...store.getters.profile_personalInformation};
    let whatMakesMeUniqueEdit = ref(false);
    let whoIAmEdit = ref(false);
    let whatIExpectFromNextJob = ref(false);
    let interestEdit = ref(false);
    let modalInterest = ref(false);
    let modalTechnology = ref(false);
    let modalPhoto = ref(false);
    let modalSkills = ref(false);
    let myPhotosEdit = ref(false);
    let myVideosEdit = ref(false);
    let externalCoursesEdit =ref(false);
    let volunteeringEdit = ref(false);
    let technologicalToolsEdit = ref(false);
    let modalCreate = ref(false);
    let modalRemove = ref(false);
    let modelModalRemove = ref({});
    let typeModalCreate = ref({});
    let hasImageUpload = ref(false);
    let imagePreviewName = ref("");
    let editModeActive = ref(false);
    let editingItemIndex = ref(null);
    let levelProfile = ref(5);
    const yearsList = ref([]);
    const monthsList = [
      {id: "1", name: "Enero"},
      {id: "2", name: "Febrero"},
      {id: "3", name: "Marzo"},
      {id: "4", name: "Abril"},
      {id: "5", name: "Mayo"},
      {id: "6", name: "Junio"},
      {id: "7", name: "Julio"},
      {id: "8", name: "Agosto"},
      {id: "9", name: "Septiembre"},
      {id: "10", name: "Octubre"},
      {id: "11", name: "Noviembre"},
      {id: "12", name: "Diciembre"},
    ]
    let modelImages = ref({
        picture: '',
        description: ''
    });
    let modelModalCreate = ref({
      field1: '',
      field2: '',
      field3: '',
      field4: '',
      monthFrom: '',
      monthTo: '',
      yearFrom: '',
      yearTo: ''
    });
    let videoModel = ref({
      link: '',
      description: ''
    });
    let modalVideo = ref(false);
    let technologicalToolString = ref("");
    let interestAddModel = ref("");
    let interestOtherModel = ref("");
    let skillAddModel = ref("");
    let skillOtherModel = ref("");
    let listInterest = ref([]);
    let listSkills = ref([]);
    let submitted = ref(false);
    let buttonsInterest = ref(null);

    /* async function formatDates(){
      aboutMeModel.value.activities.forEach(el => {
        el.fromDate = formatDate(el.fromDate, 'YYYY-MM');
        if(el.toDate != null) el.toDate = formatDate(el.toDate, 'YYYY-MM');
      })
      aboutMeModel.value.externalCourses.forEach(el => {
        el.fromDate = formatDate(el.fromDate, 'YYYY-MM');
        if(el.toDate != null) el.toDate = formatDate(el.toDate, 'YYYY-MM');
      })
      aboutMeModel.value.volunteerings.forEach(el => {
        el.fromDate = formatDate(el.fromDate, 'YYYY-MM');
        if(el.toDate != null) el.toDate = formatDate(el.toDate, 'YYYY-MM');
      })
    } */

    function createWorkYears() {
      let anio = new Date().getFullYear();
      let anios = [];
      for (let i = anio; i > anio - 20; i--) {
        anios.push(i.toString());
      }
      yearsList.value = anios;
    }

    function orderCardsMobile(){
      let $container = document.querySelector('#best-version-container-mobile');
      let $cards = document.querySelectorAll('.profile__card');
      let $fragment = document.createDocumentFragment();
      $cards.forEach(card => {
        let orderNumber = card.querySelector('.counter').innerText;
        card.style.order = orderNumber;
        $fragment.appendChild(card);
      })
      $container.appendChild($fragment);
    }

    watch(()=>mobileVersion.value,()=>{
      if(mobileVersion.value) orderCardsMobile();
      else location.reload();
    })

    onMounted(async()=>{
      levelProfile.value = persistentData.get("forgeLevel", true);
      listInterest.value = await store.dispatch('stuff_interests');
      listSkills.value = await store.dispatch('stuff_skills');
      //formatDates();
      createWorkYears();
      if(mobileVersion.value) orderCardsMobile();
    });

    function formatDate(date, format = 'L') {
      return moment(date).format(format);
    }
    function getImage(path) {
      return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
    }

    watch(modalCreate, function() {
      if (!modalCreate.value) {
        modelModalCreate.value = {
          field1: '',
          field2: '',
          field3: '',
          field4: '',
          monthFrom: '',
          yearFrom: '',
          monthTo: '',
          yearTo: '',
          current: false
        };
        editModeActive.value = false;
        editingItemIndex.value = null;
        typeModalCreate.value = "";
        submitted.value = false;
      }
    });

    function toggleMultiselect(open){
      if(open){
        setTimeout(()=>{
          let dropdown = document.querySelector(".multiselect-dropdown");
          buttonsInterest.value.style.marginTop = dropdown.clientHeight+"px";
        },50)

      }
      else{
        buttonsInterest.value.style.marginTop = "0"       
      }
    }

    // Intereses
    const listInterestFiltered = computed(() => {
      const listInterestActives = aboutMeModel.value.profileInterest.map(interest => interest.name);
      const valueToReturn = listInterest.value.filter(interest => !listInterestActives.includes(interest.name));
      return valueToReturn;
    });

    async function addInterest(interestName) {
      if (interestName == 'Otro') {
        if (interestOtherModel.value == '') return;
        aboutMeModel.value.profileInterest.push({id: null, name: interestOtherModel.value});
        interestOtherModel.value = ''
      } else {
        const item = listInterest.value.find(i => i.name == interestName);
        aboutMeModel.value.profileInterest.push(item);
      }
      
      modalInterest.value = false;
      try{
        await store.dispatch('profile_updateInterests', aboutMeModel.value.profileInterest);
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.profileInterest`)});
      }
      catch(err){
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }

    }
    async function removeInterest(index) {
      aboutMeModel.value.profileInterest.splice(index, 1);
      try{
        await store.dispatch('profile_updateInterests', aboutMeModel.value.profileInterest);
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.remove.profileInterest`)});
      }
      catch(err){
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }
    }
    // Skills
    const listSkillsFiltered = computed(() => {
      const listSkillsActives = aboutMeModel.value.skills.map(skill => skill.skillName);
      const valueToReturn = listSkills.value.filter(skill => !listSkillsActives.includes(skill.name));
      return valueToReturn;
    });

    async function addSkill(skillName) {
      if (skillName == 'Otro') {
        if (skillOtherModel.value == '') return;
        aboutMeModel.value.skills.push({skillId: null, skillName: skillOtherModel.value});
        skillOtherModel.value = '';
      } else {
        const item = listSkills.value.find(i => i.name == skillName);
        aboutMeModel.value.skills.push({skillId: item.id, skillName: item.name});
      }
      
      modalSkills.value = false;
      try {
        await store.dispatch('profile_updateSkills', aboutMeModel.value.skills);
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.skills`)});
      } catch(err) {
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }

    }
    async function removeSkill(index) {
      aboutMeModel.value.skills.splice(index, 1);
      try{
        await store.dispatch('profile_updateSkills', aboutMeModel.value.skills);
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.remove.skills`)});
      }
      catch(err){
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }
    }

    async function uploadCertificate(event) {
      const file = event.target.files[0]
      if(!file) return false;
      const imageName = await storage.updateFile(file);
      modelModalCreate.value.fileUrl = imageName; 
    }


    async function uploadPhoto(event) {
      const file = event.target.files[0]
      imagePreviewName.value = file.name;
      if(!file) {
        return false
      }
      const imageName = await storage.updateFile(file);
      hasImageUpload.value = true;
      modelImages.value.picture = imageName;
    }
    function cancelUploadPhoto() {
      modelImages.value = { picture: '', description: '' }
      imagePreviewName.value = "";
      hasImageUpload.value = false;
      modalPhoto.value = false;
    }
    async function updatePhotos(){
      aboutMeModel.value.photos.push(modelImages.value);
      modelImages.value = { picture: '', description: '' }
      imagePreviewName.value = "";
      hasImageUpload.value = false;
      try{
        await store.dispatch('profile_updatePhotos', aboutMeModel.value.photos);
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.photos`)});
      }
      catch(err){
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }
    }
    async function removePhoto(index) {
      aboutMeModel.value.photos.splice(index, 1);
      try{
        await store.dispatch('profile_updatePhotos', aboutMeModel.value.photos);
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.photos`)});
      }
      catch(err){
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }
    }

    async function updateWhatMakesMeUnique(){
      try{
        await store.dispatch('profile_updateWhatMakesMeUnique', {text: aboutMeModel.value.whatMakesMeUnique});
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.whatMakesMeUnique`)});
      }
      catch(err){
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }
    }

    async function updateWhoIAm() {
      try{
        await store.dispatch('profile_updateWhoIAm', {text: aboutMeModel.value.whoIAm});
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.whoIAm`)});
      }
      catch(err){
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }
    }
    
    async function updateWhatIExpectFromNextJob() {
      try{
        await store.dispatch('profile_updateWhatIExpectFromNextJob', {text: aboutMeModel.value.whatIExpectFromNextJob});
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.whatIExpectFromNextJob`)});
      }
      catch(err){
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }
    }
    

    async function uploadVideo() {
      submitted.value = true;
      if (!v$.value.videoModel.$invalid) {
        aboutMeModel.value.videos.push(videoModel.value);
        videoModel.value = {
          link: '',
          description: ''
        };
        modalVideo.value = false;
        submitted.value = false;
        try{
          await store.dispatch('profile_updateVideos', aboutMeModel.value.videos);
          store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.videos`)});
        }
        catch(err){
          console.log(err);
          store.dispatch('notifications_create', { text: "Ocurrió un error" });
        }
      } else {
        store.dispatch('notifications_create', { text: t(`notifications.completeRequiredFields`) });
      }
    }

    async function uploadTecnologicalTools() {
      aboutMeModel.value.technologicalTools.push(technologicalToolString.value);
      modalTechnology.value = false;
      technologicalToolString.value = "";
      try{
        await store.dispatch('profile_updateTechnologicalTools', aboutMeModel.value.technologicalTools);
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.technologicalTools`)});
      }
      catch(err){
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }
    }

    async function updateAboutMe(type,remove=false) {
      if (type == 'photos' && !remove) {
        aboutMeModel.value.photos.push(modelImages.value);
        modelImages.value = { picture: '', description: '' }
        imagePreviewName.value = "";
        hasImageUpload.value = false;
      }
      try {
        await store.dispatch('profile_updateAboutMe', aboutMeModel.value);
        if(remove){
          store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.remove.${type}`)});
        }
        else{
          store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.${type}`)});
        }
        const profile = await store.dispatch('profile_profile');
        store.commit('profile_updateProfile', profile);
        //formatDates();
      } catch (error) {
        console.log(error);
      }
    }

    
    function editMode(index){
      editModeActive.value=true; 
      editingItemIndex.value=index; 
      switch (typeModalCreate.value) {
        case 'activities':
          modelModalCreate.value.field1 = aboutMeModel.value.activities[index].name;
          modelModalCreate.value.field2 = aboutMeModel.value.activities[index].description;
          modelModalCreate.value.field3 = aboutMeModel.value.activities[index].taskDone;
          modelModalCreate.value.field4 = aboutMeModel.value.activities[index].link;
          modelModalCreate.value.monthFrom = aboutMeModel.value.activities[index].monthFrom;
          modelModalCreate.value.yearFrom = aboutMeModel.value.activities[index].yearFrom;
          modelModalCreate.value.monthTo = aboutMeModel.value.activities[index].monthTo;
          modelModalCreate.value.yearTo = aboutMeModel.value.activities[index].yearTo;
          if(aboutMeModel.value.activities[index].yearTo == null) modelModalCreate.value.current = true;
        break;
        case 'externalCourses':
          modelModalCreate.value.field1 = aboutMeModel.value.externalCourses[index].name;
          modelModalCreate.value.field2 = aboutMeModel.value.externalCourses[index].institution;
          modelModalCreate.value.field3 = aboutMeModel.value.externalCourses[index].certificateLink;
          modelModalCreate.value.field4 = aboutMeModel.value.externalCourses[index].organizationLink;
          modelModalCreate.value.fileUrl = aboutMeModel.value.externalCourses[index].fileUrl;
          modelModalCreate.value.monthFrom = aboutMeModel.value.externalCourses[index].monthFrom;
          modelModalCreate.value.yearFrom = aboutMeModel.value.externalCourses[index].yearFrom;
          modelModalCreate.value.monthTo = aboutMeModel.value.externalCourses[index].monthTo;
          modelModalCreate.value.yearTo = aboutMeModel.value.externalCourses[index].yearTo;
          console.log(aboutMeModel.value.externalCourses[index].yearTo)
          if(aboutMeModel.value.externalCourses[index].yearTo == null) modelModalCreate.value.current = true;
        break;
        case 'volunteerings':
          modelModalCreate.value.field1 = aboutMeModel.value.volunteerings[index].name;
          modelModalCreate.value.field2 = aboutMeModel.value.volunteerings[index].institution;
          modelModalCreate.value.field3 = aboutMeModel.value.volunteerings[index].taskDone;
          modelModalCreate.value.field4 = aboutMeModel.value.volunteerings[index].link;
          modelModalCreate.value.monthFrom = aboutMeModel.value.volunteerings[index].monthFrom;
          modelModalCreate.value.yearFrom = aboutMeModel.value.volunteerings[index].yearFrom;
          modelModalCreate.value.monthTo = aboutMeModel.value.volunteerings[index].monthTo;
          modelModalCreate.value.yearTo = aboutMeModel.value.volunteerings[index].yearTo;
          if(aboutMeModel.value.volunteerings[index].yearTo == null) modelModalCreate.value.current = true;
        break;
      }      
      modalCreate.value = true;
    }

    async function addModalCreate() {
      submitted.value = true;
      if (v$.value.modelModalCreate.$invalid){
        store.dispatch('notifications_create', { text: t(`notifications.completeRequiredFields`)});
        return;
      } 
      if(editModeActive.value){
        switch (typeModalCreate.value) {
          case 'activities':
            aboutMeModel.value.activities[editingItemIndex.value].name = modelModalCreate.value.field1;
            aboutMeModel.value.activities[editingItemIndex.value].description = modelModalCreate.value.field2;
            aboutMeModel.value.activities[editingItemIndex.value].taskDone = modelModalCreate.value.field3;
            aboutMeModel.value.activities[editingItemIndex.value].link = modelModalCreate.value.field4;
            aboutMeModel.value.activities[editingItemIndex.value].monthFrom = modelModalCreate.value.monthFrom;
            aboutMeModel.value.activities[editingItemIndex.value].yearFrom = modelModalCreate.value.yearFrom;
            aboutMeModel.value.activities[editingItemIndex.value].monthTo = modelModalCreate.value.monthTo;
            aboutMeModel.value.activities[editingItemIndex.value].yearTo = modelModalCreate.value.yearTo;
          break;
          case 'externalCourses':
            aboutMeModel.value.externalCourses[editingItemIndex.value].name = modelModalCreate.value.field1;
            aboutMeModel.value.externalCourses[editingItemIndex.value].institution = modelModalCreate.value.field2;
            aboutMeModel.value.externalCourses[editingItemIndex.value].certificateLink = modelModalCreate.value.field3;
            aboutMeModel.value.externalCourses[editingItemIndex.value].organizationLink = modelModalCreate.value.field4;
            aboutMeModel.value.externalCourses[editingItemIndex.value].fileUrl = modelModalCreate.value.fileUrl;
            aboutMeModel.value.externalCourses[editingItemIndex.value].monthFrom = modelModalCreate.value.monthFrom;
            aboutMeModel.value.externalCourses[editingItemIndex.value].yearFrom = modelModalCreate.value.yearFrom;
            aboutMeModel.value.externalCourses[editingItemIndex.value].monthTo = modelModalCreate.value.monthTo;
            aboutMeModel.value.externalCourses[editingItemIndex.value].yearTo = modelModalCreate.value.yearTo;
          break;
          case 'volunteerings':
            aboutMeModel.value.volunteerings[editingItemIndex.value].name = modelModalCreate.value.field1;
            aboutMeModel.value.volunteerings[editingItemIndex.value].institution = modelModalCreate.value.field2;
            aboutMeModel.value.volunteerings[editingItemIndex.value].taskDone = modelModalCreate.value.field3;
            aboutMeModel.value.volunteerings[editingItemIndex.value].link = modelModalCreate.value.field4;
            aboutMeModel.value.volunteerings[editingItemIndex.value].monthFrom = modelModalCreate.value.monthFrom;
            aboutMeModel.value.volunteerings[editingItemIndex.value].yearFrom = modelModalCreate.value.yearFrom;
            aboutMeModel.value.volunteerings[editingItemIndex.value].monthTo = modelModalCreate.value.monthTo;
            aboutMeModel.value.volunteerings[editingItemIndex.value].yearTo = modelModalCreate.value.yearTo;
          break;
        }
      }else{
        switch (typeModalCreate.value) {
          case 'activities':
            aboutMeModel.value.activities.push({
              name: modelModalCreate.value.field1,
              description: modelModalCreate.value.field2,
              taskDone: modelModalCreate.value.field3,
              link: modelModalCreate.value.field4,
              monthFrom: modelModalCreate.value.monthFrom,
              yearFrom: modelModalCreate.value.yearFrom,
              monthTo: modelModalCreate.value.monthTo,
              yearTo: modelModalCreate.value.yearTo,
            });
            break;
          case 'externalCourses':
            aboutMeModel.value.externalCourses.push({
              name: modelModalCreate.value.field1,
              institution: modelModalCreate.value.field2,
              certificateLink: modelModalCreate.value.field3,
              organizationLink: modelModalCreate.value.field4,
              fileUrl: modelModalCreate.value.fileUrl,
              monthFrom: modelModalCreate.value.monthFrom,
              yearFrom: modelModalCreate.value.yearFrom,
              monthTo: modelModalCreate.value.monthTo,
              yearTo: modelModalCreate.value.yearTo,
            });
            break;
          case 'volunteerings':
            aboutMeModel.value.volunteerings.push({
              name: modelModalCreate.value.field1,
              institution: modelModalCreate.value.field2,
              taskDone: modelModalCreate.value.field3,
              link: modelModalCreate.value.field4,
              monthFrom: modelModalCreate.value.monthFrom,
              yearFrom: modelModalCreate.value.yearFrom,
              monthTo: modelModalCreate.value.monthTo,
              yearTo: modelModalCreate.value.yearTo,
            });
            break;
        }
      }
      let list = typeModalCreate.value;
      let listCapitalized = list.charAt(0).toUpperCase() + list.slice(1);
      try{
        let resp = await store.dispatch(`profile_update${listCapitalized}`, aboutMeModel.value[list]);
        modalCreate.value = false;
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.${list}`)});
        store.commit(`profile_add${listCapitalized}`,resp);
      }
      catch(err){ 
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }
    }

    function toggleCurrentDate(model){
      if(event.target.checked){
        model.current = true;
        model.monthTo = null;
        model.yearTo = null;
      } else{
        model.current = false;
        model.monthTo = '';
        model.yearTo = '';
      }
    }

    async function removeModal() {
      let list = modelModalRemove.value.list;
      let listCapitalized = list.charAt(0).toUpperCase() + list.slice(1);
      aboutMeModel.value[list].splice(modelModalRemove.value.idx, 1);
      modalRemove.value = false;
      modelModalRemove.value = {idx: '', name: '', list: ''};
      try{
        await store.dispatch(`profile_update${listCapitalized}`, aboutMeModel.value[list]);
        store.dispatch('notifications_create', { text: t(`notifications.updateAboutMe.${list}`)});
      }
      catch(err){ 
        console.log(err);
        store.dispatch('notifications_create', { text: "Ocurrió un error" });
      }
    }

    return {
      mobileVersion,
      aboutMe,
      whatMakesMeUniqueEdit,
      whoIAmEdit,
      whatIExpectFromNextJob,
      interestAddModel,
      interestOtherModel,
      listSkillsFiltered,
      addSkill,
      removeSkill,
      interestEdit,
      myPhotosEdit,
      aboutMeModel,
      updateAboutMe,
      cancelUploadPhoto,
      removeInterest,
      modalInterest,
      modalTechnology,
      modalPhoto,
      addInterest,
      listInterest,
      listInterestFiltered,
      personalInformation,
      getImage,
      uploadPhoto,
      uploadCertificate,
      removePhoto,
      updatePhotos,
      uploadVideo,
      myVideosEdit,
      videoModel,
      modalVideo,
      hasImageUpload,
      modelImages,
      imagePreviewName,
      externalCoursesEdit,
      volunteeringEdit,
      technologicalToolsEdit,
      modalCreate,
      toggleCurrentDate,
      modalRemove,
      removeModal,
      modelModalRemove,
      typeModalCreate,
      modelModalCreate,
      addModalCreate,
      submitted,
      uploadTecnologicalTools,
      technologicalToolString,
      v$,
      formatDate,
      toggleMultiselect,
      buttonsInterest,
      editMode,
      editModeActive,
      editingItemIndex,
      updateWhatMakesMeUnique,
      monthsList,
      yearsList,
      levelProfile,
      modalSkills,
      updateWhoIAm,
      updateWhatIExpectFromNextJob,
      skillAddModel,
      skillOtherModel
    }
  },
  validations () {
    return {
      modelModalCreate: {
        field1: { required },
        field2: { required },
        field3: { requiredIfRef: requiredIf(this.typeModalCreate !== "externalCourses" && this.typeModalCreate !== "activities") },
        monthFrom: { required },
        yearFrom: { required },
        monthTo: { requiredIfRef: requiredIf(this.modelModalCreate.monthTo !== null) },
        yearTo: { requiredIfRef: requiredIf(this.modelModalCreate.yearTo !== null) }
      },
      aboutMeModel: {
        activities: {
          $each: helpers.forEach({
            name: { required }
        })
        }
      },
      videoModel: {
        description: { required },
        link: { 
          required, 
          isFromYoutube: helpers.withMessage('El video debe ser de YouTube', isFromYoutube) }
      }
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>

  #best-version-container-mobile{
    padding-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @mixin btn-addMore{
    background: $orange;
    border-radius: 100px;
    font-family: $font-palanquin;
    @include font-settings(base, mega);
    color: $white;
    padding: $margin-milli $margin-kilo;
    font-weight: 700;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    display: inline-flex;
    cursor: pointer;
  }
  .profile-card--experience{
    .activities,.courses,.volunteerings{
      width: 100%;
      padding-bottom: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(232, 123, 27, .4);
      margin-bottom: $margin-milli;
      .btns{
        align-self: flex-start;
        display: flex;
        color: $orange;
      }
    }
    .btn-add_container{
      display: flex;
      justify-content: center;
      margin-top: 16px;
      .btn-add {
        @include btn-addMore
      }
    }
  }
  .profile__card--tabs {
    padding: $margin-base 0;

    button {
      background: $white;
      border-radius: 100px;
      font-family: $font-palanquin;
      @include font-settings(base, mega);
      color: $orange;
      padding: $margin-milli $margin-kilo;
      font-weight: 700;
      margin: $margin-milli $margin-base $margin-milli 0;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
      display: inline-flex;
      cursor: default;

      i, &.tab-add {
        cursor: pointer;
      }
    }
    .interest_btn-add {
      background-color: $orange;
      color: $white;
      cursor: pointer;
    }
  }
  .interests{
    .btn-add_container{
      display: flex;
      justify-content: center;
      button{
        @include btn-addMore;
      }
    }
  }
  .techTools{
    .btn-add_container{
      display: flex;
      justify-content: center;
      button{
        @include btn-addMore;
      }
    }
  }

  .profile__card__header__title {
    display: grid;
    grid-template-columns: 24px 1fr 24px;
    width: 100%;
  }

  .profile__card--video {
    &__add {
      border: 1px solid $lightgray;
      padding: $margin-base;
      border-radius: $margin-milli;
      margin-top: $margin-milli;
      
      input {
        color: $litedarkgray;
        font-family: $font-palanquin;
        @include font-settings(kilo, base);
        border: none;
        background: none;
        font-weight: 700;
        width: 100%;
        border-bottom: 1px solid $gray;
        margin-bottom: $margin-milli;

        &::placeholder {
          color: $graybg;
        }
      }
    }
    a {
      display: flex;

      i { 
        margin-left: $margin-milli;
      }
    }
    .delete {
      color: $orange;
    }
    .btn-add_container{
      display: flex;
      justify-content: center;
      margin-top: 16px;
      button {
        @include btn-addMore
      }
      button:disabled{
        opacity: .5;
        cursor: auto;
      }
    }
  }

  .profile__card--photos {
    .btn-add_container{
      display: flex;
      justify-content: center;
      margin-top: 16px;
      button {
        @include btn-addMore
      }
      button:disabled{
        opacity: .5;
        cursor: auto;
      }
    }
    @media (min-width: $break-lg) {
      grid-area: third;
    }
  }

  .profile__card__photos {
    padding: $margin-base 0;
    display: grid;
    grid-gap: $margin-base;
    grid-template-columns: 1fr 1fr;
    word-break: break-all;

    &__item {
      position: relative;

      i {
        position: absolute;
        top: 4px;
        right: 4px;
        padding: 4px;
        background: $white;
        border-radius: 50%;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;
      }
    }

    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      object-fit: cover;
      border-radius: $margin-milli;
    }
  }
  
  .profile__card__button--input {
    display: flex;
    justify-content: center;
    cursor: pointer;
    background: none;
    color: $orange;
    border: 1px solid $orange;
    line-height: 22px;
    text-align: center;
    padding: 4px $margin-milli;

    input {
      display: none;
    }
  }    

  textarea {
    border: 2px solid $orange;
    border-radius: $margin-milli;
    font-weight: 500!important;
    font-family: $font-opensans!important;
  }

  textarea, select {
    color: $gray;
    font-family: $font-palanquin;
    @include font-settings(kilo, base);
    background: none;
    font-weight: 700;
    width: 100%;
    resize: none;
  }

  .modal {
    overflow: visible;
    h4, &__buttons button {
      color: $orange;
    }
  }

  .profile-card--edit {
    .currentDate{
      flex-direction: row;
      align-items: center;
      input{
        width: auto;
        margin: 0 5px 0 0;
      }
    }
    input[type="month"]:disabled {
      opacity: .5;
    }
    .tell-us-more{
      margin-top: 4px;
      height: 120px;
      border-color: #606060;
    }
    .uploadPhoto_container{
      width: 100%;
      // label{
      //   display: block;
      //   padding: 5px 0;
      //   border: 3px dashed #bdbdbd;
      //   font-size: 2.5rem;
      //   font-weight: bold;
      //   color: #bdbdbd;
      //   cursor: pointer;
      // }
    //   #upload-photo{
    //     visibility: hidden;
    //   }
    }
    .modal__buttons{
      button:disabled{
        opacity: .5;
        cursor: auto;
      }
    }
  }

  .modalPhoto{
    img{
      width: 100%;
      height: auto;
    }
    .uploadPhoto_container{
      width: 100%;
      text-align: center;

      label{
        display: block;
        padding: 5px 0;
        border: 3px dashed #bdbdbd;
        font-size: 2.5rem;
        font-weight: bold;
        color: #bdbdbd;
        cursor: pointer;
      }
      #upload-photo{
        display: none;
      }
    }
    .modal__buttons{
      button:disabled{
        opacity: .5;
        cursor: auto;
      }
    }
    .description{
      span{
        display: block;
        width: 100%;
        margin: 2px;
        color: #7D7D7D;
        text-align: left;
      }
      input[type="text"]{
        width: 100%;
        height: 30px;
        margin: 2px;
        outline: thick;
      }
      textarea {
        font-size: 16px;
        padding: 4px;
      }
    }

    .previewImage {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: bold;
      color: $orange;

      i {
        position: inherit;
        font-weight: bold;
        color: $orange;
      }
    }
  }
  .modal_technology {
    .modal__buttons{
      button:disabled{
        opacity: .5;
        cursor: auto;
      }
    }
    input[type="text"]{
      width: 100%;
      margin: 2px 0;
      padding: 2px 0 2px 2px;
    }
  }

  .modal_video {
    input[type="text"]{
      width: 100%;
      margin: 10px 0;
      padding: 2px 0 2px 2px;
      height: 30px;
    }
    label{
        display:block;
        text-align: left;
        font-size: 14px;
        margin-bottom: -8px;
        margin-top: 15px;
    }
    .modal__buttons{
      margin-top: -10px;
      button:disabled{
        opacity: .5;
        cursor: auto;
      }
    }

    .input-message{
      margin-top: 12px;
      margin-bottom: -16px;
    }

    .material-icons {
      position: initial;
    }
  }
.date{
  margin-bottom: 0;
}
.date-container{
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 12px;
}
</style>