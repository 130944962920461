<template>
  <div class="input__search">
    <div class="input__search__search-bar">
      <input type="text" v-model="inputValue" placeholder="Buscar..."/>
      <span class="material-icons">search</span>
    </div>
    <!-- Se oculta hasta que se agreguen los filtros -->
    <!-- <div class="input__search__filters" @click="search">
      <span class="material-icons">filter_alt</span>
    </div> -->
  </div>
</template>
<script>
import { ref, watch } from 'vue';

export default {
  emits: ['updateSearchValue'],
  setup(_, { emit }) {
    const inputValue = ref('');
    let timer = null;

    function search () {
      emit('updateSearchValue', inputValue.value);
    }

    watch(inputValue, () => { typing(); });

    function typing () {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        search();
      }, 50);
    }

    return {
      inputValue,
      search
    }
  }
};
</script>
<style lang="scss" scoped>
.input__search {
  padding: 0 $margin-base $margin-kilo;
  display: flex;
  gap: $margin-milli;

  @media (min-width: $break-sm) {
    padding: 0 0 $margin-kilo;
  }

  &__search-bar {
    position: relative;
    width: 100%;

    input {
      height: 40px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      border: none;
      width: 100%;
      padding-left: $margin-giga;
      color: $white;
      font-size: min(100%, 16px);

      &::placeholder {
        color: $white;
        font-family: $font-opensans;
      }
    }

    span {
      position: absolute;
      left: 8px;
      top: 8px;
    }
  }

  &__filters {
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.2);
    flex-shrink: 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>