<template>
  <div class="login">
    <img src="@/assets/ruky.svg" alt="">
    <div class="container">
      <div class="login__container">
        <h1>{{ $t("register.title") }}</h1>
        <p>{{ $t("register.description") }}</p>
        <input type="text" :placeholder="$t('register.input_nickname')" autocomplete="off" v-model="nickName" :class="{'input-invalid': v$.nickName.$invalid && submitted}">
        <span class="input-message" v-if="v$.nickName.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.nickName.required.$message}}</span>

        <button @click="startRegister()" class="button button--red button--register">{{ $t("register.start") }}</button>
        <button class="button button--red-clean">
          <router-link to="/login">
            {{ $t("register.have-account") }}
          </router-link>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitted = ref(false);

    const nickName = computed({
      get: () => store.state.Register.userModel.nickName,
      set: (value) => store.commit('register_updateField', {field: 'nickName', value})
    });

    function startRegister() {
      submitted.value = true;
      if (nickName.value !== '') {
        submitted.value = false;
        router.push('/register/first');
      }
    }

    return {
      nickName,
      submitted,
      startRegister,
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      nickName: { 
        required: helpers.withMessage('Este campo es requerido', required)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  img {
    position: absolute;
    bottom: -90px;
    left: -40px;
    transform: rotate(20deg);
    width: 184px;

    @media (min-height: 736px) {
      bottom: -50px;
    }
  }
</style>