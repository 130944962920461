<template>

  <div class="profile-card--first">
    <BannerPercentage />
  </div>

   <!-- Mis datos personales -->
  <div class="profile__card profile-card--personal-data">
    <div class="profile__card__header profile__card__header--space">
      <div class="profile__card__header__title">
        <i class="material-icons">person</i>
          {{$t("profile.myInformation.personalInformation.title")}}
      </div>
      <!-- <Tooltip 
        :text="$t('profile.myBestVersion.tooltip.changeData')"
        :left="mobileVersion ? '-204px' : ''"
        :top="mobileVersion ? '30px' : ''"
      /> -->
      <i class="material-icons profile__card__header__arrow" v-if="!personalEdit" @click="personalEdit = !personalEdit">edit</i>
      <i class="material-icons profile__card__header__arrow" v-else @click="updatePersonalInformation">check</i>
    </div>
    <template v-if="completeLoad">
      <template v-if="!personalEdit">
        <div class="profile__card--personal-edit">
          <div class="profile__card--personal-edit__image" :style="`background-image: url(${getImage(personalInformation.picture, personalInformation.isPictureFromCDN) || require('@/assets/placeholder.png')})`"></div> 
          <div class="profile__card--personal-edit__nickname">
            <span>{{$t("profile.myInformation.personalInformation.nickName")}}</span>
            <strong>{{personalInformation.nickName || '-'}}</strong>
          </div>
          <div class="profile__card--personal-edit__fullname">
            <div>
              <span>{{$t("profile.myInformation.personalInformation.firstName")}}</span>
              <strong>{{personalInformation.firstName || '-'}}</strong>
            </div>
            <div>
              <span>{{$t("profile.myInformation.personalInformation.lastName")}}</span>
              <strong>{{personalInformation.lastName || '-'}}</strong>
            </div>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.identification")}}</span>
            <strong>{{personalInformation.identification || '-'}}</strong>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.birthDate")}}</span>
            <strong>{{formatDate(personalInformation.birthDate, 'DD/MM/YYYY')}}</strong>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.gender")}}</span>
            <strong>{{getGender(personalInformation.genderId)}}</strong>
          </div>
          <div v-if="personalInformation.selfPerceivedGender !== null && personalInformation.selfPerceivedGender !== ''">
            <span>{{$t("profile.myInformation.personalInformation.selfPerceivedGender")}}</span>
            <strong>{{personalInformation.selfPerceivedGender}}</strong>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.nationality")}}</span>
            <strong>{{getNationality(personalInformation.nationalityId)}}</strong>
          </div>
          <div class="card-detail__separator profile__card--personal-edit__fullrow">
            <div class="card-detail__separator__title">{{$t("profile.myInformation.personalInformation.infoContact")}}</div>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.otherPhone")}}</span>
            <strong>{{personalInformation.otherPhone || '-'}}</strong>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.mobilePhone")}}</span>
            <strong>{{personalInformation.mobilePhone || '-'}}</strong>
          </div>
          <div class="profile__card--personal-edit__fullrow">
            <span>{{$t("profile.myInformation.personalInformation.email")}}</span>
            <strong>{{personalInformation.email || '-'}}</strong>
          </div>
          <div class="card-detail__separator profile__card--personal-edit__fullrow">
            <div class="card-detail__separator__title">{{$t("profile.myInformation.personalInformation.location")}}</div>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.country")}}</span>
            <strong>{{getCountry(personalInformation.countryId)}}</strong>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.state")}}</span>
            <strong>{{getState(personalInformation.stateId)}}</strong>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.city")}}</span>
            <strong>{{personalInformation.stateId ? getCity(personalInformation.cityId) : '-'}}</strong>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.neighborhood")}}</span>
            <strong>{{personalInformation.neighborhood || '-'}}</strong>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.address")}}</span>
            <strong>{{personalInformation.address || '-'}}</strong>
          </div>
        </div>
        <!-- <button class="profile__card__button">verificar perfíl</button> -->
      </template>
      <template v-else>
        <div class="profile__card--personal-edit">
          <div class="profile__card--personal-edit__image" :style="`background-image: url(${getImage(personalInformationModel.picture, personalInformationModel.isPictureFromCDN) || require('@/assets/placeholder.png')})`"></div> 
          <input type="file" name="" id="" @change="uploadImageProfile($event)">
          <div class="profile__card--personal-edit__nickname">
            <span>{{$t("profile.myInformation.personalInformation.nickName")}}</span>
            <input type="text" :placeholder="$t('profile.myInformation.personalInformation.nickName')" v-model="personalInformationModel.nickName" :class="{'input-invalid': v$.personalInformationModel.nickName.$invalid && submitted}">
          </div>
          <div class="profile__card--personal-edit__fullname">
            <div>
              <span>{{$t("profile.myInformation.personalInformation.firstName")}}</span>
              <input type="text" :placeholder="$t('profile.myInformation.personalInformation.firstName')" v-model="personalInformationModel.firstName" :class="{'input-invalid': v$.personalInformationModel.firstName.$invalid && submitted}">
            </div>
            <div>
              <span>{{$t("profile.myInformation.personalInformation.lastName")}}</span>
              <input type="text" :placeholder="$t('profile.myInformation.personalInformation.lastName')" v-model="personalInformationModel.lastName" :class="{'input-invalid': v$.personalInformationModel.lastName.$invalid && submitted}">
            </div>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.identification")}}</span>
            <input type="text" :placeholder="$t('profile.myInformation.personalInformation.identification')" v-model="personalInformationModel.identification" :class="{'input-invalid': v$.personalInformationModel.identification.$invalid && submitted}">
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.birthDate")}}</span>
            <input type="date" v-model="personalInformationModel.birthDate" :class="{'input-invalid': v$.personalInformationModel.birthDate.$invalid && submitted}">
            <span class="input-message" v-if="v$.personalInformationModel.birthDate.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.personalInformationModel.birthDate.validBirthDate.$message}}</span>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.gender")}}</span>
            <select v-model="personalInformationModel.genderId" :class="{'input-invalid': v$.personalInformationModel.genderId.$invalid && submitted}">
              <option value="null">{{$t("profile.myInformation.personalInformation.select")}}</option>
              <option v-for="gender in listGender" :key="gender.id" :value="gender.id">{{gender.name}}</option>
            </select>
          </div>
          <div v-if="personalInformationModel.genderId == 3">
            <span>{{$t("profile.myInformation.personalInformation.selfPerceivedGender")}}</span>
            <input type="text" :placeholder="$t('profile.myInformation.personalInformation.selfPerceivedGender')" v-model="personalInformationModel.selfPerceivedGender">
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.nationality")}}</span>
            <select v-model="personalInformationModel.nationalityId">
              <option value="null">{{$t("profile.myInformation.personalInformation.select")}}</option>
              <option v-for="nationalities in listNationalities" :key="nationalities.id" :value="nationalities.id">{{nationalities.name}}</option>
            </select>
          </div>
          <div class="card-detail__separator profile__card--personal-edit__fullrow">
            <div class="card-detail__separator__title">{{$t("profile.myInformation.personalInformation.infoContact")}}</div>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.otherPhone")}}</span>
            <input type="text" :placeholder="$t('profile.myInformation.personalInformation.otherPhone')" v-model="personalInformationModel.otherPhone" :class="{'input-invalid': v$.personalInformationModel.otherPhone.$invalid && submitted}">
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.mobilePhone")}}</span>
            <input type="text" :placeholder="$t('profile.myInformation.personalInformation.mobilePhone')" v-model="personalInformationModel.mobilePhone" :class="{'input-invalid': v$.personalInformationModel.mobilePhone.$invalid && submitted}">
          </div>
          <div class="profile__card--personal-edit__fullrow">
            <span>{{$t("profile.myInformation.personalInformation.email")}}</span>
            <input type="email" :placeholder="$t('profile.myInformation.personalInformation.email')" v-model="personalInformationModel.email" :class="{'input-invalid': v$.personalInformationModel.email.$invalid && submitted}">
          </div>
          <div class="card-detail__separator profile__card--personal-edit__fullrow">
            <div class="card-detail__separator__title">{{$t("profile.myInformation.personalInformation.location")}}</div>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.country")}}</span>
            <select v-model="personalInformationModel.countryId" :class="{'input-invalid': v$.personalInformationModel.countryId.$invalid && submitted}" @change="onChangeCountry">
              <option value="null">{{$t("profile.myInformation.personalInformation.select")}}</option>
              <option v-for="countries in listCountries" :key="countries.id" :value="countries.id">{{countries.name}}</option>
            </select>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.state")}}</span>
            <select v-model="personalInformationModel.stateId" @change="onChangeState">
              <option value="null">{{$t("profile.myInformation.personalInformation.select")}}</option>
              <option v-for="state in listStates" :key="state.id" :value="state.id">{{state.name}}</option>
            </select>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.city")}}</span>
            <select v-model="personalInformationModel.cityId">
              <option value="null">{{$t("profile.myInformation.personalInformation.select")}}</option>
              <option v-for="city in listCities" :key="city.id" :value="city.id">{{city.name}}</option>
            </select>
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.neighborhood")}}</span>
            <input type="text" :placeholder="$t('profile.myInformation.personalInformation.neighborhood')" v-model="personalInformation.neighborhood">
          </div>
          <div>
            <span>{{$t("profile.myInformation.personalInformation.address")}}</span>
            <input type="text" :placeholder="$t('profile.myInformation.personalInformation.address')" v-model="personalInformationModel.address">
          </div>
        </div>
        <button class="profile__card__button" @click="updatePersonalInformation">{{$t("profile.my_best_version.save")}}</button>
      </template>
    </template>
  </div>

  <!-- Mi Formación -->
  <div class="profile__card profile__card profile-card--experience">
    <router-link to="/profile/myEducation">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <i class="material-icons">person</i>
          {{$t("profile.myInformation.academics.title")}}
        </div>
          <i class="material-icons profile__card__header__arrow">navigate_next</i>
      </div>
    </router-link>
    <template v-if="study.academics.length > 0 && completeLoad">
      <div class="card-detail__separator profile__card--personal-edit__fullrow" v-if="hasAcademic">
        <div class="card-detail__separator__title">{{$t("profile.myInformation.academics.academics")}}</div>
      </div>
      <template v-for="academic in study.academics" :key="academic.id">
        <template v-if="academic.studyLevel>=3">
          <div class="profile-card--experience__container">
            <div class="profile-card--experience__title">{{academic.educationSpecialtyId == null ? academic.educationSpecialtyOther : educationSpecialty(academic.educationSpecialtyId, 2)}}</div>
            <div class="profile-card--experience__subtitle">{{academic.educationalInstitutionId == null ? academic.educationalInstitutionOther : getInstitution(academic.educationalInstitutionId)}}</div>
            <span>{{academic.fromYear}} - {{ academic.toYear == null ? $t('profile.present') : academic.toYear }}</span>
          </div>
        </template>
      </template>
      <div class="card-detail__separator profile__card--personal-edit__fullrow" v-if="hasSecondary">
        <div class="card-detail__separator__title">{{$t("profile.myInformation.academics.secondary")}}</div>
      </div>
      <template v-for="secondarySchool in study.academics" :key="secondarySchool.id">
        <template v-if="secondarySchool.studyLevel<=2">
          <div class="profile-card--experience__container">
            <div class="profile-card--experience__title">{{secondarySchool.educationSpecialtyId == null ? secondarySchool.educationSpecialtyOther : educationSpecialty(secondarySchool.educationSpecialtyId, 1)}}</div>
            <div class="profile-card--experience__subtitle">{{secondarySchool.educationalInstitutionId == null ? secondarySchool.educationalInstitutionOther : getInstitution(secondarySchool.educationalInstitutionId)}}</div>
            <span>{{secondarySchool.fromYear}} - {{ secondarySchool.toYear == null ? $t('profile.present') : secondarySchool.toYear }}</span>
          </div>
        </template>
      </template>
    </template>
    <template v-if="study.languages.length > 0 && completeLoad">
      <div class="card-detail__separator profile__card--personal-edit__fullrow">
        <div class="card-detail__separator__title">{{$t("profile.myInformation.academics.languages")}}</div>
      </div>
      <div class="profile-card--experience__container" v-for="language in study.languages" :key="language.id">
        <div class="profile-card--experience__title">{{getLanguageKnowledge(language.languageKnowledgeId)}}</div>
        <div class="profile-card--experience__subtitle">{{$t(`profile.myInformation.language.${language.level}`)}}</div>
      </div>
    </template>
    <template v-if="study.academics.length === 0 && study.languages.length === 0">
      {{$t("profile.myInformation.academics.label")}}
    </template>
  </div>

  <!-- Experiencia Laboral --> 
  <div class="profile__card profile-card--experience">
    <router-link to="/profile/workExperience">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <i class="material-icons">person</i>
          {{$t("profile.myInformation.jobs.title")}}
        </div>
        <i class="material-icons profile__card__header__arrow">navigate_next</i>
      </div>
    </router-link>
    <template v-if="jobs.length > 0 && completeLoad">
      <div class="profile-card--experience__container" v-for="job in jobs" :key="job.id">
        <div class="profile-card--experience__title">{{job.positionId == -1 ? job.positionOther :getPosition(job.positionId)}}</div>
        <div class="profile-card--experience__subtitle">{{job.organizationId == -1 ? job.organizationOther : getOrganization(job.organizationId)}}</div>
        <span>{{job.monthFrom}}/{{job.yearFrom}} - {{job.yearTo == null ? $t('profile.present') : `${job.monthTo}/${job.yearTo}`}}</span>
        <div class="profile-card--experience__content">{{getContractType(job.employmentContractTypeId)}}</div>
      </div>
    </template>
    <template v-else>
      {{$t("profile.myInformation.jobs.label")}}
    </template>
  </div>

</template>
<script>
import BannerPercentage from '@/components/Profile/BannerPercentage.vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { onMounted, ref, inject } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import storage from "@/plugins/azure-storage";
import useVuelidate from '@vuelidate/core';
import persistentData from "@/plugins/persistentData";
import { required, email, helpers, minLength } from '@vuelidate/validators';
// import Tooltip from '@/components/Profile/Tooltip.vue';

function validBirthDate(birthDate) {
  let diffDates = moment().diff(moment(birthDate), 'years');
  return diffDates <= 25;
}

function validIdentification (identification) {
  let validPattern = new RegExp(/^[a-zA-Z0-9.-]{1,20}$/); //eslint-disable-line
  if (validPattern.test(identification)) return true;
  return false;
}

function validPhoneNumber(number) {
  if (number == '') return true;
  const validPattern = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/); //eslint-disable-line
  if (validPattern.test(number)) return true;
  return false;
}

export default {
  components: {
    BannerPercentage
  },
  setup() {
    const v$ = useVuelidate();
    const { t } = useI18n();
    const store = useStore();
    const mobileVersion = inject('mobileVersion')
    const personalInformation = {...store.getters.profile_personalInformation};
    const study = {...store.getters.profile_study};
    const jobs = [...store.getters.profile_jobs];
    let completeLoad = ref(false);
    let personalEdit = ref(false);
    let personalInformationModel = ref(personalInformation);
    personalInformationModel.value.birthDate = formatDate(personalInformation.birthDate, 'YYYY-MM-DD');
    let listNationalities = ref([]);
    let listCountries = ref([]);
    let listGender = ref([]);
    let listCities = ref([]);
    let listStates = ref([]);
    let listContractType = ref([]);
    let listJobPositions = ref([]);
    let listOrganization = ref([]);
    let listLanguageKnowledges = ref([]);
    let listeducationSpecialtyType1 = ref([]);
    let listeducationSpecialtyType2 = ref([]);
    let listEducationalInstitution = ref([]);
    let hasSecondary = ref(false);
    let hasAcademic = ref(false);
    let submitted = ref(false);

    onMounted(async () => {
      [listGender.value, listCountries.value,
      listNationalities.value, listStates.value,
      listContractType.value, listJobPositions.value,
      listOrganization.value, listLanguageKnowledges.value,
      listeducationSpecialtyType1.value, listeducationSpecialtyType2.value,
      listEducationalInstitution.value] = await Promise.all([store.dispatch('stuff_gender'), store.dispatch('stuff_countries'),
      store.dispatch('stuff_nationalities'), store.dispatch('stuff_states'), store.dispatch('stuff_employmentContractTypes'),
      store.dispatch('stuff_jobPositions'), store.dispatch('stuff_organization'), store.dispatch('stuff_languageKnowledges'),
      store.dispatch('stuff_educationSpecialty', 1), store.dispatch('stuff_educationSpecialty', 2), store.dispatch('stuff_educationalInstitution')]);
      if (personalInformationModel.value.stateId) {
        listCities.value = await store.dispatch('stuff_cities', `${personalInformationModel.value.stateId}`);
      }
      onChangeState();
      jobs.forEach(job => {
        if(job.positionId === null) job.positionId = -1;
        if(job.organizationId === null) job.organizationId = -1;
      });
      study.academics.forEach(academic => {
        if (academic.studyLevel == 1 || academic.studyLevel == 2) {
          hasSecondary.value = true;
        } 
        if (academic.studyLevel == 3 || academic.studyLevel == 4) {
          hasAcademic.value = true;
        }
      })
      completeLoad.value = true;
    });

    function formatDate (date, format) { return moment(date).format(format) }
    function getGender (id) { return store.getters.get_gender(id)}
    function getCountry (id) { return store.getters.get_country(id)}
    function getCity (id) { return store.getters.get_city(id)}
    function getState (id) { return store.getters.get_states(id)}
    function getPosition (id) { return store.getters.get_jobPositions(id)}
    function getOrganization (id) { return store.getters.get_organization(id)}
    function getContractType (id) { return store.getters.get_employmentContractTypes(id)}
    function getLanguageKnowledge (id) { return store.getters.get_languageKnowledges(id)}
    function getInstitution (id) { return store.getters.get_educationalInstitution(id)}
    function getNationality (id) { return store.getters.get_nationalities(id)}

    function getImage(path, isFromCDN) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      } 
      return path ? `${process.env.VUE_APP_FORGE_MEDIA_URL}${path}` : null;
    }

    function educationSpecialty(specialityId, type) {
      const list = type === 1 ? listeducationSpecialtyType1 : listeducationSpecialtyType2
      const speciality = list.value.find(s => s.id == specialityId);
      return speciality ? speciality.name : '-';
    }

    async function updatePersonalInformation() {
      submitted.value = true;
      if (!v$.value.personalInformationModel.$invalid) {
        try {
          await Promise.all([store.dispatch('profile_updatePersonalInformation', personalInformationModel.value),
            store.dispatch('profile_updateContacts', personalInformationModel.value),
            store.dispatch('profile_updateLocation', personalInformationModel.value)]);
          await store.dispatch('profile_profile');
          // update i18n
          const country = listCountries.value.find(c => c.id === personalInformationModel.value.countryId);
          persistentData.set('language', country.countryCode);
          if (['AR', 'CL', 'MX', 'PE', 'UY'].includes(country.countryCode)) {
            window.i18n.global.locale.value = `es-${country.countryCode.toUpperCase()}`;
          } else {
            window.i18n.global.locale.value = 'es';
          }

          store.dispatch('notifications_create', { text: t(`notifications.updatePersonalInformation`) });
          submitted.value = false;
          personalEdit.value = false;
        } catch (error) {
          console.log(error);
        }
      }
    }

    async function onChangeState(){
      if (personalInformationModel.value.stateId) {
        listCities.value = await store.dispatch('stuff_cities', `${personalInformationModel.value.stateId}`);
      } else {
        listCities.value = [];
      }
    }

    async function onChangeCountry() {
      if (personalInformationModel.value.countryId) {
        listStates.value = await store.dispatch('stuff_countries_state', `${personalInformationModel.value.countryId}`);
      } else {
        listStates.value = [];
      }
      listCities.value = [];
    }

    async function uploadImageProfile(event) {
      const file = event.target.files[0]
      if(!file) {
        return false
      }
      const imageName = await storage.updateFile(file)
      personalInformationModel.value.picture = imageName;
      personalInformationModel.value.isPictureFromCDN = true;
      personalInformation.isPictureFromCDN = true;
      await store.dispatch('profile_updatePersonalInformation', personalInformationModel.value);
      await store.dispatch('profile_pictureFromCDN');
      store.dispatch('notifications_create', { text: t(`notifications.updatePersonalInformationPicture`) });
    }

    return {
      mobileVersion,
      personalInformation,
      study,
      formatDate,
      getImage,
      getGender,
      getCountry,
      getCity,
      getState,
      completeLoad,
      personalEdit,
      personalInformationModel,
      updatePersonalInformation,
      listGender,
      listNationalities,
      listCountries,
      listCities,
      listStates,
      jobs,
      getPosition,
      getOrganization,
      getContractType,
      getLanguageKnowledge,
      getInstitution,
      educationSpecialty,
      getNationality,
      hasSecondary,
      hasAcademic,
      uploadImageProfile,
      onChangeState,
      onChangeCountry,
      v$,
      submitted
    }
  },
  validations () {
    return {
      personalInformationModel: {
        nickName: { required },
        firstName: { required },
        lastName: { required },
        email: { required, email },
        birthDate: { 
          required,
          validBirthDate: helpers.withMessage('Tu edad debe ser menor a 25 años', validBirthDate)
        },
        genderId: { required },
        countryId: { required },
        identification: { validIdentification },
        otherPhone: { validPhoneNumber, minLength: minLength(7) },
        mobilePhone: { validPhoneNumber, minLength: minLength(7) }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .profile-card--personal-data {

    @media (min-width: $break-lg) {
      grid-area: second;
    }
  }

  .profile__card {

    &.profile-card--personal-data {
      background: $white url('~@/assets/world-map.svg') center 80px;
      background-repeat: no-repeat;
      background-size: contain;
    }

    button {
      margin-top: $margin-mega;
    }

    .card-detail__separator {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: row;
      grid-column: 1 / 2 span;
      margin: $margin-milli 0;

      &:before {
        content: '';
        border-top: 2px solid $graybg;
        position: absolute;
        top: 9px;
        width: 100%;
      }

      &__title {
        font-family: $font-palanquin;
        text-align: center;
        @include font-settings(milli,base);
        color: $graybg;
        text-transform: uppercase;
        background: $white;
        position: relative;
        width: fit-content;
        padding: 0 $margin-milli;
      } 
    }

    &--personal-edit {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas: "image nickname"
      "image fullname"
      "fullrow fullrow";
      gap: $margin-base;
      margin-top: $margin-base;

      &__nickname {
        grid-area: nickname;
      }

      &__fullname {
        grid-area: fullname;
        gap: $margin-base;
      }

      &__fullrow {
        grid-column: 1 / 2 span;
      }

      &__image {
        width: 120px;
        height: 120px;
        background: $white;
        border-radius: 50%;
        border: 3px solid $orange;
        flex-shrink: 0;
        background-size: cover;
        background-position: center center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        grid-area: image;
      }

      input {
        color: $litedarkgray;
        font-family: $font-palanquin;
        @include font-settings(kilo, base);
        border: none;
        background: none;
        font-weight: 700;
        width: 100%;
        border-bottom: 1px solid $gray;

        &::placeholder {
          color: $graybg;
        }

        &[type="file"] {
          width: 40px;
          height: 40px;
          background: $orange;
          border-radius: 50%;
          border: 3px solid $orange;
          flex-shrink: 0;
          background-size: cover;
          background-position: center center;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
          grid-area: image;
          cursor: pointer;
          position: relative;
          top: 85px;
          left: 85px;

          &:before {
            font-family: "Material Icons";
            content: "\e412";
            font-size: 24px;
            display: flex;
            color: $white;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 100%;
            width: 100%;
          }

          &::file-selector-button {
            opacity: 0;
          }
        }
      }

      select {
        color: $litedarkgray;
        font-family: $font-palanquin;
        @include font-settings(kilo, base);
        border: none;
        background: none;
        font-weight: 700;
        width: 100%;

        &::placeholder {
          color: $graybg;
        }
      }

      div {
        display: flex;
        flex-direction: column;

        span {
          color: $gray;
        }
        strong {
          color: $orange;
          font-family: $font-palanquin;
          @include font-settings(kilo, base);
          word-break: break-word;
        }
      }
    }

    .input-message{
      margin-top: 20px;
    }
  }
</style>