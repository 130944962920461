<template>
  <div class="profile">
    <div class="container" v-if="!loading">
      <BannerPercentage :view="'mobile'" />

      <div class="tabs">
        <button class="tabs__tab" @click="changeTab('resume')" :class="{'active': path == '/profile/resume'}">{{ $t("profile.tabs.resume") }}</button>
        <button class="tabs__tab" @click="changeTab('myBestVersion')" :class="{'active': path == '/profile/myBestVersion'}">{{ $t("profile.tabs.myBestVersion") }}</button>
        <button class="tabs__tab" @click="changeTab('myInformation')" :class="{'active': path == '/profile/myInformation'}">{{ $t("profile.tabs.myInformation") }}</button>
        <button class="tabs__tab" @click="changeTab('myCV')" :class="{'active': path == '/profile/myCV'}" v-if="levelProfile >= 3">{{ $t("profile.tabs.myCV") }}</button>
      </div>

      <div class="profile__grid" :class="{'full-width': path.toLowerCase() == '/profile/mycv'}">
        <RouterView />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import BannerPercentage from '@/components/Profile/BannerPercentage.vue';
import { useStore } from 'vuex';
import persistentData from '@/plugins/persistentData';

export default {
  components: {
    BannerPercentage
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const path = computed(() => route.path);
    const levelProfile = ref(0);
    const loading = ref(true);

    function changeTab(params) {
      router.push(params);
    }

    onMounted(async() => {
      levelProfile.value = persistentData.get("forgeLevel", true);
      const profile = await store.dispatch('profile_profile');
      store.commit('profile_updateProfile', profile);
      loading.value = false;
    });

    return {
      changeTab,
      path,
      loading,
      levelProfile
    }
  }
}
</script>

<style lang="scss">
  .profile {
    min-height: 100vh;
    background: url('~@/assets/profile-bg.svg'), linear-gradient(180deg, #E87B1B 0%, #FFC905 100%);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 1270px, auto;
    padding-bottom: 96px;
    padding-top: $margin-kilo;

    .container {
      padding: 0 $margin-base;

      @media (min-width: $break-sm) {
        padding: 0;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: 1fr !important;
      gap: $margin-kilo;
      align-items: flex-start;

      @media (min-width: $break-md) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: "first second"
        "first second"
        "first second";
      }

      @media (min-width: $break-lg) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "first second third"
          "first second third"
          "first second third";
      }

      &.full-width {
        grid-template-columns: 1fr;
        grid-template-areas: none;
      }

      &__column {
        display: flex;
        gap: $margin-kilo;
        flex-direction: column;
      }
    }
  }

  .tabs__tab.active {
    background: $orange;
  }

  .profile__card {
    width: 100%;
    background: $white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    padding: $margin-base;

    @media (min-width: $break-md) {
      padding: $margin-kilo;
    }

    &__list {
      margin: $margin-base 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-milli;

        i {
          cursor: pointer;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      color: $orange;
      border-bottom: 1px solid $orange;
      font-family: $font-palanquin;
      font-weight: 700;
      @include font-settings(base , mega);
      text-transform: uppercase;
      padding-bottom: $margin-milli;
      margin-bottom: $margin-milli;

      &__title {
        display: flex;
        align-items: center;
        gap: $margin-milli;

        .counter {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          background: $orange;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          color: white;
        }
      }

      &__arrow {
        cursor: pointer;
        padding: 4px;
      }

      &--space {
        justify-content: space-between;
      }
    }

    &__button {
      width: 100%;
      background: $orange;
      color: $white;
      border-radius: 20px;
      font-family: $font-palanquin;
      @include font-settings(kilo, xmega);
      margin-top: $margin-milli;
    }

    &__content {
      color: $gray;

      &.available {
        strong {
          color: $orange;
        }
      }
    }
  }

</style>