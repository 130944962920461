import interceptor from '@/plugins/interceptor';
const pathController = '/Learning'

export default {
  state: () => ({
  }),
  mutations: { 
  },
  actions: {
    async learning_learningRoutes(_, params) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/learningRoutes?filter=${params.filter}&pageNumber=${params.pageNumber}&rowsPerPage=${params.rowsPerPage}`); 
      if (request.status === 200) return request.data;
      return [];
    },
    async learning_learningRoutes_neewer(_, params) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/learningRoutes/neewer?limit=${params.limit}`); 
      if (request.status === 200) return request.data;
      return [];
    },
    async learning_learningRoutes_id(_, params) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/learningRoutes/${params.id}`); 
      if (request.status === 200) return request.data;
      return [];
    },
    async learning_learningItems(_, params) {
      console.log("params", params);
      let url = `${process.env.VUE_APP_API_SITE_URL}${pathController}/learningItems?pageNumber=${params.page}&rowsPerPage=${params.rowsPerPage}`;
      if(params.filter !='') url+= `&filter=${params.filter}`;
      if(params.categoryId !='') url+= `&categoryId=${params.categoryId}`;
      if(params.positionId !='') url+= `&positionId=${params.positionId}`;
      if(params.languageCode !='') url+= `&language=${params.languageCode}`;
      const ret = await interceptor.authenticate.get(url);
      return ret.data;
    },
    async learning_learningItems_neewer(_, params) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/learningItems/neewer?limit=${params.limit}`); 
      if (request.status === 200) return request.data;
      return [];
    },
    async learning_learningItems_id(_, params) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/learningItems/${params.type}/${params.id}`); 
      if (request.status === 200) return request.data;
      return [];
    },
    async learning_learningItems_setUserRelation(_, params) {
      const request = await interceptor.anonymous.post(`${process.env.VUE_APP_API_SITE_URL}${pathController}/learningItems/${params.id}/users`); 
      if (request.status === 200) return request.data;
      return [];
    },
    async learning_learningItems_users(_,model){
      const request = await interceptor.authenticate.put(`${process.env.VUE_APP_API_SITE_URL}${pathController}/learningItems/users`,model);
      if (request.status == 200) return request.data;
      return [];
    }
  }
}