<template>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <h1 v-if="openExternalRegister">{{ $t("register.title") }}</h1>
        <template v-if="completeLoad && !openExternalRegister">
        <h1 class="title-normal">¿Quieres descubrir cuál es tu perfil y <b>potencial laboral?</b></h1>
        <h2 class="title-normal">Loguéate y juega 100% gratis Trip</h2>
        </template>
        <template v-if="!showErrorScreen">
          <template v-if="!showRegisterSocial && completeLoad">
            <!--<SocialRegister text="complete"/>-->
              <input type="text" :placeholder="$t('register.step.first.input_firstName')" autocomplete="off" v-model="model.firstName" :class="{'input-invalid': v$.model.firstName.$invalid && submitted}">
              <span class="input-message" v-if="v$.model.firstName.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.firstName.required.$message}} </span>

              <input type="text" :placeholder="$t('register.step.first.input_lastName')" autocomplete="off" v-model="model.lastName" :class="{'input-invalid': v$.model.lastName.$invalid && submitted}">
              <span class="input-message" v-if="v$.model.lastName.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.lastName.required.$message}} </span>

              <input type="text" v-maska="'#########-#'" v-if="conalepExternalRegister" :placeholder="$t('register.step.first.input_conalepNumber')" autocomplete="off" v-model="model.loginOptionData.conalepMatricula">
              <span class="input-message" v-if="v$.model.loginOptionData.conalepMatricula.validConalepNumber.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.loginOptionData.conalepMatricula.validConalepNumber.$message}} </span>

              <input type="number" min="1" max="99" @keypress="isNumber($event, 2)" :placeholder="$t('register.step.first.input_age')" autocomplete="off" v-model="model.age">
              
              <input type="email" :placeholder="$t('register.step.first.input_email')" autocomplete="off" v-model="model.email" :class="{'input-invalid': v$.model.email.$invalid && submitted}">
              <span class="input-message" v-if="v$.model.email.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.email.required.$message}} </span>
              <span class="input-message" v-if="v$.model.email.email.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.email.email.$message}}</span>

              <select v-show="!conalepExternalRegister" v-model="model.residenceCountryId" :class="{'input-invalid': v$.model.residenceCountryId.$invalid && submitted}">
                <option disabled selected value="">{{ $t('register.step.second.input_nationality') }}</option>  
                <option v-for="n in listCountries" :key="n.id" :value="n.id">{{ n.name }}</option>
              </select>
              <span class="input-message" v-if="v$.model.residenceCountryId.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.residenceCountryId.required.$message}} </span>
              <template v-if="!institutionExternalRegisterOnDemand">
                <div class="password_container">
                  <input :type="passwordType" :placeholder="$t('register.step.third.input_password')" autocomplete="off" v-model="model.password" :class="{'input-invalid': v$.model.password.$invalid && submitted}">
                  <i v-if="passwordType == 'password'" class="material-icons" @click.stop="passwordType = 'text'">visibility</i>
                  <i v-else class="material-icons" @click.stop="passwordType = 'password'">visibility_off</i>
                </div>
                <span class="input-message" v-if="v$.model.password.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.password.required.$message}} </span>
                <span class="input-message" v-if="v$.model.password.validPassword.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.password.validPassword.$message}} </span>
  
                <input :type="passwordType" :placeholder="$t('register.step.third.input_repeatPassword')" autocomplete="off" v-model="model.passwordConfirm" :class="{'input-invalid': v$.model.passwordConfirm.$invalid && submitted}">
                <span class="input-message" v-if="v$.model.passwordConfirm.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.passwordConfirm.required.$message}} </span>
                <span class="input-message" v-if="v$.model.passwordConfirm.sameAsPassword.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.passwordConfirm.sameAsPassword.$message}} </span>
              </template>

              <div class="termsAndConditions">
                <input v-model="termsAndCondition" type="checkbox" id="terms" name="terms">
                <label for="terms">Acepto los <a href="/terms_trip.pdf" target="_blank">Términos y Condiciones</a></label>
              </div>

              <template v-if="!institutionExternalRegisterOnDemand">
                
                <small v-html="$t('register.step.third.description')"></small>
              </template>

              <VueRecaptcha siteKey="6LeU3cYcAAAAAGtWKU_QBA7-KAOHZSEjrAQa9D3V" size="normal" theme="light" :tabindex="0" @verify="recaptchaVerified" 
                @expire="recaptchaExpired" @fail="recaptchaFailed" ref="refVueRecaptcha">
              </VueRecaptcha>

              <template v-if="!institutionExternalRegisterOnDemand">
                <button @click="register()" class="button button--red button--register" :disabled="awaitRequest || !recaptchaValid || (route.path.toLowerCase().includes('/ar') && !termsAndCondition)">{{ awaitRequest ? $t("register.startDisabled") : $t("register.start") }}</button>
                <button class="button button--red-clean">
                  <router-link to="/login">
                    {{ $t("register.have-account") }}
                  </router-link>
                </button>
              </template>
              <template v-else>  
                <button @click="register(true)" class="button button--red button--register" :disabled="awaitRequest || !recaptchaValid || !termsAndCondition">{{ awaitRequest ? $t("register.startDisabled") : $t("register.start") }}</button>
              </template>
            
          </template>
          <template v-else-if="showRegisterSocial">
            <select v-model="model.residenceCountryId" :class="{'input-invalid': v$.model.residenceCountryId.$invalid && submitted}">
              <option disabled selected value="">{{ $t('register.step.second.input_nationality') }}</option>  
              <option v-for="n in listCountries" :key="n.id" :value="n.id">{{ n.name }}</option>
            </select>

            <button @click="register()" class="button button--red button--register" :disabled="awaitRequest">{{ awaitRequest ? $t("register.startDisabled") : $t("register.start") }}</button>
          
            <button class="button button--red-clean">
              <router-link to="/login">
                {{ $t("register.have-account") }}
              </router-link>
            </button>
          </template>
        </template>
        <template v-else>
          <p>{{ $t("register.invalidInstitutionLink") }}<a @click="goToLogin()"> aquí </a></p>
        </template>
        
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, sameAs, email , helpers, requiredIf} from '@vuelidate/validators';
import VueRecaptcha from 'vue3-recaptcha2';
import persistentData from '@/plugins/persistentData';
import { useI18n } from 'vue-i18n';
//import SocialRegister from '@/components/Common/SocialRegister.vue';
import moment from 'moment';

function validPassword(password) {
    return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{6,32})$/.test(password);
}

function isNumber(event, len) {
  const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  const keyPressed = event.key;
    
  if (!keysAllowed.includes(keyPressed) || event.target.value.length == len) {
    event.preventDefault();
  }
}

function validConalepNumber(conalepNumber) {
  const route = useRoute();
  if (route.params.loginOptionCode != null && route.params.loginOptionCode.toLowerCase() == 'conalep') {
    return /[0-9]{9}-[0-9]{1}$/.test(conalepNumber);
  }
  return true;
}

export default {
  components: {
    VueRecaptcha,
    //SocialRegister
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const submitted = ref(false);
    const completeLoad = ref(false);
    let awaitRequest = ref(false);
    const v$ = useVuelidate();
    const listCountries = ref([]);
    const recaptchaValid = ref(false);
    const refVueRecaptcha = ref(null);
    const termsAndCondition = ref()
    let showRegisterSocial = ref(false);
    const showErrorScreen = ref(false);    
    let defaultExternalRegister = route.name == 'TRIP_REGISTER';
    let institutionExternalRegister = route.name == 'TRIP_REGISTER_BY_INSTITUTION';
    let institutionExternalRegisterOnDemand = route.name == 'TRIP_REGISTER_BY_ONDEMAND';
    
    let conalepExternalRegister = route.params.loginOptionCode != null ? route.params.loginOptionCode.toLowerCase() == 'conalep' : false;
    let openExternalRegister = route.params.loginOptionCode != null ? route.params.loginOptionCode.toLowerCase().startsWith("open") : false;

    const userModelSocial = computed(() => store.getters.register_userModelSocial);
    const passwordType = ref('password');
    const model = ref({
      firstName: '',
      lastName: '',
      email: '',
      residenceCountryId: '',
      password: '',
      passwordConfirm: '',
      registrationReference: 'trip',
      loginOptionCode: '',
      birthDate: '',
      age: '',
      loginOptionData: {
        conalepMatricula: ''
      }
    });
    const { t } = useI18n();
    
    function generateUuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    onMounted(async () => {
      store.dispatch('manageUser_disabled');
      listCountries.value = await store.dispatch('stuff_countries');
      const loginOptionCode = institutionExternalRegisterOnDemand ? 'open_od' :route.params.loginOptionCode;
      const uuid = generateUuid();

      if(openExternalRegister)
      {
        try {
          const request = await store.dispatch('stuff_loginOptionCheckIfExists', {code: loginOptionCode});
          if (request.status == 200) {
            const country = listCountries.value.find(c => c.countryCode != null && c.countryCode.toLowerCase() == route.params.countryCode.toLowerCase())
            model.value.residenceCountryId = country ? Number(country.id) : '';
            model.value.email= `trip_${loginOptionCode}_${uuid}@fondationforge.org`;
            model.value.password= `trip_${loginOptionCode}_${uuid}`;
            model.value.passwordConfirm= `trip_${loginOptionCode}_${uuid}`;
            model.value.registrationReference= 'trip';
            model.value.loginOptionCode = loginOptionCode;
            model.value.age= 24;
            model.value.birthDate= calculateBirthDate(model.value.age);
            model.value.loginOptionData = { conalepMatricula: '' };

            register(true);
            return;
          }
        } catch (error) {
          if (error.response.data == 'Login option does not exist') {
            showErrorScreen.value = true;
          } else {
            store.dispatch('notifications_create', { text: t(`notifications.errorGeneric`) });
          }
        }
      }

      
      if (defaultExternalRegister) {
        const country = listCountries.value.find(c => c.countryCode != null && c.countryCode.toLowerCase() == route.params.countryCode.toLowerCase())
        model.value.residenceCountryId = country ? Number(country.id) : '';
        completeLoad.value = true;
      } else if (institutionExternalRegister || institutionExternalRegisterOnDemand) {
        
        try {
          const request = await store.dispatch('stuff_loginOptionCheckIfExists', {code: loginOptionCode});
          if (request.status == 200) {
            if(!institutionExternalRegisterOnDemand)
              model.value.residenceCountryId = request.data.country.id;
            model.value.loginOptionCode = loginOptionCode;
            completeLoad.value = true;
          }
        } catch (error) {
          if (error.response.data == 'Login option does not exist') {
            showErrorScreen.value = true;
          } else {
            store.dispatch('notifications_create', { text: t(`notifications.errorGeneric`) });
          }
        }
      }
      
    });

    watch(userModelSocial, (userModel) => {
      model.value.firstName = userModel.firstName;
      model.value.lastName = userModel.lastName;
      model.value.email = userModel.email;
      if (model.value.residenceCountryId == '') {
        showRegisterSocial.value = true;
      } else {
        model.value.password = 'Ruk14pp';
        model.value.passwordConfirm = 'Ruk14pp';
        recaptchaValid.value = true;
        setTimeout(async () => await register())
      }
    });

    function calculateBirthDate(age) {
      if (age != '') {
        return moment().subtract(age, 'years');
      }
      return null;
    }

    function goToLogin() {
      router.push('/login');
    }

    async function register(forceRegister = false) {
      submitted.value = true;
      awaitRequest.value = true;
      model.value.birthDate = calculateBirthDate(model.value.age);
      if (!v$.value.model.$invalid && recaptchaValid.value || forceRegister) {
        try {
          const request = await store.dispatch(showRegisterSocial.value ? 'security_userCreateSocialMediaExternal' : 'security_userCreateExternal', model.value);
          if (request.status == 200) {
            persistentData.set('token', request.data.token);
            persistentData.set('tokenExpiration', request.data.tokenExpiration);
            persistentData.set('registrationReference', request.data.registrationReference);
            persistentData.set('language', request.data.countryCode);
            store.dispatch('manageUser_active');
            submitted.value = false;
            awaitRequest.value = false;
            router.push('/gamesExternal/TRIP/trip');
          }
        } catch (error) {
          submitted.value = false;
          awaitRequest.value = false;
          if(error.response != null && error.response.data == 'userAlreadyExists')
          {
            store.dispatch('notifications_create', { text: t(`notifications.${error.response.data}`) });
          }
          else
          {
            store.dispatch('notifications_create', { text: t(`notifications.errorGeneric`) });
          }
        }
      } else {
        awaitRequest.value = false;
      }
    }

    function recaptchaVerified() {
      recaptchaValid.value = true;
    }
    function recaptchaExpired() {
      refVueRecaptcha.value.reset();
      recaptchaValid.value = false;
    }
    function recaptchaFailed() {
      recaptchaValid.value = false;
    }

    return {
      store,
      router,
      submitted,
      awaitRequest,
      v$,
      listCountries,
      model,
      register,
      refVueRecaptcha,
      recaptchaVerified,
      recaptchaExpired,
      recaptchaFailed,
      recaptchaValid,
      showRegisterSocial,
      showErrorScreen,
      goToLogin,
      isNumber,
      conalepExternalRegister,
      completeLoad,
      passwordType,
      openExternalRegister,
      route,
      termsAndCondition,
      institutionExternalRegisterOnDemand
    }
  },
  validations () {
    return {
      model: {
        firstName: {
          required: helpers.withMessage('Este campo es requerido', required)
        },
        lastName: { 
          required: helpers.withMessage('Este campo es requerido', required)
        },
        email: { 
          required: helpers.withMessage('Este campo es requerido', required),
          email: helpers.withMessage('El formato del mail es inválido', email)
         },
        residenceCountryId: { 
          required: helpers.withMessage('Este campo es requerido', required)
         },
        password: {
          required: helpers.withMessage('Este campo es requerido', required),
          validPassword: helpers.withMessage('Contraseña no válida', validPassword)
        },
        loginOptionData: {
           conalepMatricula: {
             requiredIfRef:  helpers.withMessage('Este campo es requerido', requiredIf(this.conalepExternalRegister)),
             validConalepNumber: helpers.withMessage('Matrícula no válida (Ej: 012345678-9)', validConalepNumber)
           }
        },
        passwordConfirm: {
          required: helpers.withMessage('Este campo es requerido', required),
          sameAsPassword: helpers.withMessage('Las contraseñas no coinciden', sameAs(this.model.password))
        },
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  img {
    position: absolute;
    bottom: -90px;
    left: -40px;
    transform: rotate(20deg);
    width: 184px;

    @media (min-height: 736px) {
      bottom: -50px;
    }
  }

  small {
    max-width: 300px;
    margin-top: 0;
    margin-bottom: $margin-kilo;
    color: $white;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  }

  p {
    white-space: pre-line;
    a {
      color: $red;
      gap: $margin-milli;
      cursor: pointer;
    }
  }
  .password_container{
    margin-bottom: 16px;
    position: relative;
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 2px solid #fff;
    .material-icons{
      position: absolute;
      top: 50%;
      right: 0;
      padding-right: 8px;
      transform: translateY(-50%);
      color: #fff;
      font-size: 28px;
      cursor: pointer;
    }
    input{
      margin: 0;
      flex-grow: 1;
      border: none;
    }
  }

  .termsAndConditions{
      display: flex;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      gap: 8px;
      a{
        text-decoration: revert;
      }
  }

  input[type="checkbox"] {
    appearance: none;
    width: 18px;
    height: 18px;
    background: none;
    border: 1px solid #fff;
    border-radius: 2px;
    position: relative;
    flex-shrink: 0;
    cursor: pointer;
    &:checked {
      background-color: inherit;
      &:after {
        content: "check";
        font-family: "Material icons";
        font-size: 18px;
        position: absolute;
        top: -8px;
        right: -1px;
        color: #fff;
      }
    }
  }

  input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus{
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff;
    font-size: 16px !important;
  }
  .title-normal{
    font-weight: 100;
    text-align: center;
    padding: 0px 20px;
    line-height: 32px;
  }

  h1.title-normal  {font-size: 28px;}
  h2.title-normal  {font-size: 24px;}
  h3.title-normal  {font-size: 20px;}
</style>