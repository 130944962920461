<template>
  <header :class="[classActive]" v-if="layoutActive.length > 0">
    <div class="container">
      <!-- HEADER -->
      <template v-for="(item, idx) in layoutActive" :key="idx">
        <template v-if="item.type === 'LOGO'">
          <router-link to="/">
            <template v-if="item.value === 'green'">
              <img src="@/assets/logo--green.svg" alt="logo" class="header__logo" />
            </template>
            <template v-else>
              <img src="@/assets/logo.svg" alt="logo" class="header__logo" />
            </template>
          </router-link>
        </template>

        <template v-if="item.type === 'BACK'">
          <img src="@/assets/arrow-back.svg" alt="back" class="header__back" @click="backRoute()" />
        </template>

        <template v-if="item.type === 'MENU'">
          <template v-if="item.value== 'green'">
            <img src="@/assets/menu--green.svg" alt="menu" class="header__menu" :class="{logged}" @click="toggleModal"/>
          </template>
          <template v-else>
            <img src="@/assets/menu.svg" alt="menu" class="header__menu" :class="{logged}" @click="toggleModal"/>
          </template>
        </template>

        <template v-if="item.type === 'TEXT'">
          <h5>{{item.value}}</h5>
        </template>

        <div v-if="!item.type"></div>
      </template>

      <!-- MENU -->
      <div v-if="openModal" class="header__menu__open" :class="{'header__menu__open--login': logged}">
        <template v-if="logged">
          <ul v-if="registrationReference != null && registrationReference.toLowerCase() !== 'trip' && registrationReference !== 'IIVVO'">
            <router-link to="/">
              <li @click="toggleModal">
                home
              </li>
            </router-link>
            <router-link to="/work/jobOffers">
              <li @click="toggleModal">
                trabajar
              </li>
            </router-link>
            <router-link to="/learning/routes">
              <li @click="toggleModal">
                aprender
              </li>
            </router-link>
            <li @click="goProfile">
              perfil
            </li>
            <li @click="toggleModalLogout">cerrar sesión</li>
          </ul>
          <ul v-else>
            <li @click="toggleModalLogout">cerrar sesión</li>
          </ul>
        </template>
        <template v-else>
          <!-- <span class="material-icons" data-v-3df1bd60="">close</span> -->
          <ul>
            <li @click="toggleModal">
              <router-link to="/login">
                Ingresar
              </router-link>
            </li>
          </ul>
          <!-- <ul>
            <li>ruky para jóvenes</li>
            <li>ruky para empresas</li>
            <li>¿quiénes somos?</li>
          </ul> -->
        </template>
      </div>
      <div class="header__menu__overlay" @click="toggleModal" v-if="openModal"></div>
    </div>
  </header>

  <!-- Modal Logout -->
  <template v-if="modalLogout">
    <VisibleModal />
    <div class="modal__overlay" @click.stop="toggleModalLogout()">
      <div class="modal modal-logout" @click.stop="''">
        <span class="material-icons" @click.stop="toggleModalLogout()">close</span>
        <h3>Cerrar sesión</h3>
        <p>¿Estás seguro de que querés salir?</p>
        <div class="modal__buttons">
          <button class="button--gray" @click.stop="toggleModalLogout()">AHORA NO</button>
          <button @click="logout">salir</button>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import { computed, watch } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref } from 'vue';
import VisibleModal from '@/components/Common/VisibleModal.vue';

export default {
  components: {
    VisibleModal
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const logged = computed(() => {
      if (route.name == 'HOME') makeLayout(route.name); 
      return store.getters.manageUser_logged
    });
    const registrationReference = computed(() => store.getters.manageUser_registrationReference);
    const openModal = ref(false);
    const modalLogout = ref(false);

    const classActive = ref('');
    const layoutActive = ref([]);

    setTimeout(() => { makeLayout(route.name); });
    watch(() => route.name, (routeName) => { makeLayout(routeName); });

    function makeLayout (routeName) {
      switch (routeName) {
        case 'HOME':
          if (logged.value) {
            layoutActive.value = [{type:'MENU', value: 'menu'}, {type: null}, {type: null}];
          } else {
            layoutActive.value = [{type:'LOGO', value: 'logo'}, {type: null}];
          }
          classActive.value = 'header--home';
          break;
        case 'PROFILE_RESUME':
        case 'PROFILE_MY_INFORMATION':
        case 'PROFILE_MY_BEST_VERSION':
          layoutActive.value = [{type: 'MENU', value: 'menu'}, {type: 'TEXT', value: 'perfil'}, {type: null}];
          classActive.value = '';
          break;
        case 'PROFILE_CERTIFICATES':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'mis certificaciones'}, {type: null}];
          classActive.value = '';
          break;
        case 'PROFILE_REFERENCES':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'mis referencias'}, {type: null}];
          classActive.value = '';
          break;
        case 'PROFILE_SKILLS':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'mis habilidades'}, {type: null}];
          classActive.value = '';
          break;
        case 'PROFILE_SKILLS_DETAILS':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'mis habilidades'}, {type: null}];
          classActive.value = '';
          break;
        case 'PROFILE_WORK_EXPERIENCE':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'experiencia laboral'}, {type: null}];
          classActive.value = '';
          break;
        case 'PROFILE_MY_EDUCATION':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'mi formación'}, {type: null}];
          classActive.value = '';
          break;
        case 'PROFILE_PROGRESS_LEARNING':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'mi progreso en aprender'}, {type: null}];
          classActive.value = '';
          break;
        case 'PROFILE_MY_CV':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'mi cv'}, {type: null}];
          classActive.value = '';
          break;
        case 'REGISTER':
        case 'REGISTER_FIRST':
        case 'REGISTER_SECOND':
        case 'REGISTER_THIRD':
        case 'REGISTER_TERMS':
        case 'REGISTER_ACTIVE':
        case 'ONBOARDING_SOCIAL':
        case 'LOGIN':
        case 'LOGIN_RECOVERY_PASSWORD':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: routeName === 'LOGIN' ? 'ingresar' : routeName === 'LOGIN_RECOVERY_PASSWORD' ? 'recuperar contraseña' : 'registro'}, {type: null}]
          classActive.value = 'header--login-register';
          break;
        case 'TRIP_REGISTER':
          layoutActive.value = [{type: null}, {type: 'TEXT', value: 'registro trip'}, {type: null}]
          classActive.value = 'header--login-register';
          break;
        case 'IIVVO_REGISTER':
          layoutActive.value = [{type: null}, {type: 'TEXT', value: 'registro IIVVO'}, {type: null}]
          classActive.value = 'header--login-register';
          break;
        case 'LEARN_ROUTE_DETAIL':
        case 'LEARN_COURSE_DETAIL':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'aprender'}, {type: null}]
          classActive.value = 'header--green';
          break;
        case 'LEARN_ROUTE':
        case 'LEARN_COURSES':
          if (logged.value) {
            layoutActive.value = [{type: 'MENU', value: 'green'}, {type: 'TEXT', value: 'aprender'}, {type: null}]
          } else {
            layoutActive.value = [{type: 'LOGO', value: 'green'}, {type: 'TEXT', value: 'aprender'}, {type: 'MENU', value: 'green'}]
          }
          classActive.value = 'header--green';
          break;
        case 'WORK_JOB_DETAIL':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'trabajar'}, {type: null}]
          classActive.value = 'header--blue';
          break;
        case 'WORK_JOBS_OFFERS':
        case 'WORK_MY_APPLICATIONS':
        case 'WORK_SAVED_OFFERS':
          if (logged.value) {
            layoutActive.value = [{type: 'MENU', value: 'green'}, {type: 'TEXT', value: 'trabajar'}, {type: null}]
          } else {
            layoutActive.value = [{type: 'LOGO', value: 'green'}, {type: 'TEXT', value: 'trabajar'}, {type: 'MENU', value: 'green'}]
          }
          classActive.value = 'header--blue';
          break;
        case 'GAMES_EXTERNAL':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'test'}, {type: null}];
          classActive.value = 'header--games';
          break;
        case 'GAMES_EXTERNAL_RUKY':
          layoutActive.value = [{type: 'MENU'}, {type: 'TEXT', value: 'test'}, {type: null}];
          classActive.value = 'header--games';
          break;
        case 'GAMES_EXTERNAL_GENOMA_RESULTS':
          layoutActive.value = [{type: 'BACK'}, {type: 'TEXT', value: 'resultado genoma'}, {type: null}];
          classActive.value = '';
          break;
      }
    }

    function backRoute () {
      if (window.history.length > 2 && route.name !== 'GAMES_EXTERNAL') {
        router.go(-1)
      } else {
        router.push('/')
      }
    }

    function toggleModal() {
      openModal.value = !openModal.value;
    }

    function toggleModalLogout() {
      if (openModal.value) toggleModal();
      modalLogout.value = !modalLogout.value;
    }

    function logout() {
      store.dispatch('manageUser_disabled');
      toggleModal();
      toggleModalLogout();
      if (registrationReference.value != null && registrationReference.value.toLowerCase() !== 'trip' && registrationReference.value.toLowerCase() !== 'iivvo') {
        router.push('/');
      } else {
        router.push('/login');
      }
    }
 
    async function goProfile() {
      const force = await store.dispatch('manageUser_forceLogin')
      openModal.value = false;
      if (force) return;

      router.push('/profile/resume');
    }

    return {
      backRoute,
      logged,
      openModal,
      toggleModal,
      logout,
      classActive,
      layoutActive,
      toggleModalLogout,
      modalLogout,
      goProfile,
      registrationReference,
      route
    };
  }
}
</script>
<style lang="scss" scoped>
header {
  background: linear-gradient(180deg, #f1b80f 0%, #e25523 76.04%);
  padding: $margin-base;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  &.header--login-register {
    background: none;
    position: fixed;
    width: 100%;
    box-shadow: none;
  }

  &.header--green {
    background: linear-gradient(360deg, #04a190 8.21%, #80d8d1 145.52%);
  }

  &.header--blue {
    background: linear-gradient(180deg, #087B9A 0%, #29A1C2 100%);
  }

  &.header--home {
    background: $orange;
    box-shadow: none;
  }

  @media (min-width: $break-sm) {
    padding: $margin-base 0;
  }

  &.header--games {
    position: relative;
    height: 75px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    position: relative;
    height: 100%;

    .header__logo {
      width: 96px;
    }

    .header__back {
      width: 48px;
    }

    .header__menu {
      width: 40px;
      justify-self: end;

      &.logged {
        justify-self: start;
      }
    }
  }

  .header__menu__open {
    background: linear-gradient(178.53deg, #e25523 3.48%, #882705 140.42%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: $margin-kilo;
    text-align: left;
    max-width: 200px;
    width: 100%;
    border: 3px solid #882705;
    margin-top: $margin-base;
    position: absolute;
    right: 0;
    top: $margin-mega;
    z-index: 9;

    &--login {
      text-align: left;
      width: 200px;
      left: 0;

      ul {
        li {
          &:nth-child(5) {
            margin-top: $margin-kilo;
            padding-top: $margin-base;
            border-top: 2px solid $white;
          }
        }
      }
    }

    .material-icons {
      position: absolute;
      right: $margin-milli;
      top: $margin-milli;
      font-weight: bold;
    }

    li {
      font-family: $font-palanquin;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
      color: $white;
      text-transform: uppercase;
      padding: math.div($margin-milli, 2) 0;
      cursor: pointer;
    }
  }

  .header__menu__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: none;
  }
}

.modal-logout h3, .modal-logout .modal__buttons button{
  color: $red;
}
</style>