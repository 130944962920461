<template>
  <div class="work">
    <div class="container">
      <!-- <div class="work__filters-mobile">
        <h4>Filters</h4>
      </div> -->
      <div class="tabs">
        <button class="tabs__tab" @click="changeTab('jobOffers')" :class="{'active': path == '/work/jobOffers'}">{{$t("work.tabs.jobOffers")}}</button>
        <button class="tabs__tab" @click="changeTab('myApplications')" :class="{'active': path == '/work/myApplications'}">{{$t("work.tabs.myApplications")}}</button>
        <button class="tabs__tab" @click="changeTab('saved')" :class="{'active': path == '/work/saved'}">{{$t("work.tabs.saved")}}</button>
      </div>
      
      <div v-if="levelProfile < 5" class="available-level-5" style="margin-bottom: 24px">
        <i class="material-icons">error</i>
        <span>Podrás postularte a cualquiera de los trabajos a partir del <b>NIVEL 5</b></span>
      </div>

    </div>
    <RouterView />
      <!-- <InputSearch @updateSearchValue="searchFilter" /> -->
     
      <!-- <div class="work__grid">
        <h4 class="work__grid__results-number">RESULTADOS DE TU BÚSQUEDA (28)</h4>
        <div class="work__grid__filters">
          <h4>Filters</h4>
        </div>
        <div class="work__grid__results">
          <article class="work__result-card">
            <div class="work__result-card__image" style="background-image: url('https://via.placeholder.com/64')"></div>
            <div class="work__result-card__content">
              <span>Publicado hace 2 días</span>
              <h3>Diseñador Gráfico</h3>
              <h4>Coderhouse</h4>
              <div class="work__result-card__content__details">
                <span>Ubicación</span>
                <span>Jornada</span> 
              </div>
            </div>
            <div class="work__result-card__star">
              <i class="material-icons">star_outline</i>
            </div>
          </article>
          <article class="work__result-card">
            <div class="work__result-card__image" style="background-image: url('https://via.placeholder.com/64')"></div>
            <div class="work__result-card__content">
              <h3>Diseñador Gráfico</h3>
              <h4>Coderhouse</h4>
              <div class="work__result-card__content__details">
                <span>Ubicación</span>
                <span>Jornada</span> 
              </div>
              <div class="work__result-card__content__stages">
                <div class="work__result-card__content__stages__stage active"></div>
                <div class="work__result-card__content__stages__stage active"></div>
                <div class="work__result-card__content__stages__stage"></div>
                <div class="work__result-card__content__stages__stage"></div>
                <h4>Etapa 2: <b>Perfil visto</b></h4>
              </div>
            </div>
            <div class="work__result-card__star">
              <i class="material-icons">star_outline</i>
            </div>
          </article>
          <article class="work__result-card disabled">
            <div class="work__result-card__image" style="background-image: url('https://via.placeholder.com/64')"></div>
            <div class="work__result-card__content">
              <h3>Diseñador Gráfico</h3>
              <h4>Coderhouse</h4>
              <div class="work__result-card__content__details">
                <span>Ubicación</span>
                <span>Jornada</span> 
              </div>
            </div>
          </article>
        </div>
      </div> -->
  </div>
</template>
<script>
import { computed } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import persistentData from '@/plugins/persistentData';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const path = computed(() =>route.path)
    const levelProfile = persistentData.get("forgeLevel", true);

    function changeTab(params) {
      router.push(params);
    }

    return {
      levelProfile,
      changeTab,
      path
    }
  }  
}
</script>
<style lang="scss">
  .work {
    background: url('~@/assets/work-bg.svg'), linear-gradient(180deg, #087B9A, #29A1C2);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 1270px, auto;
    min-height: 100vh;
    padding-bottom: 96px;

    .tabs {
      margin-left: $margin-base; 

      @media (min-width: $break-sm) {
        margin-left: 0;
      }
    }

    .tabs__tab.active {
      background: $blue;
    }


    &__filters-mobile {
      height: 100vh;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin: 0 auto;
      width: 100%;
      background: $blue;
      position: fixed;
      padding: $margin-base;
      z-index: 10;

      h4 {
        color: $white;
      }
    }

    .work__sub-tabs {
      display: flex;
      justify-content: center;
      gap: $margin-kilo;
      margin-bottom: $margin-base;

      button {
        font-family: $font-palanquin;
        @include font-settings(base,base);
        color: #FFFFFF;
        padding: $margin-milli $margin-kilo;
        
        &.active {
          background: #087B9A;
          border-radius: 16px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }


    .work__grid {
      padding: 0 $margin-base;

      @media (min-width: $break-sm) {
        padding: 0;
      }

      @media (min-width: $break-md) {
        display: grid;
        grid-template-columns: .7fr 2fr;
        grid-template-rows: auto;
        gap: $margin-base;
        grid-template-areas: "filters results"
        "filters cards";
      }
    }

    .work__grid__results-number {
      grid-area: results;
      color: $white;
      margin-bottom: $margin-base;

      @media (min-width: $break-md) {
        margin-bottom: 0;
      }
    }

    .work__grid__filters {
      background: $blue;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: $margin-base $margin-base $margin-kilo;
      color: $white;
      display: none;
      grid-area: filters;

      @media (min-width: $break-md) {
        display: block;
      }
    }

    .work__grid__results {
      display: grid;
      gap: $margin-base;
      grid-area: cards;

      @media (min-width: $break-lg) {
        grid-template-columns: 1fr 1fr;
      }
    } 

    .work__result-card {
      background: $blue;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: $margin-base $margin-base $margin-kilo;
      display: flex;
      flex-direction: row;
      gap: $margin-base;
      position: relative;
      cursor: pointer;

      &.disabled {
        
        h3,
        h4,
        span {
          color: $lightgray;
        }

        .work__result-card__image {
          opacity: .5;
        }
      }

      &__image {
        width: 64px;
        height: 64px;
        background: $white;
        border-radius: 50%;
        flex-shrink: 0;
        background-size: cover;
        background-position: center center;
      }

      &__content {
        flex-grow: 1;

        h3 {
          font-family: $font-opensans;
          margin: $margin-milli 0 0;
        }
        
        h4 {
          font-family: $font-opensans;
          color: $lightblue;
          font-weight: 700;
        }

        &__details {
          margin-top: $margin-base;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        &__stages {
          display: flex;
          flex-direction: row;
          gap: $margin-milli;
          margin-top: $margin-kilo;
          align-items: center;
          flex-wrap: wrap;

          &__stage {
            flex-shrink: 0;
            width: $margin-base;
            height: $margin-base;
            background: $white;
            transform: skew(2deg, 2deg);

            &.active {
              background: $lightblue;
            }

            &:nth-child(odd) {
              transform: skew(-2deg, -2deg);
            }
          }

          h4 {

            b {
              color: $white;
            }
          }
        }
      }

      &__star {

        i {
          color: $white;
        }
      }

      // &__modal {
      //   background: $white;
      //   border-radius: $margin-milli;
      //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      //   padding: $margin-base;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      // }
    }
  }
</style>