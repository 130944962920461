import interceptor from '@/plugins/interceptor';
const pathController = '/Bot'

export default {
  state: () => ({
    botActionActive: {}
  }),
  getters: {
    bot_botActionActive: state => state.botActionActive
  },
  mutations: {
    setActionActive(state, data) {
      state.botActionActive = data;
    }
  },
  actions: {
    async bot_analizeResponse({commit}, params) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/analizeResponse?view=${params.view}&action=${params.action}`); 
      if (request.status === 200) commit('setActionActive', {...request.data, action: params.action})
    },
    bot_close({commit}) {
      commit('setActionActive', {});
    }
  }
}