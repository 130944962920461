<template>
  <div ref="svgAnimated"/>
</template>
<script>
import lottie from "lottie-web";
import { onMounted, ref } from 'vue';

export default {
  props: {
    data: JSON
  },
  setup(props) {
    const svgAnimated = ref(null);
    
    onMounted(() => {
      const anim = lottie.loadAnimation({
        container: svgAnimated.value,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: props.data
      });
  
      setTimeout(() => {
        anim.play();
      })
    });

    return {
      svgAnimated
    }
  }
}
</script>