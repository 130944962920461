<template>
  <div>
  </div>
</template>
<script>
import { onMounted, onUnmounted } from '@vue/runtime-core'
export default {
  setup() {
    onMounted(() => {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    });
    onUnmounted(() => {
      document.getElementsByTagName('body')[0].style.overflow = 'visible';
    });
  }
}
</script>