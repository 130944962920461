import { createStore } from 'vuex';
import ManageUser from '@/store/common/ManageUser';
import Notifications from '@/store/common/Notifications';
import Home from '@/store/modules/Home';
import Register from '@/store/modules/Register';
import Profile from '@/store/modules/Profile';
// API
import APISecurity from '@/store/modules/API/Security';
import APIStuff from '@/store/modules/API/Stuff';
import APIInfo from '@/store/modules/API/Info';
import APILearning from '@/store/modules/API/Learning';
import APIBot from '@/store/modules/API/Bot';
import APIOrganization from '@/store/modules/API/Organization';
import APIGame from '@/store/modules/API/Game';
import APIProfile from '@/store/modules/API/Profile';

export default createStore({
  modules: {
    ManageUser,
    Notifications,
    Home,
    Register,
    Profile,
    APISecurity,
    APIStuff,
    APIInfo,
    APILearning,
    APIBot,
    APIOrganization,
    APIGame,
    APIProfile
  }
});