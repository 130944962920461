<template>
  <div class="container">

    <!-- Modal select filters -->
    <div class="modal__overlay" v-if="modalSelectFilters" @click.stop="modalSelectFilters=false">
      <div class="filters" @click.stop="''">
        <div class="filters__close">
          <span>FILTRAR RESULTADOS</span>
          <i class="material-icons" @click.stop="modalSelectFilters=false">close</i>
        </div>
        <div class="filters__category">
          <span>CATEGORÍA</span>
          <div>
            <select v-model="modelFilters.categoryId">
              <option disabled select value = "">Seleccionar categoría</option>
              <option v-for="c in learningCategories" :key="c.id" :value="c.id">{{ c.name }}</option>
            </select>
            <i class="material-icons">keyboard_arrow_down</i>
          </div>
        </div>
        <div class="filters__job-area">
          <span>ÁREA LABORAL</span>
          <div>
            <select v-model="modelFilters.positionId">
              <option disabled select value = "">Seleccionar área</option>
              <option v-for="jp in jobPositions" :key="jp.id" :value="jp.id">{{ jp.name }}</option>
            </select>
            <i class="material-icons">keyboard_arrow_down</i>
          </div>
        </div>
        <div class="filters__languages">
          <span>IDIOMA</span>
          <div>
            <select v-model="modelFilters.languageCode">
              <option disabled select value = "">Seleccionar idioma</option>
              <option v-for="l in languages" :key="l.code" :value="l.code">{{ l.name }}</option>
            </select>
            <i class="material-icons">keyboard_arrow_down</i>
          </div>
        </div>
        <div class="filters__buttons">
          <button class="filters__buttons-clear" @click.stop="clearFilters(); modalSelectFilters=false">
            <i class="material-icons">delete</i>
            <span>Limpiar filtros</span> 
          </button>
          <button class="filters__buttons-apply" @click.stop="applyFilters(); modalSelectFilters = false;">APLICAR</button>
        </div>
      </div>
    </div>

    <!-- Nav filters -->
    <div class="search-nav">
        <div class="search-nav__search-field">
          <input type="search" v-model="modelFilters.filter" placeholder="Buscar" @keydown.enter="applyFilters()">
          <label><i class="material-icons" @click.stop="applyFilters">search</i></label>
        </div>
        <div class="search-nav__filter">
          <button @click.stop="modalSelectFilters=true;">
            <i class="material-icons">filter_list</i> 
            <span>Filtrar</span>
          </button>
        </div>
    </div>

    <div class="filters-actives">
      <div v-if="modelFiltersActives.filter != ''">
        <span>{{ modelFiltersActives.filter }}</span>
          <i class="material-icons" @click.stop="modelFilters.filter=''; applyFilters();">close</i>
      </div>
      <div v-if="modelFiltersActives.categoryId != ''">
        <span>Categoría: {{ getCategoryNameById(modelFilters.categoryId) }}</span>
          <i class="material-icons" @click.stop="modelFilters.categoryId=''; applyFilters();">close</i>
      </div>
      <div v-if="modelFiltersActives.positionId != ''">
        <span>Puesto: {{ getJobPositionNameById(modelFilters.positionId) }}</span>
          <i class="material-icons" @click.stop="modelFilters.positionId=''; applyFilters();">close</i>
      </div>
      <div v-if="modelFiltersActives.languageCode != ''">
        <span>Idioma: {{ getLanguageNameByCode(modelFilters.languageCode) }}</span>
          <i class="material-icons" @click.stop="modelFilters.languageCode=''; applyFilters();">close</i>
      </div>
    </div>

    <div v-if="levelProfile < 5" class="available-level-5" style="margin: 16px 0 24px 0">
      <i class="material-icons">error</i>
      <span>Podrás acceder a cualquiera de los cursos a partir del <b>NIVEL 5</b></span> 
    </div>

    <div v-if="!filterActive">
      <h3>{{ $t("learning.courses.news") }}</h3>
      <div class="carousel">
        <CourseCard v-for="item in newsCourses" :key="item.id" :course="item" />
      </div>
    </div>

    <div>
      <h3>{{ $t("learning.courses.all") }}</h3>
      <div class="learning__courses__container">
        <CourseCardSmall v-for="item in allCourses" :key="item.id" :course="item" />
      </div>
      <div class="learning__courses__viewMore">
        <button class="button button--outline-white" @click.stop="loadMoreCourses" v-if="(pageNumber + 1) * 20 < qtyCourses">VER MAS</button>
      </div>
    </div>
  </div>
</template>

<script>
import CourseCard from '@/components/Course/Card.vue';
import CourseCardSmall from '@/components/Course/CardSmall.vue';
import { useStore } from 'vuex';
import { ref, onMounted, watch } from 'vue';
import persistentData from '@/plugins/persistentData';

export default {
  components: { CourseCard, CourseCardSmall },
  setup() {
    const store = useStore();
    const levelProfile = persistentData.get("forgeLevel", true);
    let newsCourses = ref([]);
    let allCourses = ref([]);
    let qtyCourses = ref(0);
    let pageNumber = ref(0);
    let learningCategories = ref([]);
    let jobPositions = ref([]);
    let languages = ref ([]);

    /* FILTROS */
    const modelFilters = ref({
      filter: '',
      categoryId: '',
      positionId: '',
      languageCode: '',
      page: pageNumber,
      rowsPerPage: 20
    });

    let modelFiltersActives = ref({
      filter: '',
      categoryId: '',
      positionId: '',
      languageCode: '',
      page: pageNumber,
      rowsPerPage: 20
    });

    let filterActive = ref('');
    const modalSelectFilters = ref(false);

    async function applyFilters(){    
      const response = await store.dispatch('learning_learningItems',modelFilters.value);       
      modelFiltersActives.value = JSON.parse(JSON.stringify(modelFilters.value));
      filterActive.value = JSON.parse(JSON.stringify(modelFilters.value.filter));
      allCourses.value = response.data;
      qtyCourses.value = response.totalResults;
      filterActive.value = true;
    }

    async function clearFilters(){
      modelFilters.value = {
        filter: '',
        categoryId: '',
        positionId: '',
        languageCode: '',
        page: pageNumber,
        rowsPerPage: 20
      };
      filterActive.value = false;
      modelFiltersActives.value = modelFilters.value;
      const response = await store.dispatch('learning_learningItems',modelFilters.value);
      allCourses.value = response.data;
      qtyCourses.value = response.totalResults;
      filterActive.value = false;
    }
    watch(modalSelectFilters, ()=>{
      if(modalSelectFilters.value){
        modelFilters.value = JSON.parse(JSON.stringify(modelFiltersActives.value));
      }
    })

    function getCategoryNameById (id){
      return learningCategories.value.find(x => x.id == id).name;
    }

    function getJobPositionNameById (id){
      return jobPositions.value.find(x => x.id == id).name;
    }

    function getLanguageNameByCode (code){
      return languages.value.find(x => x.code == code).name;
    }
/* FIN FILTROS */

    onMounted(async () => {
      newsCourses.value = await store.dispatch('learning_learningItems_neewer', {limit: 4});
      jobPositions.value = await store.dispatch('stuff_jobPositions');
      languages.value = await store.dispatch('stuff_languages');
      learningCategories.value = await store.dispatch('stuff_learningCategories');
      
      pageNumber.value = 0;
      allCourses.value = [];
      const requestLearningItems = await store.dispatch('learning_learningItems', modelFilters.value);
      allCourses.value = requestLearningItems.data;
      qtyCourses.value = requestLearningItems.totalResults;
    });

    /*async function searchFilter(filter = '') {
      if (filter != filterString) pageNumber.value = 0;
      const requestLearningItems = await store.dispatch('learning_learningItems', {filter, pageNumber: pageNumber.value, rowsPerPage: 20});
      filter !== '' ? filterActive.value = true : filterActive.value = false;
      if (filter != filterString) {
        allCourses.value = [...requestLearningItems.data];
      } else {
        allCourses.value.push(...requestLearningItems.data);
      }
      qtyCourses.value = requestLearningItems.totalResults;
      filterString = filter;
    }*/

    async function loadMoreCourses() {
      pageNumber.value += 1;
      const requestLearningItems = await store.dispatch('learning_learningItems', modelFilters.value);
      allCourses.value.push(...requestLearningItems.data);
      qtyCourses.value = requestLearningItems.totalResults;
      if (modelFilters.value.filter != '' || modelFilters.value.categoryId != '' || modelFilters.value.positionId != '' || modelFilters.value.languageCode != '') {
        filterActive.value = true;
      }
    }

    return {
      levelProfile,
      newsCourses,
      allCourses,
      filterActive,
      loadMoreCourses,
      pageNumber,
      qtyCourses,
      applyFilters,
      clearFilters,
      modelFilters,
      modelFiltersActives,
      modalSelectFilters,
      jobPositions,
      languages,
      learningCategories,
      getCategoryNameById,
      getJobPositionNameById,
      getLanguageNameByCode
    }
  }
}
</script>
<style lang="scss" scoped>
  .learning__courses__container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 16px;
    padding: 0 $margin-base;

    @media (min-width: $break-sm) {
      grid-template-columns: 1fr 1fr;
      padding: 0;
    }

    @media (min-width: $break-md) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: $break-lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

  }
  .learning__courses__viewMore {
    display: flex;
    justify-content: center;
    margin: $margin-kilo 0;
    button {
      height: 42px;
      padding: 0 $margin-mega;
      display: flex;
      align-items: center;
    }
  }

.filters{
  position: relative;
  width: 98%;
  max-width: 490px;
  height: 481px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #00866E;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: 500;
  &__close{
    width: 100%;
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span{
      font-family: Palanquin Dark;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.05em;
      color: #FFFFFF;
    }
    i{
      color: #fff;
      cursor: pointer;
    }
  }
  &__category, &__job-area, &__languages{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    span{
      padding-left: 12px;
      font-family: Palanquin Dark;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
    }
    div{
      position: relative;
      display: inline-flex;
      align-items: center;
      select{
        position: relative;
        appearance: none;
        width: 100%;
        height: 41px;
        padding: 0 16px;
        border: 1px solid #F2F2F2;
        border-radius: 30px;
        background-color: transparent;
        font-family: Palanquin Dark;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.05em;
        color: #F2F2F2;
        option{
          background-color: #fff;
          color:#00866E;
        }
      }
      i{
        position: absolute;
        right: 10px;
        color: #F2F2F2;
        z-index: -1;
      }
    }
  }
  &__buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-clear{
      display: inline-flex;
      align-items: center;
      gap: 8px;
      i{
        color: #E0E0E0;
      }
      span{
        font-family: Palanquin Dark;
        font-weight: 500;
        font-size: 18px;
        color: #E0E0E0;
        line-height: 16px;
      }
    }
    &-apply{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 24px;
      background-color: #FFFFFF;
      border-radius: 30px;
      font-family: Palanquin Dark;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: #00866E;
    }
  }
}

.search-nav{
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: center;
  gap: 8px;
  &__search-field{
    width: 100%;
    max-width: 345px;
    display: flex;
    label{
      height: 40px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      color: #fff;
      border-color: #fff;
      border-style: solid;
      border-width: 1px 1px 1px 0;
      border-radius: 0 30px 30px 0;
    }
    input{
      width: 100%;
      height: 40px;
      padding-left: 16px;
      border: none;
      border-style: solid;
      border-width: 1px 0 1px 1px;
      border-radius: 30px 0 0 30px;
      border-color: #fff;
      background-color: transparent;
      color: #fff;
      &::placeholder {
        font-family: Palanquin Dark;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;        
        color: #fff;
      }
    }
  }
  &__filter{
    button{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 8px 20px;
      background: #FFFFFF;
      border-radius: 30px;  
      span{
        font-family: Palanquin Dark;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: $darkgreen;
      }
      i{
        color: $darkgreen;
      }
    }
  }
  &__toggle-view {
    height: 40px;
    display: inline-flex;
    align-items: stretch;
    background: #FFF;
    border: 1px solid #087B9A;
    border-radius: 3px;
    div {
      width: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &:first-child {
        border-right: 1px solid #087B9A;
      }
      i {
        color: #9C9B9B;
        cursor: pointer;
      }
    }
  }
  .activeView {
    color: #087B9A;
  }
}

.filters-actives {
  padding: 0 8px;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  div {
    height: 32px;
    padding: 0 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: 1px solid #fff;
    border-radius: 210px;
    background-color: #fff;
    font-family: Palanquin Dark;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    span {
      font-family: Palanquin Dark;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #04A190;
    }

    i {
      font-size: 20px;
      cursor: pointer;
    }
  }
}
</style>