<template>
  <div class="profile__grid__column">
    <!-- Detail CVS -->
    <div class="profile__card" v-if="modeActive == 'list'">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <i class="material-icons">assignment</i>
          MIS CV
        </div>
      </div>
      <div class="profile__card__content">
        El currículum vitae o CV es tu herramienta de presentación. Tiene que
        ser claro, convincente y despertar el interés de quien lo lee.
        <span v-if="!mobileVersion" style="color: #606060; font-size: 16px;">Desde aquí podrás subir, crear y manejar tus CV's</span>

        <div class="profile__card__container__buttons">
          <button class="button" @click.stop="beginCreateCv()">
            Crear un nuevo CV <i class="material-icons">arrow_forward</i>
          </button>
          ó
          <button class="button button--outline button--without-border" @click.stop="openModalUploadFile()">
            Subir un CV propio
          </button>

          <div class="modal__overlay" v-if="modalUploadFile.active">
            <div class="modal-upload-file">
              <i class="material-icons close" @click.stop="modalUploadFile.active = false">close</i>
              <div class="modal-upload-file__title">SUBIR UN CV</div>
              <div class="modal-upload-file__info">Puedes subir hasta 5 CV’s. Chequea el <a href="https://view.genial.ly/64126c14e417ac0016bb558e/learning-experience-didactic-unit-2023-etm23" target="_blank">tutorial</a> para armar tu CV.</div>
              <div class="upload-file__container" @dragover="dragover" @drop="dropFile">
                <label for="uploadFileInput">
                  <i class="material-icons">cloud_upload</i>
                  <b>Subir un archivo</b>
                  <small>Formato PDF - Máximo 2 MB</small>
                </label>
                <input type="file" ref="uploadFileInput" id="uploadFileInput" accept="application/pdf" @change="uploadCvFile" />
              </div>
              <div class="files-title" v-if="modalUploadFile.uploading || modalUploadFile.model">Archivo subido</div>
              <div v-if="modalUploadFile.uploading" class="uploading">
                <div class="uploading__info">Estamos subiendo tu archivo</div>
                <div class="bar"></div>
              </div>
              <div v-if="!modalUploadFile.uploading && modalUploadFile.model" class="file-uploaded">
                <div>
                  <i class="material-icons">description</i>
                  {{modalUploadFile.model.name}}
                </div>
                <div>
                  <span></span>
                  {{modalUploadFile.model.fileSize}}
                </div>
              </div>

              <div class="reference">
                <h4>
                  ETIQUETA
                  <tooltip
                    class="icon-blue"
                    :icon="'info'"
                    :text="
                      `<div style='margin-bottom:8px;font-weight:700'>ETIQUETA</div>Agrega una etiqueta a tu CV para que sea más fácil localizarlos al aplicar a trabajos posteriormente.`
                    "
                  />
                </h4>
                <input
                  type="text"
                  placeholder="Ejemplo: CV ventas"
                  v-model="modalUploadFile.reference"
                />
              </div>

              <div class="next-back-btns">
                <button class="back" @click.stop="modalUploadFile.active = false">Cancelar</button>
                <button class="next" @click.stop="postCvFile()" :disabled="modalUploadFile.uploading || !modalUploadFile.model">Continuar<i class="material-icons">arrow_forward</i></button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- Con CV -->
      <div class="profile__card__content cv-files">
        <div class="cv-files__title">
          <b>Archivos subidos</b> 
          <span>Desde aquí podrás subir, crear y manejar tus CV's</span>
        </div>
        <template v-if="!mobileVersion">
          <div class="tbl-header">
            <table cellpadding="0" cellspacing="0" border="0">
              <thead>
                <tr>
                  <th>Archivo</th>
                  <th>Peso</th>
                  <th>Creación</th>
                  <th>Etiqueta</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="tbl-content">
            <table cellpadding="0" cellspacing="0" border="0">
              <tr v-for="cv in cvFiles" :key="cv.id">
                <td>
                  <div class="file-ruky" v-if="cv.createdOnRuky">CREADO EN RUKY</div>
                  <div class="file-name">
                    <i class="material-icons">description</i>
                    {{ cv.name }}
                    <!-- <i class="material-icons star" v-if="cv.isMainCV">star</i> -->
                    <Tooltip v-if="cv.isMainCV"
                      class="star-icon"
                      :icon="'star'"
                      :text="'<strong>CV PRINCIPAL</strong><br>Es el CV predetermidado para cuando postules desde Ruky. Podrás modificarlo en la misma postulación.'"
                    />
                  </div>
                </td>
                <td>{{ cv.fileSize }}</td>
                <td>{{ formatDate(cv.createdAt, "DD-MM-YYYY") }}</td>
                <td><div class="reference" v-if="cv.description">{{ cv.description }}</div></td>
                <td style="position: relative;">
                  <div :class="`modal-cvFile-options--${cv.id}`" class="more-options"
                    @click="!cv.modalOptions ? cv.modalOptions = true : cv.modalOptions = false">
                    <i class="material-icons" :class="`modal-cvFile-options--${cv.id}`">more_vert</i> Más opciones
                  </div>
                  <div v-if="cv.modalOptions" class="modal-cvFile-options" :class="`modal-cvFile-options--${cv.id}`">
                    <span @click.stop="setMainCv(cv.id)"><i class="material-icons star-icon">star</i>Principal</span>
                    <span @click.stop="downloadCv(cv); cv.modalOptions = false"><i class="material-icons">file_download</i>Descargar</span>
                    <span @click.stop=" cv.deleteCvModal = true; cv.modalOptions = false"><i class="material-icons" style="color: red">cancel</i>Eliminar</span>
                  </div>
                </td>

                <div class="modal__overlay" v-if=" cv.deleteCvModal ">
                  <div class="modal-upload-file">
                    <div class="modal-upload-file__title--exp">Vamos a eliminar el siguiente cv. <i @click=" cv.deleteCvModal = false " class="material-icons-outlined closeButton">cancel</i></div>
                    <div class="modal-upload-file__info--pdf">
                      <i class="material-icons-outlined">picture_as_pdf</i>
                      {{ cv.name }}
                    </div>
                    <div class="next-back-btns" style="flex-direction: column; gap: 26px">
                      <button :disabled=" deletingCv " @click="deleteCv(cv.id)" class="next">Si, quiero eliminarlo</button>
                      <button @click="cv.deleteCvModal = false" class="back">No, quiero conservarlo</button>
                    </div>
                  </div>
               </div>

              </tr>
            </table>
          </div>
        </template>

        <div v-else class="cv-files--mobile">
          <div class="file-card" v-for="cv in cvFiles" :key="cv.id">

            <div class="file-card__header">
              <div class="reference" v-if="cv.description">{{cv.description}}</div>
              <Tooltip v-if="cv.isMainCV"
                class="star-icon"
                :icon="'star'"
                :text="'<strong>CV PRINCIPAL</strong><br>Es el CV predetermidado para cuando postules desde Ruky. Podrás modificarlo en la misma postulación.'"
              />
            </div>

            <div class="file-card__content">
              <div class="file-name">
                <i class="material-icons">description</i>
                {{cv.name}}
              </div>
              <div style="position: relative;">
                <div :class="`modal-cvFile-options--${cv.id}`" class="more-options">
                  <i class="material-icons" :class="`modal-cvFile-options--${cv.id}`" @click="!cv.modalOptions ? cv.modalOptions = true : cv.modalOptions = false">more_vert</i>
                </div>
                <div v-if="cv.modalOptions" class="modal-cvFile-options" :class="`modal-cvFile-options--${cv.id}`">
                  <span @click.stop="setMainCv(cv.id)"><i class="material-icons star-icon">star</i>Principal</span>
                  <span @click.stop="downloadCv(cv); cv.modalOptions = false"><i class="material-icons">file_download</i>Descargar</span>
                  <span @click.stop="cv.deleteCvModal = true; cv.modalOptions = false"><i class="material-icons" style="color: red">cancel</i>Eliminar</span>
                </div>
              </div>
            </div>

            <div class="file-card__info">
              {{cv.fileSize}}
              <div class="file-ruky" v-if="cv.createdOnRuky">CREADO EN RUKY</div>
            </div>

            <div class="modal__overlay" v-if=" cv.deleteCvModal ">
              <div class="modal-upload-file">
                <div class="modal-upload-file__title--exp">Vamos a eliminar el siguiente cv. <i @click=" cv.deleteCvModal = false " class="material-icons-outlined closeButton">cancel</i></div>
                <div class="modal-upload-file__info--pdf">
                  <i class="material-icons-outlined">picture_as_pdf</i>
                  {{ cv.name }}
                </div>
                <div class="next-back-btns" style="flex-direction: column; gap: 26px">
                      <button :disabled=" deletingCv " @click=" deleteCv(cv.id) " class="next">Si, quiero eliminarlo</button>
                      <button @click=" cv.deleteCvModal = false " class="back">No, quiero conservarlo</button>
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>

    <!-- Form CV -->
    <div class="profile__card" v-if="modeActive == 'create'">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <i class="material-icons">assignment</i>
          MI CV
        </div>
      </div>

      <button
        class="button button--red-clean with-icon"
        @click="modeActive = 'list'; stepActive=0"
      >
        <i class="material-icons">arrow_back</i> Volver
      </button>
      <strong>Crear un CV nuevo</strong>

      <div class="profile__card__content" v-if="stepActive == 0">
        <div>
          <br />
          Vamos a crear un nuevo CV a partir de los datos que has completado en
          la plataforma. Recuerda que todos los datos que edites solo se verán
          en el CV pero no afectarán lo que has completado en Ruky.
        </div>

        <div class="ready">
          <strong>¿Todo listo?</strong>
          <button
            class="button button--red-clean with-icon"
            @click="changeStep('next')"
          >
            Empezar <i class="material-icons">arrow_forward</i>
          </button>
        </div>
      </div>

      <div class="content__form">
        <div class="profile__card__content" v-if="stepActive > 0">
          <div class="step-container">
            <div
              class="step-item"
              :class="{ completed: stepActive > 1, active: stepActive == 1 }"
            >
              <div class="step-counter">
              </div>
              <div class="step-name active">Datos Personales</div>
            </div>
            <div
              class="step-item"
              :class="{ completed: stepActive > 2, active: stepActive == 2 }"
            >
              <div class="step-counter">
              </div>
              <div class="step-name">Perfil</div>
            </div>
            <div
              class="step-item"
              :class="{ completed: stepActive > 3, active: stepActive == 3 }"
            >
              <div class="step-counter">
              </div>
              <div class="step-name">Estudios</div>
            </div>
            <div
              class="step-item"
              :class="{ completed: stepActive > 4, active: stepActive == 4 }"
            >
              <div class="step-counter">
              </div>
              <div class="step-name">Experiencia</div>
            </div>
            <div
              class="step-item"
              :class="{ completed: stepActive > 5, active: stepActive == 5 }"
            >
              <div class="step-counter">
              </div>
              <div class="step-name">Habilidades</div>
            </div>
            <div
              class="step-item"
              :class="{ completed: stepActive > 6, active: stepActive == 6 }"
            >
              <div class="step-counter">
              </div>
              <div class="step-name">Idiomas</div>
            </div>
            <div
              class="step-item"
              :class="{ completed: stepActive > 7, active: stepActive == 7 }"
            >
              <div class="step-counter">
              </div>
              <div class="step-name">Intereses</div>
            </div>
            <div
              class="step-item"
              :class="{ completed: stepActive > 8, active: stepActive == 8 }"
            >
              <div class="step-counter">
              </div>
              <div class="step-name">Diseño de cv</div>
            </div>
            <div
              class="step-item"
              :class="{ completed: stepActive > 9, active: stepActive == 9 }"
            >
              <div class="step-counter">
              </div>
              <div class="step-name">Confirmación</div>
            </div>
          </div>
        </div>

        <!-- Datos personales -->
        <div class="personal-data" v-if="stepActive == 1">
          <div class="section-title">
            Editar datos personales
            <Tooltip
              class="icon-blue"
              :icon="'info'"
              :text="'<strong>IMPORTANTE</strong><br>Los datos que edites se reflejarán únicamente en este CV. Si deseas que los cambios sean permanentes contacta a tu facilitador.'"
              :left="mobileVersion ? '-180px' : ''"
              :top="mobileVersion ? '30px' : ''"
            />
          </div>
          <div>
            <span>{{
              $t("profile.myInformation.personalInformation.firstName")
            }}</span>
            <input
              type="text"
              :placeholder="
                $t('profile.myInformation.personalInformation.firstName')
              "
              readonly
              :value="CVModel.fullName"
            />
          </div>
          <div>
            <span>{{
              $t("profile.myInformation.personalInformation.birthDate")
            }}</span>
            <input
              type="text"
              readonly
              :value="formatDate(CVModel.birthDate, 'DD-MM-YYYY')"
            />
          </div>
          <div>
            <span>{{
              $t("profile.myInformation.personalInformation.mobilePhone")
            }}</span>
            <input
              type="text"
              :placeholder="
                $t('profile.myInformation.personalInformation.mobilePhone')
              "
              :class="{ 'input-invalid': false }"
              v-model="CVModel.mobilePhone"
            />
          </div>
          <div>
            <span>{{
              $t("profile.myInformation.personalInformation.email")
            }}</span>
            <input
              type="email"
              :placeholder="$t('profile.myInformation.personalInformation.email')"
              :class="{ 'input-invalid': false }"
              v-model="CVModel.email"
            />
          </div>
          <div>
            <!-- <span>{{
              $t("profile.myInformation.personalInformation.state")
            }}</span> -->
            <span>
              {{ traducciones.state }}
            </span>
            <input
              type="text"
              :placeholder="
                // $t('profile.myInformation.personalInformation.state')
                traducciones.state
              "
              v-model="CVModel.city"
              />
            </div>
            <div v-if="persistentData.get('language') !== 'CO'">
              <!-- <span>{{
                $t("profile.myInformation.personalInformation.neighborhood")
              }}</span> -->
              <span>
                {{ traducciones.neighborhood }}
              </span>
              <input
                type="text"
                :placeholder="
                  // $t('profile.myInformation.personalInformation.neighborhood')
                  traducciones.neighborhood
                "
                v-model="CVModel.neighborhood"
              />
            </div>
        </div>

        <!-- Perfil -->
        <div class="profile-data" v-if="stepActive == 2">
          <div class="section-title">
            Editar tu perfil
            <!-- <Tooltip :text="'<strong>IMPORTANTE</strong><br>Los datos que edites se reflejarán únicamente en este CV. Si deseas que los cambios sean permanentes contacta a tu facilitador.'"/> -->
          </div>
          <div>
            <strong>Quién soy</strong>
            <span>Cúentale a las empresas quién eres.</span>
            <div class="textarea" >
              <small class="counter"
                >{{ CVModel.whoIAm.length }}/300 Caracteres</small
              >
              <textarea
                placeholder="Ingresar ..."
                :class="{ 'input-invalid': false }"
                v-model="CVModel.whoIAm"
                maxlength="300"
              ></textarea>
            </div>
          </div>
          <div>
            <strong>Qué busco en mi próximo empleo</strong>
            <span>Cúentale a las empresas tus metas.</span>
            <div class="textarea" >
              <small class="counter"
                >{{ CVModel.whatIExpectFromNextJob.length }}/300 Caracteres</small
              >
              <textarea
                placeholder="Ingresar ..."
                :class="{ 'input-invalid': false }"
                v-model="CVModel.whatIExpectFromNextJob"
                maxlength="300"
              ></textarea>
            </div>
          </div>
          <div>
            <strong>Qué me hace especial</strong>
            <span>Cúentale a las empresas por qué deben contratarte.</span>
            <div class="textarea" >
              <small class="counter"
                >{{ CVModel.whatMakesMeUnique.length }}/300 Caracteres</small
              >
              <textarea
                placeholder="Ingresar ..."
                :class="{ 'input-invalid': false }"
                v-model="CVModel.whatMakesMeUnique"
                maxlength="300"
              ></textarea>
            </div>
          </div>
        </div>

        <!-- Tus estudios -->
        <div class="study-data" v-if="stepActive == 3">
          <div class="section-title">
            Tus Estudios
            <!-- <Tooltip :text="'<strong>IMPORTANTE</strong><br>Los datos que edites se reflejarán únicamente en este CV. Si deseas que los cambios sean permanentes contacta a tu facilitador.'"/> -->
          </div>
          <span>Selecciona los estudios que quieres incluir.</span>

          <div class="alert" v-if="CVModel.listAcademics.length == 0">
            <i class="material-icons icon-blue">info</i><span><strong>No hay estudios para seleccionar</strong>.<br>Para añadir estudios, ingresa a tu perfil de Ruky, opción <strong>Mis datos / Mi formación</strong>. Luego, estarán disponibles aquí.</span>
          </div>

          <template v-else>
            <div class="alert">
              <i class="material-icons icon-blue">info</i> 
              <span>Para añadir un nuevo estudio, ingresa a tu perfil de Ruky,<br>opción <strong>Mis datos / Mi formación</strong>. Luego, estará dispobible aquí. </span> 
            </div>

            <div
              class="card"
              v-for="academic in CVModel.listAcademics"
              :key="academic.id"
            >
              <div>
                <input
                  type="checkbox"
                  v-model="academic.checked"
                  :name="academic.id"
                />
              </div>
              <div class="card__description">
                <div class="label" :for="academic.id">
                  {{
                    academic.studyLevel >= 3
                      ? $t("profile.myInformation.academics.academics")
                      : $t("profile.myInformation.academics.secondary")
                  }}
                </div>
                <strong>{{
                  academic.educationalInstitutionId == null
                    ? academic.educationalInstitutionOther
                    : getInstitution(academic.educationalInstitutionId)
                }}</strong>
                <span>{{
                  academic.educationSpecialtyId == null
                    ? academic.educationSpecialtyOther
                    : educationSpecialty(
                        academic.educationSpecialtyId,
                        academic.studyLevel == 3 ? 2 : 1
                      )
                }}</span>
                <span
                  >{{ academic.fromYear }} -
                  {{
                    academic.toYear == null
                      ? $t("profile.present")
                      : academic.toYear
                  }}</span
                >
              </div>
            </div>
          </template>

          <template v-if="CVModel.listExternalCourses.length > 0">
            <strong style="margin-top: 26px;" >CURSOS</strong>
            <div
              class="card"
              v-for="(course, index) in CVModel.listExternalCourses"
              :key="index"
            >
              <div>
                <input type="checkbox" v-model="course.checked" />
              </div>
              <div class="card__description">
                <strong>{{ course.name }}</strong>
                <span>{{ course.institution }}</span>
                <span
                  >{{ course.monthFrom }}/{{ course.yearFrom }} -
                  {{
                    course.yearTo == null
                      ? $t("profile.present")
                      : `${course.monthTo}/${course.yearTo}`
                  }}</span
                >
              </div>
            </div>
          </template>
        </div>

        <!-- Experiencia laboral -->
        <div class="work-data" v-if="stepActive == 4">
          <div class="section-title">
            Tu experiencia laboral
            <!-- <Tooltip :text="'<strong>IMPORTANTE</strong><br>Los datos que edites se reflejarán únicamente en este CV. Si deseas que los cambios sean permanentes contacta a tu facilitador.'"/> -->
          </div>

          <template v-if="CVModel.listJobs.length == 0">
            <div class="alert">
              <i class="material-icons icon-blue">info</i>No hay experencia laboral para seleccionar.<br>Para añadir experiencia laboral, ingresa a tu perfil de Ruky, opción Mis datos / Experiencia laboral. Luego, estará disponible aquí. Si aún no tienes experiencia laboral, activa la opción Busco mi primer empleo.
            </div>

            <div class="toggle">
              Busco mi primer empleo
              <input
                type="checkbox"
                id="switch"
                v-model="CVModel.searchFirstWork"
              /><label for="switch">Toggle</label>
            </div>

            <div v-if="CVModel.searchFirstWork">
              Incluiremos el siguiente texto en el apartado
              <textarea rows="5" v-model="CVModel.labelFirstWork"></textarea>
            </div>
          </template>
          <template v-else>
            <span>Selecciona las experencias que deseas incluir en tu CV.</span>
            <div class="card" v-for="job in CVModel.listJobs" :key="job.id">
              <div>
                <input type="checkbox" v-model="job.checked" />
              </div>
              <div class="card__description">
                <div class="label">
                  {{ getContractType(job.employmentContractTypeId) }}
                </div>
                <strong>{{
                  job.organizationId == null || job.organizationId == -1
                    ? job.organizationOther
                    : getOrganization(job.organizationId)
                }}</strong>
                <span
                  >{{ job.monthFrom }}/{{ job.yearFrom }} -
                  {{
                    job.yearTo == null
                      ? $t("profile.present")
                      : `${job.monthTo}/${job.yearTo}`
                  }}</span
                >
                <span>{{
                  job.positionId == null || job.positionId == -1
                    ? job.positionOther
                    : getPosition(job.positionId)
                }}</span>
              </div>
            </div>
          </template>
          <!-- Modal para cuando no tiene trabajos seleccionados -->
          <div class="modal__overlay" v-if="modalNotWork">
            <div class="modal-upload-file">
              <div class="modal-upload-file__title--exp">¿No deseas incluir ninguna experiencia laboral? <i @click="modalNotWork = false" class="material-icons-outlined closeButton">cancel</i></div>
              <div class="modal-upload-file__info">Utiliza este apartado para describir tu búsqueda de trabajo.</div>

              <span style="font-weight: bold;">CUENTALE A LAS EMPRESAS CUÁLES SON TUS METAS Y ASPIRACIONES.</span>
              <div class="textarea" style="margin-top: 24px; position: relative;">
                <small class="counter" style="float: right">{{ CVModel.textNotWork.length }}/300 Caracteres</small>
                <textarea
                  :class="{ 'input-invalid': false }"
                  v-model="CVModel.textNotWork"
                  maxlength="300"
                  rows="3"
                ></textarea>
              </div>
              
              <div class="next-back-btns" style="padding-bottom: 0px">
                <button class="next" @click.stop="CVModel.textNotWork.length > 0 ? changeStep('next', true) : null" :disabled="CVModel.textNotWork.length == 0">Guardar<i class="material-icons">edit</i></button>
              </div>
            </div>
          </div>

          <div v-if="CVModel.listVolunteerings.length > 0">
            <h4>¿QUIERES AGREGAR TU EXPERIENCIA DE VOLUNTARIADO?</h4>

            <div
              class="card"
              v-for="volunteering in CVModel.listVolunteerings"
              :key="volunteering.id"
            >
              <div>
                <input type="checkbox" v-model="volunteering.checked" />
              </div>
              <div class="card__description">
                <div class="label">{{ $t('profile.my_best_version.volunteerings.title') }}</div>
                <strong>{{ volunteering.institution }}</strong>
                <span
                  >{{ volunteering.monthFrom }}/{{ volunteering.yearFrom }} -
                  {{
                    volunteering.yearTo == null
                      ? $t("profile.present")
                      : `${volunteering.monthTo}/${volunteering.yearTo}`
                  }}</span
                >
                <span>{{ volunteering.taskDone }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Habilidades -->
        <div class="skills-data" v-if="stepActive == 5">
          <div class="section-title">
            Habilidades
            <!-- <Tooltip :text="'<strong>IMPORTANTE</strong><br>Los datos que edites se reflejarán únicamente en este CV. Si deseas que los cambios sean permanentes contacta a tu facilitador.'"/> -->
          </div>
          <span>Selecciona hasta <strong>6 habilidades</strong> que deseas incluir en tu CV.</span>
          <div class="tools">
            <ul class="list-pills" v-if="CVModel.listSkills.length > 0">
              <li
                v-for="(skill, idxSkill) in CVModel.listSkills"
                :key="skill.skillName"
              >
                {{ skill.skillName }}
                <i
                  class="material-icons"
                  @click="CVModel.listSkills.splice(idxSkill, 1)"
                  >close
                </i>
              </li>
            </ul>
            <div class="add-pill">
              <input
                type="text"
                placeholder="Nueva habilidad"
                v-model="skillModel"
                :disabled="CVModel.listSkills.length == 6"
              />
              <button
                @click="skillModel ? addSkill() : ''"
                :disabled="CVModel.listSkills.length == 6"
              >
                Agregar habilidad
              </button>
            </div>
          </div>
          <div class="tools">
            <h4>HERRAMIENTAS TECNOLOGICAS</h4>
            <ul
              class="list-pills"
              v-if="CVModel.listTechnologicalTools.length > 0"
            >
              <li
                v-for="(tool, idxTool) in CVModel.listTechnologicalTools"
                :key="tool"
              >
                {{ tool }}
                <i
                  class="material-icons"
                  @click="CVModel.listTechnologicalTools.splice(idxTool, 1)"
                  >close</i
                >
              </li>
            </ul>
            <div class="add-pill">
              <input
                type="text"
                placeholder="Nueva herramienta"
                v-model="technologicalToolModel"
                maxlength="50"
                />
                <button
                @click="technologicalToolModel ? addTechnologicalTool() : ''"
                >
                Agregar herramienta
              </button>
            </div>
            <small class="counter">{{ technologicalToolModel.length }}/50 Caracteres</small>
          </div>
        </div>

        <!-- Idiomas -->
        <div class="languages-data" v-if="stepActive == 6">
          <div class="section-title">
            Idiomas
            <!-- <Tooltip :text="'<strong>IMPORTANTE</strong><br>Los datos que edites se reflejarán únicamente en este CV. Si deseas que los cambios sean permanentes contacta a tu facilitador.'"/> -->
          </div>
          <div class="alert" v-if="CVModel.listLanguages.length == 0">
            <i class="material-icons icon-blue">info</i>
            <span>
              No hay idiomas para seleccionar.<br>Para añadir idiomas, ingresa a tu perfil de Ruky, opción <strong>Mis datos / Mi formación / Idiomas.</strong> Luego, estarán disponibles aquí.
            </span>
          </div>
          <template v-else>
            <span>Selecciona los idiomas que quieres incluir.</span>
            <div
              class="card"
              v-for="language in CVModel.listLanguages"
              :key="language.id"
            >
              <div>
                <input type="checkbox" v-model="language.checked" />
              </div>
              <div class="card__description">
                <strong>{{
                  getLanguageKnowledge(language.languageKnowledgeId)
                }}</strong>
                <span>{{
                  $t(`profile.myInformation.language.${language.level}`)
                }}</span>
              </div>
            </div>
          </template>
        </div>

        <!-- Intereses -->
        <div class="interests-data" v-if="stepActive == 7">
          <div class="section-title">
            Intereses
            <!-- <Tooltip :text="'<strong>IMPORTANTE</strong><br>Los datos que edites se reflejarán únicamente en este CV. Si deseas que los cambios sean permanentes contacta a tu facilitador.'"/> -->
          </div>
          <span>Selecciona hasta <strong>6 intereses</strong> que deseas incluir en tu CV.</span>
          <div>
            <ul class="list-pills" v-if="CVModel.listProfileInterest.length > 0">
              <li
                v-for="(interest, idxInterest) in CVModel.listProfileInterest"
                :key="interest.name"
              >
                {{ interest.name }}
                <i
                  class="material-icons"
                  @click="CVModel.listProfileInterest.splice(idxInterest, 1)"
                  >close</i
                >
              </li>
            </ul>
            <div class="add-pill">
              <input
                type="text"
                placeholder="Nuevo interés"
                v-model="interestModel"
                :disabled="CVModel.listProfileInterest.length == 6"
              />
              <button
                @click="interestModel ? addInterest() : ''"
                :disabled="CVModel.listProfileInterest.length == 6"
              >
                Agregar Interés
              </button>
            </div>
          </div>
        </div>

        <!-- Diseño de CV -->
        <div class="cv-data" v-if="stepActive == 8">
          <div>
            <div class="section-title">
              Diseño de CV
              <!-- <Tooltip :text="'<strong>IMPORTANTE</strong><br>Los datos que edites se reflejarán únicamente en este CV. Si deseas que los cambios sean permanentes contacta a tu facilitador.'"/> -->
            </div>
            <div>
              <h4>
                ¿QUIERES AGREGAR UNA FOTO A TU CV?<tooltip
                  class="icon-blue"
                  :icon="'info'"
                  :text="`<div style='margin-bottom:8px;font-weight:700'>¿ERES DE CHILE? LEE ESTO</div>En Chile no se utilizan los CVs con fotografía, por lo que te recomendamos que selecciones la opción sin foto.`"
                  :left="mobileVersion ? '-200px' : ''"
                  :top="mobileVersion ? '30px' : ''"
                />
              </h4>
              <span
                >Los cambios que realices solo afectarán al CV que estás
                creando.</span
              >

              <div class="container-radio">
                <div>
                  <input
                    type="radio"
                    v-model="CVModel.withPicture"
                    :value="true"
                    name="true"
                  />
                  <label for="true">Sí</label>
                </div>
                <div>
                  <input
                    type="radio"
                    v-model="CVModel.withPicture"
                    :value="false"
                    name="false"
                  />
                  <label for="false">No</label>
                </div>
              </div>
            </div>

            <div class="card-image" v-if="CVModel.withPicture">
              <!-- <div class="img" :style="`background-image: url(${getImage(CVModel.picture, CVModel.isPictureFromCDN) || require('@/assets/placeholder.png')})`"></div> -->
              <div
                v-if="CVModel.picture"
                class="img"
                :style="
                  `background-image: url(${getImage(
                    CVModel.picture,
                    CVModel.isPictureFromCDN
                  )})`
                "
              ></div>
              <div v-else class="img">
                <i class="material-icons">account_circle</i>
              </div>
              <div class="btn__profile-picture">
                <div @change="uploadProfilePicture($event)" class="upload-file__container">
                  <label for="upload-picture"
                    >{{
                      CVModel.picture
                        ? "Editar foto"
                        : "Subir foto"
                    }}<i class="material-icons">photo_camera</i></label
                  >
                  <input type="file" id="upload-picture" />
                </div>
                <small><strong>Ver recomendaciones</strong> para tu foto</small>
              </div>
            </div>

            <!-- En caso de querer agregar una foto -->
            <template v-if="editorPicture">
              <VisibleModal />
              <div class="modal__overlay">
                <div class="container-editor">
                  <div class="container-editor__title">
                    SUBIR UNA FOTO PARA TU CV
                  </div>
                  <div class="container-editor__subtitle">
                    Selecciona la foto para tu CV.
                  </div>
                  <VuePictureCropper
                    v-if="pictureUploaded.loaded"
                    :boxStyle="{
                      width: '100%',
                      maxHeight: '400px',
                      aspectRatio: 1 / 1,
                      backgroundColor: '#f8f8f8',
                      margin: 'auto',
                    }"
                    :img="
                      getImage(
                        pictureUploaded.picture,
                        pictureUploaded.isPictureFromCDN
                      )
                    "
                    :options="{
                      viewMode: 1,
                      dragMode: 'crop',
                      aspectRatio: 1 / 1,
                    }"
                  />
                  <div v-else class="img-loader">
                    <div class="custom-loader"></div>
                  </div>
                  <div class="next-back-btns">
                    <button class="back" @click="editorPicture = false">
                      Cancelar
                    </button>
                    <button
                      class="next"
                      @click="
                        getResult();
                        editorPicture = false;
                      "
                      :disabled="!pictureUploaded.loaded"
                    >
                      Guardar<i class="material-icons">arrow_forward</i>
                    </button>
                  </div>
                </div>
              </div>
            </template>

            <div class="reference">
              <h4>
                ETIQUETA
                <tooltip
                  class="icon-blue"
                  :icon="'info'"
                  :text="`<div style='margin-bottom:8px;font-weight:700'>ETIQUETA</div>Agrega una etiqueta a tu CV para que sea más fácil localizarlos al aplicar a trabajos posteriormente.`"
                  :left="mobileVersion ? '-82px' : ''"
                  :top="mobileVersion ? '30px' : ''"
                />
              </h4>
              <input
                type="text"
                placeholder="Ejemplo: CV ventas"
                v-model="CVModel.ref"
              />
            </div>
          </div>
        </div>

        <!-- Diseño de CV -->
        <div class="preview-data" v-if="stepActive == 9">
          <h4>Tu CV está casi listo</h4>
          <span>Chequea los datos incluidos en tu CV y confirma.</span>

          <div class="alert">
            <i class="material-icons icon-blue">info</i>
            <div>
              <strong>Revisa tu CV </strong>
              <span>Recuerda que es recomendable que el CV tenga 1 hoja.</span>
            </div>
          </div>
          <PDFPreview :CVModel="CVModel" @back="changeStep('back')" />
        </div>

        <div class="next-back-btns" v-if="stepActive > 0 && stepActive < 9">
          <button class="back" @click="changeStep('back')" v-if="stepActive > 1">
            <i class="material-icons">arrow_back</i> Atrás
          </button>
          <button class="next" @click="changeStep('next')">
            Continuar <i class="material-icons">arrow_forward</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, onBeforeUnmount, ref, inject } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import PDFPreview from "@/components/PDF/Main";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import Tooltip from "@/components/Profile/Tooltip.vue";
import storage from "@/plugins/azure-storage";
import VisibleModal from "@/components/Common/VisibleModal.vue";
import { useI18n } from "vue-i18n";
import persistentData from "@/plugins/persistentData";

export default {
  components: {
    PDFPreview,
    VuePictureCropper,
    Tooltip,
    VisibleModal,
  },
  setup() {
    const store = useStore();
    const mobileVersion = inject('mobileVersion')
    const orderDesc = (arr,propName) => arr.sort( (a,b) => b[propName] - a[propName] );
    const modeActive = ref("list");
    const stepActive = ref(0);
    const deletingCv = ref(false);
    const uploadFileInput = ref(null);
    const personalInformation = {
      ...store.getters.profile_personalInformation,
    };
    const traducciones = ref({
      state: undefined,
      neighborhood: undefined
    })
    const aboutMe = { ...store.getters.profile_aboutMe };
    const study = { ...store.getters.profile_study };
    const jobs = [...store.getters.profile_jobs];
    const cvFiles = ref([]);
    const modalUploadFile = ref({
      active: false,
      uploading: false,
      model: null,
      reference: ''
    })
    const CVModel = ref({
      fullName: `${personalInformation.firstName} ${personalInformation.lastName}`,
      birthDate: personalInformation.birthDate,
      mobilePhone: personalInformation.mobilePhone,
      email: personalInformation.email,
      neighborhood: personalInformation.neighborhood,
      city: "",
      whoIAm: aboutMe.whoIAm ?? '',
      whatIExpectFromNextJob: aboutMe.whatIExpectFromNextJob ?? '',
      whatMakesMeUnique: aboutMe.whatMakesMeUnique ?? '',
      listAcademics: orderDesc([...study.academics.map((item) => { return { ...item, checked: true };}),], 'fromYear'),
      listExternalCourses: orderDesc([...aboutMe.externalCourses.map((item) => { return { ...item, checked: true };}),], 'yearFrom'),
      searchFirstWork: false,
      labelFirstWork: "Actualmente me encuentro en la búsqueda de mi primera experiencia laboral.",
      listJobs: orderDesc([...jobs.map((item) => { return { ...item, checked: true }}),], 'yearFrom'),
      //listJobs: [],
      textNotWork: "Actuamente, busco adquirir experiencia laboral en el área de...",
      listSkills: [...aboutMe.skills].splice(0, 6),
      listTechnologicalTools: [...aboutMe.technologicalTools],
      listLanguages: [...study.languages.map((item) => { return { ...item, checked: true };}),],
      listProfileInterest: [...aboutMe.profileInterest].splice(0, 6),
      listVolunteerings: orderDesc([...aboutMe.volunteerings.map((item) => { return { ...item, checked: true };}),], 'yearFrom'),
      withPicture: personalInformation.picture != "",
      picture: personalInformation.picture,
      isPictureFromCDN: personalInformation.isPictureFromCDN,
      ref: "",
    });

    let listeducationSpecialtyType1 = ref([]);
    let listeducationSpecialtyType2 = ref([]);
    let listEducationalInstitution = ref([]);
    const skillModel = ref("");
    const technologicalToolModel = ref("");
    const interestModel = ref("");
    const editorPicture = ref(false);
    const modalNotWork = ref(false);
    const { t } = useI18n();

    async function getCvFiles() {
      try {
        const request = await store.dispatch("profile_getCVs");
        cvFiles.value = request.data;
      } catch {
        store.dispatch("notifications_create", {
          text: "Error al intentar obtener CVs",
        });
      }
    }
    async function setMainCv(id) {
      try {
        const request = await store.dispatch("profile_favCVs", { id });
        cvFiles.value = request.data;
      } catch (e) {
        store.dispatch("notifications_create", {
          text: "Error al intentar marcar CV como principal",
        });
      }
    }
    async function downloadCv(cv) {
      window.open(`${process.env.VUE_APP_CDN_URL_USER}${cv.fileUrl}`, "_blank");
    }
    async function deleteCv(id) {
      try {
        deletingCv.value = true
        await store.dispatch("profile_deleteCVs", { id });
        cvFiles.value = cvFiles.value.filter((cv) => cv.id != id);
      } catch (e) {
        if (e.request.status === 400) {
          store.dispatch("notifications_create", {
            text: t(`notifications.${e.response.data}`),
          });
          cvFiles.value.forEach(cv => {
            if (cv.id === id) cv.deleteCvModal = false
          })
        } else {
          store.dispatch("notifications_create", {
            text: "Error al intentar eliminar CV",
          });
        }
      } finally {
        deletingCv.value = false
      }
    }

    const closeModalCvOptions = (e)=> {
      cvFiles.value.forEach(cv => {
        if(!e.target.matches(`.modal-cvFile-options--${cv.id}`)){
          cv.modalOptions = false;
        }
      })
    }

    onMounted(async () => {
      getCvFiles();
      // Traduccion provisoria
      switch (persistentData.get('language')) {
        case 'AR':
          traducciones.value = { state: 'Provincia', neighborhood: 'Partido / Ciudad' }
          break
        case 'UY':
          traducciones.value = { state: 'Departamento', neighborhood: 'Barrio' }
          break
        case 'CL':
          traducciones.value = { state: 'Región', neighborhood: 'Comuna' }
          break
        case 'CO':
          traducciones.value = { state: 'Departamento' }
          break
        case 'PE':
          traducciones.value = { state: 'Departamento', neighborhood: 'Ciudad' }
          break
        case 'EC':
          traducciones.value = { state: 'Departamento', neighborhood: 'Localidad' }
          break
      }
      listeducationSpecialtyType1.value = await store.dispatch("stuff_educationSpecialty",1);
      listeducationSpecialtyType2.value = await store.dispatch("stuff_educationSpecialty",2);
      listEducationalInstitution.value = await store.dispatch("stuff_educationalInstitution");
      await store.dispatch("stuff_employmentContractTypes");
      await store.dispatch("stuff_organization");
      await store.dispatch("stuff_jobPositions");
      await store.dispatch("stuff_languageKnowledges");
      await store.dispatch("stuff_cities", `${personalInformation.stateId}`);
      CVModel.value.city = personalInformation.stateId ? getCity(personalInformation.cityId) : "";
      document.addEventListener('click', closeModalCvOptions);

    });
    onBeforeUnmount(()=>{
      document.removeEventListener('click', closeModalCvOptions);
    })

    function changeStep(direction, force = false) {
      // Validación para cuando no tiene trabajos seleccionados
      if (direction == 'next' && stepActive.value == 4 && !CVModel.value.listJobs.some(work => work.checked) && !force && !CVModel.value.searchFirstWork) {
        modalNotWork.value = true;
      } else {
        modalNotWork.value = false;
        stepActive.value =
          direction == "next" ? stepActive.value + 1 : stepActive.value - 1;
        window.scrollTo({ top: 120, behavior: 'smooth' });
      }
    }

    function getContractType(id) {
      return store.getters.get_employmentContractTypes(id);
    }
    function formatDate(date, format) {
      return moment(date).format(format);
    }
    function getOrganization(id) {
      return store.getters.get_organization(id);
    }
    function getPosition(id) {
      return store.getters.get_jobPositions(id);
    }
    function getLanguageKnowledge(id) {
      return store.getters.get_languageKnowledges(id);
    }
    function getCity(id) {
      return store.getters.get_city(id);
    }

    function educationSpecialty(specialityId, type) {
      const list =
        type === 1 ? listeducationSpecialtyType1 : listeducationSpecialtyType2;
      const speciality = list.value.find((s) => s.id == specialityId);
      return speciality ? speciality.name : "-";
    }
    function getInstitution(institutionId) {
      const institution = listEducationalInstitution.value.find((i) => i.id == institutionId);
      return institution ? institution.name : "-";
    }

    function addSkill() {
      CVModel.value.listSkills.push({ skillName: skillModel.value });
      skillModel.value = "";
    }

    function addTechnologicalTool() {
      CVModel.value.listTechnologicalTools.push(technologicalToolModel.value);
      technologicalToolModel.value = "";
    }

    function addInterest() {
      CVModel.value.listProfileInterest.push({ name: interestModel.value });
      interestModel.value = "";
    }

    function getImage(path, isFromCDN) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      }
      return path ? `${process.env.VUE_APP_FORGE_MEDIA_URL}${path}` : null;
    }

    const pictureUploaded = ref({
      picture: '',
      isPictureFromCDN: true,
      loaded: false,
    });

    async function uploadProfilePicture(event) {
      let file = event.target.files[0];
      if (!file) return false;
      pictureUploaded.value.loaded = false;
      try {
        editorPicture.value = true;
        let fileCDN = await storage.updateFile(file);
        pictureUploaded.value.picture = fileCDN;
        pictureUploaded.value.loaded = true;
      } catch (error) {
        editorPicture.value = false;
        console.log(error);
        store.dispatch("notifications_create", {
          text: "Error al intentar subir archivo",
        });
      } finally {
        event.target.value = null;
      }
    }

    async function beginCreateCv(){
      try {
        let req = await store.dispatch('profile_canCreateCVs');
        if(req.data.canCreateCv){
          modeActive.value = 'create'
        }
        else { 
          store.dispatch("notifications_create", { text: "Tu lista de CVs ha alcanzado el máximo de 5 archivos." });
          return;
        }
      } catch (error) {
        store.dispatch("notifications_create", { text: "Error al comprobar cantidad de CVs activos" });
      }
    }

    async function openModalUploadFile(){
      try {
        let req = await store.dispatch('profile_canCreateCVs');
        if(req.data.canCreateCv){
          modalUploadFile.value.active = true; 
          modalUploadFile.value.model = null;
          modalUploadFile.value.reference = '';
        }
        else { 
          store.dispatch("notifications_create", { text: "Tu lista de CVs ha alcanzado el máximo de 5 archivos." });
          return;
        }
      } catch (error) {
        store.dispatch("notifications_create", { text: "Error al comprobar cantidad de CVs activos" });
      }
    }

    function dragover (event) {
      event.preventDefault();
    }

    function dropFile(event) {
      event.preventDefault();
      uploadFileInput.value.files = event.dataTransfer.files;
      this.uploadCvFile();
    }

    async function uploadCvFile() {
      let file = uploadFileInput.value.files[0];
      if (!file) return false;
      if (file.type !== 'application/pdf') {
        store.dispatch("notifications_create", { text: "El archivo seleccionado debe ser formato PDF." });
        return
      }
      modalUploadFile.value.uploading = true;
      try {
        let fileCDN = await storage.updateFile(file);
        modalUploadFile.value.model = {
        fileUrl: fileCDN,
        fileSize: file.size + 'KB',
        name: file.name,
        //description: 'CV propio',
        createdOnRuky: false
      };
      } catch (error) {
        console.log(error);
        store.dispatch("notifications_create", { text: "Error al intentar subir archivo" });
      } finally {
        uploadFileInput.value = null;
        modalUploadFile.value.uploading = false;
      }
    }

    async function postCvFile(){
      try {
        modalUploadFile.value.reference 
          ? modalUploadFile.value.model.description = modalUploadFile.value.reference
          : modalUploadFile.value.model.description = 'CV propio'
        await store.dispatch('profile_postCVs', modalUploadFile.value.model);
        modalUploadFile.value.active = false;
        modalUploadFile.value.model = null;
        getCvFiles();
      } catch (error) {
        console.log(error);
        store.dispatch("notifications_create", { text: "Error al guardar CV propio" });
      }
    }

    async function getResult() {
      if (!cropper) return;
      // const base64 = cropper.getDataURL()
      // const blob = await cropper.getBlob()
      // if (!blob) return

      let file = await cropper.getFile({
        fileName: "profile",
      });

      // previewImage.value.dataURL = base64
      // previewImage.value.blobURL = URL.createObjectURL(blob)
      // Este blob hay que subirlo a la cdn
      const imageName = await storage.updateFile(file);
      CVModel.value.picture = imageName;
      CVModel.value.isPictureFromCDN = true;
    }

    return {
      mobileVersion,
      cvFiles,
      modalUploadFile,
      openModalUploadFile,
      beginCreateCv,
      setMainCv,
      downloadCv,
      deleteCv,
      personalInformation,
      aboutMe,
      study,
      jobs,
      modeActive,
      stepActive,
      CVModel,
      skillModel,
      technologicalToolModel,
      interestModel,
      editorPicture,
      addInterest,
      addSkill,
      addTechnologicalTool,
      changeStep,
      formatDate,
      educationSpecialty,
      getInstitution,
      getContractType,
      getOrganization,
      getPosition,
      getLanguageKnowledge,
      getImage,
      uploadProfilePicture,
      dropFile,
      dragover,
      uploadCvFile,
      postCvFile,
      pictureUploaded,
      getResult,
      modalNotWork,
      deletingCv,
      persistentData,
      traducciones,
      uploadFileInput
    };
  },
};
</script>
<style lang="scss" scoped>
.section-title{
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 17px;
  font-weight: 700;
}

.button--red-clean{
  color: $orange;
}

.tools{
  @media (max-width: $break-sm){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.personal-data,.profile-data,.study-data,.work-data,.skills-data,.languages-data,.interests-data,.cv-data,.preview-data{
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media (max-width: $break-md){
    padding: 22px 0;
  }
  span{
    display: block;
    color: black;
  }
}

.profile-data{
  span{
    padding: 6px 0;
  }
  textarea{
    width: 98%;
    max-width: 600px;
    height: 110px;
    margin-top: 4px;
  }
  small{
    display: block;
  }
}

.study-data,.work-data,.languages-data{
  gap: 8px;
  h4{
    margin: 22px 0 30px 0;
    font-size: 14px;
    font-weight: 700;
  }
}

.skills-data, .interests-data{
  h4{
    margin: 36px 0 26px 0;
    font-size: 15px;
    font-weight: 700;
    align-self: flex-start;
  }
  .list-pills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    margin: 12px 0;
    @media (max-width: $break-sm){
      flex-direction: column;
      width: 100%;
    }
    li {
      display: flex;
      gap: 15px;
      color: #E87B1B;
      padding: 8px 12px 8px 24px;
      font-size: 16px;
      font-weight: 700;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      @media (max-width: $break-sm){
        align-items: center;
        justify-content: center;
      }
      i {
        cursor: pointer;
      }
    }
  }
  .add-pill {
    margin-top: 36px;
    display: flex;
    align-items: center;
    gap: 18px;
    @media (max-width: $break-sm){
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 24px;
    }
    input {
      background: #f6f6f6;
      padding: 8px 12px;
      border-radius: 12px;
    }

    button {
      white-space: nowrap;
      font-size: 13px;
      font-weight: 600;
      &:disabled {
        cursor: default;
      }
    }
    small {

    }
  }
}

.preview-data{
  gap: 8px;
}

/* Input para el nuevo diseño */
@mixin input-text-rounded {
  height: 45px;
  max-width: 400px;
  padding: 0 20px;
  background: #f6f6f6;
  border-radius: 50px;
  border: none;
  font-size: 13px;
  font-weight: 500;
  &::placeholder {
    font-size: 12px;
  }
}
/* Pill para el nuevo diseño */
@mixin pill {
  display: flex;
  align-items: center;
  gap: 12px;
  background: #f7f5ff;
  width: fit-content;
  padding: 5px 16px 5px 29px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 44px;
  margin-bottom: 4px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

.upload-file__container {
  input {
    visibility: hidden;
    position: absolute;
    width: 0px;
    height: 0px;
    top: 0;
    left: 0;
  }
  label{
    cursor: pointer;
  }
}

.card {
  display: flex;
  gap: 12px;
  margin: 18px 0;
  &__description {
    display: flex;
    flex-direction: column;
    gap: 2px;
    strong, span {
      padding: 1px 8px;
    }
    .label {
      padding: 4px 12px;
      background: #f7f5ff;
      width: fit-content;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      border-radius: 12px;
      margin: -2px 0 4px 0;
    }
  }
  input[type="checkbox"] {
    appearance: none;
    width: 18px;
    height: 18px;
    background: none;
    border: 1px solid black;
    border-radius: 2px;
    position: relative;
    flex-shrink: 0;
    &:checked {
      background-color: black;
      &:after {
        content: "check";
        font-family: "Material icons";
        font-size: 18px;
        position: absolute;
        top: -3px;
        color: white;
      }
    }
  }
}

.cv-files {
  padding: 16px;
  box-shadow: 5px 5px 35px rgba(217, 217, 217, 0.5);
  border-radius: 10px;
  color: #212121;
  @media (max-width: $break-sm){
    box-shadow: none;
  }
  &__title{
    font-size: 16px;
    margin-bottom: 26px;
    b{
      display: block; 
      margin-bottom: 10px;
    }
    span{
      display: block;      
      color: #212121;
    }
  }
  .tbl-content {
    td{
      border-bottom: 1px solid #bdbdbd3f;
      font-size: 14px;
    }
    tr:last-child{
      td{
        border: none;
      }
    }
  }
  .file{
    &-name {
      display: flex;
      align-items: center;
      gap: 6px;
      .material-icons {
        font-size: 18px;
        &.star {
          margin-left: 4px;
          font-size: 20px;
          color: black;
        }
      }
      .tooltip__text{
        display: block;
      }
    }
    &-ruky{
      width: fit-content;
      padding: 2px 6px;
      margin-bottom: 2px;
      font-size: 11px;
      font-weight: 800;
      background: #F7F5FF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  }
  .reference{
    width: fit-content;
    padding: 5px 16px;
    background: #DFDCF6;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 600;
    //text-align: center;
  }
  .more-options{
    width: fit-content;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
  }
  .modal-cvFile-options {
    position: absolute;
    top: 50px;
    left: -20px;
    padding: 20px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    background: #ffffff;
    box-shadow: 5px 5px 35px rgba(217, 217, 217, 0.5);
    border-radius: 10px;
    z-index: 50;
    span {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #000000;
      font-weight: 600;
      font-size: 13px;
      cursor: pointer;
      .material-icons {
        font-size: 18px;
      }
    }
  }
  &--mobile{
    .file-card{
      padding: 22px 0;
      display: flex;
      flex-direction: column;
      gap: 6px;
      border-bottom: 1px solid #bdbdbda2;
      >div{
        display: flex;
        align-items: center;
        gap: 16px;
      }
      &__content{
        justify-content: space-between;
      }
      .file-name{
        font-size: 14px;
      }
    }
    .modal-cvFile-options{
      top: 30px;
      left: -100px;
    }
  }
}

.hiddenOnMobile{
  @media screen and (max-width: $break-md){
    display: none;
  }
}

.modal-upload-file{
  position: relative;
  width: 98%;
  max-width: 700px;
  padding: 52px;
  background: #fff;
  box-shadow: 5px 5px 35px rgba(217, 217, 217, 0.5);
  border-radius: 10px;
  color: #212121;
  font-size: 16px;
  position: relative;
  @media (max-width: $break-sm){
    padding: 32px;
  }
  .close{
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 26px;
    cursor: pointer;
  }
  &__title{
    font-weight: 700;
    &--exp{
      font-weight: 800;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__info{
    margin: 16px 0;
    font-weight: 400;
    a{
      font-weight: 700;
    }
    &--pdf{
      margin-top: 28px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .upload-file__container{
    margin-bottom: -20px;
    margin-top: 22px;
    label{
      width: 100%;
      padding: 22px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #F6F6F6;
      border-radius: 10px;
      .material-icons{
        font-size: 36px;
      }
    }
  }
  .files-title{
    padding: 18px 0;
    font-weight: 700;
  }
  .uploading{
    width: 100%;
    &__info{
      margin: 10px 0;
      font-size: 13px;
    }
    .bar{
      width: 0%;
      height: 4px;
      background  : linear-gradient(270deg, #009696 0%, #DDDDDD 100%);
      border-radius: 999px;
      align-self: flex-start;
      animation: uploading 5s infinite ease-in;
      @keyframes uploading{
        0%{
          width: 0%;
        }
        100%{
          width: 100%;
        }
      }
    }
  }
  .file-uploaded{
    width: 100%;
    padding: 22px 12px;
    background: #F6F6F6;
    border-radius: 10px;
    div{
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      i,span{
        width: 30px;
      }
    }
  }
  .reference{
    h4{
      padding: 28px 0 8px 0;
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 13px;
      .material-icons{
        font-size: 22px;
      }
    }
  }
  .next-back-btns{
    padding-bottom: 0;
  }
}

.next-back-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px 0px;
  gap: 36px;
  button {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    .material-icons {
      font-size: 18px;
    }
    &:disabled {
      opacity: 0.7;
      cursor: default;
    }
  }
  .next {
    padding: 10px 22px 10px 36px;
    background: $orange;
    color: #fff;
    border-radius: 44px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  }
  @media screen and (max-width: 320px) {
    flex-direction: column-reverse;
    gap: 12px;
  }
}

.cv-data {
  h4 {
    margin: 12px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 13px;
    font-weight: 700;
    &:first-child {
      margin-top: 24px;
    }
    i.material-icons {
      font-size: 19px;
    }
  }
  .container-radio {
    margin: 24px 0;
    display: flex;
    gap: 90px;
    > div {
      display: flex;
      align-items: center;
      gap: 10px;
      input[type="radio"] {
        height: 22px;
        width: 22px;
        margin-bottom: 2px;
      }
      label {
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
  .container-editor {
    max-height: 86vh;
    overflow-y: auto;
    position: relative;
    background-color: #fff;
    padding: 20px;
    box-shadow: 5px 5px 35px rgba(217, 217, 217, 0.5);
    border-radius: 10px;
    &__title {
      font-weight: 700;
      font-size: 12px;
    }
    &__subtitle {
      margin: 18px 0;
      font-weight: 400;
      font-size: 12px;
    }
    .next-back-btns {
      padding: 30px 0 0 6px;
      gap: 18px;
      button {
        font-size: 14px;
        &.next {
          padding: 9px 16px 9px 24px;
        }
      }
    }
    .img-loader {
      padding: 18px 0;
      display: flex;
      justify-content: center;
      .custom-loader {
        text-align: center;
        width: 110px;
        height: 18px;
        background: radial-gradient(circle closest-side, $gray 90%, #0000) 0%
            50%,
          radial-gradient(circle closest-side, $gray 90%, #0000) 50% 50%,
          radial-gradient(circle closest-side, $gray 90%, #0000) 100% 50%;
        background-size: calc(100% / 3) 100%;
        background-repeat: no-repeat;
        animation: d7 1.4s infinite linear;
        @keyframes d7 {
          33% {
            background-size: calc(100% / 3) 20%, calc(100% / 3) 100%,
              calc(100% / 3) 100%;
          }
          50% {
            background-size: calc(100% / 3) 100%, calc(100% / 3) 20%,
              calc(100% / 3) 100%;
          }
          66% {
            background-size: calc(100% / 3) 100%, calc(100% / 3) 100%,
              calc(100% / 3) 20%;
          }
        }
      }
    }
  }
  .card-image {
    margin: 24px 0;
    display: flex;
    gap: 20px;
    @media (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait){
      max-width: 82vw;
    }
    .btn__profile-picture {
      display: flex;
      max-width: 100%;
    }
    small {
      font-size: 12px;
    }
    .upload-file__container {
      label {
        // margin: 12px 0;
        //max-width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px 8px 29px;
        gap: 15px;
        border: 1px solid #212121;
        border-radius: 44px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        @media (max-width: 400px){
          font-size: 14px;
        }
        .material-icons {
          font-size: 16px;
        }
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    .img {
      width: 126px;
      height: 126px;
      @media (max-width: 500px){
        width: 105px;
        height: 105px;
      }
      @media (max-width: 400px){
        width: 86px;
        height: 86px;
      }
      @media (max-width: 385px){
        width: 76px;
        height: 76px;
      }
      @media (max-width: 360px){
        width: 126px;
        height: 126px;
      }
      .material-icons {
        font-size: 126px;
        color: #e9e7f9;
      }
    }
    @media screen and (max-width: 360px){
      flex-direction: column;
      align-items: center;
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) { 
      flex-direction: column;
      align-items: center;
    }
  }
  .reference {
    margin-top: 42px;
    input{
      @include input-text-rounded;
    }
  }
}

.alert {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 12px 16px;
  margin: 8px 0;
  background: #f8f0dc;
  width: 100%;
  @media (max-width: $break-sm){
    font-size: 14px;
  }
}

.profile__card{
  >.button{
    font-size: 12px;
    height: fit-content;
    padding: 12px 0;
    .material-icons{
      font-size: 18px;
    }
  }
}

.profile__card__container__buttons {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 32px 0;
  @media screen and (max-width: 600px){
    flex-direction: column;
  }
  .button {
    max-width: 300px;
    margin: 0;
    padding: 0 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    @media screen and (min-width: 600px){
      &:last-child{
        padding-left: 0
      }
    }
  }
  // justify-content: center;
}

.ready {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 12px 0;
}

// Table
table {
  width: 100%;
  table-layout: fixed;
}
.table-header {
  background-color: rgba(255, 255, 255, 0.3);
}
.table-content {
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
th {
  padding: 20px 15px;
  text-align: left;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}
td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 500;
  font-size: 12px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  div {
    display: flex;
    align-items: center;
  }
}
// Steps
.step-container {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  background: rgba(242, 169, 67, 0.35);
  margin: 24px 0;
  padding: 24px 12px;
  @media (max-width: $break-md){
    display: none;
  }
  .step-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    @media (max-width: 768px) {
      font-size: 12px;
    }

    &::before {
      position: absolute;
      content: "";
      border-bottom: 2px dotted tomato;
      width: 100%;
      top: 13px;
      left: -50%;
      z-index: 2;
    }

    & .step-counter {
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      border: 3px solid tomato;
      background: #fff;
      margin-bottom: 6px;
      .material-icons{
        font-size: 19px;
        display: none;
      }
    }

    & .step-name {
      padding-top: 12px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      font-size: 11px;
      color: tomato;
    }

    &.active {
      color: black;
      .step-counter{
        background-color: tomato;
        &::before{
          position: absolute;
          content: "";
          color: White;
          border: 3px solid white;
          padding: 6px;
          border-radius: 50%;
          top: 0px;
          bottom: 0px;
          left: 0px;
          right: 0px;
        }
      }
    }

    &.active .step-counter {
      background-color: tomato;
    }

    &.active::after {
      position: absolute;
      content: "";
      border-bottom: 2px solid tomato;
      width: 100%;
      top: 13px;
      left: 50%;
      z-index: 3;
    }
    &.completed .step-counter {
      background-color: tomato;
      .material-icons{
        display: inline-block;
        color: white;
      }
    }
    &.completed .step-counter::before {
      position: absolute;
      font-family: 'Material Icons';
      font-size: 19px;
      content: "checked";
      color: White;
      //height: 14px;
      //border: 4px solid white;
      border-radius: 50%;
      top: 1px;
      bottom: 0px;
      left: 1px;
      right: 0px;
    }

    &:first-child::before {
      content: none;
    }
    &:last-child::after {
      content: none;
    }
  }
}

input, textarea {
  width: 100%;
  color: $litedarkgray;
  font-family: $font-palanquin;
  @include font-settings(kilo, base);
  border: none;
  background: none;
  font-weight: 700;
  &::placeholder {
    color: $graybg;
  }

  &[type="file"] {
    // width: 40px;
    // height: 40px;
    background: $orange;
    border-radius: 50%;
    border: 3px solid $orange;
    flex-shrink: 0;
    background-size: cover;
    background-position: center center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    grid-area: image;
    cursor: pointer;
    position: relative;
    // top: 85px;
    // left: 85px;

    &:before {
      font-family: "Material Icons";
      content: "\e412";
      font-size: 24px;
      display: flex;
      color: $white;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      width: 100%;
    }

    &::file-selector-button {
      opacity: 0;
    }
  }
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
  font-weight: 700;
  font-size: 14px;
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 34px;
    height: 15px;
    background: #c5c3c3;
    display: block;
    border-radius: 25px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: -2px;
    left: -5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 15px;
    transition: all 0.2s;
    box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.5);
  }

  input:checked + label {
    background: #c5c3c3;
  }

  input:checked + label:after {
    left: calc(100% + 5px);
    transform: translateX(-100%);
    background: #000;
  }
}

textarea {
  resize: none;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  background-color: #f6f6f6;
  padding: 18px 16px;
  border-radius: 15px;
  outline: none;
  border: none;
  @media (max-width: $break-sm ) {
    padding: 12px;
  }
}

.textarea{
  background-color: inherit;
  @media (max-width: $break-sm){
    background-color: #f6f6f6;
    border-radius: 15px;
    position: relative;
    height: 138px;
  }
}

.img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
}

.counter {
  text-align: right;
  font-size: 11px;
  font-weight: 400;
  color: #bdbdbd;
  padding-right: 36px;
  @media (max-width: $break-sm) {
    position: absolute;
    bottom: 8px;
    right:  -12px;
  }
}

.content__form {
  box-shadow: 0px 0px 10px rgba(0,0,0,.3);
  @media (max-width: $break-md){
    box-shadow: none;
  }
}

.star-icon {
  color: orange;
}

input:read-only {
  opacity: .7;
}

.icon-blue {
  color: #263554;
}


.closeButton{
  cursor: pointer;
}
</style>
