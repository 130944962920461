<template>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <div class="login__circle">
          <img src="@/assets/passport.svg" alt="">
        </div>
        <h3>{{ $t("register.step.first.title") }}</h3>
        <p>{{ $t("register.step.first.description") }}</p>
        
        <SocialRegister text="register" />

        <input type="text" :placeholder="$t('register.step.first.input_firstName')" autocomplete="off" v-model="firstName" :class="{'input-invalid': v$.firstName.$invalid && submitted}">
        <span class="input-message" v-if="v$.firstName.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.firstName.required.$message}}</span>
        
        <input type="text" :placeholder="$t('register.step.first.input_lastName')" autocomplete="off" v-model="lastName" :class="{'input-invalid': v$.lastName.$invalid && submitted}">
        <span class="input-message" v-if="v$.lastName.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.lastName.required.$message}}</span>
        
        <input type="email" :placeholder="$t('register.step.first.input_email')" autocomplete="off" v-model="email" :class="{'input-invalid': v$.email.$invalid && submitted}">
        <span class="input-message" v-if="v$.email.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.required.$message}}</span>
        <span class="input-message" v-if="v$.email.email.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.email.$message}}</span>

        <button @click="continueRegister" class="button button--red button--register" :disabled="awaitResponse">{{ awaitResponse ? $t("register.nextDisabled") : $t("register.next") }}</button>
       </div>
    </div>
  </div>
  <BulletsRegister :active="1"/>
</template>
<script>
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { computed, ref } from 'vue';
  import BulletsRegister from '@/components/Register/BulletsRegister';
  import useVuelidate from '@vuelidate/core';
  import { required, email, helpers } from '@vuelidate/validators';
  import SocialRegister from '@/components/Common/SocialRegister.vue';
  import { useI18n } from 'vue-i18n';
  
  export default {
    components: {
      BulletsRegister,
      SocialRegister
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const submitted = ref(false);
      let awaitResponse = ref(false);
      const { t } = useI18n();
      const v$ = useVuelidate();

      const firstName = computed({
        get: () => store.state.Register.userModel.firstName,
        set: (value) => store.commit('register_updateField', {field: 'firstName', value})
      });
      const lastName = computed({
        get: () => store.state.Register.userModel.lastName,
        set: (value) => store.commit('register_updateField', {field: 'lastName', value})
      });
      const email = computed({
        get: () => store.state.Register.userModel.email,
        set: (value) => store.commit('register_updateField', {field: 'email', value})
      });

      async function continueRegister() {
        submitted.value = true;
        if (!v$.value.firstName.$invalid && !v$.value.lastName.$invalid && !v$.value.email.$invalid) {
          // Validate mail
          awaitResponse.value = true;
          try {
            const request = await store.dispatch('security_emailIsAvailable', email.value)
            if (request.status == 200) {
              if (request.data) {
                router.push('/register/second');
                awaitResponse.value = false;
                submitted.value = false;
              } else {
                awaitResponse.value = false;
                store.dispatch('notifications_create', { text: t(`notifications.emailNotAvailable`) });
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      }

      return {
        firstName,
        lastName,
        email,
        submitted,
        continueRegister,
        v$,
        awaitResponse
      }
    },
    validations () {
      return {
        firstName: { 
          required: helpers.withMessage('Este campo es requerido', required)
        },
        lastName: { 
          required: helpers.withMessage('Este campo es requerido', required)
        },
        email: {         
          required: helpers.withMessage('Este campo es requerido', required),
          email: helpers.withMessage('El formato del mail es invalido', email) 
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .social-register {
    flex-direction: row;
    gap: $margin-base;
  }
</style>