<template>
  <div :class="{'container': logged, 'games': games}" v-if="logged && registrationReference != null && registrationReference.toLowerCase() !== 'trip' && registrationReference !== 'IIVVO'">
    <button class="chatbot" :class="{logged}" @click="openBot('RUKY_TAP')">
      <img src="@/assets/chatbot.svg" alt="chatbot">
    </button>
  </div>

  <template v-if="botActionActive.window === 'FULL' && botActionActive.autoShow">
    <div class="modal__overlay" @click.stop="closeBot()">
      <VisibleModal />
      <template v-if="botActionActive.type === 'VIDEO'">
        <div class="modal modal--video">
          <div class="modal__responsive">
            <iframe :src="botActionActive.message" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
      </template>
      <template v-if="botActionActive.type === 'MESSAGE'">
        <div class="modal" @click.stop="''">
          <span class="material-icons" @click.stop="closeBot()">close</span>
          <SvgAnimated class="modal__svg" :data="rukyAnimated" />
          <!-- <h3>¡Llená tu valija de recursos!</h3> -->
          <p>{{botActionActive.message}}</p>
          <div class="modal__buttons">
            <button @click.stop="closeBot()">entendido</button>
          </div>
        </div>
      </template>
    </div>
  </template>
  <template v-if="botActionActive.window === 'TAP'">
    <VisibleModal />
    <div class="chatbot-message__overlay" @click.stop="closeBot()">
      <div class="chatbot-message" @click.stop="''">
        <p v-html="$t('home.ruky')"></p>
      </div>
    </div>
  </template>

</template>
<script>
import { watch } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';
import VisibleModal from '@/components/Common/VisibleModal.vue';
import SvgAnimated from '@/components/SvgAnimated/Main.vue';
import rukyAnimated from '@/components/SvgAnimated/JSON/ruky_luggage.json';

export default {
  components: {
    VisibleModal,
    SvgAnimated
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const logged = computed(() => store.getters.manageUser_logged);
    const botActionActive = computed(() => store.getters.bot_botActionActive);
    const registrationReference = computed(() => store.getters.manageUser_registrationReference);
    const games = computed(() => route.name == 'GAMES_EXTERNAL');
    let actionActive = ""

    setTimeout(() => {
      openBot('ENTER');
    });

    async function openBot(action) {
      const routesBot = ["HOME", "WORK_JOBS_OFFERS", "LEARN_COURSES", "LEARN_ROUTE", "PROFILE_RESUME"];
      const routeNameActive = computed(() => route.name);      
      if (action !== 'ENTER' || (action === 'ENTER' && routesBot.includes(routeNameActive.value))) {
        actionActive = action;
        await store.dispatch('bot_analizeResponse', {view: routeNameActive.value.split('_')[0], action});
      }
    }

    watch(botActionActive, (value) => {
      actionActive = value.action;
    });

    function closeBot() {
      store.dispatch('bot_close');
      if (actionActive === 'JOB_APPLY') {
        actionActive = '';
        router.push('/work/jobOffers');
      }

    }

    watch(() => route.path, () => {
      openBot('ENTER');
    });

    return {
      openBot,
      logged,
      botActionActive,
      closeBot,
      rukyAnimated,
      games,
      registrationReference
    }
  }
}
</script>
<style lang="scss" scoped>

  .container {
    position: fixed;
    top: 0;
    right: $margin-base;
    z-index: 11;

    @media (min-width: $break-sm) {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }

  .games {
    position: absolute;
  }

  .modal__svg {
    width: 280px;
    margin: 0 auto $margin-base;
    filter: drop-shadow( 1px 1px 3px rgba(0, 0, 0, .4));
  }

  .modal--video {
    max-width: 720px;
    padding: $margin-base;

    .modal__responsive {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      border-radius: $margin-milli;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .chatbot {
    width: 64px;
    height: 64px;
    background: $white;
    border-radius: 100%;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    right: $margin-base;
    bottom: $margin-base;
    padding: $margin-milli;
    display: flex;
    align-items: center;
    z-index: 2;

    img {
      width: 100%;
    }

    &.logged {
      position: absolute;
      top: $margin-milli;
      width: 48px;
      height: 48px;
      padding: 4px;
      z-index: 11;
      right: 0;
    }
  }

  .chatbot-message {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: $margin-basedo;
    padding: $margin-kilo;
    margin: $margin-base 0;
    text-align: center;
    background: $white;
    max-width: 400px;
    width: 100%;
    position: relative;
    top: 60px;
    right: 8px;

    @media (min-width: $break-md) {
      margin-right: 330px;
      margin-bottom: 0;
      margin-top: 32px;
    }

    &:after {
      content: " ";
      position: absolute;
      right: 16px;
      top: -14px;
      border-top: none;
      border-bottom: 15px solid white;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
    }

    p {
      text-shadow: none;
      color: $gray;
      font-family: $font-opensans;
      font-weight: 700;
      @include font-settings(base,base);

      b {
        color: $red;
      }
    }
  }

  .chatbot-message__overlay {
    background: none;
    justify-content: flex-end;
    align-items: flex-start;
  }
</style>