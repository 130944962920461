<template>
  <Header />
  <RouterView />
  <Bot v-if="!routesToShare.includes(routeActive)"/>
  <Navbar v-if="!routesRegisterLogin.includes(routeActive) && !routesToShare.includes(routeActive)"/>
  <Notifications />
  <ModalRegister />
</template>

<script>
import { computed, onMounted, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { ref, provide } from 'vue';
import { useRoute } from 'vue-router';
import Header from '@/components/Common/Header.vue';
import Bot from '@/components/Common/Bot.vue';
import Navbar from '@/components/Common/Navbar.vue';
import Notifications from '@/components/Common/Notifications/Main.vue';
import ModalRegister from '@/components/Common/ModalRegister.vue';

export default {
  components: {
    Header,
    Bot,
    Navbar,
    Notifications,
    ModalRegister
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const logged = computed(() => store.getters.manageUser_logged);
    const routesRegisterLogin = ['REGISTER', 'REGISTER_FIRST', 'REGISTER_SECOND', 'REGISTER_THIRD', 'REGISTER_TERMS', 'REGISTER_ACTIVE', 'LOGIN'];
    const routesToShare = ['GAMES_SHARE_TRIP','GAMES_SHARE_IIVVO','GAMES_SHARE_GENOMA','SHARE_GENOMA_RESULTS'];
    const routeActive = ref('');
    const mobileVersion = ref(false);
    provide('mobileVersion', mobileVersion);

    function detectMobile(){
      if(window.matchMedia("(max-width: 1000px)").matches) {
        mobileVersion.value = true;
      }
      window.addEventListener('resize',()=>{
        if (window.matchMedia("(max-width: 1000px)").matches) {
          mobileVersion.value = true;
        } else {
          mobileVersion.value = false;
        }
      })
    }

    onMounted(() => {
      store.dispatch('manageUser_active');
      routeActive.value = route.name;
      detectMobile();
    });

    watch(() => route.name, (val) => { routeActive.value = val });

    return {
      logged,
      routeActive,
      routesRegisterLogin,
      routesToShare
    }
  }
}
</script>

<style lang="scss">
  @import "@/styles/main.scss";
</style>