<template>
  <div class="bullets">
    <div :class="{'active': i <= active}" v-for="i in [1,2,3,4]" :key="i"></div>
  </div>
</template>
<script>
export default {
  props: {
    active: Number
  }
}
</script>
<style lang="scss" scoped>
  .bullets {
    position: fixed;
    bottom: $margin-mega;
    display: flex;    
    width: 100%;
    justify-content: center;

    div {
      width: 12px;
      height: 12px;
      background: $white;
      border-radius: 50%;
      margin-right: $margin-base;

      &.active {
        background: $orange;
      }
    }
  }
</style>