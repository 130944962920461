<template>
  <div class="learning learning__detail">
    <div class="container main-container">

      <div v-if="levelProfile < 5" class="available-level-5" style="margin-bottom: 24px">
        <i class="material-icons">error</i>
        <span>Podrás acceder a esta y a cualquiera de las rutas de aprendizaje a partir del <b>NIVEL 5</b></span>
      </div>

      <article class="learning__card__detail">
        <div class="learning__card__detail__img" :style="`background-image: url('${routeModel.picture}')`" :alt="routeModel.name"></div>
        <div class="learning__card__detail__container">
          <div class="learning__card__detail__title">
            {{routeModel.name}}
          </div>
          <div class="learning__card__detail__rate">
            <i class="material-icons" v-for="i in [0,1,2,3,4]" :key="i" :class="{'active': i < routeModel.rate}">star</i>
          </div>
          <p class="learning__card__detail__content">
            {{routeModel.description}}
          </p>

          <div class="learning__card__detail__separator">
            <div class="learning__card__detail__separator__title">{{ $t("learning.detail_route.content") }}</div>
          </div>

          <div>
            <div v-for="course in routeModel.items" :key="course.id" class="learning__card__content__step">
              <div class="learning__card__content__step__header">
                <img src="@/assets/ticket-white.svg" alt="Ticket">
                <div>
                  <div class="learning__card__detail__title learning__card__detail__title--small"> {{ course.name }} </div>
                  <div class="learning__card__detail__subtitle">{{ course.vendor }}</div>
                  <router-link :to="`/learning/courses/${course.type}/${course.id}`">{{ $t("learning.detail_route.view_detail") }}</router-link>
                </div>
              </div>
              <p class="learning__card__detail__content learning__card__detail__content--small">
                {{ course.description }}
              </p>
              <a v-if="levelProfile >= 5" :href="`${course.url}`"  target="_blank" @click="setUserRelation(course.id)">
                <button class="button button--outline button--register">¡COMENZAR CURSO!</button>
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="learning__card__detail__button">
          <button class="button button--register">¡COMENZAR!</button>
        </div> -->
      </article>
    </div>
  </div>
</template>
<script>
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ref } from 'vue';
import persistentData from '@/plugins/persistentData';

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const levelProfile = persistentData.get("forgeLevel", true);

    let routeModel = ref({});
    onMounted(async () => {
      routeModel.value = await store.dispatch('learning_learningRoutes_id', {id: route.params.id});
    });

    async function setUserRelation(courseId) {
      await store.dispatch('learning_learningItems_setUserRelation', {id: courseId});
    }

    return {
      levelProfile,
      routeModel,
      setUserRelation
    }
  }
}
</script>
<style lang="scss" scoped>

  .learning__detail {
    padding: $margin-base $margin-base 96px;

    @media (min-width: $break-sm) {
      padding: $margin-mega 0 96px;
    }
  }

  .learning__card__detail {
    background: $white;
    border-radius: $margin-basedo;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    &__container {
      padding: $margin-milli $margin-kilo $margin-kilo;
      width: 100%;
    }

    &__img {
      width: 100%;
      height: 0;
      padding-bottom: 56.5%;
      background-size: cover;
      background-position: center center;
      border-radius: 20px 20px 0 0;
    }

    &__button {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -24px;

      button {
        margin: 0;
      }
    }

    &__title {
      font-family: $font-opensans;
      text-align: left;
      @include font-settings(giga,base);
      color: $green;
      font-weight: 700;
      margin: $margin-base 0;
      padding: 0 $margin-kilo 0 0;
      margin-bottom: 4px;

      &--small {
        @include font-settings(kilo,base);
        margin: 0;
        padding: 0;
      }
    }

    span {
      font-family: $font-palanquin;
      @include font-settings(xmilli,base);
      text-transform: uppercase;
      color: $gray;
      padding-left: $margin-kilo;
      font-weight: 400;
      color: $lightgray;
    }

    &__rate {
      display: flex;
      margin-bottom: $margin-base;

      i {
        color: #BDBDBD;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        transform: rotate(-5.31deg);

        &.active {
          color: #FFC905;
        }
      }
    }
    
    .learning__card__detail__content {
      font-family: $font-opensans;
      text-align: left;
      text-shadow: none;
      @include font-settings(base,base);
      color: $gray;
      font-weight: 400;
      padding: 0 $margin-kilo $margin-giga 0;

      &--small {
        @include font-settings(milli,base);
        padding: 0 0 $margin-milli 0;
      }
    }

    .learning__card__detail__separator {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      &:before {
        content: '';
        border-top: 2px solid $lightgray;
        position: absolute;
        top: 9px;
        width: 100%;
      }

      &__title {
        font-family: $font-palanquin;
        text-align: center;
        @include font-settings(base,base);
        color: $lightgray;
        text-transform: uppercase;
        margin-bottom: $margin-kilo;
        background: $white;
        position: relative;
        width: fit-content;
        padding: 0 $margin-milli;
      } 
    }

    .learning__card__detail__subtitle {
      font-family: $font-palanquin;
      text-align: left;
      text-shadow: none;
      @include font-settings(milli,base);
      color: $gray;
      font-weight: 400;
    }

    .learning__card__content__step {
      margin-bottom: $margin-mega;

      a {
        color: $yellow;
        text-transform: uppercase;
        font-family: $font-palanquin;
        @include font-settings(base,base);
        margin-top: $margin-milli;
        display: inline-block;
      }

      .learning__card__content__step__header {
        display: flex;
        flex-direction: row;
        gap: $margin-small;
        align-items: flex-start;
        margin-bottom: $margin-small;

        img {
          background: $green;
          border-radius: 50%;
          width: 48px;
          height: 48px;
          padding: $margin-milli;
          transform: rotate(-15deg);
          flex-shrink: 0;
        }
      }
    }

    &__included {
      padding: 0 $margin-kilo $margin-kilo;

      img {
        width: $margin-kilo;
        margin-right: 4px;
      }
    }
  }
</style>