import interceptor from '@/plugins/interceptor';
const pathController = '/Info'

export default {
  state: () => ({
  }),
  mutations: { 
  },
  actions: {
    async info_homeImfo() {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/homeInfo`); 
      if (request.status === 200) return request.data;
      return [];
    }
  }
}