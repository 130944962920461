import persistentData from "@/plugins/persistentData";
import moment from "moment";

let intervalRefreshToken = null;

export default {
  state: () => ({
    logged: false,
    forceLogin: false,
    registrationReference: null
  }),
  getters: {
    manageUser_logged: state => state.logged,
    manageUser_forgeLogin: state => state.forceLogin,
    manageUser_registrationReference: state => state.registrationReference
  },
  mutations: { 
    manageUser_setLogged(state, value) {
      state.logged = value;
    },
    manageUser_setForceLogin(state, value) {
      state.forceLogin = value;
    },
    manageUser_setRegistrationReference(state, value) {
      state.registrationReference = value;
    }
  },
  actions: {
    manageUser_active({ commit, dispatch }) {
      if (persistentData.get('token')) {
        // Validate tokenExpiration
        const validateToken = async function() {
          const expiredToken = moment(persistentData.get('tokenExpiration')).diff(moment());
          if (expiredToken < 0) {
            try {
              const request = await dispatch('security_refreshToken', {value: persistentData.get('token')});
              if (request.status === 200) {
                persistentData.set('token', request.data.token);
                persistentData.set('tokenExpiration', request.data.tokenExpiration);
              } else {
                dispatch('manageUser_disabled');
              }
            } catch (error) {
              dispatch('manageUser_disabled');
            }
          }
        }

        validateToken();

        if (intervalRefreshToken) clearInterval(intervalRefreshToken);
        intervalRefreshToken = setInterval(() => {
          validateToken();
        }, 10000);
        commit('manageUser_setLogged', true);
        commit('manageUser_setRegistrationReference', persistentData.get('registrationReference'));
        // Set i18n
        const lang = persistentData.get('language');
        if (['AR', 'CL', 'MX', 'PE', 'UY'].includes(lang)) {
          window.i18n.global.locale.value = `es-${lang.toUpperCase()}`;
        } else {
          window.i18n.global.locale.value = 'es';
        }
      }
    },
    manageUser_disabled({ commit }) {
      persistentData.remove('token');
      persistentData.remove('tokenExpiration');
      persistentData.remove('registrationReference');
      if (intervalRefreshToken) clearInterval(intervalRefreshToken);
      commit('manageUser_setLogged', false);
    },
    manageUser_forceLogin({ commit }, close = false) {
      let force = false;
      if (close) {
        commit('manageUser_setForceLogin', force);
      } else if (!persistentData.get('token')) {
        force = true;
        commit('manageUser_setForceLogin', force);
      }

      return force;
    }
  }
}