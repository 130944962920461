import axios from 'axios';
import persistentData from '@/plugins/persistentData';
import i18n from '@/i18n';

const instance = axios.create({
  timeout: 99000,
});

const getHeaders = function(isAnon = false) {
  let params = {
    LANG: i18n.global.locale.value.toUpperCase().split('-')[0],
    'Content-Type': 'application/json',
  };
  if (!isAnon && persistentData.get('token')) {
    params.Authorization = `Bearer ${persistentData.get('token')}`;
  }
  return params;
};

const interceptor = {
  anonymous: {
    get: (url) => instance.get(url, { headers: getHeaders() }),
    post: (url, data) => instance.post(url, data, { headers: getHeaders() }),
  },
  authenticate: {
    get: (url) => instance.get(url, { headers: getHeaders() }),
    post: (url, data) => instance.post(url, data, { headers: getHeaders() }),
    put: (url, data) => instance.put(url, data, { headers: getHeaders() }),
    patch: (url, data) => instance.patch(url, data, { headers: getHeaders() }),
    delete: (url) => instance.delete(url, { headers: getHeaders() }),
  },
};

export default interceptor;
