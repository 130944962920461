<template>
    <div class="loginCampus">
        <div>
            <h1>Ingresando a Ruky </h1>
            <div class="custom-loader"></div>
        </div>
    </div>
</template>
<script>
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import base64url from 'base64url'
import { useStore } from 'vuex';
import persistentData from '@/plugins/persistentData';
import { useI18n } from 'vue-i18n';

export default{
    setup(){
        const route = useRoute();
        const store = useStore()
        const { t } = useI18n();
        const router = useRouter()
        onMounted( async () => {
            let json = JSON.parse(base64url.decode(route.query.identifier))
            try {
                const request = await store.dispatch('security_authenticateCampus', json)
                if (request.status == 200) {
                    persistentData.set('token', request.data.token);
                    persistentData.set('tokenExpiration', request.data.tokenExpiration);
                    persistentData.set('registrationReference', request.data.registrationReference);
                    persistentData.set('language', request.data.countryCode);
                    persistentData.set('forgeLevel', request.data.forgeLevel);
                    store.dispatch('manageUser_active');
                    if (request.data.registrationReference != null && request.data.registrationReference.toLowerCase() == 'trip') {
                        router.push('/gamesExternal/TRIP/trip');
                    } else if (request.data.registrationReference == 'IIVVO') {
                        router.push('/gamesExternal/IIVVO/IIVVO');
                    } else {
                        router.push('/');
                    }
                }
            } catch (error) {
                store.dispatch('notifications_create', { text: t(`notifications.${error.response.data}`) });
            }
        })
            return
        }
    }
</script>

<style lang="scss" scoped>
.loginCampus{
    widows: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('~@/assets/background-login-laptop.svg'), linear-gradient(180deg, #E66F1E 0%, #F1B80F 52.08%, #FAD06A 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    >div{
        display: flex;
        align-items: center;
        gap: 12px;
    }
}

.custom-loader {
  width:72px;
  height:18px;
  background: 
    radial-gradient(circle closest-side,#fff 90%,#0000) 0%   50%,
    radial-gradient(circle closest-side,#fff 90%,#0000) 50%  50%,
    radial-gradient(circle closest-side,#fff 90%,#0000) 100% 50%;
  background-size:calc(100%/3) 100%;
  background-repeat: no-repeat;
  animation:d7 2s infinite linear;
}
@keyframes d7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}

</style>