<template>
  <div class="profile__references">
    <div class="container main-container">
      <div class="tabs">
        <button class="tabs__tab active">Aprobadas</button>
        <button class="tabs__tab">Sin aprobar</button>
      </div>
      <div class="profile__references__container">
        <div v-for="i in [0,1,2,3,4]" :key="i" class="profile__references__card">
          <div class="profile__references__card__image" style="background-image: url('https://via.placeholder.com/76')"></div>
          <div class="profile__references__card__content">
            <span class="profile__references__card__title">Nombre del referente</span>
            <span class="profile__references__card__description">Tipo de relaciòn</span>
          </div>
        </div>
        <button class="profile__card__button">+ solicitar nueva</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
  .profile__references {
    min-height: 100vh;
    background: url('~@/assets/profile-bg.svg'), linear-gradient(180deg, #E87B1B 0%, #FFC905 100%);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 1270px, auto;
    padding-bottom: 96px;
    padding-top: $margin-kilo;

    .container {
      padding: 0 $margin-base;
    }

    &__container {
      background: $white;
      border-radius: 20px;
      padding: $margin-kilo $margin-base;

      button {
        width: 100%;
      }
    }

    &__card {
      display: flex;
      align-items: center;
      padding: $margin-kilo 0;
      border-bottom: 2px solid $lightestofgrays;

      &:last-child {
        border-bottom: none;
      }

      &__content {
        display: flex;
        flex-direction: column;
        margin-left: $margin-base;
      }

      &__image {
        width: 64px;
        height: 64px;
        background: $white;
        border-radius: 50%;
        border: 3px solid $graybg;
        flex-shrink: 0;
        background-size: cover;
        background-position: center center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      }
      &__title {
        color: $orange;
        @include font-settings(kilo, base);
        font-weight: 700;
        font-family: $font-palanquin;
      }
      &__description {
        @include font-settings(milli, base);
        font-weight: bold;
        color: $gray;
        margin-top: $margin-milli;
      }
    }
  }
</style>