<template>
  <div class="learning learning__course">
    <div class="container main-container">
      <div v-if="levelProfile < 5" class="available-level-5" style="margin-bottom: 24px">
        <i class="material-icons">error</i>
        <span>Podrás acceder a este y a cualquiera de los cursos a partir del <b>NIVEL 5</b></span>
      </div>
      <article class="course__card">
        <div class="course__card__img" :style="`background-image: url(${itemModel.picture})`" :alt="itemModel.name">
        </div>
        <div class="course__card__container">
          <div class="course__card__title">
            {{itemModel.name}}
          </div>
          <div class="course__card__vendor">{{itemModel.vendor}}</div>
          <div class="course__card__rate">
            <i class="material-icons" v-for="i in [0,1,2,3,4]" :key="i" :class="{'active': i < itemModel.rate}">star</i>
          </div> 
          <p class="course__card__subtitle">
            {{itemModel.description}}
          </p>
          <span>{{ $t("learning.detail_course.level") }} {{itemModel.level}}</span>
        </div>
        <div v-if="levelProfile >= 5" class="course__card__button" @click="setUserRelation()">
          <a :href="`${itemModel.url}`" target="_blank">
            <button class="button button--register">{{ $t("learning.detail_course.start") }}</button>
          </a>
        </div>
      </article>
    </div>
  </div>
</template>
<script>
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ref } from 'vue';
import persistentData from '@/plugins/persistentData';

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const levelProfile = persistentData.get("forgeLevel", true);

    let itemModel = ref({});
    onMounted(async () => {
      itemModel.value = await store.dispatch('learning_learningItems_id', {type: route.params.type, id: route.params.id});
    });

  async function setUserRelation() {
    await store.dispatch('learning_learningItems_setUserRelation', {id: itemModel.value.id});
  }

    return {
      levelProfile,
      itemModel,
      setUserRelation
    }
  }
}
</script>
<style lang="scss" scoped>

  .learning__course {
    padding: $margin-base $margin-base 96px;

    @media (min-width: $break-sm) {
      padding: $margin-mega 0 96px;
    }
  }

  .course__card {
    background: $white;
    border-radius: $margin-basedo;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    &__container {
      padding: $margin-milli $margin-kilo $margin-kilo;
      width: 100%;
    }

    &__img {
      width: 100%;
      height: 0;
      padding-bottom: 56.5%;
      background-size: cover;
      background-position: center center;
      border-radius: 20px 20px 0 0;
    }

    &__button {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -24px;

      button {
        margin: 0;
      }
    }

    &__title {
      font-family: $font-opensans;
      text-align: left;
      @include font-settings(kilo,base);
      color: $yellow;
      font-weight: 700;
      margin: $margin-base 0;
      padding: 0 $margin-kilo 0 0;
      margin-bottom: 4px;
    }

    &__vendor {
      font-family: $font-palanquin;
      @include font-settings(xmilli,base);
      text-transform: uppercase;
      color: $gray;
      // padding-left: $margin-kilo;
      font-weight: 400;
      margin-bottom: $margin-milli;
    }

    span {
      font-family: $font-palanquin;
      @include font-settings(milli,base);
      // padding-left: $margin-kilo;
      font-weight: 400;
      color: $lightgray;
      margin-bottom: $margin-mega;
      display: inline-block;
      text-transform: uppercase;
    }

    &__rate {
      display: flex;
      // padding-left: $margin-kilo;
      margin-bottom: $margin-base;

      i {
        color: #BDBDBD;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        transform: rotate(-5.31deg);
        
        &.active {
          color: #FFC905;
        }
      }
    }
    
    p.course__card__subtitle {
      font-family: $font-opensans;
      text-align: left;
      text-shadow: none;
      @include font-settings(milli,base);
      color: $gray;
      font-weight: 400;
      padding: 0 $margin-kilo $margin-kilo 0;
    }

    &__included {
      padding: 0 $margin-kilo $margin-kilo 0;

      img {
        width: $margin-kilo;
        margin-right: 4px;
      }
    }
  }
</style>