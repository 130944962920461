<template>
  <div class="social-register">
    <button :disabled="loading || !isGoogleInit" @click="authenticateGoogle" class="button button--white">
      <img src="@/assets/google.svg" alt="">
      {{ loading ? 'Ingresando' : $t(`social_register.${text}-with`, {type: 'Google'}) }}
    </button>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import gAuth from 'vue3-google-auth';
import { useStore } from 'vuex';
import persistentData from '@/plugins/persistentData';
import { ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
// import { BroadcastChannel } from 'broadcast-channel';

export default {
  props: {
    text: String
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const { t } = useI18n();

    // const bc = new BroadcastChannel('login');
    // function authenticateFacebook () {
    //   loading.value = true;

    //   // Se emite desde el LoginRedirectFacebook
    //   bc.onmessage = async ({ data }) => {
    //     try {
    //       if (data.error) {
    //         loading.value = false;
    //       } else if (data.accessToken) {
    //         const request = await store.dispatch('security_authenticateFacebook', { accessToken: data.accessToken });
    //         loading.value = false;
    //         if (request.status == 200) {
    //           if (request.data.relatedUser) {
    //             persistentData.set('token', request.data.relatedUser.token);
    //             persistentData.set('tokenExpiration', request.data.relatedUser.tokenExpiration);
    //             store.dispatch('manageUser_active');
    //             router.push('/');
    //           } else {
    //             router.push('/onboardingSocial');
    //           }
    //         }
    //       }
    //     } catch (e) {
    //       console.log(e);
    //       loading.value = false;
    //     }
    //   };

    //   const facebookLoginPopUp = window.open(
    //     `https://www.facebook.com/v9.0/dialog/oauth?&display=popup&client_id=${process.env.VUE_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_FACEBOOK_REDIRECT_URL}&response_type=token&scope=public_profile,email`,
    //     null,
    //     'width=600,height=400'
    //   );

    //   const popupCheck = setInterval(() => {
    //     if (!facebookLoginPopUp.closed) return;
    //     if (bc) bc.close();
    //     loading.value = false;
    //     clearInterval(popupCheck);
    //   }, 500);
    // }

    let isGoogleInit = ref(false);
    const $gAuth = gAuth.useGAuth()
    const checkGAuthLoadInterval = setInterval(() => {
      isGoogleInit.value = $gAuth.isInit;
      if (isGoogleInit.value) clearInterval(checkGAuthLoadInterval);
    }, 250);

    async function authenticateGoogle() {
      if (!isGoogleInit.value) {
        store.dispatch('notifications_create', { text: t(`notifications.cant-login-with-google`) });
        return;
      }

      loading.value = true;
      try {
        const authCode = await $gAuth.signIn();
        const gAuthResponse = authCode.getAuthResponse();
        const request = await store.dispatch('security_authenticateGoogle', { idToken: gAuthResponse.id_token });
        if (request.status == 200) {
          if (route.name == 'TRIP_REGISTER') {
            if (request.data.relatedUser) {
              persistentData.set('token', request.data.relatedUser.token);
              persistentData.set('tokenExpiration', request.data.relatedUser.tokenExpiration);
              persistentData.set('registrationReference', request.data.relatedUser.registrationReference);
              persistentData.set('language', request.data.relatedUser.countryCode);
              store.dispatch('manageUser_active');
              router.push('/gamesExternal/TRIP/trip');
            } else {
              store.commit('register_updateUserModelSocial', request.data);
            }
          } else if (route.name == 'IIVVO_REGISTER') {
            if (request.data.relatedUser) {
              persistentData.set('token', request.data.relatedUser.token);
              persistentData.set('tokenExpiration', request.data.relatedUser.tokenExpiration);
              persistentData.set('registrationReference', request.data.relatedUser.registrationReference);
              persistentData.set('language', request.data.relatedUser.countryCode);
              store.dispatch('manageUser_active');
              router.push('/gamesExternal/IIVVO/IIVVO');
            } else {
              store.commit('register_updateUserModelSocial', request.data);
            }            
          } else {
              if (request.data.relatedUser) {
                persistentData.set('token', request.data.relatedUser.token);
                persistentData.set('tokenExpiration', request.data.relatedUser.tokenExpiration);
                persistentData.set('registrationReference', request.data.relatedUser.registrationReference);
                persistentData.set('language', request.data.relatedUser.countryCode);
                store.dispatch('manageUser_active');
                if (request.data.relatedUser.registrationReference != null && request.data.relatedUser.registrationReference.toLowerCase() == 'trip') {
                  router.push('/gamesExternal/TRIP/trip');
                } else if (request.data.relatedUser.registrationReference == 'IIVVO') {
                  router.push('/gamesExternal/IIVVO/IIVVO');
                } else {
                  router.push('/');
                }
              } else {
                // store.commit('register_updateUserModelSocial', request.data);
                // router.push('/onboardingSocial');
                store.dispatch('notifications_create', { text: t(`notifications.userNotRegister`) });
              }
            }
        }
        loading.value = false;
      } catch (e) {
        console.log(e);
        loading.value = false;
      }
    }

    return {
      //authenticateFacebook,
      authenticateGoogle,
      loading,
      isGoogleInit
    }
  }
}
</script>
<style lang="scss" scoped>
  .social-register {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: $margin-milli;
    margin-bottom: $margin-tera;

    span {
      font-weight: 700;
    }

    // &__buttons {
    //   height: 36px;
    //   display: flex;
    //   gap: 20px;

    //   button {
    //     width: 36px;
    //     height: 36px;
    //     margin: 0;
    //     padding: 0;
    //   }
    // }

    button {
      display: flex;
      align-items: center;
      padding: $margin-base;
      gap: $margin-milli;

      img {
        width: 28px;
      }
    }
  }
</style>