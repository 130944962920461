<template>
  <div v-if="!completeLoad">Cargando...</div>

  <template v-else>
    <div v-if="gamesUnfinished" class="gamesUnfinished" >
      <i class="material-icons">error</i>
      <span>Debes completar los 3 tests de Genoma para poder ver tus resultados</span> 
    </div>
    <div v-else class="genoma-results" >
    <div v-if="personName != null" class="person-name">Resultados de {{ personName }}</div>
      <div class="test-results">
        <div class="test-results__title">RASGOS COGNITIVOS Y EMOCIONALES</div>
        <div class="test-results__description">
          <div>Los rasgos cognitivos y emocionales son características que describen la forma en que las personas procesan información para tener una comprensión del mundo que las rodea, así como la forma en que reconocen sus propias emociones y las de los demás para adaptarse a los entornos, respectivamente.</div> 
          <img src="@/assets/genoma_rasgos-cognitivos.png">
          </div>
        <template v-for="(score, idx) in scores" :key="idx">
          <template v-if="score.name == 'Disposición al riesgo' 
          || score.name == 'Habilidad para reconocer emociones'
          || score.name == 'Duración de la atención'
          || score.name == 'Control de la impulsividad'
          ">
            <div class="score">
              <div class="score__title">
                <span class="score__title-name">{{score.name}}</span>
                <span class="score__title-score">{{score.score}}%</span>
              </div>
              <input type="range" :value="score.score" class="slider" disabled>
              <div class="score__labels">
                <span class="score__labels-lowerBound">{{score.lowerBound}}</span>
                <span class="score__labels-upperBound">{{score.upperBound}}</span>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div class="test-results">
        <div class="test-results__title">HABILIDADES DE RAZONAMIENTO</div>
        <div class="test-results__description">
          <div>Estas habilidades tienen que ver con tu capacidad general para aprender, resolver problemas, entender instrucciones y trabajar con información. Debido a que todos somos distintos, en general cada persona tiene una de estas tres áreas más desarrollada que las otras.</div>
          <img src="@/assets/genoma_razonamiento.png">
        </div>
        <template v-for="(score, idx) in scores" :key="idx">
          <template v-if="score.name == 'Razonamiento verbal' 
          || score.name == 'Razonamiento lógico'
          || score.name == 'Razonamiento numérico'
          ">
            <div class="score">
              <div class="score__title">
                <span class="score__title-name">{{score.name}}</span>
                <span class="score__title-score">{{score.score}}%</span>
              </div>
              <input type="range" :value="score.score" class="slider" disabled>
              <div class="score__labels">
                <span class="score__labels-lowerBound">{{score.lowerBound}}</span>
                <span class="score__labels-upperBound">{{score.upperBound}}</span>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div class="test-results">
        <div class="test-results__title">PERFIL DE PERSONALIDAD</div>
        <div class="test-results__description">
          <div>La personalidad es el conjunto de características específicas que te diferencian de los demás en términos de tus pensamientos, sentimientos y comportamiento. Tu personalidad está definida por tu genética y por las experiencias que has tenido.</div>
          <img src="@/assets/genoma_personalidad.png">
        </div> 
        <template v-for="(score, idx) in scores" :key="idx">
          <template v-if="score.name == 'Apertura a la experiencia' 
          || score.name == 'Cordialidad'
          || score.name == 'Emocionalidad'
          || score.name == 'Escrupulosidad - Meticulosidad'
          || score.name == 'Extraversión'
          ">
            <div class="score">
              <div class="score__title">
                <span class="score__title-name">{{score.name}}</span>
                <span class="score__title-score">{{score.score}}%</span>
              </div>
              <input type="range" :value="score.score" class="slider" disabled>
              <div class="score__labels">
                <span class="score__labels-lowerBound">{{score.lowerBound}}</span>
                <span class="score__labels-upperBound">{{score.upperBound}}</span>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </template>

</template>

<script>
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
//import { useRouter } from 'vue-router';
import { ref } from '@vue/reactivity';
export default {
  setup(){
    const store = useStore();
    const route = useRoute();
    const scores = ref([]);
    const personName = ref(null);
    const gamesUnfinished = ref(false);
    const completeLoad = ref(false);
    //const router = useRouter();
    onMounted(async ()=>{
      if(route.name == 'SHARE_GENOMA_RESULTS'){
        //console.log(route.params.gameIdentification)
        let req = await store.dispatch('game_genomaResultByGameIdentification', {gameIdentification: route.params.gameIdentification});
        console.log(req)
        scores.value = req.result.scores;
        personName.value = req.name;
      }else{
        try {
          let results = await store.dispatch('game_genomaResults');
          scores.value = results.scores;
          gamesUnfinished.value = false;
        } catch (error) {
          if(error == 'User has not finished the game') gamesUnfinished.value = true;
          else store.dispatch('notifications_create', { text: "Ocurrió un error al cargar los resultados" });
        }
      }
      completeLoad.value = true;
    });
    return{
      scores,
      gamesUnfinished,
      completeLoad,
      personName
    } 

  }
}
</script>

<style lang="scss" scoped>
*{
  font-family: Lato, Roboto, "Open Sans";
}
.material-icons{
  font-family: 'Material Icons'!important;
}
.gamesUnfinished{
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  span{
    color: black;
    font-size: 18px;
  }
  i{
    font-size: 44px;
    color: #E25523;
  }
}
.genoma-results{
  width: 100%;
  padding: 0 16px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.test-results{
  width: 100%;
  max-width: 700px;
  padding: 24px 0;
  &__title{
    padding: 0 0 8px 0;
    margin: 0 0 8px 0;
    text-align: center;
    font-size: 16px;
    color: #ea4c89;
    font-weight: 600;
    border-bottom: 1px solid rgb(130, 130, 130,.2);
  }
  &__description{
    padding: 8px 0 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    div{
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 14px;
      color: #828282;
      font-weight: 400;
      text-align: justify;
    }
  }
}
.score{
  margin-bottom: 25px;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 5px;  
    background: #ffdaef;
    outline: none;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%; 
    background: #ea4c89;
  }

  input[type=range]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ea4c89;
  }
  div span{
    max-width: 40%;
  }
  &__title{
    display: flex;
    justify-content: space-between;
    &-name{
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      font-size: 15px;
    }
    &-score{
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      font-size: 15px;
      letter-spacing: .9px;
    }
  }
  &__labels{
    display: flex;
    justify-content: space-between;
    span{
      color: rgba(0, 0, 0, 0.65);
    }
    &-lowerBound{
      text-align: left;
    }
    &-upperBound{
      text-align: right;
    }
  }
}
.person-name {
  width: 100%;
  padding: 10px 0;
  position: sticky;
  top: 0;
  background-color: #fff;
  text-align: center;
  color: $gray;
  font-size: 22px;
}
</style>

