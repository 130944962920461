export default {
  state: () => ({
    userModel: {
      nickName: '',
      firstName: '',
      lastName: '',
      email: '',
      genderId: '',
      birthDate: '',
      residenceCountryId: '',
      password: '',
      passwordConfirm: '',
      gtm: 0
    },
    userModelSocial : {}
  }),
  getters: {
    register_userModel: state => state.userModel,
    register_userModelNickName: state => state.userModel.nickName,
    register_userModelSocial: state => state.userModelSocial
  },
  mutations: { 
    register_updateField(state, params) {
      state.userModel[params.field] = params.value;
    },
    register_updateUserModelSocial(state, model) {
      state.userModelSocial = model;
    }
  },
  actions: { 
  }
}