<template>
  <div class="profile__skills">
    <div class="container">
      <div class="profile__grid">
        <article class="profile__skills__card" v-for="i in [0,1,2]" :key="i">
          <div class="profile__skills__card__img" style="background-image:url('https://via.placeholder.com/120');">
          </div>
          <div class="profile__skills__card__title">
            Lorem
          </div>
          <div class="profile__skills__card__time">
            Tiempo aproximado: 10/15 min.
          </div>
          <div class="profile__skills__card__status">
            <div class="Pending"></div>
            <span>Pending</span>  
          </div>
          <p class="profile__skills__card__subtitle">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis, consectetur culpa.
          </p>
          <button class="profile__card__button">iniciar</button>
        </article>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
  .profile__skills {
    min-height: 100vh;
    background: url('~@/assets/profile-bg.svg'), linear-gradient(180deg, #E87B1B 0%, #FFC905 100%);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 1270px, auto;
    padding-bottom: 96px;
    padding-top: $margin-kilo;

    .container {
      padding: 0 $margin-base;

      @media (min-width: $break-sm) {
        padding: 0;
      }
    }

    &__card {
      background: $white;
      border-radius: $margin-basedo;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      flex-shrink: 0;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-top: 74px;
      padding: 0 $margin-base $margin-kilo;

      &__img {
        width: 148px;
        height: 148px;
        border: 3px solid $red;
        box-shadow: -3px 4px 0px rgba(20, 30, 55, 0.25);
        border-radius: 100%;
        margin-top: -74px;
        align-self: center;
        background-size: cover;
        background-position: center center;
      }

      &__title {
        font-family: $font-opensans;
        @include font-settings(kilo,base);
        color: $red;
        font-weight: 700;
        margin-top: $margin-base;
        text-transform: uppercase;
      }

      &__time {
        color: $gray;
        font-family: $font-palanquin;
        @include font-settings(milli,base);
        font-weight: 700;
        padding-bottom: $margin-milli;
      }
      
      &__status {
        margin: $margin-milli 0;
        display: flex;

        div {
          width: 12px;
          height: 12px; 
          border-radius: 50%;
          margin-right: $margin-milli;

          &.Pending {
            background: $red;
          }

          &.Completed {
            background: $green;
          }

          &.InProgress {
            background: $yellow;
          }
        }

        span {
          color: $gray;
          text-transform: uppercase;
          font-family: $font-palanquin;
          @include font-settings(milli,base);
          font-weight: 700;
        }
      }

      p {
        font-family: $font-opensans;
        text-shadow: none;
        @include font-settings(milli,base);
        color: $gray;
        font-weight: 400;
        text-align: left;
        padding: $margin-milli 0 $margin-mega;
        margin-bottom: 0;
      }
    }
  }
</style>