<template>
<div class="login">
    <div class="container">
      <div class="login__container">
        <div class="login__circle">
          <img src="@/assets/passport.svg" alt="">
        </div>
        <h3>{{ $t("register.step.second.title") }}</h3>
        <p>{{ $t("register.step.second.description") }}</p>
        <select v-model="genderId" :class="{'input-invalid': v$.genderId.$invalid && submitted}">
          <option disabled selected value="">{{ $t('register.step.second.input_gender') }}</option>
          <option v-for="g in listGender" :key="g.id" :value="g.id">{{ g.name }}</option>
        </select>
        <span class="input-message" v-if="v$.genderId.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.genderId.required.$message}}</span>

        <input type="date" :placeholder="$t('register.step.second.input_birthDate')" autocomplete="off" v-model="birthDate" :class="{'input-invalid': v$.birthDate.$invalid && submitted}"> 
        <span class="input-message" v-if="v$.birthDate.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.birthDate.required.$message}}</span>
        <span class="input-message" v-if="v$.birthDate.validBirthDate.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.birthDate.validBirthDate.$message}}</span>

        <select v-model="residenceCountryId" :class="{'input-invalid': v$.residenceCountryId.$invalid && submitted}">
          <option disabled selected value="">{{ $t('register.step.second.input_nationality') }}</option>  
          <option v-for="n in listCountries" :key="n.id" :value="n.id">{{ n.name }}</option>
        </select>
        <span class="input-message" v-if="v$.residenceCountryId.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.residenceCountryId.required.$message}}</span>

        <button @click="continueRegister" class="button button--red button--register">{{ $t("register.next") }}</button>
        <small>* Debes tener entre 18 y 25 años para registrarte</small>
      </div>
    </div>
  </div> 
  <BulletsRegister :active="2"/>
</template>
<script>
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { computed, onMounted, ref } from 'vue';
  import BulletsRegister from '@/components/Register/BulletsRegister';
  import useVuelidate from '@vuelidate/core';
  import { required, helpers } from '@vuelidate/validators';

  import moment from 'moment';

  function validBirthDate(birthDate) {
    let diffDates = moment().diff(moment(birthDate), 'years');
    return diffDates <= 25 && diffDates >= 18;
  }

  export default {
    components: {
      BulletsRegister
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const submitted = ref(false);

      const genderId = computed({
        get: () => store.state.Register.userModel.genderId,
        set: (value) => store.commit('register_updateField', {field: 'genderId', value})
      });
      const birthDate = computed({
        get: () => store.state.Register.userModel.birthDate,
        set: (value) => store.commit('register_updateField', {field: 'birthDate', value})
      });
      const residenceCountryId = computed({
        get: () => store.state.Register.userModel.residenceCountryId,
        set: (value) => store.commit('register_updateField', {field: 'residenceCountryId', value})
      });

      const listCountries = ref([]);
      const listGender = ref([]);
      onMounted(async () => {
        listGender.value = await store.dispatch('stuff_gender');
        listCountries.value = await store.dispatch('stuff_countries');
      });
      
      function continueRegister() {
        submitted.value = true;
        if (genderId.value !== '' && birthDate.value !== '' && residenceCountryId.value !== '') {
          submitted.value = false;
          router.push('/register/third');
        }
      }

      return {
        genderId,
        listGender,
        listCountries,
        birthDate,
        residenceCountryId,
        continueRegister,
        submitted,
        v$: useVuelidate()
      }
    },
    validations () {
      return {
        genderId: { 
          required: helpers.withMessage('Este campo es requerido', required)
        },
        birthDate: { 
          required: helpers.withMessage('Este campo es requerido', required), 
          validBirthDate: helpers.withMessage('Tu edad debe ser entre 18 y 25 años', validBirthDate) 
        },
        residenceCountryId: { 
          required: helpers.withMessage('Este campo es requerido', required)
        },
      }
    }
  }
</script>