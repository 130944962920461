<template>
  <div class="profile__skill-header">
    <img src="@/assets/trip_bg.jpg" alt="">
  </div>
  <div class="profile__skill-detail">
    <div class="container main-container">
      <div class="profile__skill-detail__container">
        <div class="profile__skill-detail__header">
          <div class="profile__skill-detail__header__image" style="background-image: url('https://via.placeholder.com/148')"></div> 
          <div class="profile__skill-detail__header__content">
            <span class="profile__skill-detail__header__title">TRIP</span>
            <span class="profile__skill-detail__header__status">FINALIZADO</span>
          </div>
        </div>
        <div class="profile__skill-detail__grid">
          <section class="profile__skill-detail__section profile__skill-detail__section--intro">
            <h4>Autopercepción</h4>
            <p>“Me considero una persona sociable, reflexiva, racional, atenta y cauta”</p>
          </section>
          <div class="profile__skill-detail__section--container profile__skill-detail__section--first">
            <section class="profile__skill-detail__section">
              <h4>TENDENCIAS COMPORTAMENTALES</h4>
              <div class="profile__skill-detail__progress">
                <div class="profile__skill-detail__progress__icon">
                  <img src="@/assets/icon-home.svg" alt="">
                  <span>innovador</span>
                </div>
                <div class="profile__skill-detail__progress__progress-bar">
                  <div class="profile__skill-detail__progress__progress-bar__line"></div>
                  <div class="profile__skill-detail__progress__progress-bar__circle" style="left: 60%"></div>
                </div>
                <div class="profile__skill-detail__progress__icon">
                  <img src="@/assets/icon-home.svg" alt="">
                  <span>apego a normas</span>
                </div>
              </div>
              <p>Juan tiende a ser una <b>persona espontánea</b>, es decir que trabaja con <b>dinamismo y agilidad</b>. Prefiere aventurarse a lo desconocido, a los <b>cambios y desafíos</b>. No es demasiado analítico ni reflexivo sino que prueba y se apoya en el ensayo y el error.</p>
            </section>
            <section class="profile__skill-detail__section">
              <div class="profile__skill-detail__progress">
                <div class="profile__skill-detail__progress__icon">
                  <img src="@/assets/icon-home.svg" alt="">
                  <span>innovador</span>
                </div>
                <div class="profile__skill-detail__progress__progress-bar">
                  <div class="profile__skill-detail__progress__progress-bar__line"></div>
                  <div class="profile__skill-detail__progress__progress-bar__circle" style="left: 60%"></div>
                </div>
                <div class="profile__skill-detail__progress__icon">
                  <img src="@/assets/icon-home.svg" alt="">
                  <span>apego a normas</span>
                </div>
              </div>
              <p>Juan tiende a ser una <b>persona espontánea</b>, es decir que trabaja con <b>dinamismo y agilidad</b>. Prefiere aventurarse a lo desconocido, a los <b>cambios y desafíos</b>. No es demasiado analítico ni reflexivo sino que prueba y se apoya en el ensayo y el error.</p>
            </section>
            <section class="profile__skill-detail__section">
              <div class="profile__skill-detail__progress">
                <div class="profile__skill-detail__progress__icon">
                  <img src="@/assets/icon-home.svg" alt="">
                  <span>innovador</span>
                </div>
                <div class="profile__skill-detail__progress__progress-bar">
                  <div class="profile__skill-detail__progress__progress-bar__line"></div>
                  <div class="profile__skill-detail__progress__progress-bar__circle" style="left: 60%"></div>
                </div>
                <div class="profile__skill-detail__progress__icon">
                  <img src="@/assets/icon-home.svg" alt="">
                  <span>apego a normas</span>
                </div>
              </div>
              <p>Juan tiende a ser una <b>persona espontánea</b>, es decir que trabaja con <b>dinamismo y agilidad</b>. Prefiere aventurarse a lo desconocido, a los <b>cambios y desafíos</b>. No es demasiado analítico ni reflexivo sino que prueba y se apoya en el ensayo y el error.</p>
            </section>
          </div>
          <div class="profile__skill-detail__section--container profile__skill-detail__section--second">
            <section class="profile__skill-detail__section">
              <h4>CARACTERÍSTICAS DE LA PERSONALIDAD</h4>
              <div class="profile__skill-detail__section__row">
                <img src="@/assets/person-test.svg" alt="">
                <div>
                  <span class="big">Extrovertido</span>
                  <span class="big">Resolutivo</span>
                  <span class="medium">Innovador</span>
                  <span class="medium">Ágil</span>
                  <span class="medium">Práctico</span>
                  <span>Creativo</span>
                  <span>Conciliador</span>
                  <span>Sentido común</span>
                </div>
              </div>
            </section>
            <section class="profile__skill-detail__section">
              <h4>Habilidades tecnicas</h4>
              <div class="profile__skill-detail__section__row profile__skill-detail__section__row--simple">
                <img src="@/assets/person-test.svg" alt="">
                <div>
                  <h5>Prueba de atención y concentración</h5>
                  <p>Velocidad: Alto<br>
                  Eficiencia: Medio</p>
                  <h5>Rendimiento: ALTO</h5>
                </div>
              </div>
            </section>
             <section class="profile__skill-detail__section">
              <div class="profile__skill-detail__section__row profile__skill-detail__section__row--simple">
                <img src="@/assets/person-test.svg" alt="">
                <div>
                  <h5>Prueba de lógica</h5>
                  <p>Flexibilidad cognitiva para aprender y adaptarse a situaciones cambiantes-complejas bajo presión</p>
                  <h5>Rendimiento: ALTO</h5>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>

section {
  padding: $margin-mega $margin-base !important;
}

  .profile__skill-header {
    width: 100%;
    max-width: 1216px;
    margin: 0 auto;

    img {
      object-fit: cover;
      width: 100%;
      height: 296px;
    }
    
    @media (min-width: $break-md) {
      margin: $margin-mega auto;

      img {
        height: 336px;
        border-radius: 20px;
      }
    }
  }

  .profile__skill-detail {
    min-height: 100vh;
    // background: url('~@/assets/profile-bg.svg'), linear-gradient(180deg, #E87B1B 0%, #FFC905 100%);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: auto 1270px, auto;
    padding-bottom: 96px;
    // padding-top: $margin-kilo;

    .container {
      padding: 0 $margin-base;

      @media (min-width: $break-sm) {
        padding: 0;
      }
    }

    &__container {
      background: $white;
      border-radius: 20px;
      padding: $margin-kilo;

      @media (min-width: $break-md) {
        margin-top: 48px;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: $margin-base;
      section{
        padding: $margin-mega $margin-base ;
      }
      @media (min-width: $break-md) {
        grid-template-columns: 1fr 1fr;
        gap: $margin-mega;
        grid-template-areas: "intro intro"
        "first second";
      }
    }

    &__progress {
      display: flex;
      flex-direction: row;
      align-items: center;
      // height: 80px;
      gap: $margin-kilo;
      margin: $margin-base 0 $margin-milli;

      &__icon {
        width: 64px;
        flex-shrink: 0;
        text-align: center;

        span {
          color: $red;
          text-transform: uppercase;
          font-family: $font-palanquin;
          font-weight: 700;
          @include font-settings(xmilli,base);
          display: block;
        }
      }

      &__progress-bar {
        flex-grow: 1;
        position: relative;

        &__line {
          height: 2px;
          width: 100%;
          background: black;
          position: absolute;
          top: calc(50% - 2px);
        }

        &__circle {
          width: $margin-base;
          height: $margin-base;
          border-radius: 50%;
          background: $red;
          position: absolute;
          top: calc(50% - 8px);
          left: 50%;
        }
      }
    }

    &__section {
      padding: $margin-kilo 0;
      border-bottom: 2px solid $lightestofgrays;

      @media (min-width: $break-md) {

        &--intro {
          grid-area: intro;
        }

        &--first {
          grid-area: first;
        }

        &--second {
          grid-area: second;
        }
      }

      h4 {
        color: $gray;
        @include font-settings(kilo, base);
        font-weight: 700;
        font-family: $font-palanquin;
        margin: 0;
        text-transform: uppercase;
      }

      h5 {
        color: $gray;
        @include font-settings(base, base);
        font-weight: 700;
        font-family: $font-palanquin;
        margin: 0;
      }

      p {
        color: $gray;
        font-family: $font-opensans;
        @include font-settings(base, base);
        padding: $margin-milli 0;
        text-shadow: none;
        text-align: left;
      }

      &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: $margin-base;
        margin-top: $margin-base;

        @media (min-width: $break-sm) {
          gap: 0;
          justify-content: space-evenly;
        }

        &--simple {
          justify-content: flex-start;

          img {
            width: 96px!important;
          }

          @media (min-width: $break-sm) {
            gap: $margin-kilo;
          }
        }

        img {
          width: 120px;
          flex-shrink: 0;
        }

        span {
          color: $gray;
          @include font-settings(milli,base);
          display: block;

          &.big {
            @include font-settings(giga,base);
            font-weight: 700
          }

          &.medium {
            @include font-settings(kilo,base);
          }
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      padding: $margin-kilo 0;
      border-bottom: 2px solid $lightestofgrays;

      @media (min-width: $break-md) {
        gap: $margin-base;
        flex-direction: column;
        text-align: center;
        margin-top: -166px;

        &__content {
          margin-left: 0!important;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        margin-left: $margin-base;
      }

      &__image {
        width: 64px;
        height: 64px;
        background: $white;
        border-radius: 50%;
        border: 3px solid $white;
        box-shadow: 0 0 0 6px $green;
        flex-shrink: 0;
        background-size: cover;
        background-position: center center;

        @media (min-width: $break-md) {
          width: 148px;
          height: 148px;
        }
      }

      &__title {
        color: $orange;
        @include font-settings(kilo, base);
        font-weight: 700;
        font-family: $font-palanquin;
      }

      &__status {
        font-family: $font-palanquin;
        @include font-settings(milli, base);
        font-weight: 700;
        color: $gray;
      }
    }
  }
</style>