<template>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <div class="login__circle">
          <img src="@/assets/chatbot.svg" alt="">
        </div>
        <h3>{{ $t("register.step.termsAndConds.title") }}</h3>
        <p v-html="$t('register.step.termsAndConds.description')"></p>
        
        <button @click="confirmRegister" :disabled="awaitRequest" class="button button--red button--register">{{ awaitRequest ? $t("register.step.termsAndConds.confirmDisabled") : $t("register.step.termsAndConds.confirm") }}</button>
      </div>
    </div>
  </div> 
  <BulletsRegister :active="4"/>
</template>
<script>
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import BulletsRegister from '@/components/Register/BulletsRegister';

  export default {
    components: {
      BulletsRegister
    },
    setup() {
      const router = useRouter();
      const store = useStore();
      let awaitRequest = ref(false);

      const { t } = useI18n();

      async function confirmRegister() {
        if (awaitRequest.value) return;
        awaitRequest.value = true;
        try {
          const userModel = {...store.getters.register_userModel}
          await store.dispatch('security_userCreate', userModel);
          awaitRequest.value = false;
          router.push('/register/activate');
        } catch (e) {
          awaitRequest.value = false;
          store.dispatch('notifications_create', { text: t(`notifications.${e.response.data}`) });
        }
      }

      return {
        confirmRegister,
        awaitRequest
      };
    }
  }
</script>

<style lang="scss" scoped>
  .login__circle {
    width: 148px;
    height: 148px;

    img {
      width: 100%;
    }
  }
</style>