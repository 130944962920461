<template>
  <router-link :to="`/learning/courses/${course.type}/${course.id}`">
    <article class="course__card-small">
      <div class="course__card-small__img" :style="`background-image: url(${course.picture})`" :alt="course.name">
      </div>
      <div class="course__card-small__content">
        <div class="course__card-small__title">
          {{course.name}}
        </div>
        <div class="course__card-small__vendor">{{course.vendor}}</div>
        <!-- <span>LOREM IPSUM</span> -->
      </div>
    </article>
  </router-link>
</template>
<script>
export default {
  props: {
    course: Object
  }
}
</script>
<style lang="scss" scoped>
  .course__card-small {
    background: $white;
    border-radius: $margin-basedo;
    // width: 160px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;

    &__img {
      width: 100%;
      height: 0;
      padding-bottom: 56.5%;
      background-size: cover;
      background-position: center center;
    }

    &__content {
      margin: $margin-base $margin-kilo $margin-kilo;
    }

    &__title {
      font-family: $font-opensans;
      text-align: left;
      @include font-settings(kilo,base);
      color: $yellow;
      font-weight: 700;
      margin: 0 0 $margin-base 0;
      // padding: 0 $margin-kilo;
      margin-bottom: 4px;
      height: 44px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }

    &__vendor {
      font-family: $font-palanquin;
      @include font-settings(xmilli,base);
      text-transform: uppercase;
      color: $gray;
      // padding-left: $margin-kilo;
      font-weight: 400;
      margin-bottom: $margin-milli;

      @media (min-width: $break-sm) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 220px;
      }
    }

    span {
      font-family: $font-palanquin;
      @include font-settings(milli,base);
      // padding-left: $margin-kilo;
      font-weight: 400;
      color: $lightgray;
      margin-bottom: $margin-base;
    }

    &__included {
      // padding: 0 0 $margin-kilo;

      img {
        width: $margin-kilo;
        margin-right: 4px;
      }
    }
  }
</style>