<template>
  <div class="tooltip">
    <i class="material-icons">{{ icon ? icon : 'help'}}</i>
    <div class="tooltip__text" v-html="text" :style="{ left: left, top: top }"></div>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    icon: String,
    left: String,
    top: String,
  }
}
</script>
<style lang="scss">
.tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  
  &__text {
    visibility: hidden;
    width: 240px;
    background-color: $gray;
    color: $white !important;
    text-align: left;
    padding: $margin-small;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
    top: -8px;
    left: 30px;
    @include font-settings(xmilli,base);
    text-transform: none;
    font-family: $font-opensans!important;
    font-size: 12px;
    font-weight: 400;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   width: 0; 
    //   height: 0; 
    //   border-left: $margin-milli solid transparent;
    //   border-right: $margin-milli solid transparent;
    //   border-bottom: $margin-small solid $gray;
    //   top: -$margin-small;
    //   right: $margin-basedo;
    // }
  }
  
  &:hover &__text {
    visibility: visible;
  }
}
</style>