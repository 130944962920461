<template>
  <div class="redirect-facebook">
    <img src="@/assets/logo.svg" alt="" width="200">
    <h3>Ingresando ...</h3>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { BroadcastChannel } from 'broadcast-channel';

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    
    function parseHash(hash) {
      return hash.split('&').reduce((res, param) => {
        // divide el parametro en dos partes
        const [firstParamSplit, secondParamSplit] = param.split('=');
        let firstParam = firstParamSplit;
        const response = res;
        // si el parametro empieza con #, lo saca de la string
        if (firstParam.startsWith('#')) firstParam = firstParam.substring(1);
        // arma el conjunto key:value para añadir al objeto resultante
        response[firstParam] = secondParamSplit;
        return response;
      }, {});
    }
    
    onMounted(() => {
      store.dispatch('manageUser_disabled');
      const bc = new BroadcastChannel('login');
      if (router.currentRoute.value.query.error) {
        const {error, errorCode, errorDescription, errorReason} = router.currentRoute.query;
        bc.postMessage({error, errorCode, errorDescription, errorReason});
      } else {
        const { access_token: accessToken } = parseHash(router.currentRoute.value.hash);
        bc.postMessage({ accessToken });
      }
  
      setTimeout(() => {
        bc.close();
        window.close();
      }, 500);
    })
  },
  beforeRouteLeave(to, from, next) {
    next(false);
  }
}
</script>

<style lang="scss" scoped>
  .redirect-facebook {
    min-height: 100vh;
    background: linear-gradient(180deg, #04A190, #80D8D1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
