<template>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <h3>{{ $t('register.step.confirm.title') }}</h3>
        <p>{{ $t("register.step.confirm.description") }}</p>  
        <input type="text" autocomplete="off" v-model="code" :placeholder="$t('register.step.confirm.input_code')" :class="{'input-invalid': v$.code.$invalid && submitted}">
        <span class="input-message" v-if="v$.code.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.code.required.$message}}</span>
        
        <button @click="confirmCode" class="button button--red button--register" :disabled="awaitRequest">{{ awaitRequest ? $t("register.step.confirm.actionDisabled") : $t("register.step.confirm.action") }}</button>  
      </div>
    </div>
  </div> 
</template>
<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import persistentData from '@/plugins/persistentData';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

export default {
  setup() {
    const code = ref('');
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const submitted = ref(false);
    let awaitRequest = ref(false);


    async function confirmCode () {
      submitted.value = true;
      awaitRequest.value = true;
      if (code.value != '') {
        try {
          const request = await store.dispatch('security_userActivate', code.value);
          if (request.status === 200) {
            persistentData.set('token', request.data.token);
            persistentData.set('tokenExpiration', request.data.tokenExpiration);
            persistentData.set('registrationReference', request.data.registrationReference);
            persistentData.set('language', request.data.countryCode);
            store.dispatch('manageUser_active');
            submitted.value = false;
            awaitRequest.value = false;
            router.push('/');
          }
        } catch (e) {
          submitted.value = false;
          awaitRequest.value = false;
          store.dispatch('notifications_create', { text: t(`notifications.${e.response.data}`) });
        }
      } else {
        awaitRequest.value = false;
      }
    }

    return {
      code,
      confirmCode,
      submitted,
      awaitRequest,
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      code: { 
        required: helpers.withMessage('Este campo es requerido', required)
      }
    }
  }
}
</script>