<template>
  <div class="container">
    <InputSearch @updateSearchValue="searchFilter" />
    <div class="work__grid">
      <h4 class="work__grid__results-number">{{$t("work.jobOffers.results")}} ({{qtyJobOffers}})</h4>
      <!-- <div class="work__grid__filters">
        <h4>Filters</h4>
      </div> -->
      <div class="work__grid__results" v-if="completeLoad">
        <template v-for="(job, idx) in allJobOffers" :key="idx">
          <article class="work__result-card" @click="goDetail(job.id)">
            <div class="work__result-card__image" :style="`background-image: url('${getImage(job.organizationImage, job.isPictureFromCDN)}')`"></div>
            <div class="work__result-card__content">
              <span>{{getDiffDates(job.publishDate)}}</span>
              <h3>{{job.title}}</h3>
              <h4>{{job.organizationName}}</h4>
              <div class="work__result-card__content__details">
                <span>{{job.location}}</span>
                <span>{{job.schedule}}</span> 
              </div>
            </div>
            <div class="work__result-card__star" @click.stop="toggleWishlist(idx, job.wish, job.id)">
              <i class="material-icons" :class="{'star__active': job.wish}">{{job.wish ? 'star' : 'star_outline'}}</i>
            </div>
          </article>
        </template>
      </div>
    </div>
    <div v-if="!completeLoad" class="loading-points"><span>.</span><span>.</span><span>.</span></div>
    
  </div>
</template>
<script>
import InputSearch from '@/components/Common/InputSearch.vue';
import { onMounted, ref, computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import loader from '@/components/SvgAnimated/JSON/loader.json';

export default {
  components: {
    InputSearch,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    let allJobOffers = ref([]);
    let qtyJobOffers = ref(0);
    const jobsWishlist = computed(() => store.getters.profile_jobsWishlist);
    const completeLoad = ref(false);

    onMounted(async () => {
      searchFilter()
    });

    async function searchFilter(filter = '') {
      try {
        const requestLearningItems = await store.dispatch('organization_jobSearches', {filter, pageNumber: 0, rowsPerPage: 20});
        allJobOffers.value = requestLearningItems.data;
        qtyJobOffers.value = requestLearningItems.totalResults;
        completeLoad.value = true;        
      } catch (error) {
        store.dispatch('notifications_create', { text: t(`notifications.errorGeneric`) });
      }
    }

    function getImage(path, isFromCDN) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      } else {
        return path ? `${process.env.VUE_APP_FORGE_ORGANIZATION_MEDIA_URL}${path}` : null;
      }
    }

    function getDiffDates(publishDate) {
      const diff = moment(moment()).diff(publishDate, 'days');
      let label = '';
      switch (diff) {
        case 0:
          label = t('work.diffDates.today');
          break;
        case 1:
          label = t('work.diffDates.yesterday');
          break;
        default:
          label = t('work.diffDates.days', {diff});
          break;
      }
      return label;
    }

    async function toggleWishlist(idx, active, id) {
      const force =  await store.dispatch('manageUser_forceLogin');
      if (force) return;
      if (jobsWishlist.value.includes(id)) return;

      store.commit('profile_addJobWishlist', id);
      if (active) {
        await store.dispatch('organization_wishes_remove', id);
        store.dispatch('notifications_create', { text: t(`notifications.wishes_remove`) });
      } else {
        await store.dispatch('organization_wishes_add', id);
        store.dispatch('notifications_create', { text: t(`notifications.wishes_add`) });
      }
      allJobOffers.value[idx].wish = !active;
    }

    function goDetail(id) {
      router.push(`/work/jobOffers/${id}`);
    }

    return {
      allJobOffers,
      qtyJobOffers,
      searchFilter,
      getDiffDates,
      toggleWishlist,
      goDetail,
      getImage,
      completeLoad,
      loader
    }
  }
}
</script>
<style lang="scss" scoped>
  .work__grid {
      padding: 0 $margin-base;

      @media (min-width: $break-sm) {
        padding: 0;
      }

      @media (min-width: $break-md) {
        display: grid;
        grid-template-columns: .7fr 2fr;
        grid-template-rows: auto;
        gap: $margin-base;
        // grid-template-areas: "filters results"
        // "filters cards";
        grid-template-areas: "results results"
        "cards cards";
      }
    }

    .work__grid__results-number {
      grid-area: results;
      color: $white;
      margin-bottom: $margin-base;

      @media (min-width: $break-md) {
        margin-bottom: 0;
      }
    }

    .work__grid__filters {
      background: $blue;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: $margin-base $margin-base $margin-kilo;
      color: $white;
      display: none;
      grid-area: filters;

      @media (min-width: $break-md) {
        display: block;
      }
    }

    .work__grid__results {
      display: grid;
      gap: $margin-base;
      grid-area: cards;

      @media (min-width: $break-md) {
        grid-template-columns: 1fr 1fr;
      }
    } 

    .work__result-card__star {
      cursor: pointer;

      .star__active {
        color: $yellow;
      }
    }

/* Loader cargando... */
.loading-points {
  //display: inline-block;
  padding-top: 24px;
  text-align: center;
  color: #fff;
  span {
    font-size: 46px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    &:nth-child(2) {
        animation-delay: .2s;
    }
    &:nth-child(3) {
        animation-delay: .4s;
    }
  }
  @keyframes blink {
    0% {
    opacity: .2;
    }
    20% {
    opacity: 1;
    }
    100% {
    opacity: .2;
    }
  }
}

</style>