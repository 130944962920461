<template>

  <div class="profile-card--first">
    <BannerPercentage />

    <!-- <div class="profile__card profile-card--personal-data">
      <div class="profile__card__header profile__card__header--space">
        <div class="profile__card__header__title">
          <i class="material-icons">person</i>
          MIS DATOS PERSONALES
        </div>
        <i class="material-icons profile__card__header__arrow" v-if="!personalEdit" @click="personalEdit = !personalEdit">edit</i>
        <i class="material-icons profile__card__header__arrow" v-else @click="updatePersonalInformation">check</i>
      </div>
      <div class="profile__card--personal" v-if="!personalEdit">
        <div class="profile__card--personal__image" :style="`background-image: url(${getImage(personalInformation.picture) || require('@/assets/placeholder.png')})`"></div> 
        <div class="profile__card--personal__nickname">
          <span>Apodo</span>
          <strong>{{personalInformation.nickName || '-'}}</strong>
        </div>
        <div class="profile__card--personal__firstname">
          <span>Nombre/s</span>
          <strong>{{personalInformation.firstName || '-'}}</strong>
        </div>
        <div class="profile__card--personal__lastname">
          <span>Apellido/s</span>
          <strong>{{personalInformation.lastName || '-'}}</strong>
        </div>
        <div class="profile__card--personal__email">
          <span>Email</span>
          <strong>{{personalInformation.email || '-'}}</strong>
        </div>
      </div>
      <div class="profile__card--personal" v-else>
        <input type="file" @change="uploadImageProfile($event)">
        <div class="profile__card--personal__nickname">
          <span>Apodo</span>
          <input type="text" placeholder="Apodo" v-model="personalInformationModel.nickName" :class="{'input-invalid': v$.personalInformationModel.nickName.$invalid && submitted}">
        </div>
        <div class="profile__card--personal__firstname">
          <span>Nombre/s</span>
          <input type="text" placeholder="Nombre/s" v-model="personalInformationModel.firstName" :class="{'input-invalid': v$.personalInformationModel.firstName.$invalid && submitted}">
        </div>
        <div class="profile__card--personal__lastname">
          <span>Apellido/s</span>
          <input type="text" placeholder="Apellido/s" v-model="personalInformationModel.lastName" :class="{'input-invalid': v$.personalInformationModel.lastName.$invalid && submitted}">
        </div>
        <div class="profile__card--personal__email">
          <span>Email</span>
          <input type="email" placeholder="Email" v-model="personalInformationModel.email" :class="{'input-invalid': v$.personalInformationModel.email.$invalid && submitted}">
        </div>
      </div>
    </div> -->
  </div>

  <!-- Mis habilidades  -->
  <div class="profile__card profile-card--abilities">
    <div class="profile__card__header">
      <i class="material-icons">person</i>
      {{ $t("profile.resume.mySkills") }}
    </div>
    <div class="profile__card--skills">
      <template v-for="game in games" :key="game.code">
        <!-- TRIP -->
        <template v-if="game.code.indexOf('TRIP') == 0">
          <div class="skill__card">
            <div class="skill__card__image" :style="`background-image: url(${game.picture})`"></div> 
            <div class="skill__card__content">
              <span class="skill__card__title">{{game.name}}</span>
              <span v-if="levelProfile > 3" class="skill__card__status" :class="'skill__card__status--' + game.status"><span class="dot"></span>{{ $t(`home.skills.game.${game.status}`) }}</span>
              <span class="skill__card__description">{{game.description}}</span>
              <span class="skill__card__description skill__card__description--alert" v-if="game.status != 'Completed' && levelProfile > 3">Debes completar el desafío para postularte a un empleo</span>
              <small v-if="game.vendor != null" class="skill__card__vendor">{{ $t("profile.resume.poweredBy") }} {{game.vendor}}</small>
              <div class="skill__card__buttons" v-if="levelProfile > 3">
                <span class="skill__card__button" v-if="game.status !== 'Completed'" @click.stop="goToGame(game.code)">{{ $t("profile.resume.takeTest") }}</span>
                <span class="skill__card__button" v-if="game.status == 'Completed'" @click.stop="goToGame(game.code)">{{ $t("profile.resume.viewResults") }}</span>
                <span class="skill__card__button" v-if="game.status == 'Completed'" @click.stop="copyResultLink(game.code)">Copiar link</span>
              </div>
              <div class="skill__card__buttons avaliable" v-else>
                {{ $t("profile.available_from") }} <strong>NIVEL 4</strong>
              </div>
            </div>
          </div>
        </template>
        <!-- Resto de los juegos -->
        <template v-else>
          <div class="skill__card" v-if="game.code != 'GENOMAVIDEO'">
            <div class="skill__card__image" :style="`background-image: url(${game.picture})`"></div> 
            <div class="skill__card__content">
              <span class="skill__card__title">{{game.name}}</span>
              <span v-if="levelProfile > 4" class="skill__card__status" :class="'skill__card__status--' + game.status"><span class="dot"></span>{{ $t(`home.skills.game.${game.status}`) }}</span>
              <span class="skill__card__description">{{game.description}}</span>
              <small v-if="game.vendor!=null" class="skill__card__vendor">{{ $t("profile.resume.poweredBy") }} {{game.vendor}}</small>
              <div class="skill__card__buttons" v-if="levelProfile > 4">
                <span class="skill__card__button" v-if="game.status !== 'Completed'" @click.stop="goToGame(game.code)">{{ $t("profile.resume.takeTest") }}</span>
                <span class="skill__card__button" v-if="game.status == 'Completed' && game.code.indexOf('GENOMA') == 0" @click.stop="goToResultGenoma()">{{ $t("profile.resume.viewResults") }}</span>
                <span class="skill__card__button" v-if="game.status == 'Completed'" @click.stop="copyResultLink(game.code)">Copiar link</span>
              </div>
              <div class="skill__card__buttons avaliable" v-else>
                {{ $t("profile.available_from") }} <strong>NIVEL 5</strong>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>

    <div class="test-forge" v-if="testForge != null && Object.keys(testForge).length > 0 && levelProfile > 4">
      <span class="skill__card__title">{{$t(`profile.resume.testForge.name`)}}</span>
      <div class="test-forge__skills">
        <template v-for="(score,skill) in testForge" :key="skill">
          <div class="test-forge__skill">
            <span class="test-forge__skill__name">{{$t(`profile.resume.testForge.${skill}`)}}:</span>
            <div class="stars__container">
              <div class="stars__not-painted">
                <i class="material-icons" v-for="i in 5" :key="i">star</i>
              </div>
              <div class="stars__painted" :style="{ width: `${score}%` }">
                <i class="material-icons" v-for="i in 5" :key="i">star</i>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>

<div class="profile__grid__column">

  <!-- Mis certificados  -->
  <div class="profile__card" v-if="modelCertificates.length > 0">
    <div class="profile__card__header profile__card__header--space">
      <div class="profile__card__header__title">
        <i class="material-icons">person</i>
        {{ $t("profile.resume.myCertifications") }}
      </div>
    </div>
    <div class="profile__card__content">
      <div class="certificate__card" v-for="(certificate, index) in modelCertificates" :key="index">
        
        <div class="certificate__card__content">
          <div class="certificate__card__link">
            <a :href="certificate.externalLink == '' ? getImage(certificate.fileUrl) : certificate.externalLink" target="_blank">
              <i class="material-icons">link</i>
              <span>Ver certificado</span>
            </a>
          </div>
          <span class="certificate__card__title">{{certificate.description}}</span>
          <span class="certificate__card__date">{{formatDate(certificate.date, "DD/MM/yyyy")}}</span>
        </div>
        <!-- <div class="certificate__card__btns">
              <i class="material-icons" @click="editMode(index)">edit</i>
              <i class="material-icons" @click="modelModalRemove = {idx: index, name: certificate.description, list: 'Mis certificados'}; modalRemove = true;">delete</i>
        </div> -->
      </div>
    </div>
    <!-- <div class="btn-add_container">
      <button @click="modalCertificate = true" class="btn-add">Agregar<i class="material-icons">add</i></button>
    </div> -->
  </div>

  <!-- Progreso en aprender  -->
  <div class="profile__card">
    <template v-if="levelProfile > 4">
      <router-link to="/profile/progressLearning">
        <div class="profile__card__header profile__card__header--space">
          <div class="profile__card__header__title">
            <i class="material-icons">person</i>
            Mi progreso en aprender
          </div>
            <i class="material-icons profile__card__header__arrow">navigate_next</i>
        </div>
      </router-link>
      <div class="profile__card__content">
        {{ $t("profile.resume.labelLearningProgress") }}
      </div>
    </template>
    <template v-else>
       <div class="profile__card__header profile__card__header--space">
          <div class="profile__card__header__title">
            <i class="material-icons">person</i>
            Mi progreso en aprender
          </div>
        </div>
      <div class="skill__card__buttons avaliable">
        {{ $t("profile.available_from") }} <strong>NIVEL 5</strong>
      </div>
    </template>
  </div>

  <!-- Video entrevistas GENOMA -->
  <div class="profile__card profile-card">
    <div class="profile__card__header">
      <i class="material-icons">person</i>
      {{ $t("profile.resume.videoInterview") }}
    </div>
    <div class="profile__card--skills">
      <template v-for="game in games" :key="game.code">
        <div class="skill__card" v-if="game.code == 'GENOMAVIDEO'" style="border:none">       
            <div class="skill__card__image" :style="`background-image: url(${game.picture})`"></div> 
            <div class="skill__card__content">
              <span class="skill__card__title">{{game.name}}</span>
              <span v-if="levelProfile > 3" class="skill__card__status" :class="'skill__card__status--' + game.status"><span class="dot"></span>{{ $t(`home.skills.game.${game.status}`) }}</span>
              <span class="skill__card__description">{{game.description}}</span>
              <small class="skill__card__vendor">{{ $t("profile.resume.poweredBy") }} {{game.vendor}}</small>
              <template v-if="levelProfile > 3">
                <span class="skill__card__button" v-if="game.status !== 'Completed'" @click="modalVideoInstructions=true" style="cursor:pointer;">Realizar Entrevista</span>
                <span class="skill__card__button" v-if="game.linkResult !==null && game.linkResult !==''">
                  <a :href="game.linkResult" target="_blank">Ver mi entrevista</a> 
                </span>
                <div class="skill__card__showVideo" v-if="game.linkResult !==null && game.linkResult !==''">
                  <input type="checkbox" name="showVideo" v-model="showGenomaVideo" @click="modalShowVideo()">
                  <label for="showVideo">Permitir que las empresas vean mi video entrevista</label>
                </div>
              </template>
              <div class="skill__card__buttons avaliable" v-else>
                {{ $t("profile.available_from") }} <strong>NIVEL 4</strong>
              </div>
            </div>        
        </div>
      </template>
    </div>
  </div>
</div>

  <!-- Modal showVideo -->
  <div v-if="modalShowVideoActive">
    <VisibleModal/>
    <div class="modal__overlay">
      <div class="modal" @click.stop="''">
        <span v-if="showGenomaVideo" class="modalShowVideo__text">¿Deseas que las empresas puedan ver tu video entrevista?</span>
        <span v-else class="modalShowVideo__text">Las empresas ya no podrán ver tu video entrevista!</span>
        <div class="modal__buttons">
          <button @click.stop="modalShowVideoActive = false; showGenomaVideo=!showGenomaVideo;" class="button--gray">{{ $t("profile.my_best_version.cancel") }}</button>
          <button @click.stop="modalShowVideoActive = false; updateGenomaVideoVisibility()">Aceptar</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal video instrucciones entrevista -->
  <div class="modal__overlay" v-if="modalVideoInstructions" @click.stop="modalVideoInstructions = false;">
      <VisibleModal />
        <div class="modal modal--video">
          <div class="modal__responsive">
            <iframe src="https://www.youtube.com/embed/RnxMZMezwe0"></iframe>
          </div>
          <div class="modal__buttons">
            <button @click.stop="modalVideoInstructions = false;">{{ $t("profile.my_best_version.cancel") }}</button>
            <button @click.stop="modalVideoInstructions = false; goToGame('GENOMAVIDEO');">Realizar entrevista</button>
          </div>
        </div>
    </div>


  <!-- Referencias  -->
  <!-- <div class="profile__card">
    <div class="profile__card__header profile__card__header--space">
      <div class="profile__card__header__title">
        <i class="material-icons">person</i>
        {{ $t("profile.resume.references") }}
      </div>
      <i class="material-icons profile__card__header__arrow">navigate_next</i>
    </div>
    <div class="profile__card__content">
      {{ $t("profile.resume.labelReferences") }}
    </div>
  </div> -->

  <!-- Modal Add Certificate -->
  <!-- <div v-if="modalCertificate">
    <VisibleModal/>
    <div class="modal__overlay" @click.stop="modalCertificate = false">
      <div class="modal modalCertificate" @click.stop="''">
        <div class="modal__header">
          diplomas y certificados
        </div>
        <div class="modalCertificate__description">{{ $t("profile.resume.modalCertificatesDescription") }}</div>
        <div @change="uploadCertificate($event)" v-if="createCertificateModel.externalLink == ''">
          <span v-if="loadingCertificate">Cargando archivo...</span>
          <div v-if="createCertificateModel.fileUrl != ''" class="modalCertificate__link">
            <a :href="getImage(createCertificateModel.fileUrl)" target="_blank">
              <i class="material-icons">link</i>
              <span>Archivo adjunto</span>
            </a>
            <span @click="createCertificateModel.fileUrl = ''; hasCertificateUpload = false" >
              <i class="material-icons">clear</i>
            </span>
          </div>
          <div class="uploadCertificate_container" v-if="!loadingCertificate && !hasCertificateUpload && createCertificateModel.fileUrl == ''">
            <span>Adjuntar</span>
            <label for="upload-certificate" :class="{'file_invalid': v$.createCertificateModel.fileUrl.$invalid && submitted}">+</label>
            <input type="file" name="certificate" id="upload-certificate">
          </div>
        </div>
        <div class="description">
          <span>Link</span>
          <input type="text" v-model="createCertificateModel.externalLink" :disabled="createCertificateModel.fileUrl != ''" :class="{'input-invalid': v$.createCertificateModel.externalLink.$invalid && submitted}">

          <span class="modalCertificate__label-description">Descripción</span>
          <input type="text" maxlength="50" v-model="createCertificateModel.description" :class="{'input-invalid': v$.createCertificateModel.description.$invalid && submitted}">

          <span>Fecha de emisión</span>
          <input type="date" v-model="createCertificateModel.date" :class="{'input-invalid': v$.createCertificateModel.date.$invalid && submitted}">
        </div>
        <div class="modal__buttons">
          <button class="button--gray" @click="modalCertificate = false ; cleanModel()">{{ $t("profile.my_best_version.cancel") }}</button>
          <button @click="updateCertificates">{{ $t("profile.finish") }}</button>
        </div>
      </div>
    </div>
  </div> -->

  <!-- modalRemove -->
  <!-- <div v-if="modalRemove">
    <VisibleModal/>
    <div class="modal__overlay" @click.stop="modalRemove = false; modelModalRemove = {idx: '', name: '', list: ''}">
      <div class="modal" @click.stop="''">
        <span class="material-icons" @click.stop="modalRemove = false; modelModalRemove = {idx: '', name: '', list: ''}">close</span>
        <p>¿{{ $t("profile.my_best_version.modalDelete") }} <strong>{{modelModalRemove.name}}</strong> de {{ modelModalRemove.list }}?</p>
        <div class="modal__buttons">
          <button @click.stop="modalRemove = false; modelModalRemove = {idx: '', name: '', list: ''}" class="button--gray">{{ $t("profile.my_best_version.cancel") }}</button>
          <button @click.stop="removeModal()">{{ $t("profile.my_best_version.accept") }}</button>
        </div>
      </div>
    </div>
  </div> -->

</template>
<script>
import BannerPercentage from '@/components/Profile/BannerPercentage.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import storage from "@/plugins/azure-storage";
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import moment from 'moment';
import VisibleModal from '@/components/Common/VisibleModal.vue';
import { onMounted } from '@vue/runtime-core';
import persistentData from '@/plugins/persistentData';

export default {
  components: {
    BannerPercentage,
    VisibleModal
  },
  setup() {
    const { t } = useI18n();
    const v$ = useVuelidate();
    const store = useStore();
    const router = useRouter();
    const certificates = [...store.getters.profile_certificates];
    const personalInformation = {...store.getters.profile_personalInformation};
    const modelCertificates = ref(certificates);
    const games = [...store.getters.profile_games];
    const testForge = {...store.getters.profile_testForge};
    let personalEdit = ref(false);
    let personalInformationModel = ref(personalInformation);
    let submitted = ref(false);
    // let modalCertificate = ref(false);
    // let createCertificateModel = ref({
    //     description: '',
    //     date: '',
    //     fileUrl: '',
    //     externalLink: ''
    // });
    const modalRemove = ref(false);
    const modelModalRemove = ref({idx: '', name: '', list: ''});
    let editModeActive = ref(false);
    // let editingCertificateIndex = ref(null);
    // let loadingCertificate = ref(false);
    let hasCertificateUpload = ref(false);
    const showGenomaVideo = ref(false);
    const modalShowVideoActive = ref(false);
    const modalVideoInstructions = ref(false);
    const levelProfile = ref(5);

    onMounted(()=>{
      levelProfile.value = persistentData.get("forgeLevel", true);
      let videoInterview = games.find(game => game.code == 'GENOMAVIDEO');
      showGenomaVideo.value = videoInterview.showVideo;
    })

    function getImage(path) {
      return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
    }

    function goToGame(code) {
      router.push(`/gamesExternal/${code}`);
    }

    function goToResultGenoma(){
      router.push(`/gamesExternal/genomaResults`);
    }

    async function updatePersonalInformation() {
      submitted.value = true;
      if (!v$.value.personalInformationModel.$invalid) {
        try {
          await store.dispatch('profile_updatePersonalInformation', personalInformationModel.value);
          await store.dispatch('profile_profile');
          store.dispatch('notifications_create', { text: t(`notifications.updatePersonalInformation`) });
          submitted.value = false;
          personalEdit.value = false;
        } catch (error) {
          console.log(error);
        }
      }
    }

    async function uploadImageProfile(event) {
      const file = event.target.files[0]
      if(!file) {
        return false
      }
      const imageName = await storage.updateFile(file)
      personalInformationModel.value.picture = imageName;
      await store.dispatch('profile_updatePersonalInformation', personalInformationModel.value);
      store.dispatch('notifications_create', { text: t(`notifications.updatePersonalInformationPicture`) });
    }

    // async function uploadCertificate(event) {
    //   loadingCertificate.value = true;
    //   const certificate = event.target.files[0];
    //   if(!certificate) {
    //     return false
    //   }
    //   const certificateUrl = await storage.updateFile(certificate);
    //   loadingCertificate.value = false;
    //   createCertificateModel.value.fileUrl = certificateUrl;
    //   hasCertificateUpload.value = true;
    // }

    // function editMode(index){
    //   editingCertificateIndex.value = index;
    //   editModeActive.value = true;
    //   createCertificateModel.value.description = modelCertificates.value[index].description;
    //   createCertificateModel.value.date = formatDate(modelCertificates.value[index].date, 'YYYY-MM-DD');
    //   createCertificateModel.value.fileUrl = modelCertificates.value[index].fileUrl;
    //   createCertificateModel.value.externalLink = modelCertificates.value[index].externalLink;
    //   modalCertificate.value = true;
    // }


    // async function updateCertificates() {
    //   submitted.value = true;
    //   if (v$.value.createCertificateModel.$invalid){
    //     store.dispatch('notifications_create', { text: 'Completa todos los campos requeridos' });
    //     return;
    //   } 
    //   modalCertificate.value = false;
    //   if(!editModeActive.value) modelCertificates.value.push(createCertificateModel.value);
    //   else{
    //     modelCertificates.value[editingCertificateIndex.value].description =  createCertificateModel.value.description;
    //     modelCertificates.value[editingCertificateIndex.value].date =  createCertificateModel.value.date;
    //     modelCertificates.value[editingCertificateIndex.value].fileUrl =  createCertificateModel.value.fileUrl;
    //     modelCertificates.value[editingCertificateIndex.value].externalLink =  createCertificateModel.value.externalLink;
    //   }
    //   try{
    //     await store.dispatch('profile_updateCertificates', modelCertificates.value);
    //     if(editModeActive.value) store.dispatch('notifications_create', { text: "Se modificó correctamente el certificado" });
    //     else store.dispatch('notifications_create', { text: "Se agregó correctamente el certificado" });
    //     cleanModel();
    //   }
    //   catch(err){
    //     console.log(err);
    //     store.dispatch('notifications_create', { text: "Ocurrió un error" });
    //   }
    // }

    // async function removeModal(){
    //   modelCertificates.value.splice(modelModalRemove.value.idx, 1);
    //   await store.dispatch('profile_updateCertificates', modelCertificates.value);
    //   store.dispatch('notifications_create', { text: `Se eliminó ${modelModalRemove.value.name} de ${modelModalRemove.value.list}` });
    //   modelModalRemove.value = {idx: '', name: '', list: ''};
    //   modalRemove.value = false;
    // }

    // function cleanModel() {
    //   createCertificateModel.value = {
    //     description: '',
    //     date: '',
    //     fileUrl: '',
    //     externalLink: ''
    //   };
    //   hasCertificateUpload.value = false;
    //   loadingCertificate.value = false;
    //   editModeActive.value = false;
    //   editingCertificateIndex.value = false;
    //   submitted.value = false;
    // }

    function formatDate (date, format) { return moment(date).format(format) }

    function modalShowVideo(){
      modalShowVideoActive.value = true;
    }
    async function updateGenomaVideoVisibility(){
      store.dispatch('profile_updateVideoPreferences',{showVideo:showGenomaVideo.value});
    }

    async function copyResultLink(game){
      let code;
      game.includes('GENOMA') ? code = 'genoma' : code = game;
      try {
        let req = await store.dispatch('game_getLinkToShare',{gameCode: code});
        let el = document.createElement('textarea');
        el.value = req.data.link;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        store.dispatch('notifications_create', { text: 'Link copiado' });
      } catch (error) {
        if(error.response.data == 'USER_NOT_FINISHED_GAME') store.dispatch('notifications_create', { text: 'Debes completar los 3 test de Genoma para acceder a los resultados' });
        else store.dispatch('notifications_create', { text: 'Ocurrió un error al copiar el link' });
      }
    }

    return {
      personalInformation,
      personalInformationModel,
      games,
      getImage,
      goToGame,
      personalEdit,
      updatePersonalInformation,
      uploadImageProfile,
      submitted,
      v$,
      // modalCertificate,
      modelCertificates,
      // createCertificateModel,
      // uploadCertificate,
      hasCertificateUpload,
      // updateCertificates,
      // cleanModel,
      formatDate,
      // editMode,
      // loadingCertificate,
      editModeActive,
      modelModalRemove,
      modalRemove,
      // removeModal,
      showGenomaVideo,
      modalShowVideo,
      modalShowVideoActive,
      updateGenomaVideoVisibility,
      modalVideoInstructions,
      goToResultGenoma,
      testForge,
      copyResultLink,
      levelProfile
    };
  },
  validations () {
    return {
      personalInformationModel: {
        nickName: { required },
        firstName: { required },
        lastName: { required },
        email: { required, email }
      },
      // createCertificateModel: {
      //   externalLink: { requiredIfRef: requiredIf(this.createCertificateModel.fileUrl == '') },
      //   fileUrl: { requiredIfRef: requiredIf(this.createCertificateModel.externalLink == '') },
      //   description: { required },
      //   date: { required }
      // }
    }
  }
}
</script>
<style lang="scss">

  @mixin btn-addMore{
    background: $orange;
    border-radius: 100px;
    font-family: $font-palanquin;
    @include font-settings(base, mega);
    color: $white;
    padding: $margin-milli $margin-kilo;
    font-weight: 700;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    display: inline-flex;
    cursor: pointer;
  }

  .btns{
    display: flex;
    align-self: flex-start;
    color: $orange;
  }

  .modal {
    overflow: visible;
    h4, &__buttons button {
      color: $orange;
    }
    .modalShowVideo__text{
      color: $gray;
      font-weight: bold;
      font-size: 17px;
    }
  }

  .modalCertificate{
    span{
      color:black;
      font-size: 16px;
    }
    &__description{
      margin-bottom: 16px;
      color: $mediumgray !important;
      text-align: left !important;
      font-size: 15px;
    }
    &__label-description{
      margin-top: 32px !important;
    }
    
    .uploadCertificate_container{
      margin-top: 8px;;
      width: 100%;
      span{
        display:block;
        color: #7D7D7D;
        text-align: left;
      }
      label{
        margin-top: 6px;
        margin-bottom: -12px;
        display: block;
        border: 2px dashed #bdbdbd;
        font-size: 2rem;
        font-weight: bold;
        color: #bdbdbd;
        cursor: pointer;
        &.file_invalid{
          border: 2px dashed tomato;
          color: tomato;
        }
      }
      #upload-certificate{
        visibility: hidden;
      }
    }
    &__link{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      .material-icons{
        position: static;
        color: #333;
      }
      a{
        display: flex;
        align-items: center;
        gap: 3px;
        .material-icons{
          position: static;
          color: blue;
          font-weight: 300;
        }
        span{
          color: blue;
          text-decoration: underline;
        }
      }
    }
    .modal__buttons{
      button:disabled{
        opacity: .5;
        cursor: auto;
      }
    }
    .description{
      span{
        display: block;
        width: 100%;
        margin: 2px;
        color: #7D7D7D;
        text-align: left;
      }
      input{
        width: 100%;
        height: 30px;
        margin: 2px;
        outline: thick;
      }
    }
  }

  .banner--grid {
    display: none;

    @media (min-width: $break-md) {
      display: flex;
      justify-content: center;
    }
  }

  .profile-card--first {

    @media (min-width: $break-md) {
      gap: $margin-kilo;
      display: grid;
    }

    @media (min-width: $break-lg) {
      grid-area: first;
    }
  }

  .profile__card {

    .btn-add_container{
      display: flex;
      justify-content: center;
      margin-top: 16px;
      button {
        @include btn-addMore
      }
      button:disabled{
        opacity: .5;
        cursor: auto;
      }
    }
    
    &.profile-card--personal-data {
      background: $white url('~@/assets/world-map.svg') center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &--personal {
      display: grid;
      grid-template-columns: 80px 1fr 1fr;
      grid-template-areas: "image nickname nickname"
      "image firstname lastname"
      "email email email";
      gap: $margin-base;
      margin-top: $margin-base;

      &__nickname {
        grid-area: nickname;
      }

      &__firstname {
        grid-area: firstname;
        display: grid!important;
      }

      &__lastname {
        grid-area: lastname;
        display: grid!important;
      }

      &__email {
        grid-area: email;
      }

      &__image {
        width: 76px;
        height: 76px;
        background: $white;
        border-radius: 50%;
        border: 3px solid $orange;
        flex-shrink: 0;
        background-size: cover;
        background-position: center center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        grid-area: image;
      }

      div {
        display: flex;
        flex-direction: column;

        span {
          color: $gray;
        }
        strong {
          color: $orange;
          font-family: $font-palanquin;
          @include font-settings(kilo, base);
        }
      }

      input {
        color: $litedarkgray;
        font-family: $font-palanquin;
        @include font-settings(kilo, base);
        border: none;
        background: none;
        font-weight: 700;
        width: 100%;
        border-bottom: 1px solid $gray;

        &::placeholder {
          color: $graybg;
        }

        &[type="file"] {
          width: 76px;
          height: 76px;
          background: $orange;
          border-radius: 50%;
          border: 3px solid $orange;
          flex-shrink: 0;
          background-size: cover;
          background-position: center center;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
          grid-area: image;
          cursor: pointer;

          &:before {
            font-family: "Material Icons";
            content: "\e412";
            font-size: 32px;
            display: flex;
            color: $white;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 100%;
            width: 100%;
          }

          &::file-selector-button {
            opacity: 0;
          }
        }
      }
    }

    &.profile-card--abilities {

      @media (min-width: $break-lg) {
        grid-area: second;
      }
    }

    .skill__card {
      display: flex;
      align-items: center;
      padding: $margin-kilo 0;
      border-bottom: 2px solid $lightestofgrays;
      &__content {
        display: flex;
        flex-direction: column;
        margin-left: $margin-base;
      }

      &__image {
        width: 64px;
        height: 64px;
        background: $white;
        border-radius: 50%;
        border: 3px solid $orange;
        flex-shrink: 0;
        background-size: cover;
        background-position: center center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      }
      &__link{
        a{
          display: flex;
          align-items: center;
          gap: 3px;
          cursor: pointer;
        }
        span{
          color: blue;
        }
      }
      &__btns{
        display: flex;
        align-self: flex-start;
        color: $orange;
      }
      &__title {
        color: $orange;
        @include font-settings(kilo, base);
        font-weight: 700;
        font-family: $font-palanquin;
        text-transform: uppercase;
      }
      &__status {
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: $font-palanquin;
        @include font-settings(milli, base);
        font-weight: 700;
        .dot {
          height: 12px;
          width: 12px;
          border-radius: 50%;
        }
        &--Completed{
          color: $darkgreen;
          .dot{
            background-color: $darkgreen;
          }
        }
        &--InProgress{
          color: $yellow;
          .dot{
            background-color: $yellow; 
          }
        }
        &--Pending{
          color: $lightgray;
          .dot{
            background-color: $lightgray;
          }
        }
      }
      &__description {
        @include font-settings(milli, base);
        font-weight: bold;
        color: $gray;
        margin-top: $margin-milli;
        &--alert {
          color: $red; 
        }
      }
      &__vendor {
        @include font-settings(milli, base);
        font-weight: bold;
        color: $graybg;
        margin-top: $margin-milli;
      }
      &__buttons{
        display: flex;
        gap: 10%;
        //justify-content: space-between;

        &.avaliable {
          gap: 8px;
          margin-top: 4px;
          strong {
            color: $orange;
          }
        }
      }
      
      &__button {
        @include font-settings(milli, base);
        font-weight: bold;
        color: $orange;
        margin-top: $margin-milli;
        text-transform: uppercase;
        cursor: pointer;
      }
      &__showVideo{
        padding: 10px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        border: 2px solid $orange;
        border-radius: 5px;
        color: $gray;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

.certificate__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $margin-kilo 0;
  border-bottom: 2px solid $lightestofgrays;
  &:last-child {
    border-bottom: none;
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-left: $margin-base;
  }
  &__btns{
    display: flex;
    align-self: flex-start;
    color: $orange;
    gap: 5px;
    i{
      cursor: pointer;
    }
  }
  &__link{
    a{
      display: flex;
      align-items: center;
      gap: 3px;
      cursor: pointer;
    }
    span{
      color: $gray;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  &__title {
    color: $orange;
    @include font-settings(kilo, base);
    font-weight: 700;
    font-family: $font-palanquin;
    text-transform: uppercase;
  }
  &__date {
    @include font-settings(milli, base);
    font-weight: bold;
    color: $gray;
  }
}
.modal--video {
  padding: 8px !important;
  padding-bottom: 13px!important;
  max-width: 720px;
  padding: $margin-base;

  .modal__responsive {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    border-radius: $margin-milli;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .modal__buttons{
    padding-top: 16px;
    gap: 10px;
    button{
      padding: 0;
      width: 160px;
      height: 60px;
      border-radius: 50px;
      line-height: 20px;
      font-weight: 500;
      &:first-child{
        background-color: $lightestofgrays;
        color: $orange;
        border: 1px solid $lightgray;
      }
      &:last-child{
        background-color: $orange;
        color: $white;
      }
    }
  }
}

.test-forge{
  padding: 24px 4%;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  &__skills{
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
  }
  &__skill{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    &__name{
      font-family: Palanquin Dark;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: $mediumgray;
    }
    .stars__container{
      position: relative;
      width: 100px;
      height: 24px;
      .stars__not-painted{
        position: absolute;
        width: 100%;    
        display: flex;
        color: #bdbdbd;
        z-index: 1;
      }
      .stars__painted{
        position: absolute;
        display: flex;
        overflow-x: hidden;
        z-index: 2;
        color: #FFC905;
      }
      .stars__not-painted i, .stars__painted i {
        margin: 0 -2px;
      }
    }
  }
}


</style>