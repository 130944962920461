export default {
  state: () => ({
    profile: {
      courses: [],
    },
    jobWishlist: []
  }),
  getters: {
    profile_bannerPercentage: state => {
      let testInProgress = 0;
      state.profile.games.forEach(game => {
        if(game.status === 'InProgress') testInProgress ++;
      });

      return {
        completionPercentage: state.profile.completionPercentage,
        testInProgress
      }
    },
    profile_personalInformation: state => {
      return {
        ...state.profile.personalInformation,
        ...state.profile.contact,
        ...state.profile.location
      }
    },
    profile_games: state => state.profile.games,
    profile_study: state => state.profile.study,
    profile_aboutMe: state => state.profile.aboutMe,
    profile_jobs: state => state.profile.jobs,
    profile_certificates: state => state.profile.certificates || [], 
    profile_courses: state => state.profile.courses,
    profile_testForge: state => state.profile.aptitudeScore,
    profile_jobsWishlist: state => state.jobWishlist
  },
  mutations: {
    profile_addActivities(state,model){
      state.profile.aboutMe.activities = model;
    },
    profile_addExternalCourses(state,model){
      state.profile.aboutMe.externalCourses = model;
    },
    profile_addVolunteerings(state,model){
      state.profile.aboutMe.volunteerings = model;
    },
    profile_updateProfile(state, profile) {
      state.profile = profile;
    },
    profile_addStudyAcademic(state, model) {
      state.profile.study.academics.push(model);
    },
    profile_addStudyLanguages(state, model) {
      state.profile.study.languages.push(model);
    },
    profile_addJob(state, model) {
      state.profile.jobs.push(model);
    },
    profile_removeJob(state, index) {
      state.profile.jobs.splice(index, 1);
    },
    profile_removeStudy(state, model) {
      const position = state.profile.study[model.list].findIndex(item => item.id == model.id);
      if (position > -1) state.profile.study[model.list].splice(position,1);
    },
    profile_updateLearningProgress(state, model){
      state.profile.courses = model;
    },
    profile_addJobWishlist(state, id) {
      state.jobWishlist.push(id);
      setTimeout(() => {
        const index = state.jobWishlist.indexOf(id);
        state.jobWishlist.splice(index, 1);
      }, 2000)
    }
  },
  actions: { 
  }
}