<template>
  <router-link :to="`/learning/routes/${learning.id}`">
    <article class="learning__card-small">
      <div class="learning__card-small__img" :style="`background-image: url('${learning.picture}')`" :alt="learning.name"></div>
      <div class="learning__card-small__content">
        <div class="learning__card-small__title">
          {{learning.name}}
        </div>
        <p class="learning__card-small__subtitle">
          {{learning.description}}
        </p>
        <span>{{$t("learning_card.courses_include", {count: learning.items, plural: learning.items > 1 ? 's' : ''})}}</span>
      </div>
    </article>
  </router-link>
</template>
<script>
export default {
  props: {
    learning: Object
  }
}
</script>
<style lang="scss" scoped>
  .learning__card-small {
    background: $white;
    border-radius: $margin-basedo;
    // width: 160px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;

    &__img {
      width: 100%;
      height: 0;
      padding-bottom: 56.5%;
      background-size: cover;
      background-position: center center;
    }

    &__content {
      margin: $margin-base $margin-kilo $margin-kilo;
    }

    &__title {
      font-family: $font-opensans;
      text-align: left;
      @include font-settings(kilo,base);
      color: $green;
      font-weight: 700;
      // margin: $margin-base 0;
      padding: 0 0 $margin-milli;
      margin-bottom: 4px;
      height: 44px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }

    span {
      font-family: $font-palanquin;
      @include font-settings(xmilli,base);
      text-transform: uppercase;
      color: $gray;
      // padding-left: $margin-kilo;
      font-weight: 400;
      color: $lightgray;
    }
    
    p.learning__card-small__subtitle {
      font-family: $font-opensans;
      text-align: left;
      text-shadow: none;
      @include font-settings(milli,base);
      color: $gray;
      font-weight: 400;
      padding: $margin-milli 0 0;      
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      margin-bottom: $margin-kilo;
      height: 62px;
    }
  }
</style>