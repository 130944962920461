<template>
  <div class="banner banner--orange banner--profile" :class="view">
    <div class="banner--profile__percentage">
      <h3>¡Sigue completando tu perfil!</h3>
      <h1>{{bannerPercentage.completionPercentage}}%</h1>
    </div>
    <div class="banner__progress-bar">
      <div class="banner__progress-bar__completition" :style="{'width': `${bannerPercentage.completionPercentage}%`}"></div>
    </div>
    <span>Tienes {{bannerPercentage.testInProgress}} tests en progreso</span>
  </div>
</template>
<script>
import { useStore } from 'vuex';

export default {
  props: {
    view: {
      type: String,
      default: 'desktop'
    }
  },
  setup() {
    const store = useStore();
    const bannerPercentage = {...store.getters.profile_bannerPercentage}

    return {
      bannerPercentage
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  &.mobile {
    @media (min-width: $break-md) {
      display: none;
    }
  }

  &.desktop {
    @media (max-width: $break-md) {
      display: none;
    }
  }

  &--profile {
    flex-direction: column;
    align-items: flex-start;

    &__percentage {
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: $margin-base;
      @media screen and (max-width: 340px){
        flex-direction: column;
        gap: 0;
      }
    }
  }

  &__progress-bar {
    height: 32px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
    border-radius: $margin-base;
    width: 100%;
    position: relative;
    overflow: hidden;

    &__completition {
      height: 32px;
      border-radius: $margin-base;
      // width: 30%;
      left: 0;
      top: 0;
      background: linear-gradient(182.32deg, #E25523 -0.15%, #E87B1B 102.14%);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background: linear-gradient(269.89deg, #ED9F14 1.57%, #E87B1B 102%);
        height: 7px;
        border-radius: 8px;
        width: calc(100% - 32px);
        left: $margin-base;
        top: $margin-milli;
      }

      &:after {
        content: '';
        position: absolute;
        background: $white;
        height: 7px;
        border-radius: 8px;
        width: 7px;
        left: $margin-base;
        top: $margin-milli;
      }
    }
  }
}
</style>