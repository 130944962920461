<template>
  <div class="container">
    <InputSearch @updateSearchValue="searchFilter" />

    <div v-if="levelProfile < 5" class="available-level-5" style="margin: 16px 0 24px 0">
      <i class="material-icons">error</i>
      <span>Podrás acceder a cualquiera de las rutas de aprendizaje a partir del <b>NIVEL 5</b></span> 
    </div>

    <div v-if="!filterActive">
      <h3>{{ $t("learning.routes.news") }}</h3>
      <div class="carousel">
        <LearningCard v-for="route in newsRoutes" :key="route.id" :learning="route"/>
      </div>
    </div>
    <div>
      <h3>{{ $t("learning.routes.all") }}</h3>
      <div class="learning__routes__container">
        <LearningCardSmall v-for="route in allRoutes" :key="route.id" :learning="route"/>
      </div>
      <div class="learning__courses__viewMore">
        <button class="button button--outline-white" @click="loadMoreRoute" v-if="(pageNumber + 1) * 20 < qtyRoute">{{ $t("learning.routes.view_more") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import InputSearch from '@/components/Common/InputSearch.vue';
import LearningCard from '@/components/Learning/Card.vue';
import LearningCardSmall from '@/components/Learning/CardSmall.vue';
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';
import persistentData from '@/plugins/persistentData';

export default {
  components: { InputSearch, LearningCard, LearningCardSmall },
  setup() {
    const store = useStore();
    const levelProfile = persistentData.get("forgeLevel", true);
    let newsRoutes = ref([]);
    let allRoutes = ref([]);
    let filterActive = ref(false);
    let qtyRoute = ref(0);
    let filterString = "";
    let pageNumber = ref(0);

    onMounted(async () => {
      newsRoutes.value = await store.dispatch('learning_learningRoutes_neewer', {limit: 4});
      searchFilter();
    });

    async function searchFilter(filter = '') {
      if (filter != filterString) pageNumber.value = 0;
      const requestLearningRoutes = await store.dispatch('learning_learningRoutes', {filter, pageNumber: pageNumber.value, rowsPerPage: 20});
      filter !== '' ? filterActive.value = true : filterActive.value = false;
      if (filter != filterString) {
        allRoutes.value = [...requestLearningRoutes.data];
      } else {
        allRoutes.value.push(...requestLearningRoutes.data);
      }
      qtyRoute.value = requestLearningRoutes.totalResults;
      filterString = filter;
    }

    async function loadMoreRoute() {
      pageNumber.value += 1;
      searchFilter(filterActive.value ? filterString : '')
    }

    return {
      levelProfile,
      newsRoutes,
      allRoutes,
      searchFilter,
      filterActive,
      loadMoreRoute,
      qtyRoute,
      pageNumber
    }
  }
}
</script>
<style lang="scss">
  .learning__routes__container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 16px;
    padding: 0 $margin-base;

    a {
      display: contents;
    }

    @media (min-width: $break-sm) {
      grid-template-columns: 1fr 1fr;
      padding: 0;
    }

    @media (min-width: $break-md) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: $break-lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  .learning__courses__viewMore {
    display: flex;
    justify-content: center;
    margin: $margin-kilo 0;
    button {
      height: 42px;
      padding: 0 $margin-mega;
      display: flex;
      align-items: center;
    }
  }
</style>