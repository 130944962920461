<template>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <h1>{{ $t("register.title") }}</h1>

        <template v-if="!showRegisterSocial">
          <SocialRegister text="complete"/>

          <input type="text" :placeholder="$t('register.step.first.input_firstName')" autocomplete="off" v-model="model.firstName" :class="{'input-invalid': v$.model.firstName.$invalid && submitted}">
          <span class="input-message" v-if="v$.model.firstName.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.firstName.required.$message}} </span>

          <input type="text" :placeholder="$t('register.step.first.input_lastName')" autocomplete="off" v-model="model.lastName" :class="{'input-invalid': v$.model.lastName.$invalid && submitted}">
          <span class="input-message" v-if="v$.model.lastName.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.lastName.required.$message}} </span>

          <input type="email" :placeholder="$t('register.step.first.input_email')" autocomplete="off" v-model="model.email" :class="{'input-invalid': v$.model.email.$invalid && submitted}">
          <span class="input-message" v-if="v$.model.email.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.email.required.$message}} </span>
          <span class="input-message" v-if="v$.model.email.email.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.email.email.$message}}</span>

          <select v-model="model.residenceCountryId" :class="{'input-invalid': v$.model.residenceCountryId.$invalid && submitted}">
            <option disabled selected value="">{{ $t('register.step.second.input_nationality') }}</option>  
            <option v-for="n in listCountries" :key="n.id" :value="n.id">{{ n.name }}</option>
          </select>
          <span class="input-message" v-if="v$.model.residenceCountryId.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.residenceCountryId.required.$message}} </span>


          <div class="password_container">
            <input :type="passwordType" :placeholder="$t('register.step.third.input_password')" autocomplete="off" v-model="model.password" :class="{'input-invalid': v$.model.password.$invalid && submitted}">
            <i v-if="passwordType == 'password'" class="material-icons" @click.stop="passwordType = 'text'">visibility</i>
            <i v-else class="material-icons" @click.stop="passwordType = 'password'">visibility_off</i>
          </div>
          <span class="input-message" v-if="v$.model.password.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.password.required.$message}} </span>
          <span class="input-message" v-if="v$.model.password.validPassword.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.password.validPassword.$message}} </span>

          <div class="password_container">
            <input :type="passwordType" :placeholder="$t('register.step.third.input_repeatPassword')" autocomplete="off" v-model="model.passwordConfirm" :class="{'input-invalid': v$.model.passwordConfirm.$invalid && submitted}">
            <!-- <i v-if="passwordType == 'password'" class="material-icons" @click.stop="passwordType = 'text'">visibility</i>
            <i v-else class="material-icons" @click.stop="passwordType = 'password'">visibility_off</i> -->          
          </div>
          <span class="input-message" v-if="v$.model.passwordConfirm.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.passwordConfirm.required.$message}} </span>
          <span class="input-message" v-if="v$.model.passwordConfirm.sameAsPassword.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.model.passwordConfirm.sameAsPassword.$message}} </span>

          <small v-html="$t('register.step.third.description')"></small>

          <VueRecaptcha siteKey="6LeU3cYcAAAAAGtWKU_QBA7-KAOHZSEjrAQa9D3V" size="normal" theme="light" :tabindex="0" @verify="recaptchaVerified" 
            @expire="recaptchaExpired" @fail="recaptchaFailed" ref="refVueRecaptcha">
          </VueRecaptcha>

          <button @click="register()" class="button button--red button--register" :disabled="awaitRequest || !recaptchaValid">{{ awaitRequest ? $t("register.startDisabled") : $t("register.start") }}</button>
        
          <button class="button button--red-clean">
            <router-link to="/login">
              {{ $t("register.have-account") }}
            </router-link>
          </button>
        </template>
        <template v-else>
          <select v-model="model.residenceCountryId" :class="{'input-invalid': v$.model.residenceCountryId.$invalid && submitted}">
            <option disabled selected value="">{{ $t('register.step.second.input_nationality') }}</option>  
            <option v-for="n in listCountries" :key="n.id" :value="n.id">{{ n.name }}</option>
          </select>

          <button @click="register()" class="button button--red button--register" :disabled="awaitRequest">{{ awaitRequest ? $t("register.startDisabled") : $t("register.start") }}</button>
        
          <button class="button button--red-clean">
            <router-link to="/login">
              {{ $t("register.have-account") }}
            </router-link>
          </button>
        </template>
        
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, sameAs, email , helpers} from '@vuelidate/validators';
import VueRecaptcha from 'vue3-recaptcha2';
import persistentData from '@/plugins/persistentData';
import { useI18n } from 'vue-i18n';
import SocialRegister from '@/components/Common/SocialRegister.vue';

function validPassword(password) {
  let validPassword = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{6,32})$/); //eslint-disable-line
  if (validPassword.test(password)) return true;
  return false;
}

export default {
  components: {
    VueRecaptcha,
    SocialRegister
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const submitted = ref(false);
    let awaitRequest = ref(false);
    const v$ = useVuelidate();
    const listCountries = ref([]);
    const recaptchaValid = ref(false);
    const refVueRecaptcha = ref(null);
    const passwordType = ref('password');
    let showRegisterSocial = ref(false); 
    const userModelSocial = computed(() => store.getters.register_userModelSocial);
    const model = ref({
      firstName: '',
      lastName: '',
      email: '',
      residenceCountryId: '',
      password: '',
      passwordConfirm: '',
      registrationReference: 'IIVVO'
    });
    const { t } = useI18n();
    
    onMounted(async () => {
      store.dispatch('manageUser_disabled');
      listCountries.value = await store.dispatch('stuff_countries');
      const country = listCountries.value.find(c => c.countryCode.toLowerCase() == route.params.countryCode.toLowerCase())
      model.value.residenceCountryId = country ? Number(country.id) : '';
    });

    watch(userModelSocial, (userModel) => {
      model.value.firstName = userModel.firstName;
      model.value.lastName = userModel.lastName;
      model.value.email = userModel.email;
      if (model.value.residenceCountryId == '') {
        showRegisterSocial.value = true;
      } else {
        model.value.password = 'Ruk14pp';
        model.value.passwordConfirm = 'Ruk14pp';
        recaptchaValid.value = true;
        setTimeout(async () => await register())
      }
    });

    async function register() {
      submitted.value = true;
      awaitRequest.value = true;
      if (!v$.value.model.$invalid && recaptchaValid.value) {
        try {
          const request = await store.dispatch(showRegisterSocial.value ? 'security_userCreateSocialMediaExternal' : 'security_userCreateExternal', model.value);
          if (request.status == 200) {
            persistentData.set('token', request.data.token);
            persistentData.set('tokenExpiration', request.data.tokenExpiration);
            persistentData.set('registrationReference', request.data.registrationReference);
            persistentData.set('language', request.data.countryCode);
            store.dispatch('manageUser_active');
            submitted.value = false;
            awaitRequest.value = false;
            router.push('/gamesExternal/IIVVO/IIVVO');
          }
        } catch (error) {
          submitted.value = false;
          awaitRequest.value = false;
          store.dispatch('notifications_create', { text: t(`notifications.${error.response.data}`) });
        }
      } else {
        awaitRequest.value = false;
      }
    }

    function recaptchaVerified() {
      recaptchaValid.value = true;
    }
    function recaptchaExpired() {
      refVueRecaptcha.value.reset();
      recaptchaValid.value = false;
    }
    function recaptchaFailed() {
      recaptchaValid.value = false;
    }

    return {
      store,
      router,
      submitted,
      awaitRequest,
      v$,
      listCountries,
      model,
      register,
      refVueRecaptcha,
      recaptchaVerified,
      recaptchaExpired,
      recaptchaFailed,
      recaptchaValid,
      showRegisterSocial,
      passwordType,
    }
  },
  validations () {
    return {
      model: {
        firstName: {
          required: helpers.withMessage('Este campo es requerido', required)
        },
        lastName: { 
          required: helpers.withMessage('Este campo es requerido', required)
        },
        email: { 
          required: helpers.withMessage('Este campo es requerido', required),
          email: helpers.withMessage('El formato del mail es invalido', email)
         },
        residenceCountryId: { 
          required: helpers.withMessage('Este campo es requerido', required)
         },
        password: {
          required: helpers.withMessage('Este campo es requerido', required),
          validPassword: helpers.withMessage('Contraseña no valida', validPassword)
         },
        passwordConfirm: {
          required: helpers.withMessage('Este campo es requerido', required),
          sameAsPassword: helpers.withMessage('Las contraseñas no coinciden', sameAs(this.model.password))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  img {
    position: absolute;
    bottom: -90px;
    left: -40px;
    transform: rotate(20deg);
    width: 184px;

    @media (min-height: 736px) {
      bottom: -50px;
    }
  }

  small {
    max-width: 300px;
    margin-top: 0;
    margin-bottom: $margin-kilo;
    color: $white;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  }

.password_container{
    margin-bottom: 16px;
    position: relative;
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 2px solid #fff;
    .material-icons{
      position: absolute;
      top: 50%;
      right: 0;
      padding-right: 8px;
      transform: translateY(-50%);
      color: #fff;
      font-size: 28px;
      cursor: pointer;
    }
    input{
      margin: 0;
      flex-grow: 1;
      border: none;
    }
  }
  input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus{
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff;
    font-size: 16px !important;
  }
</style>