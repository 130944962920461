import interceptor from '@/plugins/interceptor';
const pathController = '/Stuff';

export default {
  state: () => ({
    gender: null,
    countries: null,
    cities: null,
    states: null,
    interests: null,
    educationalInstitution: null,
    educationSpecialty: {
      1: null,
      2: null
    },
    languageKnowledges: null,
    jobSchedules: null,
    jobPositions: null,
    organization: null,
    employmentContractTypes: null,
    nationalities: null,
    languages: null,
    learningCategories: null,
    skills: null
  }),
  mutations: {
    set_gender(state, value) {
      state.gender = value;
    },
    set_countries(state, value) {
      state.countries = value;
    },
    set_cities(state, value) {
      state.cities = value;
    },
    set_states(state, value) {
      state.states = value;
    },
    set_interests(state, value) {
      state.interests = value;
    },
    set_skills(state, value) {
      state.skills = value;
    },
    set_educationalInstitution(state, value) {
      state.educationalInstitution = value;
    },
    set_educationSpecialty(state, model) {
      state.educationSpecialty[model.type] = model.value;
    },
    set_languageKnowledges(state, value) {
      state.languageKnowledges = value;
    },
    set_jobSchedules(state, value) {
      state.jobSchedules = value;
    },
    set_jobPositions(state, value) {
      state.jobPositions = value;
    },
    set_organization(state, value) {
      state.organization = value;
    },
    set_employmentContractTypes(state, value) {
      state.employmentContractTypes = value;
    },
    set_nationalities(state, value) {
      state.nationalities = value;
    },
    set_languages(state, value) {
      state.languages = value;
    },
    set_learningCategories(state, value) {
      state.learningCategories = value;
    }
  },
  getters: {
    get_gender: (state) => (id) => {
      const gender = state.gender.find(g => g.id == id);
      return gender ? gender.name : '-';
    },
    get_country: (state) => (id) => {
      const country = state.countries.find(n => n.id == id);
      return country ? country.name : '-';
    },
    get_city: (state) => (id) => {
      const city = state.cities.find(p => p.id == id);
      return city ? city.name : '-';
    },
    get_states: (state) => (id) => {
      const states = state.states.find(p => p.id == id);
      return states ? states.name : '-';
    },
    get_jobPositions: (state) => (id) => {
      const position = state.jobPositions.find(p => p.id == id);
      return position ? position.name : '-';
    },
    get_organization: (state) => (id) => {
      const organization = state.organization.find(o => o.id == id);
      return organization ? organization.name : '-';
    },
    get_employmentContractTypes: (state) => (id) => {
      const contract = state.employmentContractTypes.find(c => c.id == id);
      return contract ? contract.name : '-';
    },
    get_languageKnowledges: (state) => (id) => {
      const language = state.languageKnowledges.find(c => c.id == id);
      return language ? language.name : '-';
    },
    get_educationalInstitution: (state) => (id) => {
      const institution = state.educationalInstitution.find(c => c.id == id);
      return institution ? institution.name : '-';
    },
    get_nationalities: (state) => (id) => {
      const nationality = state.nationalities.find(c => c.id == id);
      return nationality ? nationality.name : '-';
    },
    get_languages: (state) => (id) => {
      const language = state.languages.find(l => l.id == id);
      return language ? language.name : '-';
    },
    get_learningCategories: (state) => (id) => {
      const category = state.learningCategories.find(c => c.id == id);
      return category ? category.name : '-';
    }
  },
  actions: {
    async stuff_gender({state, commit}) {
      if (!state.gender) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/gender`);
        commit('set_gender', request.status === 200 ? request.data : []);
      }
      
      return state.gender;
    },
    async stuff_countries({state, commit}) {
      if (!state.countries) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/countries`);
        commit('set_countries', request.status === 200 ? request.data : []);
      }
      
      return state.countries;
    },
    async stuff_cities({state, commit}, stateId) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/states/${stateId}/cities`);
      commit('set_cities', request.status === 200 ? request.data : []);
      return state.cities;
    },
    async stuff_states({state, commit}) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/states`);
      commit('set_states', request.status === 200 ? request.data : []);
      
      return state.states;
    },
    async stuff_interests({state, commit}) {
      if (!state.interests) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/interests`);
        commit('set_interests', request.status === 200 ? [{id:-1,name:'Otro'}, ...request.data] : []);
      }
      
      return state.interests;
    },
    async stuff_skills({state, commit}) {
      if (!state.skills) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/skills`);
        commit('set_skills', request.status === 200 ? [{id:-1,name:'Otro'}, ...request.data] : []);
      }
      
      return state.skills;
    },
    async stuff_educationalInstitution({state, commit}) {
      if (!state.educationalInstitution) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/educationalInstitution`);
        commit('set_educationalInstitution', request.status === 200 ? request.data : []);
      }
      
      return state.educationalInstitution;
    },
    async stuff_educationSpecialty({state, commit}, type) {
      if (!state.educationSpecialty[type]) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/educationSpecialty?type=${type}`);
        commit('set_educationSpecialty', {type, value: request.status === 200 ? request.data : []});
      }
      
      return state.educationSpecialty[type];
    },
    async stuff_languageKnowledges({state, commit}) {
      if (!state.languageKnowledges) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/languageKnowledges`);
        commit('set_languageKnowledges', request.status === 200 ? request.data : []);
      }
      
      return state.languageKnowledges;
    },
    async stuff_jobSchedules({state, commit}) {
      if (!state.jobSchedules) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/jobSchedules`);
        commit('set_jobSchedules', request.status === 200 ? request.data : []);
      }
      
      return state.jobSchedules;
    },
    async stuff_jobPositions({state, commit}) {
      if (!state.jobPositions) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/jobPositions`);
        let data = [{id:-1,name:'Otro'}];
        if(request.status == 200) data = data.concat(request.data)
        commit('set_jobPositions', data);
      }
      
      return state.jobPositions;
    },
    async stuff_organization({state, commit}) {
      if (!state.organization) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/organization`);
        let data = [{id:-1,name:'Otro'}];
        if(request.status === 200) data = data.concat(request.data)
        commit('set_organization', data);
      }
      
      return state.organization;
    },
    async stuff_employmentContractTypes({state, commit}) {
      if (!state.employmentContractTypes) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/employmentContractTypes`);
        commit('set_employmentContractTypes', request.status === 200 ? request.data : []);
      }
      
      return state.employmentContractTypes;
    },
    async stuff_nationalities({state, commit}) {
      if (!state.nationalities) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/nationalities`);
        commit('set_nationalities', request.status === 200 ? request.data : []);
      }
      
      return state.nationalities;
    },
    async stuff_countries_state(_, countryId) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/countries/${countryId}/states`);
      if(request.status === 200) {
        return request.data;
      }
      return [];
    },
    async stuff_languages({state, commit}) {
      if (!state.languages) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/languages`);
        commit('set_languages', request.status === 200 ? request.data : []);
      }
      return state.languages;
    },
    async stuff_learningCategories({state, commit}) {
      if (!state.learningCategories) {
        const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/learningCategories`);
        commit('set_learningCategories', request.status === 200 ? request.data : []);
      }
      return state.learningCategories;
    },
    async stuff_loginOptionCheckIfExists(_, model) {
      return await interceptor.authenticate.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/loginOptions/${model.code}`);
    }
  }
}