<template>
  <VisibleModal />
  <div class="modal__overlay" @click.stop="close()">
    <div class="modal modal-share" @click.stop="''">
      <span class="material-icons" @click.stop="close()">close</span>
      <h3>Compartir</h3>
      <div class="modal-share__card">
        <span class="modal-share__card__title">{{job.title}}</span>
        <span class="modal-share__card__subtitle">{{job.organizationName}}</span>

        <p>{{job.location}}</p>
        <p>{{job.schedule}} ({{job.description.contractType}})</p>
      </div>
      <div class="modal-share__content">
         <!-- Facebook -->
        <a :href="`http://www.facebook.com/sharer.php?u=${location}`" target="_blank" style="background-color: #4267B2;">
            <img src="https://platform-cdn.sharethis.com/img/facebook.svg" alt="Facebook" />
        </a>
        <!-- LinkedIn -->
        <a :href="`http://www.linkedin.com/shareArticle?mini=true&amp;url=${location}`" target="_blank" style="background-color: #0e76a8;">
            <img src="https://platform-cdn.sharethis.com/img/linkedin.svg" alt="LinkedIn" />
        </a>
        <!-- Twitter -->
        <a :href="`https://twitter.com/share?url=${location}&amp;text=${job.title}&amp;hashtags=ruki`" target="_blank" style="background-color: #1DA1F2;">
            <img src="https://platform-cdn.sharethis.com/img/twitter.svg" alt="Twitter" />
        </a>
        <!-- Whatsapp -->
        <a v-if="mobile" :href="`whatsapp://send?text=${location} | ${job.title} - ${job.organizationName}`" data-action="share/whatsapp/share" target="_blank" style="background-color: #25D366;">
            <img src="https://platform-cdn.sharethis.com/img/whatsapp.svg" alt="Whatsapp" />
        </a>
        <div class="share-link" @click="copyURL()">
          <i class="material-icons" >link</i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VisibleModal from '@/components/Common/VisibleModal.vue';
import { useStore } from 'vuex';

export default {
  components: {
    VisibleModal
  },
  props: {
    job: Object
  },
  emits: ["closeModalShare"],
  setup(_, {emit}) {
    function close() {
      emit('closeModalShare');
    }
    const store = useStore();
    let location = window.location.href;
    let mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const copyURL = () => {
      const el = document.createElement('textarea');
      el.value = location;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      store.dispatch('notifications_create', { text: 'Link copiado' });
    }

    return {
      close,
      location,
      copyURL,
      mobile
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-share {
    &__content {
      display: flex;
      justify-content: space-around;
      margin-bottom: $margin-basedo;

      a {
        border-radius: 4px;
        width: 48px;
        height: 48px;

        img {
          padding: $margin-milli;
          width: 48px;
          height: 48px;
        }
      }
    }
    &__card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: $margin-base;
      border: 1px solid #BDBDBD;
      box-sizing: border-box;
      border-radius: 20px;
      margin-bottom: $margin-mega;

      &__title {
        color: $gray;
        @include font-settings(mega, base);
        font-weight: 700;
        text-align: left;
      }

      &__subtitle {
        color: #087B9A;
        font-weight: 700;
        margin-bottom: $margin-base;
      }

      p {
        margin: 0;
        text-align: left;
        @include font-settings(milli,base);
        padding: 0;
      }
    }
    .share-link{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #263554;
      width: 48px;
      height: 48px;
      border-radius: 4px;
      cursor: pointer;
      i.material-icons{
        position: static;
        font-size: 32px;
        color: #fff;
        font-weight: 400;
      }
    }
  }
</style>