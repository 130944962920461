import interceptor from '@/plugins/interceptor';
const pathController = '/Game'

export default {
  state: () => ({
  }),
  getters: {
  },
  mutations: {
  },
  actions: {
    async game_gameLink(_, gameCode) {
      const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/gameLink?gameCode=${gameCode}`); 
      if (request.status === 200) return request.data;
    },
    async game_tripOrIivvoResultByGameIdentification(_, params) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/gameLink/${params.gameIdentification}?gameCode=${params.gameCode}`); 
      return request;
    },
    async game_genomaResultByGameIdentification(_, params) {
      const request = await interceptor.anonymous.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/results/genomaAll/${params.gameIdentification}`); 
      return request.data;
    },
    async game_genomaResults(){
      const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/results/genomaAll`);
      if (request.status == 200) {
        return request.data;
      }
      else {
        throw request.statusText;
      }
    },
    async game_getLinkToShare(_,params){
      const request = await interceptor.authenticate.get(`${process.env.VUE_APP_API_SITE_URL}${pathController}/getLinkToShare?gameCode=${params.gameCode}`);
      return request;
    }
  }
}