<template>
  <VisibleModal />
  <div class="modal__overlay" @click.stop="close()" v-if="finishLoad">
    <div class="modal modal-bot" @click.stop="''">
      <span class="material-icons" @click.stop="close()">close</span>
      <template v-if="finishTrip">
        <img src="@/assets/ruky-apply.svg" alt="">
        <h3>¿Te gustaría tener este trabajo? ¿Confirmas tu postulación?</h3>
        <div class="modal__buttons">
          <button @click.stop="close()" class="button--gray">Cancelar</button>
          <button @click.stop="apply(job.id)">Postularme</button>
        </div>
      </template>
      <!-- Modal finish Trip -->
      <div v-else class="finish-trip">
        <img src="@/assets/ruky-alert.svg" alt="">
        <h4>Para poder postularte a empleos, primero tienes que completar el test de personalidades Trip</h4>
        <div class="modal__buttons">
          <button @click="goToTrip()">Ir a trip</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import VisibleModal from '@/components/Common/VisibleModal.vue';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  components: {
    VisibleModal
  },
  props: {
    job: Object
  },
  emits: ["closeModalApply"],
  setup(props, {emit}) {
    const store = useStore();
    const { t } = useI18n();
    const finishTrip = ref(false);
    const router = useRouter();
    const finishLoad = ref(false);

    function close() {
      emit('closeModalApply');
    }

    async function apply (id) {
      try {
        const request = await store.dispatch('organization_jobSearch_apply', id);
        if (request.status === 200) {
          emit('closeModalApply');
          window.location.reload();
          await store.dispatch('bot_analizeResponse', {view: '', action: 'JOB_APPLY'});
        }
      } catch (error) {
          store.dispatch('notifications_create', { text: t(`notifications.errorGeneric`) });
      }
    }

    function goToTrip(){
      router.push(`/gamesExternal/TRIP`);
    }

    onMounted(async ()=>{
      try {
        const request = await store.dispatch('organization_finishTrip');
        finishTrip.value = request.data;
        finishLoad.value = true;
      } catch (error) {
        store.dispatch('notifications_create', { text: t(`notifications.errorGeneric`) });
      }
    })

    return {
      close,
      apply,
      finishTrip,
      goToTrip,
      finishLoad
    }
  }
}
</script>
<style lang="scss" scoped>

  .modal-bot {
    h3 {
      color: $gray;
      @include font-settings(mega, base);
      padding: $margin-base;
      margin-bottom: 0;
    }

    img {
      margin-bottom: 0;
      width: 110px;
    }

    p {
      margin: 0;
      text-align: left;
      @include font-settings(milli,base);
      padding: 0 $margin-base;
    }

    .modal__buttons {
      margin-top: 0;
    }

    &__card {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: flex-start;
      padding: $margin-base;
      border: 1px solid #BDBDBD;
      box-sizing: border-box;
      border-radius: 20px;
      margin-bottom: $margin-base;

      &__title {
        color: $gray;
        @include font-settings(mega, base);
        font-weight: 700;
        text-align: left;
      }

      &__subtitle {
        color: #087B9A;
        font-weight: 700;
        margin-bottom: $margin-base;
      }

      p {
        margin: 0;
        text-align: left;
        @include font-settings(milli,base);
        padding: 0;
      }
    }
  }
  .finish-trip{
    h4{
      margin-top: 8px;
      color: $gray;
    }
  }
</style>