<template>
  <div class="work work__detail" v-if="Object.keys(jobDetail).length > 0">
    <div class="container main-container">
      <div v-if="levelProfile < 5" class="available-level-5" style="margin-bottom: 24px">
        <i class="material-icons">error</i>
        <span>Podrás postularte a este y a cualquiera de los trabajos a partir del <b>NIVEL 5</b></span>
      </div>
      <article class="card-detail">
        <div class="card-detail__more" @click="activeDetailMore = !activeDetailMore">
          <i class="material-icons">more_vert</i>
        </div>
        <template v-if="activeDetailMore">
          <div class="card-detail__modal">
            <a @click="toggleWishlist(jobDetail.wish, jobDetail.id)" v-if="!jobDetail.wish" ><i class="material-icons">star</i> {{$t("work.detailJob.actionsModal.save")}}</a>
            <a @click="toggleWishlist(jobDetail.wish, jobDetail.id)" v-else class="active"><i class="material-icons">star</i> {{$t("work.detailJob.actionsModal.discard")}}</a>
            <a @click="toggleModalShare()"><i class="material-icons">share</i> {{$t("work.detailJob.actionsModal.share")}}</a>
          </div>
          <div class="card-detail__modal-overlay" @click="activeDetailMore = false"></div> 
        </template>
        <div class="card-detail__container">
          <div class="card-detail__header">
            <div class="card-detail__img" :style="`background-image: url('${getImage(jobDetail.organizationImage, jobDetail.isPictureFromCDN)}')`" alt=""></div>
            <div>
              <h3>{{jobDetail.title}}</h3>
              <h4>{{jobDetail.organizationName}}</h4>
              <span>{{getDiffDates(jobDetail.publishDate)}}</span>
              <div class="card-detail__header__details">
                <span>{{jobDetail.address}}, {{jobDetail.location}}</span>
                <span>{{jobDetail.schedule}}</span> 
              </div>
            </div>
          </div>

          <div class="card-detail__stages" v-if="jobDetail.applyStatus">
            <div class="card-detail__stages__content">
              <div class="card-detail__stages__container" :class="{'active': getIndexStatus(jobDetail.applyStatus) == 1}">
                <div class="card-detail__stages__stage">1</div>
                {{ $t(`work.status.Ok`) }}
              </div>
              <div class="card-detail__stages__container" :class="{'active': getIndexStatus(jobDetail.applyStatus) == 2}">
                <div class="card-detail__stages__stage">2</div>
                {{ $t(`work.status.Viewed`) }}
              </div>
              <div class="card-detail__stages__container" :class="{'active': getIndexStatus(jobDetail.applyStatus) == 3}">
                <div class="card-detail__stages__stage">3</div>
                {{ $t(`work.status.ContactDataRequired`) }}
              </div>
              <div class="card-detail__stages__container" :class="{'active': getIndexStatus(jobDetail.applyStatus) == 4}">
                <div class="card-detail__stages__stage">4</div>
                {{ $t(`work.status.Hired`) }}
              </div>
            </div>
            <h4>{{$t("work.detailJob.state")}} {{getIndexStatus(jobDetail.applyStatus)}}: <b>{{ $t(`work.status.${jobDetail.applyStatus}`)}}</b></h4>
          </div>

          <div class="card-detail__module">
            <div class="card-detail__separator">
              <div class="card-detail__separator__title">{{$t("work.detailJob.descriptionJob")}}</div>
            </div>
            <p v-html="jobDetail.description.text"></p>
            <h4>{{$t("work.detailJob.contractType")}}: <b>{{jobDetail.description.contractType}}</b></h4>
            <h4 v-if="jobDetail.jobSeniority !== ''">{{$t("work.detailJob.seniority")}}: <b>{{jobDetail.jobSeniority}}</b></h4>
            <h4>{{$t("work.detailJob.modality")}}: 
              <b v-if="jobDetail.workPlace == 0">Presencial</b>
              <b v-if="jobDetail.workPlace == 1">Remoto</b>
              <b v-if="jobDetail.workPlace == 2">Híbrido</b>
            </h4>
            <h4 v-if="jobDetail.salaryAround > 0">{{$t("work.detailJob.salaryAround")}}: <b>{{jobDetail.salaryAround}} {{ jobDetail.currency.alias }} ({{jobDetail.currency.code}})</b></h4>
            <h4 v-if="jobDetail.minimumEducationLevel">{{$t("work.detailJob.educationLevel")}}: <b>{{$t(`work.detailJob.educationLevelLabel.${jobDetail.minimumEducationLevel}`)}}</b></h4>
            <h4 v-if="jobDetail.gender">{{$t("work.detailJob.gender")}}: <b>{{jobDetail.gender.name}}</b></h4>
          </div>

          <div class="card-detail__module">
            <div class="card-detail__separator">
              <div class="card-detail__separator__title">{{$t("work.detailJob.required")}}</div>
            </div>
            <h5>Buscamos perfiles:</h5>
            <ul class="card-detail__module__list">
              <li v-for="skill in jobDetail.exclusiveSkills" :key="skill">{{skill}}</li>
            </ul>
            <h5>Habilidades:</h5>
            <ul class="card-detail__module__list">
              <li v-for="skill in jobDetail.optionalSkills" :key="skill">{{skill}}</li>
            </ul>
          </div>

          <div class="card-detail__module">
            <div class="card-detail__separator">
              <div class="card-detail__separator__title">{{$t("work.detailJob.beneffits")}}</div>
            </div>
            <ul>
              <li v-for="(beneffits, idx) in jobDetail.beneffits" :key="idx">{{beneffits}}</li>
            </ul>
            <p v-html="jobDetail.otherBennefits"></p>
          </div>
          <div class="card-detail__module" v-if="jobDetail.organizationAbout || jobDetail.organizationSite">
            <div class="card-detail__separator">
              <div class="card-detail__separator__title">{{$t("work.detailJob.aboutJob")}}</div>
            </div>
            <p v-if="jobDetail.organizationAbout" v-html="jobDetail.organizationAbout"></p>
            <a v-if="jobDetail.organizationSite" :href="jobDetail.organizationSite" target="_blank">{{$t("work.detailJob.goSite")}}</a>
          </div>
        </div>
        <div class="card-detail__button" v-if="levelProfile >= 5">
          <button v-if="jobDetail.applyStatus == null" class="button button--blue button--register" @click="toggleModalApply()">{{$t("work.detailJob.apply")}}</button>
          <button v-if="jobDetail.applyStatus == 'Ok'" class="button button--blue button--register" style="filter: grayscale(100%); cursor:initial">{{$t("work.detailJob.applied")}}</button>
          <button v-if="jobDetail.applyStatus == 'Viewed'" class="button button--blue button--register" style="filter: grayscale(100%); cursor:initial">{{$t("work.status.Viewed")}}</button>
          <button v-if="jobDetail.applyStatus == 'ContactDataRequired' || jobDetail.applyStatus == 'NotInterviewed'" class="button button--blue button--register" style="filter: grayscale(100%); cursor:initial">{{$t("work.status.ContactDataRequired")}}</button>
          <button v-if="jobDetail.applyStatus == 'Hired'" class="button button--blue button--register" style="filter: grayscale(100%); cursor:initial">{{$t("work.status.Hired")}}</button>
          <button v-if="jobDetail.applyStatus == 'NotHired'" class="button button--blue button--register" style="filter: grayscale(100%); cursor:initial">{{$t("work.status.NotHired")}}</button>
        </div>
      </article>
    </div>
  </div>
  <ModalApply :job="jobDetail" v-if="modalApplyActive" @closeModalApply="toggleModalApply()"/>
  <ModalShare :job="jobDetail" v-if="modalShareActive" @closeModalShare="toggleModalShare()"/>


</template>
<script>
import { onMounted, ref, computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
import moment from 'moment';
import { useRoute } from 'vue-router';
import ModalApply from '@/components/Work/ModalApply';
import ModalShare from '@/components/Work/ModalShare';
import { useI18n } from 'vue-i18n';
import persistentData from '@/plugins/persistentData';


export default {
  components: {
    ModalApply,
    ModalShare
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const levelProfile = persistentData.get("forgeLevel", true);
    let jobDetail = ref({});
    let modalApplyActive = ref(false);
    let modalShareActive = ref(false);
    let activeDetailMore = ref(false);
    const jobsWishlist = computed(() => store.getters.profile_jobsWishlist);
    const modalFinishTrip = ref(false);

    onMounted(async () => {
      jobDetail.value = await store.dispatch('organization_jobSearch', route.params.id);

    });

    function getDiffDates(publishDate) {
      const diff = moment(moment()).diff(publishDate, 'days');
      let label = '';
      switch (diff) {
        case 0:
          label = t('work.diffDates.today');
          break;
        case 1:
          label = t('work.diffDates.yesterday');
          break;
        default:
          label = t('work.diffDates.days', {diff});
          break;
      }
      return label;
    }

    async function toggleWishlist(active, id) {
      activeDetailMore.value = false;
      const force = await store.dispatch('manageUser_forceLogin')
      if (force) return;
      if (jobsWishlist.value.includes(id)) return;

      store.commit('profile_addJobWishlist', id);

      if (active) {
        await store.dispatch('organization_wishes_remove', id);
        store.dispatch('notifications_create', { text: t(`notifications.wishes_remove`) });
      } else {
        await store.dispatch('organization_wishes_add', id);
        store.dispatch('notifications_create', { text: t(`notifications.wishes_add`) });
      }
      jobDetail.value.wish = !jobDetail.value.wish;
    }

    async function toggleModalApply () { 
      const force = await store.dispatch('manageUser_forceLogin');
      if (force) return;
      modalApplyActive.value = !modalApplyActive.value;
    }

    function toggleModalShare () {
      if (activeDetailMore.value) activeDetailMore.value = false;
      modalShareActive.value = !modalShareActive.value; 
    }

    function getImage(path, isFromCDN) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      } else {
        return path ? `${process.env.VUE_APP_FORGE_ORGANIZATION_MEDIA_URL}${path}` : null;
      }
    }

    function getIndexStatus(status) {
      let indexStatus;
      switch (status) {
        case 'Ok':
          indexStatus = 1;
          break;
        case 'Viewed':
          indexStatus = 2;
          break;
        case 'NotInterviewed':
        case 'ContactDataRequired':
          indexStatus = 3;
          break;
        case 'Hired':
        case 'NotHired':
          indexStatus = 4;
          break;
      }
      return indexStatus;
    }

    return {
      levelProfile,
      jobDetail,
      getDiffDates,
      activeDetailMore,
      toggleWishlist,
      toggleModalApply,
      modalApplyActive,
      modalShareActive,
      toggleModalShare,
      getIndexStatus,
      getImage,
      modalFinishTrip
    }
  }
}
</script>
<style lang="scss" scoped>

  .work__detail {
    padding: $margin-base $margin-base 96px;

    @media (min-width: $break-sm) {
      padding: $margin-mega 0 96px;
    }
  }

  .card-detail {
    background: $blue;
    border-radius: $margin-basedo;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 96px;

    @media (min-width: $break-md) {
      margin-top: 0;
    }

    &__container {
      padding: $margin-kilo;
      width: 100%;
    }

    &__more {
      position: absolute;
      top: $margin-base;
      right: $margin-base;
      color: $white;
      font-size: 32px;
      cursor: pointer;
    }

    &__modal {
      background: $white;
      border-radius: $margin-milli;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: $margin-base $margin-mega $margin-base $margin-base;
      position: absolute;
      top: $margin-base;
      right: $margin-base;
      display: flex;
      flex-direction: column;
      gap: $margin-milli;
      z-index: 2;

      a {
        display: flex;
        align-items: center;
        color: $graybg;
        gap: $margin-milli;
        font-family: $font-palanquin;
        font-weight: 400;
        text-transform: capitalize;
        cursor: pointer;

        &.active {
          color: $yellow;
        }
      }

      &-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }

    &__img {
      width: 148px;
      height: 148px;
      background: $white;
      border-radius: 100%;
      margin: -96px auto $margin-base;
      background-size: cover;
      background-position: center center;

      @media (min-width: $break-md) {
        margin: 0;
        flex-shrink: 0;
      }
    }

    &__button {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -24px;

      button {
        margin: 0;
      }
    }

    &__header {
      text-align: center;

      @media (min-width: $break-md) {
        display: flex;
        text-align: left;
        gap: $margin-kilo;
        align-items: center;
      }

      h3 {
        font-family: $font-opensans;
        margin: $margin-milli 0 0;
      }
      
      h4 {
        font-family: $font-opensans;
        color: $lightblue;
        font-weight: 700;
      }

      &__details {
        margin-top: $margin-base;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: $margin-base;

        @media (min-width: $break-md) {
          justify-content: space-between;
        }
      }
    }

    &__module {
      margin-bottom: $margin-giga;

      p {
        font-family: $font-opensans;
        @include font-settings(base, base);
        color: $white;
        text-shadow: none;
        text-align: left;
        padding-bottom: $margin-base;
        white-space: pre-line;
      }

      a {
        font-family: $font-palanquin;
        text-align: center;
        @include font-settings(milli,base);
        color: $lightblue;
        text-transform: uppercase;
        font-weight: 700;
      }

      ul {
        padding-left: $margin-kilo;
        color: $white;
        padding-bottom: $margin-base;

        li {
          list-style: disc;
        }
      }

      h4 {
        color: $white;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0px;
        }

        b {
          color: $lightblue;
        }
      }

      h5 {
        font-family: $font-palanquin;
        text-align: left;
        @include font-settings(base,base);
        color: $lightgray;
        text-transform: uppercase;
        margin-bottom: $margin-base;
        font-weight: 400;
      }

      &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        margin-bottom: $margin-kilo;

        @media (min-width: $break-md) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        &--one-column {
          grid-template-columns: 1fr;
          gap: $margin-base;

          @media (min-width: $break-md) {
            grid-template-columns: 1fr 1fr;
          }
        }

        li {
          list-style: none!important;
          position: relative;

          &:before {
            content: '';
            background: url('~@/assets/check-circle.svg') no-repeat;
            position: absolute;
            width: $margin-base;
            height: $margin-base;
            left: -24px;
            top: 4px;
          }

          button {
            background: $orange;
            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            color: $white;
            font-family: $font-palanquin;
            @include font-settings(base, base);
            padding: 4px $margin-base;
            margin-left: $margin-mega;
          }
        }
      }
    }
    
    .card-detail__separator {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: $margin-mega;

      &:before {
        content: '';
        border-top: 2px solid $lightblue;
        position: absolute;
        top: 9px;
        width: 100%;
      }

      &__title {
        font-family: $font-palanquin;
        text-align: center;
        @include font-settings(base,base);
        color: $lightblue;
        text-transform: uppercase;
        margin-bottom: $margin-base;
        background: $blue;
        position: relative;
        width: fit-content;
        padding: 0 $margin-milli;
      } 
    }

    &__stages {
      background: rgba(0, 0, 0, 0.25);
      margin: $margin-basedo (-$margin-kilo) 0;
      padding: $margin-kilo;

      &__content {
        display: flex;
        // flex-direction: row;
        gap: $margin-base;
        margin-bottom: $margin-kilo;
        align-items: center;
        flex-wrap: wrap;
      }

      &__container {
        display: flex;
        color: #BDBDBD;

        &.active {
          color: $lightblue;
        }
        &.active .card-detail__stages__stage {
          background: $lightblue;
        }
      }

      &__stage {
        flex-shrink: 0;
        width: $margin-kilo;
        height: $margin-kilo;
        background: #BDBDBD;
        transform: skew(2deg, 2deg);
        text-align: center;
        margin-right: $margin-milli;
        color: #1c283f;

        &:nth-child(odd) {
          transform: skew(-2deg, -2deg);
        }
      }

      h4 {
        color: $lightblue;

        b {
          color: $white;
        }
      }
    }
  }
</style>