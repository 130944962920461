import interceptor from '@/plugins/interceptor';
const pathController = '/Security'

export default {
  state: () => ({
    
  }),
  mutations: { 
  },
  actions: {
    async security_authenticate(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/authenticate`, model);
    },
    async security_refreshToken(_, token) {
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/refreshToken`, token);
    },
    async security_userCreate(state) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/userCreate`, state.getters.register_userModel);
    },
    async security_userActivate(state, activationCode) {
      const model = {
        email: state.getters.register_userModel.email,
        activationCode
      }
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/userActivate`, model);
    },
    async security_userActivateModel(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/userActivate`, model);
    },
    async security_authenticateFacebook(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/authenticateFacebook`, model);
    },
    async security_authenticateGoogle(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/authenticateGoogle`, model);
    },
    async security_userCreateSocialMedia(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/userCreateSocialMedia`, model);
    },
    async security_userCreateSocialMediaExternal(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/userCreateSocialMediaExternal`, model);
    },
    async security_userCreateExternal(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/userCreateExternal`, model);
    },
    async security_emailIsAvailable(_, email) {
      return await interceptor.anonymous.get(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/emailIsAvailable?email=${email}`);
    },
    async security_beginPasswordRecovery(_, email) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/beginPasswordRecovery`, {email});
    },
    async security_endPasswordRecovery(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/endPasswordRecovery`, model);
    },
    async security_authenticateCampus(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/campus/authenticate`, model);
    }
  }
}