<template>
    <div>
        <div v-if="personName != null" class="person-name">Resultados de {{ personName }}</div>
        <iframe v-if="gameInfo != null" :src="gameInfo" frameborder="0" allow="fullscreen;camera *;microphone *" sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation"></iframe>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

export default {
  components: {
  },

  setup() {
    const gameInfo = ref(null);
    const personName = ref(null);
    const store = useStore();
    const route = useRoute();
    const tripResult = route.name == 'GAMES_SHARE_TRIP';
    const genomaResult = route.name == 'GAMES_SHARE_GENOMA';
    const iivvoResult = route.name == 'GAMES_SHARE_IIVVO';
    const gameIdentification = ref(route.params.gameIdentification);

    function calculateViewport() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    
    onMounted( async () => {
        if (tripResult || iivvoResult) {
            let req = await store.dispatch('game_tripOrIivvoResultByGameIdentification', {gameCode: tripResult ? "TRIP" : "IIVVO", gameIdentification: gameIdentification.value});
            gameInfo.value = req.data.result;
            personName.value = req.data.name;
        }
        else if(genomaResult){
            gameInfo.value = `/gamesExternal/shareGenoma/${gameIdentification.value}`;
        }
        calculateViewport();
        window.addEventListener('resize', calculateViewport);
    });

    return {
        gameInfo,
        personName
    }
  }
}
</script>

<style lang="scss" scoped>
iframe {
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 75px);
    @media (orientation:landscape)  {
        height: calc(100vh - 75px);
    }
    display: block;
    &.genoma-result-loading {
        display: none;
    }
}

.person-name {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: $gray;
    font-size: 22px;
}
</style>